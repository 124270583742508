import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/Button';
import { capitalizeFirstLetter, changeDateformat } from 'src/utils/global-functions';
import CommissionPayout from './staffDetail.CommissionPayout';
import Rent from './staffDetail.Rent';
import { FaRegEdit } from 'react-icons/fa';
import { SlArrowDown } from 'react-icons/sl';
import PayStructure from './StaffDetail.PayStructure';
import { useAppSelector } from 'src/redux/hooks';
import { selectUserInfo } from 'src/app/Auth/Login/Login.slice';
import Popup from 'src/components/Popup';
import { GoDownload } from 'react-icons/go';
import { IoMdClose } from 'react-icons/io';

import Card from 'src/components/Card';
import FileInputWithBorder from 'src/components/FileInputWithBorder';
import { useTranslation } from 'react-i18next';
import { PDF } from 'src/theme/Images';
const Compensation = ({ staffInfo }: { staffInfo: any }) => {
    const { t } = useTranslation();
    const [fileName, setFileName] = useState<string>('');
    const user = useAppSelector(selectUserInfo);

    const shopPayment = {
        status: 'pending',
        commission_payment_day: user.shops[0].shop_payment && user.shops[0].shop_payment.commission_day ? user.shops[0].shop_payment.commission_day : '',
        commission_frequency: user.shops[0].shop_payment && user.shops[0].shop_payment.commission_frequency ? user.shops[0].shop_payment.commission_frequency : '',
        rent_payment_day: user.shops[0].shop_payment && user.shops[0].shop_payment.rent_day ? user.shops[0].shop_payment.rent_day : '',
        rent_frequency: user.shops[0].shop_payment && user.shops[0].shop_payment.rent_frequency ? user.shops[0].shop_payment.rent_frequency : '',
    };
    const payStructure = staffInfo?.pay_structure ?? {};
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [saveDetails, setSaveDetails] = useState(false);
    const [isLoading] = useState<boolean>(false);
    const [isChangePayStructure, setIsChangePayStructure] = useState<boolean>(false);

    const {
        handleSubmit,
        formState: { errors },
        control,
        watch,
    } = useForm<any>({
        defaultValues: {
            pay_structure_type: payStructure.pay_structure_type,
            commission: payStructure.commission,
            auto_rent_payment: false,
            rent_money: payStructure.rent_money,
            effective_date: payStructure.effective_date ? changeDateformat(payStructure.effective_date, 'yyyy-MM-DD', 'DD/MM/YYYY') : null,
            start_date: payStructure.start_date ? changeDateformat(payStructure.start_date, 'yyyy-MM-DD', 'DD/MM/YYYY') : new Date(),
            payout_frequency: payStructure.payout_frequency ?? '',
            payment_day: payStructure.payment_day ?? '',
        },
    });

    const payStructureType = watch('pay_structure_type');
    const onSubmit = (data: any) => {};
    const ChangePayStructureContent = (
        <form onSubmit={handleSubmit(onSubmit)}>
            <PayStructure
                control={control}
                watch={watch}
                errors={errors}
                shop_payment={shopPayment}
                staffInfo={staffInfo}
                setIsChangePayStructure={setIsChangePayStructure}
                setSaveDetails={setSaveDetails}
            />
        </form>
    );

    const saveContent = (
        <div className="text-center">
            <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.2px]">{t('Save Changes')}</h3>

            <h3 className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.2px]">
                {payStructureType !== payStructure.pay_structure_type && payStructureType === 'commission'
                    ? "Are you sure you wish to change Anette's pay structure from commission to commission effective on DATE?"
                    : "Are you sure you wish to change Anette's pay structure from rent to commission effective on DATE?"}
            </h3>
            <div className="w-full">
                <Button className="fl-btn btn_primary px-[10px] w-full mb-3" onClick={handleSubmit(onSubmit)}>
                    {t('Yes I am sure')}
                </Button>
                <Button className="fl-btn px-[10px] btn_outline_black text-base w-full text-center justify-center font-bold" onClick={() => setSaveDetails(false)}>
                    {t('No, cancel')}
                </Button>
            </div>
        </div>
    );
    const pdfCardContent = (
        <div className="flex justify-between ">
            <div className="flex items-center">
                <figure className="NoImgName">
                    <img src={PDF} alt="client" className="w-full h-full object-cover" />
                </figure>
                <div className="">
                    <h2 className="items-center text-base font-bold mb-1">Lorem ipsum.pdf</h2>
                    <p className="text-sm text-secondaryTxtColor mb-2">10 Jan 2022</p>
                </div>
            </div>
            <div className="flex items-center">
                <span className="bg-white border text-blue-500 rounded-xl mr-2 p-2">
                    <GoDownload />
                </span>
                <span className="bg-white border text-red-500 rounded-xl p-2">
                    <IoMdClose />
                </span>
            </div>
        </div>
    );
    return (
        <div className="p-8">
            <div className="flex justify-between">
                <h2 className="flex text-xl mb-1 leading-[140%] -tracking-[0.2px]">
                    <span className="font-bold">{payStructure.pay_structure_type && capitalizeFirstLetter(payStructure.pay_structure_type)}</span>
                    <SlArrowDown className="text-secondaryTxtColor mt-1 ml-2" onClick={() => setIsChangePayStructure(true)} />
                </h2>
                {!isEdit && (
                    <FaRegEdit
                        size={24}
                        onClick={() => {
                            setIsEdit(true);
                        }}
                        className="text-primary font-thin"
                    />
                )}
            </div>

            {isEdit && (
                <div className="w-full relative">
                    <Button className={`btn_primary w-[200px] h-10 fixed right-[75px] top-3`} isLoading={isLoading} type="button" onClick={() => setSaveDetails(true)}>
                        {t('Save changes')}
                    </Button>
                </div>
            )}
            {payStructure.pay_structure_type === 'commission' ? (
                <CommissionPayout control={control} errors={errors} payStructure={payStructure} shop_payment={shopPayment} staffInfo={staffInfo} setIsEdit={setIsEdit} isEdit={isEdit} />
            ) : (
                <Rent control={control} errors={errors} payStructure={payStructure} shop_payment={shopPayment} staffInfo={staffInfo} setIsEdit={setIsEdit} isEdit={isEdit} />
            )}
            {isEdit ? (
                <div className="mt-8">
                    <span>{t('Upload contract')}</span>
                    <Card content={pdfCardContent} className="rounded-3xl bg-white py-5 mt-2" />
                </div>
            ) : (
                <>
                    <div className="flex width-[832px] py-3 px-4 border rounded-[40px] gap-2 mt-8">
                        {payStructure.pay_structure_type === 'commission'
                            ? t('staff_pay_structure_commission_description', {
                                  first_name: staffInfo.first_name,
                              })
                            : t('staff_pay_structure_rent_description', {
                                  first_name: staffInfo.first_name,
                              })}
                    </div>
                    <div className="px-[10px] w-full mt-8">
                        <Controller
                            name="upload_contract"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <FileInputWithBorder
                                    label={t('Upload contract')}
                                    disabled={!isEdit}
                                    onChange={(e: any) => {
                                        onChange(e.target.files[0]);
                                        setFileName(e.target.files[0].name);
                                    }}
                                    fileName={fileName}
                                    value=""
                                    name="file"
                                    error={!!errors.file}
                                />
                            )}
                        />
                        <p className="p-2 text-xs text-secondaryTxtColor leading-[150%] -tracking-[0.12px] mb-5 max-w-[590px] flex justify-center">{t('No documents added')}</p>
                    </div>
                </>
            )}

            {isChangePayStructure && (
                <Popup
                    size="max-w-[896px]"
                    className="p-8 pb-10"
                    handleClose={() => {
                        setIsChangePayStructure(false);
                    }}
                    content={ChangePayStructureContent}
                />
            )}

            {saveDetails && <Popup size="max-w-[407px]" className="p-8 pb-10" handleClose={() => setSaveDetails(false)} content={saveContent} />}
        </div>
    );
};

export default Compensation;
