import React from 'react';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import KpiCard from 'src/components/KpiCard';
import AmChart from '../AmChart';
import StaffPerfomance from 'src/app/Dashboard/Tables/StaffPerfomance';
import NetSalesChartPie from '../NetSalesChartPie';
import PaymentChartPie from '../PaymentChartPie';
import Discount from '../Tables/Discount';
import NetSalesIncome from '../Tables/NetSalesIncome';
import NetIncome from '../Tables/NetIncome';
import { checkFieldIsZero, formatKpiContentLable } from 'src/utils/global-functions';
import { IconMoney, IconNewClient, IconClientRotation, IconCalendar, IconProductivity } from 'src/theme/Images';
import DateSelect from 'src/components/DateSelect/DateSelect';
import { ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import SelectBox from 'src/components/SelectBox/SelectBox';

const Overview = ({ handleViewClick, AnalyticsDetails, timeFileterSelected }: any) => (
    <>
        {AnalyticsDetails && (
            <>
                <div className="flex flex-row justify-between  mb-5">
                    <div className="flex flex-row gap-3">
                        <div className="fl-tab-btn-view2 min-h-[36px] w-full">
                            <div className="flex  lg:!px-[17px] fl-tab-link2 justify-center items-center min-h-[34px] flex-grow min-w-[70px] !text-nowrap !w-fit active">Daily</div>
                            <div className="flex  lg:!px-[17px] fl-tab-link2 justify-center items-center min-h-[34px] flex-grow min-w-[70px] !text-nowrap !w-fit">Weekly</div>
                            <div className="flex  lg:!px-[17px] fl-tab-link2 justify-center items-center min-h-[34px] flex-grow min-w-[70px] !text-nowrap !w-fit">Monthly</div>
                            <div className="flex  lg:!px-[17px] fl-tab-link2 justify-center items-center min-h-[34px] flex-grow min-w-[70px] !text-nowrap !w-fit">Yearly</div>
                        </div>

                        <DateSelect
                            inputName="start_date"
                            className={`form_control_date  font-medium text-xs relative `}
                            placeholder="Select date"
                            dateFormat="EEEE, MMMM do, yyyy"
                            isFormate={false}
                            InputClass="min-w-[275px]"
                            labelClassName="mb-0"
                            // InputClass={`${fieldState.error && 'is-invalid'}`}
                            value={``}
                            onChangeValue={() => {}}
                            labelEndIcon={<ChevronDown className="text-gray-700 w-5 ml-1 h-[22px] right-[-6px]" />}
                        />
                    </div>
                    <div className="flex flex-row gap-3">
                        <div className="2xl:w-[200px] w-[160px]">
                            <SelectBox
                                isSearchable={false}
                                name="location"
                                id="location"
                                // options={calendarData?.locationOptions}
                                classComp="outline-select-box"
                                // value={``}
                                onChangeFunc={() => {}}
                                // menuIsOpen
                                openOnFocus={true}
                                // autofocus={true}
                                // isClearable={false}
                            />
                        </div>
                        <div className="2xl:w-[200px] w-[160px]">
                            <SelectBox
                                name="team"
                                id="team"
                                // options={calendarData.staffOptions}
                                // value={``}
                                onChangeFunc={() => {}}
                                classComp="outline-select-box"
                                isClearable={false}
                                isSearchable={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-3 grid max-xl:grid-cols-2 grid-cols-4 w-full gap-3">
                    <div className="">
                        <KpiCard
                            headerIcon={IconMoney}
                            headerTitle="Total Sales"
                            contentLable={`${formatKpiContentLable(AnalyticsDetails?.total?.amount)}`}
                            growth={AnalyticsDetails?.total?.amount_growth}
                            fromDate={AnalyticsDetails?.from_date}
                        />
                    </div>

                    <div className="">
                        <KpiCard
                            headerIcon={IconNewClient}
                            headerTitle="New Clients"
                            contentLable={`$${formatKpiContentLable(AnalyticsDetails?.total?.client)}`}
                            growth={AnalyticsDetails?.total?.client_growth}
                            fromDate={AnalyticsDetails?.from_date}
                        />
                    </div>

                    <div className="">
                        <KpiCard
                            headerIcon={IconClientRotation}
                            headerTitle="Client Retenetion"
                            contentLable={`${AnalyticsDetails?.total?.client_retention_rate}%`}
                            contentDescription="new"
                            growth={AnalyticsDetails?.total?.client_retention_rate_growth}
                            secondaryContentLabel={`${AnalyticsDetails?.total?.client_repeat_rate}%`}
                            secondaryContentDescription="repeat"
                            fromDate={AnalyticsDetails?.from_date}
                        />
                    </div>

                    <div className="">
                        <KpiCard
                            headerIcon={IconCalendar}
                            headerTitle="Av. Booking Frequency"
                            contentLable={`${AnalyticsDetails?.total?.avg_bookings_frequency}%`}
                            growth={`${AnalyticsDetails?.total?.avg_bookings_frequency_growth} `}
                            fromDate={`${AnalyticsDetails?.from_date}`}
                        />
                    </div>

                    {/* <div className="px-[6px] w-[20%]">
                        <div className="bg-white rounded-3xl border border-borderSecondary p-4 shadow">
                            <KpiCard
                                headerIcon={IconProductivity}
                                headerTitle="Productivity"
                                contentLable={`${AnalyticsDetails?.total?.productivity}%`}
                                growth={AnalyticsDetails?.total?.productivity_growth}
                                fromDate={AnalyticsDetails?.from_date}
                            />
                        </div>
                    </div> */}
                </div>
                <div className="mb-3 grid grid-cols-3 w-full gap-3">
                    <div className="">
                        <KpiCard
                            headerIcon={IconMoney}
                            headerTitle="Total Sales"
                            contentLable={`${formatKpiContentLable(AnalyticsDetails?.total?.amount)}`}
                            growth={AnalyticsDetails?.total?.amount_growth}
                            fromDate={AnalyticsDetails?.from_date}
                        />
                    </div>

                    <div className="">
                        <KpiCard
                            headerIcon={IconNewClient}
                            headerTitle="New Clients"
                            contentLable={`$${formatKpiContentLable(AnalyticsDetails?.total?.client)}`}
                            growth={AnalyticsDetails?.total?.client_growth}
                            fromDate={AnalyticsDetails?.from_date}
                        />
                    </div>

                    <div className="">
                        <KpiCard
                            headerIcon={IconClientRotation}
                            headerTitle="Client Retenetion"
                            contentLable={`${AnalyticsDetails?.total?.client_retention_rate}%`}
                            contentDescription="new"
                            growth={AnalyticsDetails?.total?.client_retention_rate_growth}
                            secondaryContentLabel={`${AnalyticsDetails?.total?.client_repeat_rate}%`}
                            secondaryContentDescription="repeat"
                            fromDate={AnalyticsDetails?.from_date}
                        />
                    </div>
                </div>

                <div className="flex mb-3">
                    <div className="pe-[6px] w-full">
                        <div className="bg-white rounded-xl border border-borderSecondary px-5 py-4  h-full shadow">
                            <div className="flex flex-row justify-between items-center">
                                <div className="flex flex-col gap-1">
                                    <div className="text-lg font-semibold -tracking-[0.4px] text-mainTextColor">Sales Analytics</div>
                                    <div className="text-sm font-normal -tracking-[0.4px] text-secondaryTxtColor">Manage your client and their account permissions here.</div>
                                </div>
                                <div className="fl-tab-btn-view2 min-h-[36px] max-h-[36px] ">
                                    <div className="w-full flex fl-tab-link2  !min-w-max justify-center items-center min-h-[34px] active">Total sales</div>
                                    <div className="w-full flex fl-tab-link2 !min-w-max justify-center items-center min-h-[34px]">Total services sales</div>
                                    <div className="w-full flex fl-tab-link2 justify-center items-center min-h-[34px]">Total tips</div>
                                </div>
                            </div>
                            {/* {AnalyticsDetails.sales_line_graph.length > 0 ? (
                                <AmChart salesLineGraph={AnalyticsDetails.sales_line_graph} />
                            ) : (
                                <EmptyMsgWithBtn
                                    title="No Sales Data"
                                    description="Sales Analytics will be available once clients start
                 booking appointments."
                                    btnLabel="Create booking"
                                    onClick={() => {}}
                                />
                            )} */}
                        </div>
                    </div>

                    {/* <div className="ps-[6px] w-2/5">
                        <StaffPerfomance staffData={AnalyticsDetails.staff} handleViewClick={handleViewClick} />
                    </div> */}
                </div>

                <div className="flex mb-3">
                    <div className="pe-[6px] w-1/2">
                        <div className="bg-white rounded-xl border border-borderSecondary px-5 py-4 shadow">
                            <h3 className="text-lg font-semibold -tracking-[0.4px] text-mainTextColor">Net Sales by Service</h3>
                            {checkFieldIsZero(AnalyticsDetails.sales_by_services, 'total_booking_service_amount') ? (
                                <EmptyMsgWithBtn
                                    title="No Net Sales by Service"
                                    description="Net Sales by Service will be available once clients start booking appointments.                                    "
                                    btnLabel="Create booking"
                                    onClick={() => {}}
                                />
                            ) : (
                                <NetSalesChartPie netSalesIncome={AnalyticsDetails.sales_by_services} timeFileterSelected={timeFileterSelected} />
                            )}
                        </div>
                    </div>

                    <div className="ps-[6px] w-1/2">
                        <div className="bg-white rounded-xl border border-borderSecondary px-5 py-4 shadow">
                            <h3 className="text-lg font-semibold -tracking-[0.4px] text-mainTextColor">Payment method split</h3>
                            {checkFieldIsZero(AnalyticsDetails.payment_method_split, 'total') ? (
                                <EmptyMsgWithBtn title="You have no sales" description="Create an appointment to track sales" btnLabel="Create booking" onClick={() => {}} />
                            ) : (
                                <PaymentChartPie paymentMethod={AnalyticsDetails.payment_method_split} timeFileterSelected={timeFileterSelected} />
                            )}
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-3 mb-3">
                    <div className="flex mb-3 w-full">
                        <Discount discountInfo={AnalyticsDetails.gross_revenue} />
                    </div>

                    <div className=" w-full">
                        <NetSalesIncome netSalesIncomeDetails={AnalyticsDetails.gross_revenue} />
                    </div>

                    <div className=" w-full">
                        <NetIncome netIncomeDetails={AnalyticsDetails.gross_revenue} />
                    </div>
                </div>
                {/* <div className="bg-white rounded-3xl border border-lineColor py-4">
                    <AdvancedAnalytics />
                </div> */}
            </>
        )}
    </>
);
export default Overview;
