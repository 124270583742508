import React, { useEffect, useState } from 'react';
import CustomButton from 'src/components/CustomButton';
import SearchBar from 'src/components/SearchBar/SearchBar';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, currentShop } from 'src/redux/services/common/Common.slice';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { GoDotFill } from 'react-icons/go';
import { useTranslation } from 'react-i18next';
import { Grid01 } from '@untitled-ui/icons-react/build/cjs';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import ProductsTable from './ProductsTable';
import ProductsGrid from './ProductsGrid';
import { List } from '@untitled-ui/icons-react/build/cjs';
import { View } from './Products.interface';
import { perPageOptions } from 'src/utils/global-variables';

const ProductsView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const shop = useAppSelector(currentShop);
    const shopLocationList = useAppSelector(allShopLocations);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [activeView, setActiveView] = useState(View.GRID);
    const [selectedFilter, setSelectedFilter] = useState({
        shop_location_id: null,
    });
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 25,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            shop_location_id: { value: null },
            shop_admin_id: { value: null },
            is_datatable: { value: false },
        },
    });
    const [filters, setFilters] = useState<any>({
        global: { value: null },
        shop_location_id: { value: null },
    });
    const [productListArray, setProductListArray] = useState([]);
    const [totalProducts, setTotalProducts] = useState<number>(0);

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions(locationData);
    }, []);

    const productServices = async () => {
        setIsLoading(true);
        await axiosGet(API.PRODUCT.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setProductListArray(response.data.data.data);
                setTotalProducts(response.data.data.totalRecords);
            })
            .finally(() => {
                setFilters(lazyState.filters);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        productServices();
    }, [lazyState, shop]);

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : null;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const handleClick = (path: string) => () => {
        navigate(path);
    };

    useEffect(() => {
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, is_datatable: { value: activeView === View.TABLE } } }));
    }, [activeView]);

    return (
        <div
            className={`w-full border border-gray-200 rounded-xl shadow xxl:mt-4 mt-3 staff_service_table datatable-custom-service flex-1 flex flex-col ${
                productListArray.length === 0 ? 'datatable-full-height datatable-noshow' : ''
            }`}
        >
            <div className={`flex items-center flex-col  `}>
                <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                    <div className="flex flex-col flex-1">
                        <div className="flex justify-start items-center gap-1.5">
                            <h2 className="table-title">{t('All Products')}</h2>
                            <p className={`userCounter ${isLoading ? 'custom-loading' : ''}`}>
                                <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                {totalProducts} {t('Products')}
                            </p>
                        </div>
                        <p className="table-subtitle">{t('View and manage your Products.')}</p>
                    </div>
                    <div className="flex relative gap-3">
                        <CustomButton
                            primary={activeView === View.GRID}
                            outlineSecondary={activeView !== View.GRID}
                            onClick={() => setActiveView(View.GRID)}
                            icon={<Grid01 width="16" height="16" />}
                            className="w-[36px] !px-2  py-2 rounded-lg"
                        ></CustomButton>
                        <CustomButton
                            outlineSecondary={activeView !== View.TABLE}
                            primary={activeView === View.TABLE}
                            onClick={() => setActiveView(View.TABLE)}
                            icon={<List width="16" height={16} />}
                            className="w-[36px] !px-2  py-2 rounded-lg"
                        ></CustomButton>
                        <CustomButton primary onClick={handleClick(ROUTES.PRODUCT.CREATE)} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                            {t('Add new products')}
                        </CustomButton>
                    </div>
                </div>
                <div className="flex items-center px-5 py-3 justify-between w-full border-gray-200 border-b">
                    <div className="table-searchInput">
                        <SearchBar placeholder={t('Search')} className="form-control-md" onChange={handleSearch} />
                    </div>
                    <div className="flex flex-row gap-2">
                        <div className="w-[200px]">
                            <SelectBox
                                noOptionsMessage="No Location found"
                                options={locationOptions}
                                value={selectedFilter.shop_location_id}
                                placeholder={t('All locations')}
                                onChangeFunc={handleFilter('shop_location_id')}
                                classComp="outline-select-box"
                            />
                        </div>
                        {activeView === View.TABLE && (
                            <div className="w-[70px] xl:w-[70px] page-dropdown">
                                <SelectBox
                                    name="page"
                                    options={perPageOptions}
                                    isClearable={false}
                                    isSearchable={false}
                                    onChangeFunc={onPageHandle}
                                    value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                    classComp="outline-select-box"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {activeView === View.TABLE ? (
                <ProductsTable
                    setIsLoading={setIsLoading}
                    productListArray={productListArray}
                    totalProducts={totalProducts}
                    lazyState={lazyState}
                    setLazyState={setLazyState}
                    productServices={productServices}
                    isLoading={isLoading}
                    filters={filters}
                />
            ) : (
                <ProductsGrid isLoading={isLoading} productListArray={productListArray} setLazyState={setLazyState} lazyState={lazyState} totalProducts={totalProducts} filters={filters} />
            )}
        </div>
    );
};

export default ProductsView;
