import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { AppearanceTeam } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { IFormData, IStaff, TeamProps } from './Team.interface';
import Form from './Form';
import { s3Upload } from 'src/utils/s3Operations';
import { s3Path } from 'src/constants/s3Path';
import TemplateLayout from '../Layout/Layout';
import Switch from 'src/components/Switch/Switch';
import { allShopSettings, allShopStaff, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import { roleList } from 'src/app/Staff/Staff.slice';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';

const Team: FC<TeamProps> = ({ template }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shopSettingInfo: any = useAppSelector(allShopSettings).find((setting) => setting.type === 'template')?.value?.team;
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const shopId = shop.id;
    const staffList = useAppSelector(allShopStaff);

    const schema = Yup.object({
        status: Yup.bool().required(t('This field is required')),
        is_title: Yup.bool().required(t('This field is required')),
        title: Yup.string()
            .nullable()
            .when('is_title', ([is_title], customSchema) => (is_title ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        is_subtitle: Yup.bool().required(t('This field is required')),
        is_staff: Yup.bool().required(t('This field is required')),
        subtitle: Yup.string()
            .nullable()
            .when('is_subtitle', ([is_subtitle], customSchema) => (is_subtitle ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        staff: Yup.array()
            .test({
                name: 'staff',
                message: 'Please add at least one team',
                test: function (value) {
                    const { is_staff: isStaff, status } = this.parent;
                    if ((status || isStaff) && (!value || value.length === 0)) {
                        return false;
                    }
                    return true;
                },
            })
            .of(
                Yup.object()
                    .shape({
                        name: Yup.string().required(t('This field is required')),
                        is_instgram_button: Yup.bool().required(t('This field is required')),
                        instgram_button: Yup.string()
                            .nullable()
                            .when('is_instgram_button', ([is_instgram_button], customSchema) => (is_instgram_button ? customSchema.required(t('This field is required')) : customSchema.nullable())),
                        role: Yup.string().nullable().required(t('This field is required')),
                        image: Yup.object()
                            .shape({
                                file: Yup.mixed().nullable(),
                                url: Yup.string().nullable(),
                                name: Yup.string().required(t('This field is required')),
                            })
                            .test('image-validation', t('This field is required'), function (value) {
                                if (!value) {
                                    return this.createError({
                                        path: `${this.path}`,
                                        message: t('This field is required'),
                                    });
                                }
                                const { name, url } = value;
                                if (!name || !url) {
                                    return this.createError({
                                        path: `${this.path}`,
                                        message: t('This field is required'),
                                    });
                                }
                                return true;
                            }),
                        location: Yup.array()
                            .test('location', t('This field is required'), (value) => value && value.length > 0)
                            .of(Yup.string().required(t('This field is required'))),
                    })
                    .required(t('This field is required')),
            )
            .nullable()
            .when('status', ([status], customSchema) => (status ? customSchema.required() : customSchema.nullable())),
    });

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            status: false,
            is_title: false,
            title: '',
            is_subtitle: false,
            subtitle: '',
            is_staff: false,
            staff: [],
        },
    });

    useEffect(() => {
        if (shopSettingInfo) {
            const updatedStaff = shopSettingInfo?.staff?.map((staff: any) => ({
                name: staff.name,
                role: staff.role,
                image: staff.image
                    ? {
                          name: staff.image,
                          url: staff.image && staff.image_url,
                      }
                    : null,
                location: staff.location,
                is_instgram_button: staff.is_instgram_button,
                instgram_button: staff.instgram_button,
            }));

            setValue('title', shopSettingInfo.title);
            setValue('subtitle', shopSettingInfo.subtitle);
            setValue('is_title', shopSettingInfo.is_title);
            setValue('is_subtitle', shopSettingInfo.is_subtitle);
            setValue('is_staff', shopSettingInfo.is_staff);
            setValue('status', shopSettingInfo.status);
            setValue('staff', updatedStaff);
        }
    }, [shopSettingInfo]);
    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        formState: { errors },
    } = methods;
    const isStatus = watch('status');

    const handleSwitchOnChange = (name: keyof IFormData) => (event: any) => {
        setValue(name, event.value);
    };

    const handleChange = async (data: IFormData) => {
        setIsLoading(true);
        const imagePromises = data?.staff?.map((staff: IStaff) => (staff?.image?.file ? s3Upload(staff.image.file, `${s3Path.ADMIN_PROFILE}${staff.image.name}`) : Promise.resolve(null)));
        const imageResponses = await Promise.allSettled(imagePromises);
        const isSuccess = imageResponses.every((response: any) => response.status === 'fulfilled' && (response.value?.status === errorCode.updateSuccess || response?.value === null));

        if (isSuccess) {
            const payload = {
                team: {
                    status: data?.status,
                    is_title: data?.is_title,
                    title: data?.title,
                    is_subtitle: data?.is_subtitle,
                    subtitle: data?.subtitle,
                    is_staff: data?.is_staff,
                    staff: data?.staff?.map((staff: IStaff) => ({
                        name: staff?.name,
                        is_instgram_button: staff?.is_instgram_button,
                        instgram_button: staff?.instgram_button,
                        role: staff?.role,
                        image: staff?.image?.name,
                        location: staff?.location,
                    })),
                },
                template,
            };
            await axiosPost(API.THEME.TEMPLATE.TEAM, payload, {
                shop_id: shopId,
            })
                .then(async (response) => {
                    await dispatch(getAllShopSettings({ shop_id: shop.id }));
                    return;
                })
                .catch((error) => {
                    if (error.response.data.status === errorCode.unprocessable) {
                        if (error.response.data.data) {
                            Object.keys(error.response.data.data).forEach((field) => {
                                const fieldKey = field.replace('team.', '');
                                setError(fieldKey as keyof IFormData, {
                                    type: 'manual',
                                    message: error.response.data.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                })
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
            toast.error(t('Something went wrong!'));
        }
    };
    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };

    const isStaff = watch('is_staff');
    const staffWatch = watch('staff');

    const getRoleList = async () => {
        const result = await dispatch(roleList({ shop_id: shop.id }));
        if (result.type === roleList.fulfilled.toString()) {
            const roles: any = getSelectBoxOptions(result.payload.data, 'id', 'name');
            const updatedStaff: IStaff[] = staffList?.map((staff: any) => ({
                name: staff?.full_name,
                role: roles.find((role: any) => role.id === staff?.staff_role?.id)?.label || staff?.staff_role?.id,
                image: {
                    name: staff?.profile_image_name ?? null,
                    url: staff?.profile_image_url ?? null,
                    file: null,
                },
                location: staff?.locations?.map((location: any) => location?.name),
                is_instgram_button: Boolean(staff?.instagram_link),
                instgram_button: staff?.instagram_link,
            }));

            return updatedStaff;
        }
    };

    const handleSync = async (event: any) => {
        event.stopPropagation();
        setIsLoading(true);
        try {
            const updatedStaff = await getRoleList();

            if (updatedStaff) {
                setValue('staff', updatedStaff);
            }
        } catch (error) {
            console.error('Error in handleSync:', error);
        }
        setIsLoading(false);
    };
    useFormErrorFocus<IFormData>({ errors, formSectionName: 'team' });
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleChange)} className={`h-full ${errors?.staff && errors?.staff?.message ? 'is-invalid rounded-xl border' : 'rounded-xl border border-borderPrimary'}`}>
                <Collapse
                    title={t('Team')}
                    description={t('The Team section highlights your barbers and staff, including their photos and brief biographies.')}
                    isCollapsed={isStatus}
                    isLoading={isLoading}
                    handleStatus={handleStatus}
                    handleSave={handleSubmit(handleChange)}
                    // handleSync={handleSync}
                    // syncButtonTitle={`${staffList.length !== staffWatch?.length ? `Sync with Staff (${staffList.length} specialists)` : ''}`}
                >
                    <TemplateLayout ImgProp={AppearanceTeam}>
                        <div className="w-full">
                            <div className="flex justify-between items-start mb-4 gap-4">
                                <Controller
                                    name={`title`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id="team-title"
                                                label={t('Title')}
                                                required
                                                placeholder={t('Enter team title')}
                                                onChange={onChange}
                                                value={value}
                                                name={'title'}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name="is_title"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="subtitle"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id="subtitle"
                                                label={t('Subtitle')}
                                                required
                                                placeholder={t('Enter team subtitle')}
                                                onChange={onChange}
                                                value={value}
                                                name="subtitle"
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name="is_subtitle"
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className=" items-center mb-4 ">
                                <div className="flex items-center mb-1 gap-2.5">
                                    <label htmlFor="" className="font-bold leading-5 text-gray-700 text-sm">
                                        {t('Staff members')}
                                    </label>
                                    <Switch className="" onChange={handleSwitchOnChange('is_staff')} isChecked={isStaff} />
                                </div>
                                <p className="text-sm text-gray-500 leading-5 mb-2">{t('Please add staff members')}</p>
                            </div>
                            <Form />
                        </div>
                    </TemplateLayout>
                    {errors.staff && errors?.staff?.message && <p className="text-error text-center">{errors?.staff?.message}</p>}
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end gap-4">
                        {staffList.length !== staffWatch?.length && (
                            <CustomButton type="button" secondary onClick={handleSync}>
                                <span className="h-[8px] w-[8px] bg-success-500 rounded-full"></span>
                                <span className="flex items-center ">{`${staffList.length !== staffWatch?.length ? `Sync (${staffList.length})` : ''}`}</span>
                            </CustomButton>
                        )}
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Team;
