import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/components/PageHeader';
import ClientTable from './ClientTable';
import { Plus, X } from '@untitled-ui/icons-react/build/cjs';
import { IclientStatusList, initialState } from './Client.interface';
import ActiveClient from 'src/assets/customsvg/ActiveClient';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';

const Client = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [clickedIndicator, setClickedIndicator] = useState('all');
    const [clientStatusList, setClientStatusList] = useState<IclientStatusList[]>(initialState);

    const handleIndicatorClick = (label: string) => () => {
        if (clickedIndicator === label) {
            setClickedIndicator('all');
        } else {
            setClickedIndicator(label);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        clientStatus();
    }, []);

    const clientStatus = async () => {
        await axiosGet(API.CLIENT.STATUSCOUNT, { shop_id: shop.id })
            .then((response) => {
                const updatedState = initialState.map((item: any) => ({
                    ...item,
                    count: response.data.data[item.value] !== undefined ? response.data.data[item.value] : item.count,
                }));
                setClientStatusList(updatedState);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div className="inner-page-wrape pb-5">
            <PageHeader title={t('Clients')} subtitle={t('Manage your client and their account permissions here.')}></PageHeader>
            <div className="side-spaching flex flex-1 flex-col w-full">
                <div className="grid grid-cols-5 xxl:grid-cols-5 gap-4 mb-4">
                    {clientStatusList.map((status, index) => (
                        <div
                            key={index}
                            onClick={handleIndicatorClick(status.value)}
                            className={`flex justify-between flex-col min-h-[125px] cursor-pointer hover:shadow-lg hover:bg-gray-50 rounded-xl w-full border border-gray-200 bg-gray-50 p-4 ${
                                clickedIndicator === status.value ? 'border-blue-400 bg-[#F7F9FF] shadow-lg hover:bg-[#F7F9FF]' : ''
                            }`}
                        >
                            <div className="flex justify-between flex-row">
                                <ActiveClient iconColor={status.iconColor} className="w-10" />
                                {clickedIndicator === status.value ? <X className="text-blue-600 w-5" /> : <Plus className="text-gray-600 w-5" />}
                            </div>
                            <div className="flex items-center gap-[1px]">
                                <span className="text-gray-900 xxl:text-sm xl:text-sm text-xs font-medium flex justify-start items-start">{status.name} </span>
                                <span className={`text-gray-600 font-medium xxl:text-xs xl:text-xs text-xs !leading-[17px] ${isLoading && 'custom-loading'}`}>({status.count})</span>
                            </div>
                        </div>
                    ))}
                </div>
                <ClientTable clickedIndicator={clickedIndicator} />
            </div>
        </div>
    );
};

export default Client;
