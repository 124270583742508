import React, { FC, useEffect, useState } from 'react';
import { Collapse } from '../Collapse';
import { useTranslation } from 'react-i18next';
import { AppearanceLocation } from 'src/theme/Images';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import Form from './Form';
import moment from 'moment';
import { ILocationData, ILocationInfoData, InitLocationData, LocationProps } from './Location.interface';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { IWorkingHoursValue } from 'src/app/Location/Location.interface';
import { s3Upload } from 'src/utils/s3Operations';
import { s3Path } from 'src/constants/s3Path';
import { usePhone } from 'src/hooks/usePhone';
import TemplateLayout from '../Layout/Layout';
import { allShopLocations, allShopSettings, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import CustomButton from 'src/components/CustomButton';
const Location: FC<LocationProps> = ({ template }) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const shopId = shop?.id;
    const dispatch = useAppDispatch();
    const shopSettingInfo: any = useAppSelector(allShopSettings).find((setting) => setting.type === 'template')?.value?.location;
    const { isPhoneValid, phoneInfo } = usePhone();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const locations = useAppSelector(allShopLocations);
    const schema = Yup.object().shape({
        status: Yup.boolean().required(t('Form data is required.')),
        locations: Yup.array()
            .test({
                name: 'locations',
                message: 'Please add at least one location',
                test: function (value) {
                    const { status } = this.parent;
                    if (status && (!value || value.length === 0)) {
                        return false;
                    }
                    return true;
                },
            })
            .of(
                Yup.object().shape({
                    is_title: Yup.boolean().required(t('This field is required')),
                    title: Yup.string()
                        .nullable()
                        .when('is_title', ([is_title], customSchema) => (is_title ? customSchema.required(t('This field is required')) : customSchema.nullable())),
                    is_contact: Yup.boolean().required(t('This field is required')),
                    country_code: Yup.string()
                        .nullable()
                        .when('is_contact', ([is_contact], customSchema) => (is_contact ? customSchema.required(t('This field is required')) : customSchema.nullable())),
                    contact: Yup.string()
                        .nullable()
                        .test({
                            message: t('Invalid phone number'),
                            test: function (value) {
                                const { country_code: countryCode, is_contact: isContact } = this.parent;
                                if (value) {
                                    const phoneInfo2 = phoneInfo(value, countryCode);
                                    if (phoneInfo2) {
                                        const isValid = isPhoneValid(value, countryCode);
                                        return isValid;
                                    }
                                }
                                return !isContact;
                            },
                        })
                        .when('is_contact', ([is_contact], customSchema) => (is_contact ? customSchema.required(t('This field is required')) : customSchema.nullable())),

                    hours: Yup.array()
                        .of(
                            Yup.object().shape({
                                day: Yup.string().required('Day is required').oneOf(['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'], 'Invalid day'),
                                status: Yup.boolean().required('Status is required'),

                                from_time_hours: Yup.string()
                                    .required('From time hours are required')
                                    .test({
                                        name: 'from_valid_hours_1',
                                        message: 'From hours must be between 01 to 12 and minutes must be between 00 to 59',
                                        test: function (value) {
                                            const { status } = this.parent;
                                            if (status) {
                                                const parts = value.split(':');
                                                const hours = parseInt(parts[0], 10);
                                                const minutes = parseInt(parts[1], 10);
                                                const isValidHours = !isNaN(hours) && hours >= 1 && hours <= 12;
                                                const isValidMinutes = !isNaN(minutes) && minutes >= 0 && minutes < 60;
                                                return isValidHours && isValidMinutes;
                                            }
                                            return true;
                                        },
                                    })
                                    .test({
                                        name: 'from_valid_hours_2',
                                        message: '"From" time must be less than "To" time',
                                        test: function (value) {
                                            const { from_time_type: fromTimeType, to_time_hours: toTimeHours, to_time_type: toTimeType, status } = this.parent;

                                            if (status) {
                                                const fromMoment = moment(`${value} ${fromTimeType}`, 'hh:mm a');
                                                const toMoment = moment(`${toTimeHours} ${toTimeType}`, 'hh:mm a');

                                                if (fromMoment.isSameOrAfter(toMoment)) {
                                                    return false;
                                                } else {
                                                    return true;
                                                }
                                            }
                                            return true;
                                        },
                                    }),

                                to_time_hours: Yup.string()
                                    .required('To time hours are required')
                                    .test({
                                        name: 'to_valid_hours_1',
                                        message: 'To hours must be between 01 to 12 and minutes must be between 00 to 59',
                                        test: function (value) {
                                            const { status } = this.parent;
                                            if (status) {
                                                const parts = value.split(':');
                                                const hours = parseInt(parts[0], 10);
                                                const minutes = parseInt(parts[1], 10);
                                                const isValidHours = !isNaN(hours) && hours >= 1 && hours <= 12;
                                                const isValidMinutes = !isNaN(minutes) && minutes >= 0 && minutes < 60;
                                                return isValidHours && isValidMinutes;
                                            }
                                            return true;
                                        },
                                    })
                                    .test({
                                        name: 'to_valid_hours_2',
                                        message: '"To" time must be greater than "From" time',
                                        test: function (value) {
                                            const { from_time_hours: fromTimeHours, from_time_type: fromTimeType, to_time_type: toTimeType, status } = this.parent;

                                            if (status) {
                                                const fromMoment = moment(`${fromTimeHours} ${fromTimeType}`, 'hh:mm a');
                                                const toMoment = moment(`${value} ${toTimeType}`, 'hh:mm a');

                                                if (toMoment.isSameOrBefore(fromMoment)) {
                                                    return false;
                                                } else {
                                                    return true;
                                                }
                                            }
                                            return true;
                                        },
                                    }),

                                from_time_type: Yup.string().required('From time type is required').oneOf(['am', 'pm'], 'Invalid from time type'),

                                to_time_type: Yup.string().required('To time type is required').oneOf(['am', 'pm'], 'Invalid to time type'),
                            }),
                        )
                        .required(t('This field is required')),
                }),
            )
            .nullable()
            .when('status', ([status], customSchema) => (status ? customSchema.required() : customSchema.nullable())),
    });

    const methods = useForm<ILocationData>({
        resolver: yupResolver(schema) as Resolver<ILocationData>,
        defaultValues: InitLocationData,
    });

    const {
        handleSubmit,
        setError,
        setValue,
        watch,
        formState: { errors },
    } = methods;
    const isStatus = watch('status');
    const locationsWatch = watch('locations');

    const handleChange = async (data: any) => {
        setIsLoading(true);
        const logoImagePromises = data?.locations?.map((location: ILocationInfoData) =>
            location?.image?.file ? s3Upload(location.image.file, `${s3Path.LOCATION_PROFILES}${location.image.name}`) : Promise.resolve(null),
        );
        const logoImageResponses = await Promise.allSettled(logoImagePromises);
        const isSuccess = logoImageResponses.every((response) => response.status === 'fulfilled' && (response.value?.status === errorCode.updateSuccess || response?.value === null));
        if (isSuccess) {
            const payload = {
                template,
                location: {
                    status: isStatus,
                    locations: data?.locations?.map((location: ILocationInfoData) => ({
                        is_title: location.is_title,
                        title: location.title,
                        is_contact: location.is_contact,
                        contact: location.contact,
                        country_code: location.country_code,
                        is_image: location.is_image,
                        image: location.image && location.image.name ? location.image.name : null,
                        hours: location?.hours?.map((day: IWorkingHoursValue) => {
                            const from = moment(`${day.from_time_hours} ${day.from_time_type}`, 'hh:mm a').format('HH:mm:ss');
                            const to = moment(`${day.to_time_hours} ${day.to_time_type}`, 'hh:mm a').format('HH:mm:ss');
                            return {
                                day: day.day,
                                status: day.status,
                                from,
                                to,
                            };
                        }),
                    })),
                },
            };

            await axiosPost(API.THEME.TEMPLATE.LOCATION, payload, {
                shop_id: shopId,
            })
                .then(async (response) => {
                    await dispatch(getAllShopSettings({ shop_id: shop.id }));
                    return;
                })
                .catch((error) => {
                    if (error.response.data.status === errorCode.unprocessable) {
                        if (error.response.data.data) {
                            Object.keys(error.response.data.data).forEach((field) => {
                                const fieldKey = field.replace('location.', '');
                                setError(fieldKey as keyof ILocationData, {
                                    type: 'manual',
                                    message: error.response.data.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                })
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
            toast.error('Something went wrong!');
        }
    };

    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };

    useEffect(() => {
        if (shopSettingInfo) {
            const updatedLocations = shopSettingInfo?.locations?.map((loc: any) => ({
                is_title: loc.is_title,
                title: loc.title,
                is_contact: loc.is_contact,
                contact: loc.contact,
                country_code: loc.country_code,
                is_image: loc.is_image,
                image: loc.image
                    ? {
                          name: loc.image,
                          url: loc.image && loc.image_url,
                      }
                    : null,
                hours: loc?.hours.map((hour: any) => {
                    const momentFromTime = moment(hour.from, 'HH:mm:ss');
                    const momentToTime = moment(hour.to, 'HH:mm:ss');

                    const fromTimeHours = momentFromTime.format('hh:mm');
                    const fromTimeType = momentFromTime.format('a');

                    const toTimeHours = momentToTime.format('hh:mm');
                    const toTimeType = momentToTime.format('a');

                    return {
                        day: hour.day,
                        status: hour.status,
                        from_time_hours: fromTimeHours,
                        to_time_hours: toTimeHours,
                        from_time_type: fromTimeType,
                        to_time_type: toTimeType,
                    };
                }),
            }));

            setValue('status', shopSettingInfo.status);
            setValue('locations', updatedLocations);
        }
    }, [shopSettingInfo]);

    const handleSync = (event: any) => {
        event.stopPropagation();
        const updatedLocations: any = locations?.map((loc: any) => ({
            title: loc.name,
            is_title: loc.name ? true : false,
            contact: loc.phone,
            is_contact: loc.phone && loc.phone_country_code ? true : false,
            country_code: loc.phone_country_code,
            is_image: loc.image ? true : false,
            image: loc.profile_image_name
                ? {
                      name: loc.profile_image_name,
                      url: loc.profile_image_url,
                  }
                : null,
            hours: loc?.working_hours?.map((hour: any) => {
                const momentFromTime = moment(hour.from, 'HH:mm:ss');
                const momentToTime = moment(hour.to, 'HH:mm:ss');

                const fromTimeHours = momentFromTime.format('hh:mm');
                const fromTimeType = momentFromTime.format('a');

                const toTimeHours = momentToTime.format('hh:mm');
                const toTimeType = momentToTime.format('a');

                return {
                    day: hour.day,
                    status: hour.status,
                    from_time_hours: fromTimeHours,
                    to_time_hours: toTimeHours,
                    from_time_type: fromTimeType,
                    to_time_type: toTimeType,
                };
            }),
        }));
        setValue('locations', updatedLocations);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleChange)} className={`h-full ${errors.locations ? 'is-invalid rounded-xl border' : 'rounded-xl border border-borderPrimary'}`}>
                <Collapse
                    title={t('Locations')}
                    description={t('The Locations section provides detailed information, images and locations for each branch, helping customers find and contact your shops.')}
                    isCollapsed={isStatus}
                    // handleSync={handleSync}
                    // syncButtonTitle={`${locations.length !== locationsWatch?.length ? `Synced with Locations (${locations.length})` : ''}`}
                    handleStatus={handleStatus}
                    isLoading={isLoading}
                    handleSave={handleSubmit(handleChange)}
                >
                    <TemplateLayout ImgProp={AppearanceLocation}>
                        <Form />
                        {errors.locations && <p className="text-error">{errors.locations.message}</p>}
                    </TemplateLayout>
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end gap-4">
                        {locations.length !== locationsWatch?.length && (
                            <CustomButton type="button" secondary onClick={handleSync}>
                                <span className="h-[8px] w-[8px] bg-success-500 rounded-full"></span>
                                <span className="flex items-center ">{`${locations.length !== locationsWatch?.length ? `Sync (${locations.length})` : ''}`}</span>
                            </CustomButton>
                        )}
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Location;
