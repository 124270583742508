import { AsYouType, CountryCode, parsePhoneNumber } from 'libphonenumber-js';

export const usePhone = () => {
    const setFormatPhone = (code: string, phone: string, regionCode: CountryCode) => {
        try {
            if (phone) {
                const number = parsePhoneNumber(phone, regionCode);
                return number.isValid() ? number.format('NATIONAL', { nationalPrefix: false }) : new AsYouType(regionCode).input(phone);
            }
            return new AsYouType(regionCode).input(phone);
        } catch {
            return new AsYouType(regionCode).input(phone);
        }
    };

    const isPhoneValid = (phone: any, regionCode: any): boolean => {
        try {
            const number = parsePhoneNumber(phone, regionCode);

            return number.isValid();
        } catch {
            return false;
        }
    };
    const phoneInfo = (phone: string, regionCode: CountryCode) => {
        try {
            return parsePhoneNumber(phone, regionCode);
        } catch {
            return false;
        }
    };

    const getCustomFormatPhone = (phone: string, regionCode: CountryCode) => {
        try {
            const info: any = phoneInfo(phone, regionCode);
            const formatedPhoneNumber = setFormatPhone(info.countryCallingCode, info.nationalNumber, info.country);
            return `(+${info.countryCallingCode}) ${formatedPhoneNumber}`;
        } catch {
            return false;
        }
    };

    const getFormatPhone = (phone: string, regionCode: CountryCode) => {
        const number = parsePhoneNumber(phone, regionCode);
        return number.isValid() ? `+${number.countryCallingCode} ${number.format('NATIONAL', { nationalPrefix: false })}` : phone;
    };
    const getFormatPhoneWithBracket = (phone: string, regionCode: CountryCode) => {
        try {
            if (phone) {
                const number = parsePhoneNumber(phone, regionCode);
                return number.isValid() ? `(+${number.countryCallingCode}) ${number.format('NATIONAL', { nationalPrefix: false })}` : new AsYouType(regionCode).input(phone);
            }
            return new AsYouType(regionCode).input(phone);
        } catch {
            return new AsYouType(regionCode).input(phone);
        }
    };

    return { setFormatPhone, getFormatPhone, isPhoneValid, phoneInfo, getCustomFormatPhone, getFormatPhoneWithBracket };
};
