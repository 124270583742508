import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Radio } from 'flowbite-react';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations } from 'src/redux/services/common/Common.slice';
import { getSelectBoxOptions } from 'src/utils/global-functions';

const ExpenseLocation = () => {
    const { t } = useTranslation();
    const { control } = useForm();
    const shopLocationList = useAppSelector(allShopLocations);
    const [locationList, setLocationList] = useState<any[]>([]);

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationList(locationData);
    }, [shopLocationList]);

    return (
        <>
            <Controller
                name="location"
                control={control}
                render={({ field: { onChange, value }, fieldState }: any) => (
                    <>
                        <div className="grid grid-cols-2 gap-4 max-h-[300px] overflow-y-auto">
                            {locationList.map((item: any, index: number) => (
                                <label
                                    key={index}
                                    className={`border rounded-xl p-3 xxl:p-4 cursor-pointer relative ${value === item.value ? 'border-primary' : ''} ${fieldState.error ? 'is-invalid' : ''}`}
                                    htmlFor={item.value}
                                >
                                    <img
                                        src={item.profile_image_url}
                                        alt="Location Img"
                                        className="h-[150px] xl:h-[190px] w-full xxl:h-[200px] rounded-xl object-cover"
                                        // onError={onError}
                                    />
                                    <span className="text-sm font-semibold text-gray-900 mt-3">{t(item.name)}</span>
                                    <Radio className="custom-round-radio d-none" name="location " id={item.value} value={item.value} checked={value === item.value} onChange={onChange} />
                                </label>
                            ))}
                        </div>
                        {fieldState.error && <p className="text-error">{fieldState.error.message}</p>}
                    </>
                )}
            />
        </>
    );
};

export default ExpenseLocation;
