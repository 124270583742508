import React, { memo, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import StaffBooking from './StaffBooking';
import { ChevronRight, ChevronUp } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';

const Overview = ({ staff }: any) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const [booking, setBooking] = useState<any>({});
    const [viewAll, setViewAll] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (id) {
            axiosGet(API.STAFF.BOOKING, { shop_id: shop.id, id })
                .then((response) => {
                    const data = response.data.data;
                    setBooking(data);
                })
                .finally(() => setIsLoading(false));
        }
    }, [id, shop]);

    const handleView = () => {
        setViewAll(!viewAll);
    };

    const KPIS = useMemo(() => {
        const kpis = [
            {
                name: t('Total Sales'),
                number: `${booking.total_amount || 0}$`,
            },
            {
                name: t('Service Sales'),
                number: `${booking.total_service_amount || 0}$`,
            },
            {
                name: t('Total Appointments'),
                number: booking.total_bookings || 0,
            },
            {
                name: t('Total Product Sales'),
                number: `${booking.total_product_amount || 0}$`,
            },
            {
                name: t('Total Tips'),
                number: `${booking.total_tips || 0}$`,
            },
            {
                name: t('Total Referrals'),
                number: `${booking.total_referrals || 0} referrals`,
            },
            {
                name: t('Total Referral Sales'),
                number: `${booking.total_referrals_amount || 0}$`,
            },
            {
                name: t('Appointment Frequency'),
                number: `Every ${booking.booking_frequency || 0} days`,
            },
            {
                name: t('Total Cancellations'),
                number: booking.total_canceled || 0,
            },
        ];
        return viewAll ? kpis : kpis.slice(0, 8);
    }, [booking, viewAll]);

    return (
        <div>
            <div className="mb-8">
                <div className="flex justify-between items-center gap-2 mb-3 mt-6">
                    <h2 className="text-lg font-semibold">{t('Analytics')}</h2>
                    <CustomButton
                        className="!text-sm text-btnBg font-semibold flex items-center gap-1.5 h-[20px] shadow-none pr-0.5"
                        onClick={handleView}
                        rightIcon={viewAll ? <ChevronUp className="w-4 text-btnBg" /> : <ChevronRight className="w-4 text-btnBg" />}
                    >
                        {viewAll ? t('View less') : t('View more')}
                    </CustomButton>
                </div>
                <div className="grid grid-cols-4 gap-5">
                    {KPIS.map((kpi: any, index) => (
                        <div key={index} className="border flex flex-col rounded-lg p-3">
                            <p className="text-gray-600 text-xs font-normal mb-1.5">{kpi.name}</p>
                            <p className={`text-gray-900 text-xs font-medium ${isLoading ? 'custom-loading' : ''}`}>{kpi.number}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mb-8 ">
                <StaffBooking staff={staff} />
            </div>
        </div>
    );
};

export default memo(Overview);
