import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { ArrowRight, Box, ArrowUp, ArrowDown } from '@untitled-ui/icons-react/build/cjs';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { DataTable } from 'primereact/datatable';
import NoDataMessage from 'src/components/NoDataMessage';
import { Column } from 'primereact/column';
import { perPageOptions } from 'src/utils/global-variables';
import { Skeleton } from 'primereact/skeleton';
import moment from 'moment';
import { getShortName } from 'src/utils/global-functions';
import { Stripe } from 'src/theme/Images';
import SearchBar from 'src/components/SearchBar/SearchBar';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'src/constants/path';
import { SETTING_TABS } from 'src/constants/common';
interface Transaction {
    id: string;
    object: string;
    amount: number;
    balance: number;
    created: number;
    created_at: string;
    booking: any;
}
const Transactions = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(true);
    const [transactionList, setTransactionList] = useState([]);
    const [totalTransaction, setTotalTransaction] = useState<number>(0);
    const [filteredTransactionList, setFilteredTransactionList] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 0,
        sortField: null,
        sortOrder: null,
    });

    useEffect(() => {
        getPaymentDetails();
    }, []);

    useEffect(() => {
        if (searchValue) {
            const list = transactionList.filter((obj: any) => {
                const booking = obj.booking;
                if (booking) {
                    const name = booking.user.full_name.toLowerCase();
                    const location = booking.location.name.toLowerCase();
                    const searchParam = searchValue.toLowerCase();
                    return name.includes(searchParam) || location.includes(searchParam);
                }
                return false;
            });
            setFilteredTransactionList(list);
        } else {
            setFilteredTransactionList(transactionList);
        }
    }, [searchValue, transactionList]);

    const debouncedSearch = useCallback(
        debounce((value: string) => {
            setSearchValue(value);
        }, 500),
        [],
    );

    const getPaymentDetails = async () => {
        setIsLoading(true);
        await axiosGet(API.STRIPE.STATEMENTS, { shop_id: shop.id })
            .then((response) => {
                if (response.data.data.length > 0) {
                    let counter = 0;
                    const filteredTransactions = response.data.data.filter((element: any) => {
                        let matchFound = false;
                        if (element.object === 'payout') {
                            counter++;
                            matchFound = true;
                        }
                        if (element.object === 'transfer' && element.booking !== null) {
                            counter++;
                            matchFound = true;
                        }
                        return matchFound;
                    });

                    const updatedTransactions = filteredTransactions.map((transaction: Transaction) => {
                        // If the object is 'payout', ensure the amount is negative, else positive
                        const updatedAmount = transaction.object === 'payout' ? -Math.abs(transaction.amount) : Math.abs(transaction.amount);
                        return {
                            ...transaction,
                            amount: updatedAmount,
                        };
                    });
                    setTotalTransaction(counter);
                    setTransactionList(updatedTransactions);
                } else {
                    setTransactionList([]);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const onPageChange = (event: any) => {
        setLazyState((prevState: any) => ({
            ...prevState,
            first: event.first,
            rows: event.rows,
            page: event.page,
        }));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        debouncedSearch(value);
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ currentPage, totalPages }: { currentPage: number; totalPages: number }) => (
            <div className="text-gray-700 text-sm flex items-center font-medium leading-5 mr-auto">
                Page {currentPage} of {totalPages}
            </div>
        ),
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
    };

    const getUser = (row: any) => {
        const bookingObj = row.booking;
        return (
            <>
                {row.object === 'payout' ? (
                    <div className="flex items-center">
                        <figure className="NoImgName">
                            <img src={Stripe} alt="stripe" title="stripe" className="w-full h-full object-cover" />
                        </figure>
                        <div>
                            <p className="text-xs font-medium w-[80px] min-w-[80px] max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                                Stripe Withdrawal
                            </p>
                            <p className="text-xs font-normal text-secondaryTxtColor w-[80px] min-w-[80px] max-w-[80px] 2xl:w-[120px] 2xl:max-w-[120px] 2xl:min-w-[120px] xlm:min-w-[150px] xlm:w-[150px] xlm:max-w-[150px] truncate">
                                {row.created_at ? moment(row.created_at).fromNow() : '-'}
                            </p>
                        </div>
                    </div>
                ) : (
                    bookingObj && (
                        <div className="flex items-center">
                            <figure className="NoImgName">
                                {bookingObj.user.profile_image_url ? (
                                    <img src={bookingObj.user.profile_image_url} alt={bookingObj.user.full_name} title={bookingObj.user.full_name} className="w-full h-full object-cover" />
                                ) : (
                                    getShortName(bookingObj.user.full_name)
                                )}
                            </figure>
                            <div>
                                <p className="text-xs font-medium w-[90px] min-w-[90px]  max-w-[90px]  xlm:min-w-[110px] xlm:w-[110px] xlm:max-w-[110px] truncate">{bookingObj.user.full_name}</p>
                                <p className="text-xs font-normal text-secondaryTxtColor w-[90px] min-w-[90px]  max-w-[90px]  xlm:min-w-[110px] xlm:w-[110px] xlm:max-w-[110px] truncate">
                                    {row.created_at ? moment(row.created_at).fromNow() : '-'}
                                </p>
                            </div>
                        </div>
                    )
                )}
            </>
        );
    };

    const getLocation = (row: any) => (row.object === 'payout' ? '' : row.booking.location && row.booking.location.name);
    const getTransactionDate = (row: any) =>
        row.object === 'payout' ? moment(row.created_at).format('DD MMM, YYYY · hh:mma') : row.created_at && moment(row.created_at).format('DD MMM, YYYY · hh:mma');
    const getTransactionId = (row: any) => <p className="truncate">{row.object === 'payout' ? row.id : row.id && row.id}</p>;
    const getAmount = (row: any) =>
        row.object === 'payout' ? <p className="text-[#D92D20]">{`-$${Math.abs(row.amount.toFixed(2))}`}</p> : <p className="text-[#079455]">{`+$${row.amount.toFixed(2)}`}</p>;
    const getBalance = (row: any) => (row.object === 'payout' ? `$${row.balance.toFixed(2)}` : `$${row.balance.toFixed(2)}`);
    const getMethod = (row: any) => (row.object === 'payout' ? `` : `Card on file`);
    const getService = (row: any) => {
        const bookingObj = row.booking;
        const servicesArray = row.booking ? row.booking.booking_services : [];
        return (
            <>
                {bookingObj && (
                    <p className="text-xs font-medium text-secondaryTxtColor  w-[100px] min-w-[100px]  max-w-[100px]  xlm:min-w-[110px] xlm:w-[110px] xlm:max-w-[110px] truncate">{`${
                        servicesArray[0].service.name
                    } ${servicesArray.length > 1 ? `+${servicesArray.length - 1}` : ''}`}</p>
                )}
            </>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                created_at: 'loading',
                bookings: { booking_services: { service: { name: 'loading' } }, location: { name: 'loading' } },
                amount: 'loading',
                balance: 'loading',
                method: { location: { name: 'loading' } },
                id: 'loading',
            })),
        [lazyState],
    );

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const handleNavigate = (path: string) => () => {
        navigate(path);
    };

    return (
        <div className=" border-[#EAECF0] py-[30px] bg-white flex flex-wrap flex-1 ">
            <div className=" xl:w-[calc(100%_-_342px)] xls:w-[calc(100%_-_342px)]   mr-2 xls:mb-0  w-full border rounded-xl  flex-col mb-5 flex shadow datatable-custom">
                <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4 ">
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <h2 className="view-table-title capitalize-first ">{t('Transaction History')}</h2>
                        </div>
                        <p className="view-table-subtitle truncate">{t('View complete list of transaction history.')}</p>
                    </div>
                    <div className="flex flex-row gap-4">
                        <div className="w-[150px] xl:w-[180px]  location-dropdown">
                            <SearchBar labelIconClassName="text-gray-500" placeholder={t('Search')} onChange={handleSearch} className="form-control-md " />
                        </div>
                        <div className="w-[70px]">
                            <SelectBox
                                name="page"
                                isClearable={false}
                                options={perPageOptions}
                                onChangeFunc={onPageHandle}
                                value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                classComp="w-[70px]"
                            />
                        </div>
                    </div>
                </div>
                <DataTable
                    paginatorTemplate={customPaginatorTemplate}
                    value={isLoading ? skeletons : filteredTransactionList}
                    totalRecords={totalTransaction}
                    paginator={!isLoading && totalTransaction > lazyState.rows}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    onPage={onPageChange}
                    sortMode="multiple"
                    dataKey="id"
                    className=" rounded-b-xl overflow-hidden"
                    paginatorClassName="table-pagination"
                    emptyMessage={
                        <div className="rounded-xl min-h-[310px] flex justify-center items-center">
                            <NoDataMessage title="No transactions." description={`Add a bookings, to start using the transactions collection.`} iconComponent={<Box className="text-gray-700" />} />
                        </div>
                    }
                    rowClassName={(data) => 'max-w-12 truncate'}
                >
                    <Column
                        field="created_at"
                        header={renderHeader(t('User'), 'created_at')}
                        body={isLoading ? <Skeleton /> : getUser}
                        style={{ width: '160px', minWidth: '160px', maxWidth: '160px' }}
                        className="font-normal text-mainTextColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="booking.booking_services.service.name"
                        header={t('Service')}
                        body={isLoading ? <Skeleton /> : getService}
                        style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                    ></Column>
                    <Column
                        field="booking.location.name"
                        header={renderHeader(t('Location'), 'booking.location.name')}
                        body={isLoading ? <Skeleton /> : getLocation}
                        style={{ width: '120px', minWidth: '120px', maxWidth: '120px' }}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="created_at"
                        header={renderHeader(t('Transaction Date'), 'created_at')}
                        style={{ width: '160px', minWidth: '160px', maxWidth: '160px' }}
                        body={isLoading ? <Skeleton /> : getTransactionDate}
                        className="text-xs font-normal text-secondaryTxtColor truncate"
                        sortable
                    ></Column>
                    <Column
                        field="id"
                        header={renderHeader(t('Transaction ID'), 'id')}
                        body={isLoading ? <Skeleton /> : getTransactionId}
                        style={{ width: '150px', minWidth: '150px', maxWidth: '150px' }}
                        className="font-normal text-secondaryTxtColor text-xs truncate"
                        sortable
                    ></Column>
                    <Column
                        field="method"
                        header={t('Checkout method')}
                        body={isLoading ? <Skeleton /> : getMethod}
                        style={{ width: '140px', minWidth: '140px', maxWidth: '140px' }}
                        className="font-normal text-secondaryTxtColor text-xs truncate"
                    ></Column>
                    <Column
                        field="amount"
                        header={renderHeader(t('Amount'), 'amount')}
                        body={isLoading ? <Skeleton /> : getAmount}
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        className="font-normal text-secondaryTxtColor text-xs truncate"
                        sortable
                    ></Column>
                    <Column
                        field="balance"
                        header={renderHeader(t('Balance'), 'balance')}
                        body={isLoading ? <Skeleton /> : getBalance}
                        style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                        className="font-normal text-secondaryTxtColor text-xs truncate"
                        sortable
                    ></Column>
                </DataTable>
            </div>

            <div className="w-[320px] ml-2 border border-[#D0D5DD]  rounded-lg h-[461px]">
                <div className="flex justify-between items-center gap-4 py-3.5 px-4  border-b ">
                    <div className="flex flex-col">
                        <div className="text-base font-semibold text-mainTextColor">Payment Settings</div>
                        <div className="text-sm text-secondaryTxtColor font-normal">Manage your payment preferences and policies.</div>
                    </div>
                </div>
                <div onClick={handleNavigate(`${PATH.SETTINGS}${SETTING_TABS.PAYMENT}`)} className="flex justify-between items-center gap-4 p-4 border-b h-[92.12px] cursor-pointer hover:bg-[#F9FAFB]">
                    <div className="flex flex-col gap-1">
                        <div className="text-sm font-semibold text-mainTextColor">My Stripe Account</div>
                        <div className="text-xs text-secondaryTxtColor font-normal">Manage and review your Stripe account details.</div>
                    </div>
                    <ArrowRight className="w-[18px] min-w-[18px] h-[18px] text-[#344054]" />
                </div>
                <div
                    onClick={handleNavigate(`${PATH.SETTINGS}${SETTING_TABS.CALENDAR}`)}
                    className="flex justify-between items-center gap-4 p-4 border-b h-[92.12px] cursor-pointer hover:bg-[#F9FAFB]"
                >
                    <div className="flex flex-col gap-1">
                        <div className="text-sm font-semibold text-mainTextColor">Appointment Booking Policies</div>
                        <div className="text-xs text-secondaryTxtColor font-normal">Configure card-on-file requirements for clients during booking.</div>
                    </div>
                    <ArrowRight className="w-[18px] h-[18px] min-w-[18px] text-[#344054]" />
                </div>
                <div onClick={handleNavigate(`${PATH.SETTINGS}${SETTING_TABS.PAYMENT}`)} className="flex justify-between items-center gap-4 p-4 border-b h-[92.12px] cursor-pointer hover:bg-[#F9FAFB]">
                    <div className="flex flex-col gap-1">
                        <div className="text-sm font-semibold text-mainTextColor">Cancellation Policy</div>
                        <div className="text-xs text-secondaryTxtColor font-normal">Enforce policies to manage and reduce appointment cancellations.</div>
                    </div>
                    <ArrowRight className="w-[18px] h-[18px] min-w-[18px] text-[#344054]" />
                </div>
                <div
                    onClick={handleNavigate(`${PATH.SETTINGS}${SETTING_TABS.PAYMENT}`)}
                    className="flex justify-between items-center gap-4 p-4 h-[90px] rounded-b-lg cursor-pointer hover:bg-[#F9FAFB]"
                >
                    <div className="flex flex-col gap-1">
                        <div className="text-sm font-semibold text-mainTextColor">No-Show Policy</div>
                        <div className="text-xs text-secondaryTxtColor font-normal">Enforce policies to manage and reduce appointment no-shows.</div>
                    </div>
                    <ArrowRight className="w-[18px] h-[18px] min-w-[18px] text-[#344054]" />
                </div>
            </div>
        </div>
    );
};

export default Transactions;
