export const initAction = {
    login: true,
    otp: false,
    phoneEmailVerification: false,
    passwordVerification: false,
};
export interface IInitAction {
    login: boolean;
    otp: boolean;
    phoneEmailVerification: boolean;
    passwordVerification: boolean;
}

export interface IAuthData {
    isEmail: boolean;
    email: any;
    phone: any;
}
export const AuthDataInit: IAuthData = {
    isEmail: true,
    email: null,
    phone: null,
};
