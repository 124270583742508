import React, { useState } from 'react';
import Login from './Login/Login';
import VerifyOtp from './Password/VerifyOtp/VerifyOtp';
import { AuthDataInit, IAuthData, IInitAction, initAction } from './Home.interface';
import PhoneEmail from './Login/PhoneEmail';
import PasswordVerification from './Password/PasswordVerification';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from 'src/constants/common';

const Home = () => {
    const [authAction, setAuthAction] = useState(initAction);
    const [authData, setAuthData] = useState<IAuthData>(AuthDataInit);

    const handleAuthAction = (type: keyof IInitAction) => {
        setAuthAction(() => {
            const resetActions = Object.keys(initAction).reduce((acc, key) => {
                acc[key as keyof IInitAction] = false;
                return acc;
            }, {} as IInitAction);
            return { ...resetActions, [type]: true };
        });
    };

    const resetAuthAction = () => {
        setAuthAction(initAction);
        setAuthData(AuthDataInit);
    };

    if (authAction.otp) {
        return <VerifyOtp authData={authData} setAuthData={setAuthData} handleAuthAction={handleAuthAction} resetAuthAction={resetAuthAction} />;
    } else if (authAction.phoneEmailVerification) {
        return <PhoneEmail authData={authData} setAuthData={setAuthData} handleAuthAction={handleAuthAction} />;
    } else if (authAction.passwordVerification) {
        return <PasswordVerification authData={authData} resetAuthAction={resetAuthAction} />;
    } else {
        return (
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID || ''}>
                <Login authData={authData} setAuthData={setAuthData} handleAuthAction={handleAuthAction} />
            </GoogleOAuthProvider>
        );
    }
};

export default Home;
