import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from 'src/components/PageHeader';

const SideNotification = () => {
    const { t } = useTranslation();
    const tabs = useMemo(
        () => [
            {
                name: t('general'),
            },
            {
                name: t('appointment'),
            },
        ],
        [],
    );

    const [currentTab, setCurrentTab] = useState<string>('general');

    const handleTabClick = (tabName: string) => (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setCurrentTab(tabName);
    };

    return (
        <div className="inner-page-wrape">
            <div className="flex-1">
                <PageHeader title="Notification" subtitle="Manage your notifications" />
                <div className="mt-5">
                    <div className="fl-tab-btn-view w-full">
                        {tabs.map((tab, index) => (
                            <button key={index} type="button" className={`fl-tab-link ${currentTab === tab.name ? 'active' : ''}`} onClick={handleTabClick(tab.name)}>
                                {tab.name}
                            </button>
                        ))}
                    </div>
                    {currentTab}
                </div>
            </div>
        </div>
    );
};

export default SideNotification;
