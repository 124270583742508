import React from 'react';
import Header from './Header';
import SidebarNav from './SidebarNav';
import { Outlet } from 'react-router-dom';

const DashboardLayout = ({ children }: any) => (
    <div className="dashbaord_wrapper flex bg-sidebarBg w-full min-h-full">
        <SidebarNav />
        <div className="dashboard_content_wrapper">
            <div className="dashboard_content">
                <Header />
                <Outlet />
            </div>
        </div>
    </div>
);

export default DashboardLayout;
