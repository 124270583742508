import React from 'react';
import { CoinsHand, CoinsStacked01, Percent03 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import { GoDotFill } from 'react-icons/go';

const NotificationStatements = ({ notificationRecord, key, isLoading = false }: any) => (
    <div key={key}>
        <div
            className={`flex flex-row border border-borderSecondary rounded-lg gap-3 p-4 relative  w-full ${
                notificationRecord.is_read && !isLoading ? 'opacity-[50%]' : 'cursor-pointer hover:bg-[#F9FAFB]'
            }  `}
        >
            <div className="flex flex-row gap-3 ">
                <div className={`${isLoading ? 'custom-loading w-[40px] h-[40px] rounded-full' : ''}`}>
                    <div className="w-10 h-10 min-w-10 min-h-10 flex justify-center items-center rounded-lg border shadow border-[#5279F0] bg-[#EDF1FD]">
                        <Percent03 className="w-5 h-5 text-[#143CB3]" />
                    </div>
                </div>
                <div className="flex flex-col gap-[3px]">
                    {isLoading ? (
                        <span className={`${isLoading ? 'custom-loading max-w-[370px] w-[370px] min-w-[370px]' : ''}`}>loading</span>
                    ) : (
                        <div className="text-sm font-medium text-mainTextColor">{notificationRecord.description}</div>
                    )}
                    {isLoading ? (
                        <span className={`${isLoading ? 'custom-loading max-w-[250px] w-[250px] min-w-[250px]' : ''}`}>loading</span>
                    ) : (
                        <div className="text-xs font-normal text-secondaryTxtColor">{moment(notificationRecord.created_at).fromNow()}</div>
                    )}
                </div>
            </div>
            {!notificationRecord.is_read && (
                <div className="absolute top-1 right-[3px] p-1">
                    <GoDotFill size={16} color="#17B26A" className="h-4 w-4 rounded-full flex mr-0.5" />
                </div>
            )}
        </div>
        {/* <div className="flex flex-row border border-borderSecondary rounded-lg gap-3 p-4 relative  w-full mb-3 opacity-[100%] cursor-pointer hover:bg-[#F9FAFB]">
                <div className="flex flex-row gap-3 ">
                    <div className="w-10 h-10 min-w-10 min-h-10 flex justify-center items-center rounded-lg border shadow border-[#5279F0] bg-[#EDF1FD]">
                        <CoinsStacked01 className="w-5 h-5 text-[#143CB3]" />
                    </div>
                    <div className="flex flex-col gap-[3px]">
                        <div className="text-sm font-medium text-mainTextColor">Your latest rent collection statement is now available</div>
                        <div className="text-xs font-normal text-secondaryTxtColor">2 hours ago</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row border border-borderSecondary rounded-lg gap-3 p-4 relative  w-full mb-3  opacity-[100%] cursor-pointer hover:bg-[#F9FAFB]">
                <div className="flex flex-row gap-3 ">
                    <div className="w-10 h-10 min-w-10 min-h-10 flex justify-center items-center rounded-lg border shadow border-[#5279F0] bg-[#EDF1FD]">
                        <CoinsHand className="w-5 h-5 text-[#143CB3]" />
                    </div>
                    <div className="flex flex-col gap-[3px]">
                        <div className="text-sm font-medium text-mainTextColor">Your Tip payment settings has been changed from Daily to weekly.</div>
                        <div className="text-xs font-normal text-secondaryTxtColor">2 hours ago</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row border border-borderSecondary rounded-lg gap-3 p-4 relative  w-full  opacity-[100%] cursor-pointer hover:bg-[#F9FAFB]">
                <div className="flex flex-row gap-3 ">
                    <div className="w-10 h-10 min-w-10 min-h-10 flex justify-center items-center rounded-lg border shadow border-[#EAECF0] bg-[#FFFFFF] text-[#98A2B3]">
                        <CoinsHand className="w-5 h-5 text-[#98A2B3]" />
                    </div>
                    <div className="flex flex-col gap-[3px]">
                        <div className="text-sm font-medium text-secondaryTxtColor">Your Tip payment settings has been changed from Daily to weekly.</div>
                        <div className="text-xs font-normal text-secondaryTxtColor">2 hours ago</div>
                    </div>
                </div>
            </div> */}
    </div>
);

export default NotificationStatements;
