import React, { useEffect, useRef, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { HiCheck } from 'react-icons/hi';

import { ICustomSelectDropdown } from './CustomSelectDropdown.interface';
import Button from '../Button';
import { XClose } from '@untitled-ui/icons-react/build/cjs';

const CustomSelectDropdown: React.FC<ICustomSelectDropdown> = ({
    data,
    className,
    subSwitch,
    handleItemClick,
    setSubSwitch,
    dropDownMenuClassName,
    handleAddClick,
    handleRemoveClick,
    handleCloseDropdown,
    createRoleError,
    dropDownMenuItemClassName,
    dropDownMenuLinkClassName,
    onKeyPress,
    value,
    isDisabled = false,
    id,
    InputError,
}) => {
    const [Switch, setSwitch] = useState(false);

    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [hicheckIndex, setHicheckIndex] = useState<number | null>(null);
    const [newRole, setNewRole] = useState<string>('');

    const showHicheck = (event: any, index: any) => {
        event.stopPropagation();
        setHicheckIndex(index);
    };
    const handleRemoveClick2 = (event: any, item: any, index: any) => {
        event.stopPropagation();
        handleRemoveClick(item.value);
    };
    const handleAddButtonClick = () => {
        handleAddClick(newRole);
        setNewRole('');
    };
    const closeHandler = () => {
        setSwitch(false);
        setSubSwitch?.(false);
        setHicheckIndex(null);
        setHoveredIndex(null);
    };

    useEffect(() => {
        if (handleCloseDropdown) {
            closeHandler();
        }
    }, [handleCloseDropdown]);
    useEffect(() => {
        const handler = () => {
            setSwitch(false);
            setSubSwitch?.(false);
        };
        // window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        };
    }, []);

    const handlerInputClick = (e: any) => {
        e.stopPropagation();

        if (!isDisabled) {
            setSwitch(!Switch);
            setSubSwitch?.(!subSwitch || false);
        }
    };

    const onItemClick = (e: any) => {
        handleItemClick && handleItemClick(e);
        setHicheckIndex(null);
        closeHandler();
    };

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Function to handle click outside the div
        const handleClickOutside = (event: MouseEvent) => {
            if (divRef.current && !divRef.current.contains(event.target as Node)) {
                closeHandler();
            }
        };

        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [divRef]);

    return (
        <div id={id} className="relative" ref={divRef}>
            <label className="fl-field-title-label">
                Role{''}
                <span className="asterisk">*</span>
            </label>

            <div className={`flex items-center border border-gray-300 input-group ${InputError}`} onClick={handlerInputClick}>
                <div className={className}>
                    <span
                        className={`border-none focus:ring-0 text-gray-900  font-medium leading-[19px] ${value?.label ? 'text-gray-900' : 'text-placeholderTxt  font-medium leading-[19px] text-xs'}`}
                    >
                        {value?.label || 'Select Role'}
                    </span>
                    {typeof value?.label === 'string' && <i className="icon ml-auto text-xl">{Switch || subSwitch ? <HiChevronUp /> : <HiChevronDown />}</i>}
                </div>
            </div>
            {Switch && (
                <div id="dropdownDelay" className={`z-10 absolute mt-1 rounded-lg  shadow-DropDown  bg-white border border-gray-200  py-1  right-0 top-[100%]  ${dropDownMenuClassName}`}>
                    <ul className="text-sm w-full max-h-[160px] overflow-y-auto active" aria-labelledby="dropdownDelayButton">
                        {data.map((items: any, index: number) => (
                            <li
                                className={`abcv ${dropDownMenuItemClassName} ${value?.value === items?.value ? 'fwpo_select__option--is-selected' : ''}`}
                                key={index}
                                value={items.value}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                            >
                                {/* The main content */}
                                <div
                                    className={`cursor-pointer text-sm leading-[18px] flex items-center font-medium text-gray-900 mx-1 px-2 hover:rounded-md bg-[#f9fafb] hover:text-brand-500 h-[38px] ${dropDownMenuLinkClassName}`}
                                    onClick={() => onItemClick(items)}
                                >
                                    {items.label && <span>{items.label}</span>}

                                    {/* The section that becomes visible on hover */}
                                    {(hoveredIndex === index || hicheckIndex === index) && (
                                        <div className="ml-auto flex items-center">
                                            <span
                                                className="text-[#F04438]"
                                                onClick={(e: any) => {
                                                    showHicheck(e, index);
                                                }}
                                            >
                                                <XClose className="text-[#F04438] w-5" />
                                            </span>
                                            {hicheckIndex === index && (
                                                <i
                                                    className="icon ml-5 text-xl text-primary"
                                                    onClick={(e: any) => {
                                                        handleRemoveClick2(e, items, index);
                                                    }}
                                                >
                                                    <HiCheck className="w-4" />
                                                </i>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </li>
                        ))}

                        {/* <li className={`${dropDownMenuItemClassName}`}>
                                <div
                                    className={`cursor-pointer flex items-center px-5 py-[10px] hover:text-primary hover:font-medium  ${dropDownMenuLinkClassName}`}
                                    onClick={onItemClick}
                                >
                                    <span>Makeup Artist</span>
                                    <div className="ml-auto flex items-center">
                                        <span className="text-dangerText font-normal text-xs">
                                            Remove
                                        </span>
                                        <i className="icon ml-5  text-xl text-primary">
                                            <HiCheck />
                                        </i>
                                    </div>
                                </div>
                            </li>
                            <li className={`${dropDownMenuItemClassName}`}>
                                <div
                                    className={`cursor-pointer flex items-center px-5 py-[10px] hover:text-primary hover:font-medium  ${dropDownMenuLinkClassName}`}
                                    onClick={onItemClick}
                                >
                                    <span>Barber</span>
                                    <div className="ml-auto flex items-center">
                                        <span className="text-dangerText font-normal text-xs">
                                            Remove
                                        </span>
                                        <i className="icon ml-5  text-xl text-primary">
                                            <HiCheck />
                                        </i>
                                    </div>
                                </div>
                            </li> */}
                    </ul>
                    <div className="px-3 border-t py-[10px] mt-1 w-full">
                        <div className="flex items-center  w-full gap-2.5">
                            <div className="relative input-group flex-1">
                                <input
                                    type="text"
                                    placeholder="Add new role"
                                    className="bg-transparent border-0 form_input_control  py-2 px-4 flex-1 w-full text-sm focus:border-0 focus:shadow-none focus:ring-0 "
                                    autoFocus
                                    onKeyDown={(e: any) => {
                                        if (e.keyCode === 13) {
                                            e.preventDefault();
                                            handleAddButtonClick();
                                        }
                                    }}
                                    onChange={(e: any) => setNewRole(e.target.value)}
                                    value={newRole}
                                />
                            </div>
                            <Button
                                type="button"
                                primary
                                className="flex justify-center gap-[6px] w-[52px] text-xs leading-[18px] font-semibold py-[9px] bg-primary text-white  h-[36px] shadow-InputAndButton p-button p-component"
                                onClick={handleAddButtonClick}
                            >
                                ADD
                            </Button>
                        </div>
                        {createRoleError && <p className="text-error">This role has already been created</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomSelectDropdown;
