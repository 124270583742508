import React from 'react';
import CalendarNotifi from 'src/assets/svgs/calendar-silhouette 1.svg';
import Check from 'src/assets/svgs/check (5) 1.svg';
import CalenderRed from 'src/assets/svgs/calender-red.svg';
import UserSVG from 'src/assets/svgs/user.svg';
import CommissionStatementSVG from 'src/assets/svgs/commission-statement.svg';
import RentStatementSVG from 'src/assets/svgs/rent-statement.svg';
import { formatDistanceToNow } from 'date-fns';
import { INotification } from './Notification.interface';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

interface IProps {
    className: string;
    isNegative: boolean;
    notificationRecord: INotification;
    handleCloseAll: () => void;
}

const NotificationCardComponent = ({ className, isNegative, notificationRecord, handleCloseAll }: IProps) => {
    const getCardIcon = () => {
        if (['pending'].includes(notificationRecord.booking_status)) {
            return CalendarNotifi;
        } else if (['confirmed', 'completed'].includes(notificationRecord.booking_status)) {
            return Check;
        } else if (['no_show', 'declined', 'canceled', 'request_canceled', 'expired'].includes(notificationRecord.booking_status)) {
            return CalenderRed;
        } else if (['admin_staff_register_completed'].includes(notificationRecord?.status)) {
            return CalenderRed;
        } else if (['admin_user_created'].includes(notificationRecord?.status)) {
            return UserSVG;
        } else if (['admin_rent_weekly', 'admin_rent_monthly', 'admin_rent_bi_weekly'].includes(notificationRecord?.status)) {
            return RentStatementSVG;
        } else if (['admin_commission_bi_weekly', 'admin_commission_weekly', 'admin_commission_monthly'].includes(notificationRecord?.status)) {
            return CommissionStatementSVG;
        } else {
            return CalendarNotifi;
        }
    };
    const navigate = useNavigate();

    const navigateTo = () => {
        if (['pending'].includes(notificationRecord.booking_status)) {
            navigate(ROUTES.CALENDAR);
        } else if (['confirmed', 'completed'].includes(notificationRecord.booking_status)) {
            navigate(ROUTES.CALENDAR);
        } else if (['no_show', 'declined', 'canceled', 'request_canceled', 'expired'].includes(notificationRecord.booking_status)) {
            navigate(ROUTES.CALENDAR);
        } else if (['admin_staff_register_completed'].includes(notificationRecord?.status)) {
            navigate(ROUTES.STAFF.LIST);
        } else if (['admin_user_created'].includes(notificationRecord?.status)) {
            navigate(ROUTES.CLIENT.LIST);
        } else if (['admin_rent_weekly', 'admin_rent_monthly', 'admin_rent_bi_weekly'].includes(notificationRecord?.status)) {
            navigate(ROUTES.PAYMENT, { state: 'Rent Collection' });
        } else if (['admin_commission_bi_weekly', 'admin_commission_weekly', 'admin_commission_monthly'].includes(notificationRecord?.status)) {
            navigate(ROUTES.PAYMENT, { state: 'Commision Payouts' });
        } else {
            navigate(ROUTES.CALENDAR);
        }
        handleCloseAll();
    };
    return (
        <div className={className}>
            <div>
                <div className={`w-[30px] h-[30px] rounded-[10px] md:h-[50px] md:w-[50px] justify-center flex items-center px-2 md:rounded-2xl ${isNegative ? 'bg-dangerBgLight' : 'bg-inputBg'} `}>
                    <img src={getCardIcon()} alt="" />
                </div>
            </div>
            <div className="w-full">
                <div className="flex justify-between mb-2">
                    <h1 className="tracking-[-0.16px] text-sm md:text-base font-medium text-black">{notificationRecord.name}</h1>
                    <p className=" text-secondaryTxtColor tracking-[-0.14px] text-xs font-normal">
                        {formatDistanceToNow(new Date(notificationRecord.created_at), {
                            addSuffix: true,
                        })}
                    </p>
                </div>
                <div className="w-[90%]">
                    <p className="text-secondaryTxtColor tracking-[-0.12px] md:tracking-[-0.14px] text-xs md:text-sm font-normal leading-[140%]">
                        {notificationRecord.description}
                        <span className="cursor-pointer text-primary font-medium" onClick={navigateTo}>
                            View here.
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NotificationCardComponent;
