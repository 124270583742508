export interface IFormData {
    first_name: string;
    last_name: string;
    phone: string;
    phone_country_code: string;
    email: string;
    login_at: string;
    created_date: string;
    is_book_with_card: boolean;
}

export interface IclientStatusList {
    name: string;
    iconColor: string;
    count?: string;
    value: string;
}

export const initialState: IclientStatusList[] = [
    {
        name: 'Active Clients',
        iconColor: '#1570EF',
        count: '250',
        value: 'active',
    },
    {
        name: 'Loyal Clients',
        iconColor: '#444CE7',
        count: '250',
        value: 'loyal',
    },
    {
        name: 'Recent Clients',
        iconColor: '#344054',
        count: '250',
        value: 'recent',
    },
    {
        name: 'New Clients',
        iconColor: '#079455',
        count: '250',
        value: 'new',
    },
    {
        name: 'Lapsed Clients',
        iconColor: '#DC6803',
        count: '250',
        value: 'lapsed',
    },
];

export const initAction = {
    delete: false,
};

export const overviewPerPageOptions = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '150', value: 150 },
    { label: '200', value: 200 },
];

interface INoteData {
    id: string;
    [key: string]: any;
}

export const noteInitAction = {
    delete: false,
    update: false,
    noteData: null as INoteData | null,
    create: false,
};
