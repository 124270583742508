import React from 'react';
import { Outlet } from 'react-router-dom';

const SidebarLayout = ({ children }: any) => (
    <div className="flex h-screen">
        <Outlet />
    </div>
);

export default SidebarLayout;
