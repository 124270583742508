import React, { FC, useEffect, useState } from 'react';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { selectCalendarData, setData } from '../../Calendar.slice';
import { ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import DateSelect from 'src/components/DateSelect/DateSelect';
import { useTranslation } from 'react-i18next';
import Switch from 'src/components/Switch/Switch';
import DatePickerWithArrow from 'src/components/DatePickerWithArrow/DatePickerWithArrow';

const Recurring = ({}) => {
    const { t } = useTranslation();
    const calendarData = useAppSelector(selectCalendarData);

    const {
        control,
        watch,
        getValues,
        formState: { errors },
    } = useFormContext();

    const frequencyOptions = [
        { value: 'day', label: 'Day' },
        { value: 'week', label: 'Week' },
    ];

    const frequencyNumberOptions = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
    ];
    const isRecurringWatch = watch('is_recurring');
    return (
        <div className="flex flex-col">
            {(calendarData.calendarStep.updateAppointment || calendarData.calendarStep.rescheduleAppointment || calendarData.calendarStep.newAppointment) && (
                <div className="mb-4">
                    <div className="flex flex-col gap-2">
                        <Controller
                            name="is_recurring"
                            control={control}
                            render={({ fieldState: { error }, field: { value, onChange } }: any) => (
                                <div className="w-full flex flex-col">
                                    <div className="flex items-center w-full gap-2">
                                        <label htmlFor="recurring" className="text-gray-700 text-xs font-medium leading-[18px]">
                                            Recurring Appointment
                                        </label>
                                        <Switch onChange={onChange} isChecked={value} />
                                    </div>
                                    {error && <p className="text-error">{error.message}</p>}
                                </div>
                            )}
                        />
                        {isRecurringWatch && (
                            <>
                                <div className="flex flex-col w-full">
                                    <label htmlFor="recurring" className="text-gray-700 text-xs font-medium leading-[18px] mb-1.5">
                                        Frequency
                                    </label>
                                    <div className="flex gap-4">
                                        <Controller
                                            name="recurring_number"
                                            control={control}
                                            render={({ fieldState: { error }, field: { value, onChange } }: any) => (
                                                <div className="w-full recurring-label-height">
                                                    <SelectBox
                                                        isSearchable={false}
                                                        isClearable={false}
                                                        name="recurring_number"
                                                        errorText={!!error}
                                                        id="recurring_number"
                                                        value={frequencyNumberOptions.find((option) => option.value === value) || null}
                                                        options={frequencyNumberOptions}
                                                        onChangeFunc={(selectedOption: any) => {
                                                            onChange(selectedOption.value);
                                                        }}
                                                        isMenuPortalTarget={false}
                                                        placeholder="Select number"
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name="recurring_frequency"
                                            control={control}
                                            render={({ fieldState: { error }, field: { value, onChange } }: any) => (
                                                <div className="w-full">
                                                    <SelectBox
                                                        isSearchable={false}
                                                        isClearable={false}
                                                        name="recurring_frequency"
                                                        errorText={!!error}
                                                        id="recurring_frequency"
                                                        value={frequencyOptions.find((option) => option.value === value) || null}
                                                        options={frequencyOptions}
                                                        onChangeFunc={(selectedOption: any) => {
                                                            onChange(selectedOption.value);
                                                        }}
                                                        isMenuPortalTarget={false}
                                                        placeholder="Select day"
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>

                                <Controller
                                    name="recurring_end_date"
                                    control={control}
                                    render={({ fieldState: { error }, field: { value, onChange } }: any) => (
                                        <div className="w-full recurring-date ">
                                            <span className="text-gray-700 text-xs font-medium leading-[18px] mb-1.5">End date</span>
                                            <DatePickerWithArrow
                                                inputName="start_date"
                                                className={`form_control_date  font-medium text-xs relative w-full `}
                                                placeholder={t('Select date')}
                                                dateFormat="EEEE, MMMM do, yyyy"
                                                isFormate={false}
                                                value={value}
                                                minDate={new Date()}
                                                onChangeValue={onChange}
                                                labelEndIcon={<ChevronDown className="text-gray-700 w-[20px]" />}
                                            />
                                            {error && <p className="text-error">{error.message}</p>}
                                        </div>
                                    )}
                                />
                            </>
                        )}
                    </div>
                </div>
            )}

            {calendarData.bookedSlotInfo && calendarData.bookedSlotInfo.is_recurring && calendarData.calendarStep.bookedAppointment && (
                <div className="mb-3 border-b pb-3 flex flex-col ">
                    <div className="flex">
                        <label htmlFor="newclient" className={`fl-field-title-label text-gray-700 text-xs font-semibold leading-[18px] mb-1.5`}>
                            Recurring Appointment
                        </label>
                    </div>
                    <div className="gap-2 flex flex-col">
                        <div className="flex justify-between items-center text-xs">
                            <div className="flex font-medium select-box-custom ">
                                <span className="font-normal">Frequency</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="font-medium">{`${calendarData.bookedSlotInfo.recurring_number} ${calendarData.bookedSlotInfo.recurring_frequency}`}</span>
                            </div>
                        </div>
                        <div className="flex justify-between items-center text-xs">
                            <div className="flex font-medium select-box-custom ">
                                <span className="font-normal">Recurring End date</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <span className="font-medium">{calendarData.bookedSlotInfo.recurring_end_date}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Recurring;
