import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import Button from 'src/components/Button';
import { errorCode } from 'src/constants/errorCode';
import { useTranslation } from 'react-i18next';
import PhoneOrEmail from 'src/components/PhoneOrEmail';
import { usePhone } from 'src/hooks/usePhone';
import { ILoginProps } from './Login.interface';
import { selectLoading, userPhoneEmailLogin } from './Login.slice';
import { AuthDataInit, IInitAction } from '../Home.interface';
import { FaArrowLeft } from 'react-icons/fa';
import { trim } from 'lodash';
import CustomButton from 'src/components/CustomButton';

interface ILogin {
    authData: any;
    setAuthData: any;
    handleAuthAction: (type: keyof IInitAction) => void;
}
const PhoneEmail: FC<ILogin> = ({ setAuthData, handleAuthAction, authData }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const { setFormatPhone } = usePhone();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const loading = useAppSelector(selectLoading);
    const inputType = authData.email && !authData.phone ? 'phone' : authData.phone && !authData.email ? 'email' : 'both';
    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<ILoginProps>({
        defaultValues: {
            emailPhone: '',
        },
    });

    const handleChange = async (data: any) => {
        const info = data.emailPhone;
        if (info.error) {
            setError('emailPhone', {
                type: 'manual',
                message: info.error,
            });
            return;
        }
        let payload = {};
        if (!authData.isEmail) {
            const mainNumber = `+${authData.phone.code}${authData.phone.number}`;
            payload = {
                email: info.data,
                phone: mainNumber,
                phone_country_code: authData.phone.country,
            };
        } else {
            const mainNumber = `+${info.data.code}${info.data.number}`;
            payload = {
                email: authData.email,
                phone: mainNumber,
                phone_country_code: info.data.country,
            };
        }

        const result: any = await dispatch(userPhoneEmailLogin(payload));

        if (result.type === userPhoneEmailLogin.fulfilled.toString()) {
            let updateAuthData = { ...authData, isEmail: info.isEmail };

            if (!info.isEmail) {
                const phoneInfo = info.data;
                const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
                const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
                updateAuthData = {
                    ...updateAuthData,
                    phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
                };
            } else {
                updateAuthData = {
                    ...updateAuthData,
                    email: info.data,
                };
            }
            setAuthData(updateAuthData);
            handleAuthAction('otp');
        }
        if (result.type === userPhoneEmailLogin.rejected.toString()) {
            const response = result.payload;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError('emailPhone', {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                } else if (response.message) {
                    setError('emailPhone', {
                        type: 'manual',
                        message: response.message,
                    });
                }
                return;
            }
            toast.error(response.message);
        }
    };
    const onInputChange = (data: any) => {
        if (trim(data.data)) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    };
    const handleBackToLogin = () => {
        setAuthData(AuthDataInit);
        handleAuthAction('login');
    };
    return (
        <>
            <div className="content mt-[108px] flex justify-center">
                <div className="w-[380px]">
                    <h1 className=" mb-1 text-[30px] -tracking-[1.2px] leading-10 font-semibold">{!authData.isEmail ? t('Enter your email address') : t('Enter your mobile number')}</h1>
                    <p className="text-[14px] opacity-70 -tracking-[0.3px] leading-[140%] ">
                        {!authData.isEmail ? t('We will use your email address to recover your account') : t('We will use your mobile number to recover your account')}
                        {/* {authData.email && !authData.phone
                            ? t('Add your mobile to aid in account recovery')
                            : authData.phone && !authData.email
                            ? t('Enter your email address')
                            : t('Enter your phone number or email address')} */}
                    </p>
                    <div className="mt-[32px]">
                        <form onSubmit={handleSubmit(handleChange)}>
                            <div className="flex flex-wrap">
                                <p className=" text-xs mb-1 text-labelTxt font-semibold opacity-70 -tracking-[0.3px] leading-[140%]">{!authData.isEmail ? 'Email' : 'Mobile'}</p>
                                <Controller
                                    name="emailPhone"
                                    control={control}
                                    render={({ field: { onChange } }: any) => (
                                        <div className="flex flex-col w-full">
                                            <PhoneOrEmail
                                                onNumberChange={(data: any) => {
                                                    onChange(data);
                                                    onInputChange(data);
                                                }}
                                                errors={errors.emailPhone}
                                                singleType={!authData.isEmail ? 'email' : 'phone'}
                                                inputType={inputType}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <CustomButton primary type="submit" isLoading={loading} disabled={loading || isButtonDisabled} size="w-full" className="!text-base mt-[20px]">
                                {t('Continue')}
                            </CustomButton>
                            <div className="text-center mt-8">
                                <Button type="button" className="text-sm font-semibold" onClick={handleBackToLogin}>
                                    <FaArrowLeft size={14} className="mr-2" /> {t('Back to log in')}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PhoneEmail;
