import React, { useEffect, useRef, useState } from 'react';
import { Badge, Radio } from 'flowbite-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useRolePermission } from 'src/hooks/useRolePermission';
import { DefaultNavSections, initAction } from './Dashboard.interface';
import { defaultImage } from 'src/theme/Images';
import { BellRinging02, ChevronSelectorVertical, HelpCircle, LogOut01, User01 } from '@untitled-ui/icons-react/build/cjs';
import { UsersPlus } from 'src/theme/Images';
import { changeRole, changeShop, currentRole, currentShop, userMe } from 'src/redux/services/common/Common.slice';
import { getShortName, logout, onError } from 'src/utils/global-functions';
import RandomModal from 'src/components/RandomModal/RandomModal';
import { PATH } from 'src/constants/path';
import { SETTING_TABS } from 'src/constants/common';
import SearchBar from 'src/components/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import { some } from 'lodash';
import { notificationInfo, setNotificationToken } from 'src/app/Notification/Notification.slice';
import { onMessageListener, requestForFirebaseToken } from 'src/utils/firebase';
import { INotificationAction, NOTIFICATION_ACTION, NOTIFICATION_STATUS } from 'src/app/Notification/Notification.interface';
import { toast } from 'react-toastify';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import CancelModal from 'src/app/Calendar/Sidebar/CancelAppointment/CancelModal';
import { ROUTES } from 'src/constants/routes';

const SidebarNav = () => {
    const { t } = useTranslation();
    const user = useAppSelector(userMe);
    const role = useAppSelector(currentRole);
    const shop = useAppSelector(currentShop);
    const dispatch = useAppDispatch();
    const { hasPermission } = useRolePermission();
    const shopRef = useRef(null);
    const profileRef = useRef(null);
    const [action, setAction] = useState(initAction);
    const [shops, setShops] = useState([]);
    const [roles, setRoles] = useState([]);
    // const [isCreatedShop, setIsCreatedShop] = useState(false);
    const [notificationAction, setNotificationAction] = useState<INotificationAction>(NOTIFICATION_ACTION);
    const [bookingId, setBookingId] = useState(null);
    const [errorImage, setErrorImage] = useState({
        profile: false,
    });
    const navigate = useNavigate();
    const notificationData = useAppSelector(notificationInfo);
    useEffect(() => {
        const shopStaff = user ? user.shop_staff.filter((shop_staff: any) => shop_staff.shop.created_by === user.id || shop_staff.is_accepted_invitation) : [];
        const allShops = shopStaff.length ? user.shops.filter((item: any) => some(shopStaff, { shop_id: item.id })) : [];
        setShops(allShops);
    }, [user]);

    useEffect(() => {
        if (user) {
            const allRoles = user.roles.filter((item: any) => item.pivot.shop_id === shop.id);
            setRoles(allRoles);

            // setIsCreatedShop(user.shops.some((item: any) => item.created_by === user.id));
        }
    }, [shop]);

    const handleAction = (type: string) => () => {
        setAction((old: any) => ({ ...old, [type]: !old[type] }));
    };

    const handleClose = () => {
        setAction(initAction);
    };

    const handleRole = (selectRole: any) => () => {
        dispatch(changeRole(selectRole));
    };

    const handleShop = (selectShop: any) => () => {
        dispatch(changeShop(selectShop));
    };

    const onErrorImage = (name: string) => () => {
        setErrorImage((old) => ({ ...old, [name]: true }));
    };

    useEffect(() => {
        getOrUpdateFirebaseNotificationToken();
        if (user) {
            onMessageListener()
                .then((payload: any) => {
                    console.log('payload', payload);
                    console.log('payload.data.booking_status', payload.data.booking_status);

                    if (payload.data.booking_status === NOTIFICATION_STATUS.cancelledAppointment) {
                        console.log('payload.data', payload.data);

                        setBookingId(payload.data.booking_id);
                        handleNotificationAction('cancelledAppointment', true);
                        toast.error(payload?.notification?.body);
                    } else {
                        toast.success(payload?.notification?.body);
                    }
                })
                .catch((err) => err);
        }
    }, [user]);
    const handleCreateShop = () => {
        navigate(ROUTES.MULTI_BRAND);
    };
    const getOrUpdateFirebaseNotificationToken = async () => {
        let FCM_TOKEN = await requestForFirebaseToken();
        if (!FCM_TOKEN) {
            FCM_TOKEN = await requestForFirebaseToken();
        }

        if (FCM_TOKEN && FCM_TOKEN !== notificationData.notificationToken && user) {
            dispatch(setNotificationToken(FCM_TOKEN));

            try {
                const response = await axiosPost(API.NOTIFICATION.USER_NOTIFICATION_CREATE_UPDATE, {
                    token: FCM_TOKEN,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            }
        }
    };
    const handleNotificationAction = async (type: keyof INotificationAction, status: boolean) => {
        setNotificationAction({ ...notificationAction, [type]: status });
    };

    return (
        <>
            <aside className="sidebar_Nav">
                <div className="sidebar_Nav_Wrap h-full">
                    <div className="sidebar-brand-dropdown gap-2 mb-4 relative flex cursor-pointer items-center" onClick={handleAction('shop')} ref={shopRef}>
                        <div className="">
                            <img
                                src={shop.admin_logo_image_url || defaultImage}
                                alt={shop.business_name}
                                title={shop.business_name}
                                className="w-[40px] h-[40px] basis-[40px] object-contain rounded-md "
                                onError={onError}
                            />
                        </div>
                        <div className="brand-name-block text-xs flex-auto pr-6">
                            <div className="font-semibold text-gray-900 leading-[18px]  mb-[2px] truncate capitalize">{shop.business_name}</div>
                            <div className="text-gray-600 leading-[18px] truncate">{shop.slug}.getflair.ca</div>
                        </div>
                        <i className="absolute right-[7px] top-2.5 text-gray-400 w-4">
                            <ChevronSelectorVertical className="w-full" />
                        </i>
                    </div>
                    <nav className="header_sidebar_scroll h-[calc(100vh-180px)] overflow-y-scroll">
                        <div className="mb-5 mt-1 sidebar-search-input">
                            <SearchBar
                                placeholder={t('Search')}
                                labelIconClassName="pl-[6px] pr-[6px]"
                                // onChange={handleSearch}
                                className="form-control-md w-full pl-[26px]"
                            />
                        </div>
                        {DefaultNavSections.map((section, index) => {
                            const filteredItems = section.items.filter((item: any) => hasPermission(item.permission));
                            return (
                                filteredItems.length > 0 && (
                                    <React.Fragment key={index}>
                                        <p className="text-[10px] uppercase font-semibold text-gray-400 leading-[18px] mb-1.5">{section.title || shop.business_name}</p>
                                        <ul className="sidebar_Nav_List flex flex-col space-y-2 mb-3">
                                            {filteredItems.map((item: any, itemIndex) => (
                                                <li key={itemIndex}>
                                                    <NavLink to={item.disabled ? '/' : item.to} className={`${item.disabled ? 'pointer-events-none opacity-50' : ''}`}>
                                                        <i className="icon">
                                                            <item.icon className="w-full" />
                                                        </i>
                                                        <span className="text">
                                                            {item.text}
                                                            {item.hasOwnProperty('badge') && (
                                                                <Badge className="ms-auto px-[6px] py-[2px] badge-brand nav-badge" color="gray">
                                                                    {item.badge}
                                                                </Badge>
                                                            )}
                                                        </span>
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    </React.Fragment>
                                )
                            );
                        })}
                    </nav>
                    <div className="pb-[10px] relative bottom-0 z-10 w-full max-w-[218px] xl:max-w-[236px] bg-sidebarBg cursor-pointer" onClick={handleAction('profile')} ref={profileRef}>
                        <div className="sidebar-brand-dropdown gap-2 flex items-center">
                            <figure className=" grow-0 shrink-0 rounded-md overflow-hidden bg-[#F2F4F7] border flex justify-center items-center text-[#3E3CE1] text-xs font-medium">
                                {!errorImage.profile && user.profile_image_url ? (
                                    <img
                                        src={user.profile_image_url}
                                        alt={user.full_name}
                                        title={user.full_name}
                                        onError={onErrorImage('profile')}
                                        className="w-[40px] h-[40px] basis-[40px] object-contain rounded-md"
                                    />
                                ) : user && user.full_name ? (
                                    <span className="rounded-full w-[40px] h-[40px] basis-[40px] flex justify-center items-center"> {getShortName(user.full_name)}</span>
                                ) : null}
                            </figure>
                            <div className="brand-name-block text-xs flex-auto pr-1 xl:pr-6 max-w-[140px] xl:max-w-[150px]">
                                <div className="font-semibold text-gray-900 leading-[18px]  mb-[2px] truncate capitalize">{user.full_name}</div>
                                <div className="text-gray-600 leading-[18px] truncate">{user.email}</div>
                            </div>
                            <i className="absolute right-[7px] top-2.5 text-gray-400 w-4">
                                <ChevronSelectorVertical className="w-full" />
                            </i>
                        </div>
                    </div>
                </div>
            </aside>
            {action.shop && (
                <RandomModal classname="top-6 left-[260px]" parentRef={shopRef} handleClose={handleClose}>
                    <div>
                        {shops.length > 0 && (
                            <div className="flex flex-col gap-1 py-3 px-2 border-b border-gray-200">
                                {shops.map((item: any) => (
                                    <div key={item.id} className="flex p-3 border border-gray-200 rounded-xl items-center gap-3 relative">
                                        <img
                                            className="w-10 h-10 object-contain rounded-md"
                                            src={item.logo_image_url || defaultImage}
                                            alt={item.business_name}
                                            title={item.business_name}
                                            onError={onError}
                                        />
                                        <div className="text-xs leading-[18px]">
                                            <h3 className="font-semibold text-mainTextColor">{item.business_name}</h3>
                                            <p className="font-normal text-secondaryTxtColor">{item.slug}.getflair.ca</p>
                                        </div>
                                        <div className="absolute top-2.5 right-2.5 header_menu_radio">
                                            <Radio
                                                className="h-4 w-4 text-primary !ring-0 focus:!ring-0 focus:!inset-0 focus:!outline-0 !outline-0 !focus:ring-offset-0 !ring-offset-0"
                                                name="shop"
                                                checked={item.id === shop.id}
                                                onChange={handleShop(item)}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="py-3 px-2 ">
                            <button className="header_icons_border rounded-lg flex w-full h-9 justify-center items-center text-xs leading-[18px] font-semibold" onClick={handleCreateShop}>
                                <img className="w-4 h-4 mr-[6px]" src={UsersPlus} alt="UsersPlus" />
                                Create Brand
                            </button>
                        </div>
                    </div>
                </RandomModal>
            )}
            {action.profile && (
                <RandomModal classname="bottom-2 left-[260px]" parentRef={profileRef} handleClose={handleClose}>
                    <div>
                        {roles.length > 0 && (
                            <div className="flex flex-col gap-1 py-3 px-2 border-b border-gray-200">
                                {roles.map((item: any) => (
                                    <div key={item.id} className="flex p-3 border border-gray-200 rounded-xl items-center gap-2 relative">
                                        <figure className=" grow-0 shrink-0  overflow-hidden   flex justify-center items-center uppercase text-[#3E3CE1] text-xs font-medium">
                                            {!errorImage.profile && user.profile_image_url ? (
                                                <img
                                                    src={user.profile_image_url}
                                                    alt={user.full_name}
                                                    title={user.full_name}
                                                    onError={onErrorImage('profile')}
                                                    className="w-[40px] h-[40px] object-cover rounded-md border border-gray-300 border-solid"
                                                />
                                            ) : (
                                                <span className=" uppercase text-[#3E3CE1] w-[38px] h-[38px] bg-[#F2F4F7] border-[1px]   basis-[38px] text-xs font-medium flex rounded-md justify-center items-center">
                                                    {getShortName(user.full_name)}
                                                </span>
                                            )}
                                        </figure>
                                        <div className="text-xs leading-[18px]">
                                            <h3 className="font-semibold text-mainTextColor">{user.full_name}</h3>
                                            <p className="font-normal text-secondaryTxtColor">{item.display_name}</p>
                                        </div>
                                        <div className="absolute top-2.5 right-2.5 header_menu_radio">
                                            <Radio
                                                className="h-4 w-4 text-primary !ring-0 focus:!ring-0 focus:!inset-0 focus:!outline-0 !outline-0 !focus:ring-offset-0 !ring-offset-0"
                                                name="role"
                                                checked={item.id === role.id}
                                                onChange={handleRole(item)}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="border-b border-gray-200 footer_nav px-3 py-3 space-y-2">
                            <NavLink to={`${PATH.SETTINGS}${SETTING_TABS.ACCOUNT}`} className="flex items-center gap-2.5 py-3 px-4 ">
                                <User01 className="icon w-4 text-[#475467]" />
                                <span className="text-xs font-medium text-[#667085]">Account Settings</span>
                            </NavLink>
                            <NavLink to={`${PATH.SETTINGS}${SETTING_TABS.ACCOUNT}`} className="flex items-center gap-2.5 py-3 px-4">
                                <BellRinging02 className="icon w-4" />
                                <span className="text-xs font-medium text-[#667085]">Subscription</span>
                            </NavLink>
                            <NavLink to={`${PATH.SETTINGS}${SETTING_TABS.SUPPORT}`} className="flex items-center gap-2.5 py-3 px-4">
                                <HelpCircle className="icon w-4" />
                                <span className="text-xs font-medium text-[#667085]">Support</span>
                            </NavLink>
                        </div>

                        <NavLink to="#" className="flex items-center gap-2.5 py-3 px-[25px]" onClick={logout}>
                            <LogOut01 className="icon w-[18px] object-contain text-gray-600" />
                            <span className="text-xs font-medium text-[#667085]">Log out</span>
                        </NavLink>
                    </div>
                </RandomModal>
            )}
            {notificationAction.cancelledAppointment && bookingId && <CancelModal isOpen={notificationAction.cancelledAppointment} setIsOpen={handleNotificationAction} bookingId={bookingId} />}
        </>
    );
};
export default SidebarNav;
