import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { Copy02, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import InputWithLabel from 'src/components/InputWithLabel';

const ProductDetailsForm = () => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();

    const {
        fields: informationFields,
        append: appendOption,
        remove: removeOption,
    } = useFieldArray({
        control,
        name: 'details',
    });

    const handleCopyOption = (index: number) => {
        const fieldValues = watch(`details.${index}`);
        appendOption({ ...fieldValues, id: 0 });
    };
    return (
        <>
            <div className="flex flex-col border border-gray-200 rounded-xl w-full max-w-[666px] 2xl:min-w-[810px] xlm:min-w-[910px] xxl:min-w-[1120px]">
                <div className="flex justify-between items-center p-4 ">
                    <div className="flex flex-col">
                        <h2 className="table-title">Additional Information</h2>
                        <h6 className="table-subtitle">Lorem ipsum is placeholder text commonly</h6>
                    </div>
                    <div className="">
                        <CustomButton primary onClick={() => appendOption({ id: 0, name: '', description: '' })} icon={<Plus className="h-4 w-4" />} className="shadow-none ">
                            {t('Add additional product information')}
                        </CustomButton>
                    </div>
                </div>
                <table className="min-w-full">
                    <thead>
                        <tr className="bg-gray-100 *:text-gray-600">
                            <th className="pl-5 py-[13px] text-xs font-medium 2xl:min-w-[240px] xlm:min-w-[240px] xxl:min-w-[300px] text-start">{t('Title')}</th>
                            <th className="pl-5 py-[13px] text-xs font-medium 2xl:min-w-[450px] xlm:min-w-[550px] xxl:min-w-[700px] text-start">{t('Description')}</th>
                            <th className="pl-5 py-[13px] text-xs font-medium 2xl:min-w-[120px] xlm:min-w-[120px] xxl:min-w-[120px] text-start">{t('Action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {informationFields.map((item, index) => (
                            <tr key={item.id} className="py-[14px] border-b last:border-0 border-gray-200 min-h-[64px] h-[64px]">
                                <td className="py-[14px] px-5">
                                    <Controller
                                        name={`details.${index}.name`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <div>
                                                <InputWithLabel
                                                    name={`details.${index}.name`}
                                                    id={`details.${index}.name`}
                                                    required
                                                    placeholder={t('Enter product detail Title')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                />
                                                {/* {error && <p className="text-error">{error.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </td>
                                <td className=" py-[14px] px-5">
                                    <Controller
                                        name={`details.${index}.description`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <div>
                                                <InputWithLabel
                                                    name={`details.${index}.description`}
                                                    id={`details.${index}.description`}
                                                    required
                                                    placeholder={t('Enter product detail description')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                />
                                                {/* {error && <p className="text-error">{error.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </td>
                                <td className="flex gap-2.5 py-[14px] px-5 min-h-[64px] h-[64px]">
                                    <button type="button" onClick={() => handleCopyOption(index)}>
                                        <Copy02 className="text-[#475467] w-4 h-4" />
                                    </button>
                                    {informationFields.length > 1 && (
                                        <button type="button" onClick={() => removeOption(index)}>
                                            <Trash01 className="text-[#475467] w-4 h-4" />
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ProductDetailsForm;
