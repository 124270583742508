export interface IProfileField {
    first_name: string;
    last_name: string;
    email: string;
    phone_country_code: string;
    phone: string;
    profile_image_name: string;
    language: string;
    // profile_image_file: any;
}

export interface IUpdateEmailField {
    email: string;
    otp?: string[];
}

export interface IUpdatePhoneField {
    phone_country_code: string;
    phone: string;
    otp?: string[];
}

export interface IUpdateEmailVerifyPayload {
    email: string;
    otp: string;
}

export interface IUpdatePhoneVerifyPayload {
    phone_country_code: string;
    phone: string;
    otp: string;
}

export interface IUpdateEmailPayload {
    email: string;
}

export interface IUpdatePhonePayload {
    phone_country_code: string;
    phone: string;
}

export interface IInitialState {
    loading: boolean;
    data: any;
    error?: {
        message?: string;
        status?: string;
    } | null;
}

export type IFieldKey = 'first_name' | 'last_name' | 'email' | 'phone_country_code' | 'phone' | 'profile_image_name' | 'language';

export type IUpdateEmailFieldKey = 'email';

export type IUpdatePhoneFieldKey = 'phone' | 'phone_country_code';

export interface IInitLoading {
    google: boolean;
    facebook: boolean;
    apple: boolean;
    emailOrPhone: boolean;
}

export const InitLoading: IInitLoading = {
    google: false,
    facebook: false,
    apple: false,
    emailOrPhone: false,
};

export type SocialType = 'facebook' | 'google' | 'apple';
