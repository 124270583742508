import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { currentShop } from 'src/redux/services/common/Common.slice';
import Button from 'src/components/Button';
import InputWithLabel from 'src/components/InputWithLabel';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';

const PlanUpgrade = ({ planInfo, handleModalClose, currentPlan }: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const shop = useAppSelector(currentShop);
    const shopId = shop.id;

    const setPlanUpgrade = async () => {
        setLoading(true);
        try {
            const response = await axiosPost(
                API.PLAN.UPDATE,
                { plan_id: planInfo.id },
                {
                    shop_id: shopId,
                },
            );

            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setLoading(false);
                handleModalClose();
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setLoading(false);
        }
    };
    const handleSelectPlan = () => {
        setPlanUpgrade();
    };
    return (
        <>
            <h2 className="text-xl text-center font-bold mb-2 leading-[140%] -tracking-[0.4px]">Upgrade plan</h2>
            <div>
                <InputWithLabel
                    label="Your message"
                    placeholder="Enter description (optional)"
                    onChange={() => {}}
                    value={`Hi, we are interested in upgrading plans from ${currentPlan.name} to ${planInfo.name} Plan.`}
                    name="description"
                    textArea
                    readOnly
                    textAearClassName="h-[120px] rounded-3xl resize-none"
                />
                <Button className="fl-btn btn_primary mt-8" type="button" isLoading={loading} disabled={loading} onClick={handleSelectPlan}>
                    Contact Sales
                </Button>
            </div>
        </>
    );
};

export default PlanUpgrade;
