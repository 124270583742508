import React, { memo, FC } from 'react';
import { IProps } from './DeletePopupModal.interface';
import { Modal } from 'flowbite-react';
import CustomButton from '../CustomButton';
import { useTranslation } from 'react-i18next';
import { Trash01 } from '@untitled-ui/icons-react/build/cjs';

const DeletePopupModal: FC<IProps> = ({
    size = 'md',
    dismissible = false,
    position = 'center',
    onClose,
    title,
    description,
    className = '',
    buttonColor = 'bg-primary text-white',
    confirmButtonText = '',
    cancelButtonText = '',
    headerIcon = <Trash01 className="w-6 h-6 text-gray-700" />,
    primary = false,
    isLoading = false,
    headerTitle,
    descriptionClassName = '',
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            show
            popup
            dismissible={dismissible}
            size={size}
            position={position}
            className="modal_backdrop"
            theme={{ content: { inner: `bg-white rounded-xl shadow dark:bg-gray-700`, base: `${size}` } }}
            onClose={onClose(false)}
        >
            {headerTitle ? (
                <Modal.Header className="border-b px-5 py-4">{headerTitle}</Modal.Header>
            ) : (
                <Modal.Header className="px-5 py-4">
                    <div className="border rounded-xl p-3">{headerIcon}</div>
                </Modal.Header>
            )}
            <Modal.Body className={`${descriptionClassName ? 'px-0 py-0' : 'px-5 py-0'}`}>
                {title && <h3 className="text-lg font-semibold mb-1">{title}</h3>}
                <p className={`text-sm font-normal text-gray-600 ${descriptionClassName}`}>{description}</p>
            </Modal.Body>
            <Modal.Footer className="px-5 pt-5 pb-4">
                <div className="flex gap-4 w-full">
                    <CustomButton className="w-full rounded-lg" secondary onClick={onClose(false)}>
                        {cancelButtonText || t('Cancel')}
                    </CustomButton>
                    <CustomButton
                        isLoading={isLoading}
                        className={`w-full text-white rounded-lg ${className} ${primary ? buttonColor : '!bg-dangerErrorText'}`}
                        primary={primary}
                        onClick={onClose(true)}
                    >
                        {confirmButtonText || t('Delete')}
                    </CustomButton>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default memo(DeletePopupModal);
