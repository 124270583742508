import React from 'react';
import './LoadingCover.css';
import { LoaderIcon } from 'src/theme/Images';

const LoadingCover = ({ show, isFullScreen = true }: { show: boolean; isFullScreen?: boolean }) => (
    <div className={`loading-block ${show ? 'block fixed' : 'hidden'}`}>
        <img src={LoaderIcon} alt="loader" />
    </div>
);
export default LoadingCover;
