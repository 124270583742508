import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import { IUpdatePhoneField, IUpdatePhoneFieldKey, IUpdatePhonePayload, IUpdatePhoneVerifyPayload } from './Profile.interface';
import OTPField from 'src/components/OTPField';
import { me } from 'src/redux/services/common/Common.slice';
import Number from 'src/components/Number';
import { useTranslation } from 'react-i18next';
import PopupModal from 'src/components/PopupModal';
import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { usePhone } from 'src/hooks/usePhone';
import { CountryCode } from 'libphonenumber-js';

const UpdatePhone = ({ handleClose }: any) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { getFormatPhone } = usePhone();
    const [isVerify, setIsVerify] = useState(false);
    const [counter, setCounter] = useState(60);
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object({
        phone_country_code: Yup.string().required('This field is required'),
        phone: Yup.string().required('This field is required'),
    }).required();

    const {
        handleSubmit,
        control,
        setError,
        watch,
        setValue,
        formState: { errors },
    } = useForm<IUpdatePhoneField>({
        resolver: yupResolver(schema),
        defaultValues: {
            phone: '',
            otp: new Array(4).fill(''),
        },
    });

    const phoneValue = watch('phone');
    const phoneCodeValue = watch('phone_country_code');

    useEffect(() => {
        const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const handleSave = async (data: IUpdatePhoneField) => {
        setIsLoading(true);
        if (isVerify) {
            const payload: IUpdatePhoneVerifyPayload = {
                ...data,
                otp: data.otp ? data.otp.join('') : '',
            };

            await axiosPatch(API.USER.VERIFY_PHONE, payload)
                .then(async (response) => {
                    dispatch(me());
                    handleClose();
                })
                .catch((error: any) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            let message = '';
                            Object.keys(response.data).forEach((field) => {
                                message = response.data[field][0];
                                setError(field as IUpdatePhoneFieldKey, {
                                    type: 'manual',
                                    message: message,
                                });
                                return;
                            });
                        }
                        return;
                    }
                })
                .finally(() => setIsLoading(false));
        } else {
            const asyncFunction = handlerSendCode(data);
            asyncFunction();
        }
    };

    const handlerSendCode = (data: IUpdatePhoneField) => async () => {
        setIsLoading(true);
        const payload: IUpdatePhonePayload = {
            phone_country_code: data.phone_country_code,
            phone: data.phone,
        };

        await axiosPatch(API.USER.UPDATE_PHONE, payload)
            .then(async (response) => {
                setIsVerify(true);
                setCounter(60);
            })
            .catch((error: any) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = '';
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                            setError(field as IUpdatePhoneFieldKey, {
                                type: 'manual',
                                message: message,
                            });
                            return;
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <PackagePlus className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{t('Enter your mobile number')}</p>
                <span className="text-xs font-normal text-gray-500">{t('Update the phone number linked to your account.')}</span>
            </div>
        </div>
    );

    return (
        <PopupModal
            title={!isVerify && <Title />}
            onClose={handleClose}
            size="w-[480px]"
            secondaryButton={t('Cancel')}
            primaryButton={t('Continue')}
            acceptAction={handleSubmit(handleSave)}
            declineAction={handleClose}
            isLoading={isLoading}
            isCrossSign={isVerify && false}
        >
            <form onSubmit={handleSubmit(handleSave)}>
                <div className="flex flex-wrap modal-input-field">
                    <Controller
                        name="otp"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-full">
                                {isVerify && (
                                    <div className="flex flex-col gap-8 text-center">
                                        <div className="flex flex-col">
                                            <p className="text-mainTextColor leading-[28px] text-lg font-semibold">{t('The 4-digit code has been sent to at:')}</p>
                                            <p className="text-mainTextColor leading-[20px] text-sm font-normal">{phoneValue ? getFormatPhone(phoneValue, phoneCodeValue as CountryCode) : ''}</p>
                                        </div>
                                        <div className="mx-12">
                                            <OTPField otp={value} setOtp={onChange} errors={error} />
                                        </div>
                                        <div>
                                            <p className="text-secondaryTxtColor leading-[19.6px] -tracking-[0.384px] mb-2">{t("Haven't received the code yet?")}</p>
                                            {counter ? (
                                                <span className="leading-[19.6px] text-sm font-bold text-mainTextColor">
                                                    {t('Send again')}
                                                    <span className="leading-5 text-primary text-sm font-bold">
                                                        ({counter}
                                                        {t('sec')})
                                                    </span>
                                                </span>
                                            ) : (
                                                <span
                                                    onClick={handlerSendCode({
                                                        phone_country_code: phoneCodeValue,
                                                        phone: phoneValue,
                                                    })}
                                                    className="text-primary cursor-pointer leading-[19.6px] text-sm font-bold text-mainTextColor"
                                                >
                                                    {t('Send again')}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    />
                    <Controller
                        name="phone"
                        control={control}
                        render={({ field: { value } }: any) => (
                            <div className="w-full">
                                {!isVerify && (
                                    <>
                                        <label htmlFor="phone" className="fl-field-title-label">
                                            New phone number
                                        </label>
                                        <Number
                                            errors={errors}
                                            value={value}
                                            onNumberChange={(country: any, phone: any, code: any, isValid: any) => {
                                                if (isValid) {
                                                    setValue('phone', `+${code}${phone}`);
                                                    setValue('phone_country_code', country);
                                                } else {
                                                    setValue('phone', '');
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    />
                    <Controller name="phone_country_code" control={control} render={({ field: { onChange } }: any) => <input type="hidden" onChange={onChange} />} />
                </div>
            </form>
        </PopupModal>
    );
};

export default UpdatePhone;
