import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { ReactComponent as Spark } from 'src/assets/svgs/spark.svg';
import { ReactComponent as Check } from 'src/assets/svgs/check-blue.svg';
import { ReactComponent as WelcomeLoader } from 'src/assets/img/auth/spinning-dots.svg';
import { BackgroundDesign } from 'src/theme/Images';
import CustomButton from 'src/components/CustomButton';

const Welcome = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showCheck, setShowCheck] = useState(true);
    const handleStep = () => {
        navigate(ROUTES.DASHBOARD);
    };

    useLayoutEffect(() => {
        const timer = setTimeout(() => {
            setShowCheck(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            <div className="w-full flex justify-center relative flex-1 items-center flex-col text-center bg-banner bg-top bg-no-repeat">
                {/* <img src={BackgroundDesign} alt="" className="absolute -top-6 z-[-1]" /> */}
                <div className="w-[600px]">
                    <div className="">
                        <div className="flex flex-col rounded-md items-center mb-4">
                            <div className="p-[14px] bg-white border border-btnborder rounded-xl">
                                <Spark />
                            </div>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="w-[512px] text-center">
                                <h3 className="fwpo_heading_title">{t('Welcome to FLAIR')}</h3>
                                <p className="font-normal  mt-[2px] text-base text-secondaryTxtColor">{t("We're getting everything setup for you, this might take a couple seconds.")}</p>
                            </div>
                        </div>

                        <div className="flex flex-col items-center mt-[33px]">
                            {showCheck ? <WelcomeLoader className="w-[64px] mx-auto" /> : <Check />}
                            <div className="flex w-full justify-center mt-8">
                                <CustomButton type="button" primary onClick={handleStep} disabled={showCheck} className="fl-btn btn_primary w-full bg-btnBg">
                                    {t('Continue')}
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Welcome;
