export const initAction = {
    update: false,
};

export interface IFormData {
    commission_frequency: string;
    commission_day?: string;
    commission_start_date?: string;
    commission_month_day?: string;
    rent_frequency: string;
    rent_day?: string;
    rent_start_date?: string;
    rent_month_day?: string;
    tip_frequency: string;
    tip_day?: string;
    tip_start_date?: string;
    tip_month_day?: string;
}
