export interface ICalendarSettings {
    from_hours: string;
    to_hours: string;
    start_week: string;
    is_buffer_time: boolean;
    buffer_time?: string | null;
    advance_days: string;
    book_time_hours: string;
    book_time_minutes: string;
    cancel_time_hours: string;
    cancel_time_minutes: string;
}

export const timeOptions = [
    { label: '12:00 AM', value: '00:00:00' },
    { label: '1:00 AM', value: '01:00:00' },
    { label: '2:00 AM', value: '02:00:00' },
    { label: '3:00 AM', value: '03:00:00' },
    { label: '4:00 AM', value: '04:00:00' },
    { label: '5:00 AM', value: '05:00:00' },
    { label: '6:00 AM', value: '06:00:00' },
    { label: '7:00 AM', value: '07:00:00' },
    { label: '8:00 AM', value: '08:00:00' },
    { label: '9:00 AM', value: '09:00:00' },
    { label: '10:00 AM', value: '10:00:00' },
    { label: '11:00 AM', value: '11:00:00' },
    { label: '12:00 PM', value: '12:00:00' },
    { label: '1:00 PM', value: '13:00:00' },
    { label: '2:00 PM', value: '14:00:00' },
    { label: '3:00 PM', value: '15:00:00' },
    { label: '4:00 PM', value: '16:00:00' },
    { label: '5:00 PM', value: '17:00:00' },
    { label: '6:00 PM', value: '18:00:00' },
    { label: '7:00 PM', value: '19:00:00' },
    { label: '8:00 PM', value: '20:00:00' },
    { label: '9:00 PM', value: '21:00:00' },
    { label: '10:00 PM', value: '22:00:00' },
    { label: '11:00 PM', value: '23:00:00' },
];

export const bufferOptions = [
    { label: '5 Minute', value: '00:05:00' },
    { label: '10 Minute', value: '00:10:00' },
    { label: '15 Minute', value: '00:15:00' },
    { label: '20 Minute', value: '00:20:00' },
    { label: '25 Minute', value: '00:25:00' },
    { label: '30 Minute', value: '00:30:00' },
    { label: '35 Minute', value: '00:35:00' },
    { label: '40 Minute', value: '00:40:00' },
    { label: '45 Minute', value: '00:45:00' },
    { label: '50 Minute', value: '00:50:00' },
    { label: '55 Minute', value: '00:55:00' },
    { label: '60 Minute', value: '00:60:00' },
];
export const daysOptions = [
    { label: '5 day', value: '5' },
    { label: '10 day', value: '10' },
    { label: '15 day', value: '15' },
    { label: '21 day', value: '21' },
];

export const appointmentTimeOptions = {
    hours: [
        { label: '1 hour', value: '01' },
        { label: '2 hours', value: '02' },
        { label: '3 hours', value: '03' },
        { label: '4 hours', value: '04' },
        { label: '5 hours', value: '05' },
        { label: '6 hours', value: '06' },
        { label: '7 hours', value: '07' },
        { label: '8 hours', value: '08' },
        { label: '9 hours', value: '09' },
        { label: '10 hours', value: '10' },
        { label: '11 hours', value: '11' },
        { label: '12 hours', value: '12' },
        { label: '13 hours', value: '13' },
        { label: '14 hours', value: '14' },
        { label: '15 hours', value: '15' },
        { label: '16 hours', value: '16' },
        { label: '17 hours', value: '17' },
        { label: '18 hours', value: '18' },
        { label: '19 hours', value: '19' },
        { label: '20 hours', value: '20' },
        { label: '21 hours', value: '21' },
        { label: '22 hours', value: '22' },
        { label: '23 hours', value: '23' },
        { label: '24 hours', value: '24' },
    ],
    minutes: [
        { label: '0 minutes', value: '00' },
        { label: '15 minutes', value: '15' },
        { label: '30 minutes', value: '30' },
        { label: '45 minutes', value: '45' },
        { label: '60 minutes', value: '60' },
    ],
};
