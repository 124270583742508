import { ROUTES } from 'src/constants/routes';
import { Archive, BarChartSquare02, Box, Calendar, Contrast02, HomeLine, MarkerPin04, Users02, Users01, Wallet03, Settings02, Bell02 } from '@untitled-ui/icons-react/build/cjs';
import { PATH } from 'src/constants/path';
import { SETTING_TABS } from 'src/constants/common';

export const initAction = {
    notification: false,
    profile: false,
    shop: false,
};

export const DefaultNavSections = [
    {
        title: 'General',
        items: [
            { to: ROUTES.DASHBOARD, icon: HomeLine, text: 'Overview', permission: 'dashboard_view' },
            { to: ROUTES.CALENDAR, icon: Calendar, text: 'Calendar', permission: 'calendar_view' },
            // { to: '#', icon: MessageChatCircle, text: 'Consultations', disabled: true, permission: 'calendar_view', badge: 0 },
            { to: ROUTES.CLIENT.LIST, icon: Users02, text: 'Clients', permission: 'user_view' },
            { to: ROUTES.ANALYTICS, icon: BarChartSquare02, text: 'Analytics', permission: 'analytics_view' },
            { to: ROUTES.PAYMENT, icon: Wallet03, text: 'Payments', permission: 'setting_view' },
            { to: PATH.PRODUCT.LIST, icon: Archive, text: 'Products', permission: 'product_view' },
            // { to: '#', icon: Announcement02, text: 'Marketing', disabled: true, permission: 'setting_view' },
            // { to: ROUTES.ADMIN, icon: AlarmClockOff, text: 'Admin', permission: 'admin_view' },
        ],
    },
    {
        items: [
            { to: ROUTES.LOCATION.LIST, icon: MarkerPin04, text: 'Locations', permission: 'location_view' },
            { to: ROUTES.STAFF.LIST, icon: Users01, text: 'Team', permission: 'staff_view' },
            { to: ROUTES.SERVICES.LIST, icon: Box, text: 'Services', permission: 'service_view' },
            { to: ROUTES.APPEARANCE, icon: Contrast02, text: 'Appearance', permission: 'appearance_view' },
            { to: `${PATH.SETTINGS}${SETTING_TABS.BUSINESS}`, icon: Settings02, text: 'Settings', permission: 'setting_view' },
            // { to: ROUTES.SETTINGS, icon: Settings02, text: 'Settings', permission: 'setting_view' },
            { to: ROUTES.NOTIFICATIONS, icon: Bell02, text: 'Notification', permission: 'setting_view' },
        ],
    },
];
