export type IActionType = {
    upcomingAppointment: boolean;
    staffPerfomance: boolean;
    latestBookingActivity: boolean;
    topServices: boolean;
    notification: boolean;
};

export interface IDashboardDetails {
    from_date: Date | null;
    total: {
        active_users: number;
        active_users_growth: number;
        amount: number;
        amount_growth: number;
        bookings: number;
        bookings_growth: number;
        retention_rate: number;
        retention_rate_growth: number;
        amount_projected: number;
        bookings_projected: number;
    };
    graph: any[];
    upcoming: any[];
    past: any[];
    staff: any[];
    services: any[];
}

export interface IFilterArray {
    value: string;
    name: string;
}
export const overviewPerPageOptions = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '150', value: 150 },
    { label: '200', value: 200 },
];
export const FilterArray: IFilterArray[] = [
    {
        value: 'daily',
        name: 'Today',
    },
    {
        value: 'weekly',
        name: 'This Week',
    },
    {
        value: 'monthly',
        name: 'This Month',
    },
    {
        value: 'yearly',
        name: 'This Year',
    },
];
