import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/redux/hooks';
import CustomButton from 'src/components/CustomButton';
import { errorCode } from 'src/constants/errorCode';
import { userLogin, userPhoneEmailLogin } from './Login.slice';
import { ILoginProps } from './Login.interface';
import { useTranslation } from 'react-i18next';
import PhoneOrEmail from 'src/components/PhoneOrEmail';
import { usePhone } from 'src/hooks/usePhone';
import { IInitAction } from '../Home.interface';
import { trim } from 'lodash';
import { GoogleIcon, FacebookIcon, AppleIcon } from 'src/theme/Images';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { APPLE_CLIENT_ID, APPLE_REDIRECT_URI, FACEBOOK_APP_ID, LOCAL_STORAGE_KEYS } from 'src/constants/common';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { me } from 'src/redux/services/common/Common.slice';
import AppleLogin from 'react-apple-login';

interface ILogin {
    setAuthData: any;
    authData: any;
    handleAuthAction: (type: keyof IInitAction) => void;
}
interface IInitLoading {
    google: boolean;
    facebook: boolean;
    apple: boolean;
    emailOrPhone: boolean;
}
const InitLoading: IInitLoading = {
    google: false,
    facebook: false,
    apple: false,
    emailOrPhone: false,
};
const Login: FC<ILogin> = ({ authData, setAuthData, handleAuthAction }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { setFormatPhone } = usePhone();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [facebook, setFacebook] = useState({ token: null, email: null });
    const [isLoading, setIsLoading] = useState<IInitLoading>(InitLoading);
    const [isDisabled, setIsDisabled] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<ILoginProps>({
        defaultValues: {
            emailPhone: '',
        },
    });

    useEffect(() => {
        if (facebook.email && facebook.token) {
            const payload = {
                register_type: 'facebook',
                token: facebook.token,
            };
            setIsLoading((prev) => ({ ...prev, facebook: true }));
            handleSocialLogin(payload);
        }
    }, [facebook]);
    useEffect(() => {
        const result = Object.values(isLoading).some((value) => value === true);
        setIsDisabled(result);
    }, [isLoading]);

    const handlePhoneEmail = async (payload: any) => {
        const result: any = await dispatch(userPhoneEmailLogin(payload));
        if (result.type === userPhoneEmailLogin.fulfilled.toString()) {
            handleAuthAction('otp');
        }
        if (result.type === userPhoneEmailLogin.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError('emailPhone', {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                } else if (response.message) {
                    setError('emailPhone', {
                        type: 'manual',
                        message: response.message,
                    });
                }
                return;
            }
            toast.error(response.message);
        }
        setIsLoading(InitLoading);
    };

    const handlePhoneOrEmail = async (payload: any, info: any) => {
        const result: any = await dispatch(userLogin(payload));
        if (result.type === userLogin.fulfilled.toString()) {
            let updateAuthData = { ...authData, isEmail: info.isEmail };
            if (!info.isEmail) {
                const phoneInfo = info.data;
                const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
                const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
                updateAuthData = {
                    ...updateAuthData,
                    phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
                };
            } else {
                updateAuthData = {
                    ...updateAuthData,
                    email: info.data,
                };
            }
            setAuthData(updateAuthData);

            handleAuthAction('otp');
        }
        if (result.type === userLogin.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError('emailPhone', {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                } else if (response.message) {
                    setError('emailPhone', {
                        type: 'manual',
                        message: response.message,
                    });
                }
                return;
            }
            toast.error(response.message);
        }
        setIsLoading(InitLoading);
    };

    const handleSocialLogin = async (payload: any) => {
        await axiosPost(API.USER.SOCIAL_LOGIN, payload)
            .then(async (response) => {
                localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, response.data.data.token);
                await dispatch(me());
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = '';
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                        });

                        toast.error(message);
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(InitLoading));
    };

    const handleChange = (data: any) => {
        setIsLoading((prev) => ({ ...prev, emailOrPhone: true }));
        const info = data.emailPhone;
        if (info.error) {
            setError('emailPhone', {
                type: 'manual',
                message: info.error,
            });
            setIsLoading(InitLoading);
            return;
        }
        let payload = {};

        if (authData.phone || authData.email) {
            const mainNumber = `+${info.data.code}${info.data.number}`;
            payload = {
                email: info.data,
                phone: mainNumber,
                phone_country_code: info.data.country,
            };
            handlePhoneEmail(payload);
        } else {
            if (info.isEmail) {
                payload = {
                    email: info.data,
                };
            } else {
                const mainNumber = `+${info.data.code}${info.data.number}`;
                payload = {
                    phone: mainNumber,
                    phone_country_code: info.data.country,
                };
            }
            handlePhoneOrEmail(payload, info);
        }
    };

    const onInputChange = (data: any) => {
        if (trim(data.data)) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: (successResponse) => {
            const accessToken = successResponse.access_token;
            const payload = {
                register_type: 'google',
                token: accessToken,
            };
            setIsLoading((prev) => ({ ...prev, google: true }));
            handleSocialLogin(payload);

            /* try {
                const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                }
            } catch (error) {
                //
            } */
        },
    });

    const handleFacebookLogin = (response: any) => {
        setFacebook((old) => ({ ...old, token: response.accessToken }));
    };

    const handleFacebookProfile = (response: any) => {
        if (response.email) {
            setFacebook((old) => ({ ...old, email: response.email }));
        } else {
            toast.error(t('Email access is required to sign in with Facebook'));
        }
    };

    const handleAppleLogin = (response: any) => {
        if (response.authorization) {
            const payload = {
                register_type: 'apple',
                token: response.authorization.id_token,
            };
            setIsLoading((prev) => ({ ...prev, apple: true }));
            handleSocialLogin(payload);
        }
    };

    /* const handleLogoutFb = () => {
        FacebookLoginClient.logout(() => {
            console.log('logout completed!');
        });
    };

    const handleLogoutGoogle = () => {
        googleLogout();
    }; */

    return (
        <div className="content pt-[108px] flex justify-center">
            <div className="w-[380px]">
                <h1 className=" mb-1 text-[30px] text-mainTextColor -tracking-[1.2px] leading-10 font-semibold">{t('Getting started')}</h1>
                <p className="text-[14px] font-normal opacity-70 -tracking-[0.3px] text-secondaryTxtColor leading-[140%] ">
                    {authData.email && !authData.phone
                        ? t('Enter your phone number')
                        : authData.phone && !authData.email
                        ? t('Enter your email address')
                        : t('Enter your phone number or email address')}
                </p>
                <div className="mt-[32px]">
                    <form onSubmit={handleSubmit(handleChange)}>
                        <div className="flex flex-wrap">
                            <Controller
                                name="emailPhone"
                                control={control}
                                render={({ field: { onChange } }: any) => (
                                    <div className="flex flex-col w-full">
                                        <PhoneOrEmail
                                            onNumberChange={(data: any) => {
                                                onChange(data);
                                                onInputChange(data);
                                            }}
                                            errors={errors.emailPhone}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        <CustomButton primary type="submit" isLoading={isLoading.emailOrPhone} disabled={isDisabled || isButtonDisabled} size="w-full" className="!text-base mt-[20px]">
                            {t('Continue')}
                        </CustomButton>
                        <div className="flex items-center mt-4 ">
                            <div className="h-[1px] w-[175px] bg-lineColor mr-1"></div>
                            <p className="text-sm font-[500]">Or</p>
                            <div className="h-[1px] w-[175px] bg-lineColor ml-1"></div>
                        </div>
                        <CustomButton
                            outlineSecondary
                            isLoading={isLoading.google}
                            disabled={isDisabled || isLoading.google}
                            onClick={handleGoogleLogin}
                            icon={<GoogleIcon className="me-2 h-6" />}
                            size="w-full"
                            className="!text-base mt-4 hover:bg-[#f3f4f6]"
                        >
                            {t('Continue with Google')}
                        </CustomButton>
                        <FacebookLogin
                            appId={FACEBOOK_APP_ID || ''}
                            onSuccess={handleFacebookLogin}
                            onProfileSuccess={handleFacebookProfile}
                            render={({ onClick }) => (
                                <CustomButton
                                    outlineSecondary
                                    isLoading={isLoading.facebook}
                                    disabled={isDisabled || isLoading.facebook}
                                    onClick={onClick}
                                    icon={<FacebookIcon className="me-2 h-6" />}
                                    size="w-full"
                                    className="!text-base mt-4 hover:bg-[#f3f4f6]"
                                >
                                    {t('Continue with Facebook')}
                                </CustomButton>
                            )}
                        />
                        <AppleLogin
                            usePopup
                            clientId={APPLE_CLIENT_ID || ''}
                            redirectURI={APPLE_REDIRECT_URI || ''}
                            callback={handleAppleLogin}
                            scope="email name"
                            responseMode="query"
                            responseType="code"
                            render={({ onClick }) => (
                                <CustomButton
                                    outlineSecondary
                                    isLoading={isLoading.apple}
                                    disabled={isDisabled || isLoading.apple}
                                    onClick={onClick}
                                    icon={<AppleIcon className="me-2 h-6" />}
                                    size="w-full"
                                    className="!text-base mt-4 hover:bg-[#f3f4f6]"
                                >
                                    {t('Continue with Apple')}
                                </CustomButton>
                            )}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
