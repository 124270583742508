import React, { FC, useLayoutEffect, useRef } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

interface IProps {
    valueXField: string;
    seriesArray: any[];
    graphType: string;
    salesLineGraph: any[];
    activeFilter: string;
}
const Totalsaleschart: FC<IProps> = ({ valueXField, seriesArray, graphType, salesLineGraph, activeFilter }) => {
    const chartRef = useRef<am5xy.XYChart | null>(null);

    useLayoutEffect(() => {
        const root = am5.Root.new('chartdiv');
        if (root._logo) {
            root._logo.dispose();
        }
        root.setThemes([am5themes_Animated.new(root)]);

        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                pinchZoomY: false,
                layout: root.verticalLayout,
                maxTooltipDistance: -1,
                cursor: am5xy.XYCursor.new(root, {}),
            }),
        );

        const xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                startLocation: 0.1,
                endLocation: 0.9,
                baseInterval: { timeUnit: 'day', count: 1 },
                renderer: am5xy.AxisRendererX.new(root, {}),
            }),
        );
        xAxis.get('renderer').grid.template.setAll({
            visible: false,
        });

        // xAxis.data.setAll(data);

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                paddingBottom: 10,
                maxDeviation: 0.2,
                extraMax: 0.4,
                numberFormat: "'$'#",
                renderer: am5xy.AxisRendererY.new(root, {}),
            }),
        );

        yAxis.get('renderer').grid.template.setAll({
            strokeDasharray: [5, 5], // This will create a dash pattern of 5 pixels dash and 5 pixels gap
            strokeOpacity: 0.5,
            stroke: am5.color(0xcccccc),
        });

        function createSeries(name: string, field: string, forceHidden: boolean = false) {
            const series = chart.series.push(
                am5xy.SmoothedXLineSeries.new(root, {
                    xAxis: xAxis,
                    yAxis: yAxis,
                    name: name,
                    valueYField: field,
                    valueXField: valueXField,
                    connect: true,
                    setStateOnChildren: true,
                    stroke: am5.color('#0E34A5'),
                    forceHidden: forceHidden,
                    tooltip: am5.Tooltip.new(root, {
                        labelText: undefined,
                        forceHidden: true,
                        animationDuration: 0,
                    }),
                    // tooltip: am5.Tooltip.new(root, {
                    //     pointerOrientation: 'horizontal',
                    //     labelText: '[bold]{name}[/]\n{categoryX}: {valueY}',
                    // }),
                }),
            );
            series.strokes.template.setAll({
                strokeWidth: 2,
            });

            series.fills.template.setAll({
                fillOpacity: 0.1,
                visible: true,
            });

            series.fills.template.set(
                'fillGradient',
                am5.LinearGradient.new(root, {
                    stops: [
                        {
                            color: am5.color('#0E34A5'),
                            brighten: 0.1,
                        },
                        {
                            color: am5.color('#0E34A5'),
                            lighten: 1,
                        },
                    ],
                    rotation: 90,
                }),
            );
            series.bullets.push(function () {
                const circle = am5.Circle.new(root, {
                    radius: 5,
                    stroke: series.get('fill'),
                    strokeWidth: 2,
                    interactive: true,
                    fill: series.get('stroke'),
                    opacity: 0,
                });

                circle.states.create('default', {
                    opacity: 0,
                });

                circle.states.create('hover', {
                    opacity: 1,
                });

                return am5.Bullet.new(root, {
                    sprite: circle,
                });
            });

            series.set('setStateOnChildren', true);
            series.states.create('hover', {});
            series.data.processor = am5.DataProcessor.new(root, {
                dateFormat: 'yyyy-MM-dd',
                dateFields: ['booking_date'],
            });

            series.mainContainer.set('setStateOnChildren', true);
            series.mainContainer.states.create('hover', {});

            series.strokes.template.states.create('hover', {
                strokeWidth: 3,
            });

            series.data.setAll(salesLineGraph);
            series.appear(1000);
        }
        chart?.get('colors')?.set('colors', [am5.color('#1642C5'), am5.color('#01A3FF')]);

        seriesArray.forEach((item: any) => {
            createSeries(item.label, item.value);
        });

        const tooltip = am5.Tooltip.new(root, {});
        tooltip.set(
            'background',
            am5.RoundedRectangle.new(root, {
                fill: am5.color('#EFF1F5'),
                fillOpacity: 1,
                cornerRadiusBL: 12,
                cornerRadiusBR: 12,
                cornerRadiusTL: 12,
                cornerRadiusTR: 12,
            }),
        );
        chart.plotContainer.set('tooltipPosition', 'pointer');
        // chart.plotContainer.set('tooltipHTML', 'div');
        chart.plotContainer.set('tooltipText', 'a');
        chart.plotContainer.set('tooltip', tooltip);

        /* tooltip.label.adapters.add('html', function (html, target) {
            html = '<div>';
            let i = 0;
            chart.series.each(function (seriesItem) {
                const tooltipDataItem = seriesItem.get('tooltipDataItem');
                if (tooltipDataItem) {
                    const dateValueX = tooltipDataItem.get('valueX')!;
                    const date = new Date(dateValueX);
                    const formattedDate = date.toLocaleDateString();

                    if (i !== 0) {
                        html += '<br>';
                    } else {
                        html += `<b>${formattedDate}</b><br>`;
                    }
                    html += `<span style="color:${seriesItem?.get('stroke')}">&#9679;</span> <b class="customClass" style="width:100px;">${seriesItem.get('name')}:</b> $${tooltipDataItem.get(
                        'valueY',
                    )}`; // Custom class and style for series name and value
                }
                i++;
            });
            html += '</div>';
            return html;
        }); */
        tooltip.label.adapters.add('text', function (text, target) {
            text = '';
            var i = 0;

            chart.series.each(function (seriesItem) {
                var tooltipDataItem: any = seriesItem.get('tooltipDataItem');

                if (tooltipDataItem && i === 0) {
                    var dateValueX = tooltipDataItem.get('valueX')!;
                    var date = new Date(dateValueX);
                    var formattedDate = date.toLocaleDateString();
                    const totalInfo = {
                        Products: `$${tooltipDataItem.dataContext.product_amount || 0}`,
                        Services: `$${tooltipDataItem.dataContext.service_amount || 0}`,
                    };

                    if (i !== 0) {
                        text += '\n';
                    } else {
                        text += ` ${formattedDate} \n`;
                    }
                    Object.entries(totalInfo).forEach(([key, value]) => {
                        text += `[${seriesItem?.get('stroke')}]●[/] [bold width:100px class]${key}:[/] ${value}\n`;
                    });
                }
                i++;
            });
            // (target?.element as any)?.classList.add('customClass');
            return text;
        });

        const legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.percent(100),
                x: am5.percent(100),
                centerY: am5.percent(0),
                y: am5.percent(0),
                clickTarget: 'none',
                useDefaultMarker: true,
            }),
        );
        legend.valueLabels.template.set('forceHidden', true);
        legend.markers.template.setAll({
            visible: false,
            width: 10,
            height: 10,
        });
        legend.itemContainers.template.events.on('pointerover', function (e: any) {
            e.target.dataItem.dataContext.hover();
        });
        legend.itemContainers.template.events.on('pointerout', function (e: any) {
            e.target.dataItem.dataContext.unhover();
        });
        legend.data.setAll(chart.series.values);

        chart.children.unshift(
            am5.Label.new(root, {
                // text: '',
                fontSize: '1.25rem',
                fontWeight: '700',
                textAlign: 'left',
                x: am5.percent(0),
                centerX: am5.percent(0),
                paddingTop: 0,
                paddingBottom: 50,
            }),
        );

        const cursor = chart.set(
            'cursor',
            am5xy.XYCursor.new(root, {
                behavior: 'none',
                xAxis: xAxis,
                yAxis: yAxis,
            }),
        );
        cursor.lineX.setAll({
            strokeDasharray: [5, 5],
            stroke: am5.color('#8C9CBB'),
            strokeWidth: 1.5,
        });
        cursor.lineY.setAll({
            visible: false,
        });
        cursor?.events.on('cursormoved', cursorMoved);
        var previousBulletSprites: any = [];
        function cursorMoved() {
            for (var i = 0; i < previousBulletSprites.length; i++) {
                previousBulletSprites[i].unhover();
            }
            previousBulletSprites = [];
            chart.series.each(function (series: any) {
                var dataItem = series.get('tooltip').dataItem;
                if (dataItem) {
                    var bulletSprite = dataItem.bullets[0].get('sprite');
                    bulletSprite.hover();
                    previousBulletSprites.push(bulletSprite);
                }
            });
        }
        chart.plotContainer.events.on('pointerout', removeBullets);
        function removeBullets() {
            for (var i = 0; i < previousBulletSprites.length; i++) {
                previousBulletSprites[i].unhover();
            }
            previousBulletSprites = [];
        }

        chart.appear(1000, 100);

        chartRef.current = chart;
        am5.ready(function () {
            removeCredits();
        });

        const updateChartForTab = (tab: string) => {
            let timeUnit: am5.time.TimeUnit;
            let count: number;
            let dateFormats: Record<string, string> = {};
            console.log('activeFilter', activeFilter);

            switch (tab) {
                case 'yearly':
                    timeUnit = 'month';
                    count = 1; // Ensure 2-hour gaps
                    dateFormats.month = 'MMM yyyy';

                    break;
                case 'weekly':
                    timeUnit = 'day';
                    count = 1;
                    dateFormats.day = 'EEE';

                    break;
                case 'days':
                    timeUnit = 'day';
                    count = 2; // Ensure 2-day gaps
                    dateFormats.day = 'MMM dd';

                    break;
                default:
                    return;
            }

            // Set the axis configuration
            xAxis.set('baseInterval', { timeUnit, count });
            xAxis.set('dateFormats', dateFormats);
        };

        // Initial chart update
        updateChartForTab(activeFilter);

        return () => {
            root.dispose();
        };
    }, [salesLineGraph]);

    // useLayoutEffect(() => {
    //     if (chartRef.current && props?.paddingRight) {
    //         chartRef.current.set('paddingRight', props?.paddingRight);
    //     }
    // }, [chartRef, props.paddingRight]);

    const removeCredits = () => {
        const titles = Array.from(document.querySelectorAll('title'));

        for (const ele of titles) {
            if (ele.textContent && ele.textContent.includes('Chart created using amCharts library')) {
                const id = ele.id;
                const elementWithAriaLabel = document.querySelector<HTMLElement>(`[aria-labelledby=${id}]`);

                if (elementWithAriaLabel) {
                    elementWithAriaLabel.style.display = 'none';
                }
            }
        }
    };

    return <div id="chartdiv" style={{ width: '100%', height: '400px' }}></div>;
};

export default Totalsaleschart;
