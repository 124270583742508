import React, { useEffect, useMemo, useRef, useState } from 'react';
import UpcomingAppointment from './Tables/UpcomingAppointment';
import UpcomingAppointmentDrawer from './Drawers/UpcomingAppointment';
import LatestBookingActivity from './Tables/LatestBookingActivity';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { IActionType, IDashboardDetails } from './Dashboard.Interface';
import KpiCard from 'src/components/KpiCard';
import CommissionPopup from './CommissionPopup';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import LatestBookingActivityDrawer from './Drawers/LatestBookingActivityDrawer';
import AmChart from '../Analytics/AmChart';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, allShopStaff, currentShop, userMe } from 'src/redux/services/common/Common.slice';
import StaffPerfomanceDrawer from './Drawers/StaffPerfomanceDrawer';
import { getDateRange, getSelectBoxOptions } from 'src/utils/global-functions';
import { ISelectedLocation } from '../Staff/Staff.interface';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IconMoney, IconProfit, IconCalendar, IconProductivity } from 'src/theme/Images';
import PageHeader from 'src/components/PageHeader';
import { ArrowRight, TrendUp01, Inbox01 } from '@untitled-ui/icons-react/build/cjs';
import DateRangePicker from 'src/components/DateRangePicker/DateRangePicker';
import CustomButton from 'src/components/CustomButton';
import { GoDotFill } from 'react-icons/go';
import Notification from 'src/app/Notification/Notification';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import SChart from '../Skeleton/Payment/SChart';

const initAction: IActionType = {
    upcomingAppointment: false,
    staffPerfomance: false,
    latestBookingActivity: false,
    topServices: false,
    notification: false,
};
interface IPayload {
    type: string;
    location_id?: number;
    end_date?: any;
    start_date?: any;
    shop_admin_id?: number;
}
interface ITimeFileterOptions {
    label: string;
    value: string;
    endDate?: any;
    startDate?: any;
}

const timeFileterOptions: ITimeFileterOptions[] = [
    { label: 'All time', value: 'all' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Last Month', value: 'last_month' },
];

interface IGraphOptions {
    label: string;
    value: string;
    valueXField: string;
    seriesArray: any[];
}
const graphOptions: IGraphOptions[] = [
    {
        label: 'Total Sales',
        value: 'sales',
        valueXField: 'booking_date',
        seriesArray: [{ label: 'Total Sales', value: 'total_amount' }],
    },
    { label: 'Total Appointments', value: 'bookings', valueXField: 'booking_date', seriesArray: [{ label: 'Total Bookings', value: 'total_bookings' }] },
    { label: 'Total Active Clients', value: 'active_users', valueXField: 'booking_date', seriesArray: [{ label: 'Total Users', value: 'total_users' }] },
    { label: 'Client Retention Rate', value: 'retention_users', valueXField: 'booking_date', seriesArray: [{ label: 'Total Client Retention Rate', value: 'total_users' }] },
];
const selectAllOption: ISelectedLocation = {
    value: 0,
    label: 'All locations ',
};
const selectAllTeamOption: ISelectedLocation = {
    value: 0,
    label: 'All team members',
};

const Dashboard = () => {
    const { t } = useTranslation();
    const notificationButtonRef = useRef(null);
    const [action, setAction] = useState(initAction);
    const [dashboardDetails, setDashboardDetails] = useState<IDashboardDetails | null>(null);
    const shop: any = useAppSelector(currentShop);
    const user: any = useAppSelector(userMe);
    const shopId = shop.id;
    const shopLocationList: any = useAppSelector(allShopLocations);
    const [isOpenCommissionStatementPopup, setIsOpenCommissionStatementPopup] = useState<boolean>();
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('custom');
    const [startDate, setStartDate] = useState<Date | undefined>(moment().toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());
    const [allTimeStartDate, setAllTimeStartDate] = useState<any>(shop.created_at);
    const [activeFilter, setActiveFilter] = useState('daily');
    const [locationOptions, setLocationOptions] = useState<any>([]);
    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation | null>(selectAllOption);
    const [boxLabel, setBoxLabel] = useState('Yesterday');
    const allShopStaffList = useAppSelector(allShopStaff);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<ISelectedLocation | null>(selectAllTeamOption);
    const [selectedGraphOption, setSelectedGraphOption] = useState(graphOptions[0]);
    const [notificationCount, setNotificationCount] = useState({
        all: 0,
        general: 0,
        appointments: 0,
        statements: 0,
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        const updatedLocationData = [selectAllOption, ...locationData];
        setLocationOptions(updatedLocationData);
        setSelectedLocation(updatedLocationData[0]);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        const updatedTeamData = [selectAllTeamOption, ...staff];
        setStaffOptions(updatedTeamData);
        setSelectedTeam(updatedTeamData[0]);
    }, [shop]);

    console.log('selectedLocation', selectedLocation);

    useEffect(() => {
        if (selectedLocation?.value !== 0) {
            const filteredData = locationOptions.find((item: any) => item.id === selectedLocation?.value);
            console.log('filteredData', filteredData.timezone);

            // moment.tz.setDefault(filteredData.timezone);
            // setTimeZone(filteredData[0].timezone);
        }
    }, [selectedLocation]);

    useEffect(() => {
        if (activeFilter !== '') {
            const { start, end } = getDateRange(activeFilter);
            setStartDate(start);
            setEndDate(end);
        }
        if (activeFilter === 'weekly') {
            setBoxLabel('last week');
            setSelectedTimeRange(activeFilter);
        } else if (activeFilter === 'monthly') {
            setBoxLabel('last month');
            setSelectedTimeRange(activeFilter);
        } else if (activeFilter === 'yearly') {
            setBoxLabel('last year');
            setSelectedTimeRange(activeFilter);
        } else if (activeFilter === 'daily') {
            setBoxLabel('yesterday');
            setSelectedTimeRange('custom');
        }
    }, [activeFilter]);

    useEffect(() => {
        if (startDate && endDate) {
            if (selectedTimeRange === 'all_time') {
                setBoxLabel('Previous period');
            } else if (selectedTimeRange === 'quarterly') {
                setBoxLabel('last quarter');
            } else if (activeFilter === '') {
                let days = moment(endDate).diff(moment(startDate), 'days');
                days = selectedTimeRange === 'last_30_days' || selectedTimeRange === 'last_90_days' || selectedTimeRange === 'last_7_days' || selectedTimeRange === 'custom' ? days + 1 : days;

                setBoxLabel(`Previous ${days} days`);
            }
        }
    }, [startDate, endDate]);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleViewClick = (drawerName: string) => async (event: { preventDefault: () => void }) => {
        setAction((prevAction) => ({
            ...initAction,
            [drawerName]: true,
        }));
        setIsDrawerOpen(true);
    };
    const tabs = useMemo(
        () => [
            {
                value: 'daily',
                name: 'Today',
            },
            {
                value: 'weekly',
                name: 'This Week',
            },
            {
                value: 'monthly',
                name: 'This Month',
            },
            {
                value: 'yearly',
                name: 'This Year',
            },
        ],
        [],
    );
    const navigate = useNavigate();
    const createBooking = () => {
        navigate(ROUTES.CALENDAR);
    };
    const getDashboard = async () => {
        if (startDate && endDate) {
            setIsLoading(true);
            try {
                let payload: IPayload = {
                    type: selectedGraphOption.value,
                    start_date: moment(startDate).format('YYYY-MM-DD'),
                    end_date: moment(endDate).format('YYYY-MM-DD'),
                };
                if (selectedLocation && selectedLocation.value !== 0) {
                    payload.location_id = selectedLocation.id;
                }
                if (selectedTeam && selectedTeam.value !== 0) {
                    payload.shop_admin_id = selectedTeam.id;
                }

                const response = await axiosGet(
                    API.DASHBOARD.GET,
                    {
                        shop_id: shopId,
                    },
                    payload,
                );
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    let data = response.data.data;
                    console.log('data', data);

                    if (activeFilter === 'yearly') {
                    }
                    setDashboardDetails(response.data.data);
                    return;
                }

                throw response.data;
            } catch (err: any) {
                toast.error(err?.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    // const getMonthYear = (timestamp: number): string => {
    //     const date = new Date(timestamp);
    //     return date.toLocaleString('default', { month: 'short', year: 'numeric' });
    // };

    // // Group bookings by month
    // const groupedByMonth = data.reduce<Record<string, any[]>>((acc, booking) => {
    //     const monthYear = getMonthYear(booking.booking_date);

    //     if (!acc[monthYear]) {
    //         acc[monthYear] = [];
    //     }

    //     acc[monthYear].push(booking);
    //     return acc;
    // }, {});

    useEffect(() => {
        getDashboard();
    }, [selectedLocation, startDate, endDate, selectedTeam, selectedGraphOption, shop]);

    console.log('activeFilter', activeFilter);

    const handleDatePickerChange = (date: any, timeRange?: string) => {
        console.log('datedate', date);

        if (timeRange === 'weekly' || timeRange === 'monthly' || timeRange === 'yearly') {
            setActiveFilter(timeRange);
        } else {
            setActiveFilter('');
            const [start, end] = date;

            setStartDate(start);
            setEndDate(end);
            if (!end) {
                setEndDate(start);
            }
        }
        if (timeRange) {
            setSelectedTimeRange(timeRange);
        }
    };

    const handleFilterTab = (value: string) => () => {
        setActiveFilter(value);
    };
    const handleTeam = (event: any) => {
        setSelectedTeam(event);
    };
    const handleLocation = (event: any) => {
        setSelectedLocation(event);
    };
    const handleGraphOptionChange = (e: any) => (event: any) => {
        setSelectedGraphOption(e ?? event);
    };
    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleClose = () => {
        setAction(initAction);
    };
    return (
        <div className="inner-page-wrape">
            {dashboardDetails && (
                <div className="inner-page-wrape">
                    <div className="w-full flex flex-1 flex-col">
                        <PageHeader
                            title={'Dashboard'}
                            titleGray={`Week of ${moment().startOf('isoWeek').format(' MMMM Do, YYYY')}`}
                            subtitle={`Welcome back, ${user.first_name}. Here’s an overview of your week so far`}
                        >
                            <div className=" cursor-pointer relative mb-[7px]" onClick={handleAction('notification')} ref={notificationButtonRef}>
                                <CustomButton secondary className="  h-[36px]   !min-w-max ">
                                    <div className="relative flex items-center">
                                        <Inbox01 className="w-5 h-5 !relative text-mainTextColor" />
                                        {Number(notificationCount.all) > 0 && (
                                            <div className="absolute  -top-[3px] right-[7px]">
                                                <GoDotFill size={16} color="#17B26A" className="h-3.5 w-3.5 rounded-full flex " />
                                            </div>
                                        )}
                                    </div>
                                    {Number(notificationCount.all) > 0 ? notificationCount.all : ''} Notifications
                                </CustomButton>
                            </div>

                            {/* <div className="flex flex-col ">
                                <div className="flex flex-row text-xs leading-[18px] font-semibold text-[#475467] items-center gap-2 ml-auto">
                                    Rent Collection in 3 days <ArrowRight className="text-[#475467] w-4" />
                                </div>
                                <div className="flex flex-row text-xs leading-[18px] font-semibold text-[#475467] items-center gap-2">
                                    Commission Payouts in 3 days <ArrowRight className="text-[#475467] w-4" />
                                </div>
                            </div> */}
                        </PageHeader>

                        <div className="side-spaching pt-5">
                            <div className="mb-8 max-2xl:grid-cols-2 grid grid-cols-4 max-xlm:gap-1.5 gap-4 ">
                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'sales'))} className="active">
                                    <KpiCard
                                        headerIcon={IconMoney}
                                        headerTitle={t('Total Sales')}
                                        contentLable={`${dashboardDetails?.total?.amount}`}
                                        growth={dashboardDetails?.total?.amount_growth}
                                        fromDate={boxLabel}
                                        signIcon={'$'}
                                        growthDescription={dashboardDetails.total?.amount_projected ? `$${dashboardDetails.total?.amount_projected} ${t('Projected')} ` : '$0 Projected'}
                                        isActive={selectedGraphOption.value === 'sales'}
                                    />
                                </div>

                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'bookings'))}>
                                    <KpiCard
                                        headerIcon={IconProfit}
                                        headerTitle={t('Total Appointments')}
                                        contentLable={`${dashboardDetails?.total?.bookings}`}
                                        growth={dashboardDetails?.total?.bookings_growth}
                                        fromDate={boxLabel}
                                        growthDescription={dashboardDetails.total?.bookings_projected ? `${dashboardDetails.total?.bookings_projected} ${t('Projected')} ` : '0 Projected'}
                                        isActive={selectedGraphOption.value === 'bookings'}
                                    />
                                </div>

                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'active_users'))}>
                                    <KpiCard
                                        headerIcon={IconCalendar}
                                        headerTitle={t('Total Active Clients')}
                                        contentLable={`${dashboardDetails?.total?.active_users}`}
                                        contentDescription="bookings"
                                        growth={dashboardDetails?.total?.active_users_growth}
                                        fromDate={boxLabel}
                                        isActive={selectedGraphOption.value === 'active_users'}
                                    />
                                </div>
                                <div onClick={handleGraphOptionChange(graphOptions.find((option) => option.value === 'retention_users'))}>
                                    <KpiCard
                                        headerIcon={IconProductivity}
                                        headerTitle={t('Client Retention Rate')}
                                        contentLable={`${dashboardDetails?.total?.retention_rate}`}
                                        growth={dashboardDetails?.total?.retention_rate_growth}
                                        fromDate={dashboardDetails?.from_date}
                                        signIcon={'%'}
                                        isActive={selectedGraphOption.value === 'retention_users'}
                                    />
                                </div>
                            </div>
                            <div className="flex w-full justify-between mb-5 max-xlm:mb-10">
                                <div className="flex flex-col ">
                                    <div className="w-[190px] without_border_select_box ">
                                        <SelectBox
                                            className={'!border-0 without_border_select_box'}
                                            outerClassName={'!border-0 without_border_select_box '}
                                            options={graphOptions}
                                            value={selectedGraphOption}
                                            noOptionsMessage="No Options Found"
                                            placeholder={t('Total Sales')}
                                            isSearchable={false}
                                            onChangeFunc={handleGraphOptionChange(undefined)}
                                            classComp=""
                                            isClearable={false}
                                            isMenuPortalTarget={false}
                                        />
                                    </div>
                                    <div className="flex flex-row items-center gap-4">
                                        <div className="text-lg font-semibold text-mainTextColor">
                                            {selectedGraphOption.value === 'sales'
                                                ? `$${dashboardDetails.total.amount}`
                                                : selectedGraphOption.value === 'bookings'
                                                ? dashboardDetails.total.bookings
                                                : selectedGraphOption.value === 'active_users'
                                                ? dashboardDetails.total.active_users
                                                : dashboardDetails.total.retention_rate}
                                        </div>
                                        <div className="flex flex-row items-center">
                                            <TrendUp01 className="w-5 h-5 mr-1 text-[#17B26A]" />
                                            <span className="font-medium text-sm  text-[#17B26A]">
                                                {selectedGraphOption.value === 'sales'
                                                    ? dashboardDetails.total.amount_growth
                                                    : selectedGraphOption.value === 'bookings'
                                                    ? dashboardDetails.total.bookings_growth
                                                    : selectedGraphOption.value === 'active_users'
                                                    ? dashboardDetails.total.active_users_growth
                                                    : dashboardDetails.total.retention_rate_growth}
                                                %
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex max-xlm:flex-col flex-row h-[36px] gap-2">
                                    <div className="flex flex-row gap-3">
                                        <div className="fl-tab-btn-view2 h-[36px]  flex  ">
                                            {tabs.map((tab, index) => (
                                                <button
                                                    key={index}
                                                    type="button"
                                                    className={`w-full flex fl-tab-link2 justify-center items-center min-h-[34px] !max-w-max !min-w-[91px] ${
                                                        activeFilter === tab.value ? 'active' : ''
                                                    }`}
                                                    onClick={handleFilterTab(tab.value)}
                                                >
                                                    {tab.name}
                                                </button>
                                            ))}
                                        </div>

                                        <DateRangePicker
                                            isDisplayAsBox={false}
                                            handleDatePickerChange={handleDatePickerChange}
                                            selectedTimeRange={selectedTimeRange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            allTimeStartDate={allTimeStartDate}
                                            showYearDropdown={false}
                                            isToday={activeFilter === 'daily'}
                                            isShowDropDownIcon={false}
                                            containerClassName="right-0 "
                                            parentClassName="sales-datepicker min-w-[340px] w-[340px]"
                                            setStartDate={setStartDate}
                                            setEndDate={setEndDate}
                                            activeFilter={activeFilter}
                                            setActiveFilter={setActiveFilter}
                                        />
                                    </div>
                                    <div className="flex flex-row gap-3 ml-auto ">
                                        <div className="w-[200px] xlm:w-[170px]  xxl:min-w-[200px]">
                                            <SelectBox
                                                options={locationOptions}
                                                value={selectedLocation}
                                                noOptionsMessage="No Locations Found"
                                                placeholder={t('All locations')}
                                                onChangeFunc={handleLocation}
                                                classComp=""
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                        </div>
                                        <div className="w-[200px] xlm:w-[170px]   xxl:min-w-[200px]">
                                            <SelectBox
                                                options={staffOptions}
                                                noOptionsMessage="No Team Found"
                                                value={selectedTeam}
                                                placeholder={t('All Team members')}
                                                onChangeFunc={handleTeam}
                                                classComp="outline-select-box"
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white ">
                                {/* <h3 className="text-xl font-bold -tracking-[0.4px]">{t('Sales Analytics')}</h3> */}
                                {isLoading ? (
                                    <div className="h-[400px]">
                                        <SChart />
                                    </div>
                                ) : dashboardDetails.graph.length > 0 ? (
                                    <AmChart
                                        salesLineGraph={dashboardDetails.graph}
                                        seriesArray={selectedGraphOption.seriesArray}
                                        graphType={selectedGraphOption.value}
                                        valueXField={selectedGraphOption.valueXField}
                                        activeFilter={activeFilter}
                                    />
                                ) : (
                                    <>
                                        <div className="border border-borderSecondary rounded-xl shadow mb-4 p-5">
                                            <EmptyMsgWithBtn title={t('No Sales Data')} description={t('No_Sales_Data_description')} btnLabel={t('Create booking')} onClick={createBooking} />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className=" flex mb-3">
                                <div className=" w-9/12">
                                    <UpcomingAppointment />
                                </div>

                                <div className="ml-6 pr-1  w-3/12">
                                    <LatestBookingActivity handleViewClick={handleViewClick} latestData={dashboardDetails.past} />
                                </div>
                            </div>
                            {action.upcomingAppointment && <UpcomingAppointmentDrawer isOpen={isDrawerOpen} handleClose={() => setIsDrawerOpen(false)} />}
                            {action.latestBookingActivity && <LatestBookingActivityDrawer isOpen={isDrawerOpen} handleClose={() => setIsDrawerOpen(false)} />}
                            {action.staffPerfomance && <StaffPerfomanceDrawer isOpen={isDrawerOpen} handleClose={() => setIsDrawerOpen(false)} />}

                            <CommissionPopup
                                isOpenPopup={isOpenCommissionStatementPopup}
                                handleClose={() => {
                                    setIsOpenCommissionStatementPopup(false);
                                }}
                            />
                            <Notification
                                show={action.notification}
                                notificationButtonRef={notificationButtonRef}
                                notificationCount={notificationCount}
                                handleClose={handleClose}
                                setNotificationCount={setNotificationCount}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
