import React, { useEffect } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { userMe } from 'src/redux/services/common/Common.slice';
import { APP_FULL_NAME } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import { useAppSelector } from 'src/redux/hooks';

type NotGuardedRouteProps = {
    Component: any;
    title?: string;
} & RouteProps;

const NotGuardedRoute: React.FC<NotGuardedRouteProps> = ({ Component, title, ...props }) => {
    const user = useAppSelector(userMe);

    useEffect(() => {
        document.title = `${title} - ${APP_FULL_NAME}`;
    }, [title]);

    useEffect(() => {
        if (!user) {
            document.body.classList.remove('app-page');
        }
    }, []);

    return user ? <Navigate to={ROUTES.DASHBOARD} /> : <Component {...props} />;
};

export default NotGuardedRoute;
