import React, { FC, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useAppDispatch } from 'src/redux/hooks';
import { IOtp, IVerifyOtpProps } from './VerifyOtp.interface';
import { forgotPasswordVerifyOtp } from './VerifyOtp.slice';
import OTPField from './OTPField/OTPField';
import { useTranslation } from 'react-i18next';
import { errorCode } from 'src/constants/errorCode';
import { forgotPassword } from '../ForgotPassword/ForgotPassword.slice';
import { userLogin } from '../../Login/Login.slice';
import Button from 'src/components/Button';
import { FaArrowLeft } from 'react-icons/fa6';
import { ROUTES } from 'src/constants/routes';
import { useNavigate } from 'react-router-dom';
import CustomButton from 'src/components/CustomButton';
type FieldKey = 'otp';

const VerifyOtp: FC<IVerifyOtpProps> = ({ authData, setForgotPasswordStep, setAuthData }) => {
    const [activeOTPIndex, setactiveOTPIndex] = useState<number>(0);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors },
        reset,
    } = useForm<IOtp>({
        defaultValues: {
            otp: new Array(4).fill(''),
        },
    });
    const handlerOtpAgainSend = async () => {
        reset();
        let cread;
        if (authData.isEmail) {
            cread = {
                email: authData.email,
            };
        } else {
            cread = {
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
            };
        }
        const result: any = await dispatch(forgotPassword(cread));
        if (result.type === userLogin.fulfilled.toString()) {
            setForgotPasswordStep('otp');
        }
    };

    const onInputChange = async (data: any) => {
        const Values = data.filter((value: string) => value.trim() !== '');
        if (Values.length === 4) {
            setInputValue(true);
        } else {
            setInputValue(false);
        }
    };

    const handleChange = async (data: any) => {
        setLoading(true);
        let cread;
        if (authData.isEmail) {
            cread = {
                email: authData.email,
                otp: data.otp.join(''),
            };
        } else {
            cread = {
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
                otp: data.otp.join(''),
            };
        }
        const result = await dispatch(forgotPasswordVerifyOtp(cread));
        if (result.type === forgotPasswordVerifyOtp.fulfilled.toString()) {
            //localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, result.payload.data.token);
            //await dispatch(shopDetail());
            const enhancedAuthData = {
                ...authData,
                token: result.payload.data.token,
            };
            setAuthData(enhancedAuthData);
            setForgotPasswordStep('verified');
            setLoading(false);
        }
        if (result.type === forgotPasswordVerifyOtp.rejected.toString()) {
            const response = result.payload;

            if (response.status === errorCode.unprocessable) {
                Object.keys(response.data).forEach((field) => {
                    setError(field as FieldKey, {
                        type: 'manual',
                        message: response.data[field][0],
                    });
                });
                // setValue('number', new Array(4).fill(''));
                // setactiveOTPIndex(0);
            }
            setLoading(false);
        }
    };

    return (
        <div className="content mt-[84px] mr-[100px] ml-[100px]">
            <h2 className="text-3xl leading-[38px] font-semibold m-0 text-mainTextColor -tracking-[0.384px]">{t('Enter the verification code')}</h2>
            <div className="mt-1 mb-6 text-secondaryTxtColor  text-sm leading-[140%]">
                <span>
                    {t('We sent a code to ')} <span className="font-semibold text-secondaryTxtColor">{authData.isEmail ? authData.email : authData.phone.fullFormatedPhoneNumber}</span>
                </span>
            </div>
            <div className=" flex">
                <form onSubmit={handleSubmit(handleChange)}>
                    <Controller
                        name="otp"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="flex flex-col mt-3 mx-[48px]">
                                <OTPField
                                    otp={value}
                                    setOtp={(data: any) => {
                                        onChange(data); // Call the original onChange from react-hook-form
                                        onInputChange(data); // Then handle the input change for button styling
                                    }}
                                    activeOTPIndex={activeOTPIndex}
                                    setactiveOTPIndex={setactiveOTPIndex}
                                    errors={errors.otp}
                                />
                                {errors.otp && <p className="text-error">{errors.otp.message}</p>}
                            </div>
                        )}
                    />

                    <div className="flex mt-[6px] text-secondaryTxtColor text-xs leading-[18px] -tracking-[0.14px] mx-[48px]">
                        <span> {t('Tip: Be sure to check your inbox and spam folders')}</span>
                    </div>
                    <CustomButton primary type="submit" isLoading={loading} disabled={loading || !inputValue} size="w-full" className="!text-base mt-[20px]">
                        {t('Continue')}
                    </CustomButton>
                    <div className="mt-6 flex flex-col items-center">
                        <div className="flex ">
                            <p className="text-secondaryTxtColor font-medium text-sm leading-5 -tracking-[0.384px]">{t('Didn’t receive code')}?</p>
                            <Button type="button" onClick={handlerOtpAgainSend} className="cursor-pointer font-bold flex flex-col leading-5 text-sm  text-primarybtn ml-[2px]">
                                {t('Resend')}
                            </Button>
                        </div>
                        <div className="text-center mt-6">
                            <Button type="button" className="text-sm font-semibold text-secondaryTxtColor" onClick={() => navigate(ROUTES.HOME)}>
                                <FaArrowLeft size={14} className="mr-2" /> {t('Back to log in')}
                            </Button>
                        </div>
                    </div>
                    {/* isLoading={loading} disabled={isButtonDisabled} */}
                </form>
            </div>
        </div>
    );
};

export default VerifyOtp;
