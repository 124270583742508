import React, { useEffect, useState } from 'react';
import LocationBox from './LocationBox';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { locationListAction, selectListing, selectLoading } from './Location.slice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import PageHeader from 'src/components/PageHeader';
import SLocation from '../Skeleton/Location/SLocation';
import SearchBar from 'src/components/SearchBar/SearchBar';
import CustomButton from 'src/components/CustomButton';
import { MarkerPin04, Plus, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import NoDataMessage from 'src/components/NoDataMessage';
import { GoDotFill } from 'react-icons/go';

const Location = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(selectLoading);
    const listing = useAppSelector(selectListing);
    const shop = useAppSelector(currentShop);
    const [locations, setLocations] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        dispatch(locationListAction({ shop_id: shop.id }));
    }, [shop]);

    useEffect(() => {
        const list = listing.filter((obj: any) => {
            const name = obj.name.toLowerCase();
            const searchParam = searchValue.toLowerCase();
            return name.includes(searchParam);
        });

        setLocations(list);
    }, [searchValue, listing]);

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    const handleClick = () => {
        navigate(ROUTES.LOCATION.CREATE);
    };

    return (
        <div className="inner-page-wrape ">
            <div className="flex-1 flex flex-col mb-4">
                <PageHeader title={t('Locations')} subtitle="Manage and keep track of all your brand branches and locations in one place."></PageHeader>
                <div className="side-spaching flex flex-1 flex-col w-full">
                    <div className="w-full border rounded-xl flex flex-col flex-1 shadow">
                        <div className=" w-full flex flex-col items-center border-b border-gray-200">
                            <div className="flex items-center px-5 py-3 justify-between w-full gap-4">
                                <div className="flex flex-col flex-1">
                                    <div className="flex justify-start items-center gap-1.5">
                                        <h2 className="table-title">{t('All locations')}</h2>
                                        <p className={`userCounter w-fit ${loading ? 'custom-loading' : ''}`}>
                                            <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                            {locations.length} {t('Locations')}
                                        </p>
                                    </div>
                                    <p className="table-subtitle">{t('Add, view and manage your different brand locations.')}</p>
                                </div>
                                <div className="flex gap-3">
                                    <div className="table-searchInput">
                                        <SearchBar labelIconClassName="text-gray-500" placeholder={t('Search')} onChange={handleSearch} className="form-control-md " />
                                    </div>
                                    <CustomButton primary onClick={handleClick} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                                        {t('Add new location')}
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                        {!loading && locations.length === 0 ? (
                            <NoDataMessage
                                title={`${searchValue ? 'No Locations Found' : 'No Locations Added.'}`}
                                description={`${
                                    searchValue
                                        ? 'There are no locations matching your search criteria. Try adjusting your filters or add a new location to list it here.'
                                        : 'Start by adding your brand’s first location and make it available for client bookings.'
                                }`}
                                iconComponent={searchValue ? <SearchLg className="text-gray-700" /> : <MarkerPin04 className="text-gray-700" />}
                            />
                        ) : loading ? (
                            <div className="grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5  gap-4  overflow-hidden overflow-x-hidden p-4">
                                <SLocation />
                            </div>
                        ) : (
                            <div className="grid  grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xxl:grid-cols-5  gap-4  overflow-hidden overflow-x-hidden p-4">
                                {locations.map((location: any) => (
                                    <LocationBox location={location} key={location.id} />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Location;
