import { currentRole, userMe } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';

export const useRolePermission = () => {
    const user = useAppSelector(userMe);
    const role = useAppSelector(currentRole);

    const getRoleByShop = (shopId: number) => user.roles.find((item: any) => item?.pivot && item.pivot.shop_id === shopId);

    const hasRole = (roleName: string) => role.name === roleName;

    const hasPermission = (permissionName: string) => (Object.keys(role).length && role.permissions.length ? role.permissions.some((permission: any) => permission.name === permissionName) : false);

    return { hasRole, hasPermission, getRoleByShop };
};
