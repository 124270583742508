import { TemplateType } from '../TemplateSettings.interface';

export interface ITestimonial {
    name: string;
    is_description: true;
    description: string;
    image?: {
        file: File | null;
        url: string | null;
        name: string | null;
    };
}

export interface IFormData {
    status: boolean;
    is_title: boolean;
    title: string;
    is_subtitle: boolean;
    subtitle: string;
    testimonials: ITestimonial[];
}

export const InitTestimonialData: IFormData = {
    status: false,
    is_title: false,
    title: '',
    is_subtitle: false,
    subtitle: '',
    testimonials: [] as ITestimonial[],
};

export const InitTestimonialInfoData: ITestimonial = {
    name: '',
    is_description: true,
    description: '',
    image: {
        file: null,
        url: null,
        name: null,
    },
};

export interface TestimonialProps {
    template: TemplateType;
}
