import React, { FC, useEffect, useRef, useState } from 'react';
// import 'src/styles/otp.css';
import { IOTPField } from './OTPField.interface';

let currentOTPIndex: number = 0;
const OTPField: FC<IOTPField> = ({ otp, setOtp, errors }): JSX.Element => {
    const [activeOTPIndex, setactiveOTPIndex] = useState<number>(0);
    const handlerOnChange = ({ target }: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = target;
        const newOTP: string[] = [...otp];
        newOTP[currentOTPIndex] = value.substring(value.length - 1);
        if (!value) {
            setactiveOTPIndex(currentOTPIndex - 1);
        } else {
            setactiveOTPIndex(currentOTPIndex + 1);
        }
        setOtp(newOTP);
    };
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        inputRef.current?.focus();
    }, [activeOTPIndex]);

    const handlerKeyDown = ({ key }: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        currentOTPIndex = index;
        if (key === 'Backspace') {
            setactiveOTPIndex(currentOTPIndex - 1);
        }
    };
    return (
        <div className="flex justify-between items-center">
            {otp.map((_: any, index: number) => (
                <React.Fragment key={index}>
                    <input
                        ref={index === activeOTPIndex ? inputRef : null}
                        type="number"
                        onKeyDown={(e) => handlerKeyDown(e, index)}
                        onChange={handlerOnChange}
                        value={otp[index]}
                        // className="w-[87px] h-[87px] border-transparent rounded-2xl xxl:rounded-3xl bg-inputBg transition p-4 text-4xl text-black font-medium text-center focus:outline-0 focus:ring-2 focus:ring-primary"
                        className={`w-[64px] h-[64px]  rounded-xl  border-borderGray transition p-4 text-4xl text-black font-medium text-center focus:outline-0 focus:ring-2  ${
                            errors ? 'is-invalid border-red-500 text-dangerText' : 'focus:ring-primary'
                        }`}
                    />
                </React.Fragment>
            ))}
        </div>
    );
};

export default OTPField;
