import React, { FC, useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import moment from 'moment';

const AmChart: FC<any> = (props) => {
    const { currentTab, paymentDetails } = props;

    useLayoutEffect(() => {
        const root = am5.Root.new('chartdiv');
        if (root._logo) {
            root._logo.dispose();
        }
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                paddingLeft: 0,
                paddingBottom: 15,
            }),
        );

        // Add cursor
        const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}));
        cursor.lineY.set('visible', false);

        // Hide right-side zoom button
        chart.zoomOutButton.set('forceHidden', true);

        // Create X axis
        const xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                startLocation: 0.1,
                endLocation: 0.9,
                markUnitChange: false,
                baseInterval: { timeUnit: 'hour', count: 2 }, // Default to 2-hour intervals
                renderer: am5xy.AxisRendererX.new(root, {}),
            }),
        );

        // Hide vertical grid lines (X-axis grid lines)
        xAxis.get('renderer').grid.template.setAll({
            forceHidden: true, // Hides the vertical grid lines
        });

        // Create Y axis
        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {
                    strokeOpacity: 0, // Hides the Y-axis line itself
                }),
                extraMin: 0.1,
                strictMinMax: false,
            }),
        );

        // Show horizontal grid lines (Y-axis grid lines)
        yAxis.get('renderer').grid.template.setAll({
            forceHidden: false, // Shows horizontal grid lines
            strokeOpacity: 0.1, // Optional: Adjust transparency of grid lines
        });

        yAxis.get('renderer').labels.template.setAll({
            forceHidden: true, // Hides Y-axis labels
        });

        // Create series
        const series = chart.series.push(
            am5xy.SmoothedXLineSeries.new(root, {
                name: 'Series',
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: 'value',
                valueXField: 'date',
                stroke: am5.color('#0E34A5'),
                tooltip: am5.Tooltip.new(root, {
                    getFillFromSprite: false,
                    getStrokeFromSprite: true,
                    labelText: "{valueY} at {valueX.formatDate('MMM dd, yyyy HH:mm')}",
                }),
            }),
        );

        series.set(
            'tooltip',
            am5.Tooltip.new(root, {
                getFillFromSprite: false,
                getStrokeFromSprite: true,
                autoTextColor: true,
                pointerOrientation: 'horizontal',
                labelText: "{valueY} at {valueX.formatDate('MMM dd, yyyy HH:mm')}",
                keepTargetHover: true,
            }),
        );

        series.strokes.template.setAll({
            strokeWidth: 2,
        });

        series.fills.template.setAll({
            fillOpacity: 0.1,
            visible: true,
        });

        series.fills.template.set(
            'fillGradient',
            am5.LinearGradient.new(root, {
                stops: [
                    {
                        color: am5.color('#0E34A5'),
                        brighten: 0.1,
                    },
                    {
                        color: am5.color('#0E34A5'),
                        lighten: 1,
                    },
                ],
                rotation: 90,
            }),
        );

        const parseData = (tabData: any[]) =>
            tabData.map((item) => {
                const parsedDate = moment(item.date).toDate().getTime(); // Ensure date is in timestamp format
                const parsedValue = item.value; // Retain original value
                return { date: parsedDate, value: parsedValue };
            });

        const updateChartForTab = (tab: string) => {
            let timeUnit: am5.time.TimeUnit;
            let count: number;
            let dateFormats: Record<string, string> = {};

            switch (tab) {
                case 'hours':
                    timeUnit = 'hour';
                    count = 3; // Ensure 2-hour gaps
                    dateFormats.hour = 'HH:mm';
                    series.data.setAll(parseData(paymentDetails));
                    series.get('tooltip')!.label.set('text', "{valueY} at {valueX.formatDate('MMM dd, yyyy HH:mm')}");
                    break;
                case 'week':
                    timeUnit = 'day';
                    count = 1;
                    dateFormats.day = 'EEE';
                    series.data.setAll(parseData(paymentDetails));
                    series.get('tooltip')!.label.set('text', "{valueY} on {valueX.formatDate('EEE, MMM dd')}");
                    break;
                case 'months':
                    timeUnit = 'month';
                    count = 1;
                    dateFormats.month = 'MMM';
                    series.data.setAll(parseData(paymentDetails));
                    series.get('tooltip')!.label.set('text', "{valueY} in {valueX.formatDate('MMM yyyy')}");
                    break;
                case 'days':
                    timeUnit = 'day';
                    count = 2; // Ensure 2-day gaps
                    dateFormats.day = 'MMM dd';
                    series.data.setAll(parseData(paymentDetails));
                    series.get('tooltip')!.label.set('text', "{valueY} on {valueX.formatDate('MMM dd')}");
                    break;
                default:
                    return;
            }

            // Set the axis configuration
            xAxis.set('baseInterval', { timeUnit, count });
            xAxis.set('dateFormats', dateFormats);
        };

        // Initial chart update
        updateChartForTab(currentTab);

        // Animations
        series.appear(1000);
        chart.appear(1000, 100);

        // Cleanup on unmount
        return () => {
            root.dispose();
        };
    }, [currentTab]);

    return <div id="chartdiv" className="h-[226px]"></div>;
};

export default AmChart;
