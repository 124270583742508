import React, { FC } from 'react';
import { IKpiCard } from './KpiCard.interface';
import { IconArrowUp, IconArrowDown, GrowthIcon } from 'src/theme/Images';
import { ArrowDown, ArrowsUp, ArrowUp, ArrowUpRight, Check, TrendDown01, TrendUp01 } from '@untitled-ui/icons-react/build/cjs';

const KpiCard: FC<IKpiCard> = ({ signIcon, headerTitle, contentLable, growthDescription, growth, fromDate, isActive = false, isAnalytics }) => (
    <div className={`overview-card ${isActive ? 'active' : ''} ${isAnalytics ? 'analytics-card !rounded-xl' : ''}`}>
        {/* active class aapi dejo */}
        <div className="flex items-center justify-between mb-1.5 analytics-card-header">
            {/* <i className="rounded-xl border border-lineColor flex items-center justify-center mr-1 w-10 h-10">
                <img src={headerIcon} alt="" className="w-5" />
            </i> */}
            <h4 className="font-medium text-base text-mainTextColor -tracking-[0.3]  analytics-first-text">{headerTitle}</h4>

            {isAnalytics ? (
                <div className="flex flex-row gap-1">
                    <h4 className="font-semibold text-xs text-[#143CB3]">View Details</h4>
                    <ArrowUpRight className="w-4 h-4  text-[#143CB3]" />
                </div>
            ) : (
                <Check className=" saw-icon" />
            )}
        </div>
        <div className="flex flex-row items-start mb-1 analytics-card-header2">
            <h3 className="text-[36px] font-semibold leading-[44px] -tracking-[0.5px] analytics-second-text">{contentLable}</h3>
            {signIcon && <span className="text-[24px] font-semibold leading-[30px] -tracking-[0.5px] analytics-second-text2">{signIcon}</span>}
            {/* {contentLable} {contentDescription && <span className="text-xs text-secondaryTxtColor font-normal -tracking-[0.3px]">{contentDescription}</span>}
            {secondaryContentLabel && <span className="ml-1 text-xs font-bold -tracking-[0.5px]">{secondaryContentLabel}</span>}
            {secondaryContentDescription && <span className="ml-1 text-xs text-secondaryTxtColor font-normal -tracking-[0.3px]">{secondaryContentDescription}</span>} */}
        </div>

        <div className="flex flex-row justify-between items-center gap-3">
            <div className="flex flex-row items-center">
                {isAnalytics ? (
                    <span className={`mr-[4px]  flex items-center`}>
                        {growth >= 0 ? (
                            <ArrowUp className="w-4 h-4 mr-[2px] max-xlm:w-4 max-xlm:h-4  text-[#17B26A]" />
                        ) : (
                            <ArrowDown className="w-4 h-4 mr-[2px] max-xlm:w-4 max-xlm:h-4 text-[#ff4d54] " />
                        )}
                        <span className="font-medium text-sm max-xlm:text-xs text-secondaryTxtColor analytics-last-text">{`${Math.abs(growth)}%`}</span>
                    </span>
                ) : (
                    <span className={`mr-[4px] ${growth >= 0 ? 'text-[#17B26A]' : 'text-dangerText'} flex items-center`}>
                        {growth >= 0 ? (
                            <TrendUp01 className="w-5 h-5 mr-1 max-xlm:w-4 max-xlm:h-4  text-[#17B26A]" />
                        ) : (
                            <TrendDown01 className="w-5 h-5 mr-1 max-xlm:w-4 max-xlm:h-4 text-dangerText " />
                        )}
                        <span className="font-medium text-sm max-xlm:text-xs analytics-last-text">{`${Math.abs(growth)}%`}</span>
                    </span>
                )}

                <span className="leading-[20px] max-xxl:text-xs  text-sm -tracking-[0.3px] font-medium text-secondaryTxtColor max-2xl:max-w-[120px] max-xlm:max-w-[90px] max-xlm:truncate analytics-last-text">
                    {`vs. ${fromDate}`}{' '}
                </span>
            </div>
            {growthDescription && <span className="text-sm max-xxl:text-xs  font-medium text-secondaryTxtColor max-xlm:max-w-[99x] max-2xl:max-w-[120px] max-2xlm:truncate">{growthDescription}</span>}
        </div>
    </div>
);

export default KpiCard;
