import React from 'react';
import { GoDotFill } from 'react-icons/go';

interface ITypeProp {
    statusData: string;
    icon?: boolean;
}

const Badge = ({ statusData, icon }: ITypeProp) => {
    const StatusBagde = (status: string) => {
        switch (status) {
            case 'Completed':
                return 'px-[8px] py-[2px] border rounded-full text-success-700 border-success-200 bg-success-50';

            case 'Confirmed':
                return 'px-[8px] py-[2px] border rounded-full text-blueExtra-700 border-blueExtra-200 bg-blueExtra-50';
            case 'Declined':
                return 'px-[8px] py-[2px] border rounded-full text-error-700 border-error-200 bg-error-50';
            case 'Canceled':
                return 'px-[8px] py-[2px] border rounded-full text-error-700 border-error-200 bg-error-50';
            case 'Pending':
                return 'px-[8px] py-[2px] border rounded-full text-yellow-700 border-yellow-200 bg-yellow-50';
            default:
                return '';
        }
    };
    return (
        <div className={`userCounter ${StatusBagde(statusData)}`}>
            {icon && <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />}
            {statusData}
        </div>
    );
};

export default Badge;
