import React from 'react';
import { useTranslation } from 'react-i18next';
import KpiCard from 'src/components/KpiCard';
import { IconMoney, IconNewClient, IconClientRotation } from 'src/theme/Images';
import { formatKpiContentLable } from 'src/utils/global-functions';

const OverviewNew = ({ AnalyticsDetails, boxLabel }: any) => {
    const { t } = useTranslation();
    const AnalyticsTotal = AnalyticsDetails ? AnalyticsDetails.total : null;
    return (
        <>
            {AnalyticsTotal && (
                <>
                    <div className=" grid max-2xl:grid-cols-2  grid-cols-3 w-full gap-3">
                        <div className="">
                            <KpiCard
                                headerIcon={IconMoney}
                                headerTitle="Total Revenue"
                                contentLable={`${formatKpiContentLable(AnalyticsTotal?.amount)}`}
                                signIcon={'$'}
                                growth={AnalyticsTotal?.amount_growth}
                                fromDate={boxLabel}
                                isAnalytics={true}
                            />
                        </div>
                        <div className="">
                            <KpiCard
                                headerIcon={IconNewClient}
                                headerTitle="Total Appointments"
                                contentLable={`${formatKpiContentLable(AnalyticsTotal?.client)}`}
                                growth={AnalyticsTotal?.client_growth}
                                fromDate={boxLabel}
                                isAnalytics={true}
                            />
                        </div>
                        <div className="">
                            <KpiCard
                                headerIcon={IconClientRotation}
                                headerTitle="Client Retention"
                                contentLable={`${AnalyticsTotal?.client_retention_rate}`}
                                signIcon={'%'}
                                growth={AnalyticsTotal?.client_retention_rate_growth}
                                fromDate={boxLabel}
                                isAnalytics={true}
                            />
                        </div>
                        <div className="">
                            <KpiCard
                                headerIcon={IconMoney}
                                headerTitle="Productivity"
                                contentLable={`${formatKpiContentLable(AnalyticsTotal?.productivity)}`}
                                signIcon={'%'}
                                growth={AnalyticsTotal?.productivity_growth}
                                fromDate={boxLabel}
                                isAnalytics={true}
                            />
                        </div>
                        <div className="">
                            <KpiCard
                                headerIcon={IconNewClient}
                                headerTitle="New Customers"
                                contentLable={`${formatKpiContentLable(AnalyticsTotal?.client)}`}
                                growth={AnalyticsTotal?.client_growth}
                                fromDate={boxLabel}
                                isAnalytics={true}
                            />
                        </div>
                        <div className="">
                            <KpiCard
                                headerIcon={IconClientRotation}
                                headerTitle="Client Booking Freq."
                                contentLable={`${AnalyticsTotal?.avg_bookings_frequency}`}
                                signIcon={'days'}
                                growth={AnalyticsTotal?.avg_bookings_frequency_growth}
                                fromDate={boxLabel}
                                isAnalytics={true}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default OverviewNew;
