import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PopupModal from 'src/components/PopupModal';
import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputWithLabel from 'src/components/InputWithLabel';
import SelectBox from 'src/components/SelectBox/SelectBox';
import * as Yup from 'yup';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import Checkbox from 'src/components/Checkbox';
import { axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useParams } from 'react-router-dom';
import { IInventoryFormData } from './Products.interface';

const InventoryForm = ({ handleClose, inventoryDetails, product, getInventoriesByLocation, listInventories, targetInventory }: any) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [locationOptions, setLocationOptions] = useState<any>([]);
    const [variantOptions, setVariantOptions] = useState<any>([]);
    const [selectedVariant, setSelectedVariant] = useState({ variant: null, units: null, price: '', ischange: false });

    useEffect(() => {
        if (inventoryDetails) {
            const locationData: any = getSelectBoxOptions(inventoryDetails, 'id', 'name', 'id', 'shortname', 'location_image_url');
            setLocationOptions(locationData);
            if (locationData.length > 0) {
                const location = locationData[0];
                if (!targetInventory) {
                    setValue('shop_location_id', location.id);
                }
            }
        }
        if (targetInventory) {
            const existingData: IInventoryFormData = {
                shop_product_variant_id: targetInventory.variant.id,
                shop_location_id: targetInventory.location.id,
                cost: targetInventory.cost,
                price: targetInventory.price,
                init_quantity: targetInventory.init_quantity,
            };
            (Object.keys(existingData) as Array<keyof IInventoryFormData>).forEach((key) => {
                setValue(key, existingData[key]!);
            });
        }
    }, [inventoryDetails, targetInventory]);

    function customValidation(initQuantityValue: any): boolean {
        if (targetInventory && initQuantityValue < targetInventory.sales_quantity) {
            return false;
        }
        return true;
    }

    const schema = Yup.object({
        shop_product_variant_id: Yup.string().required(t('This field is required')),
        shop_location_id: Yup.string().required(),
        cost: Yup.number().required().positive('Cost must be a positive value'),
        price: Yup.number().required().positive('Cost must be a positive value'),
        init_quantity: Yup.number()
            .required(t('Initial quantity is required'))
            .positive(t('Initial quantity must be a positive value'))
            .test({
                name: 'to_valid_hours_2',
                message: '"To" time must be greater than "From" time',
                test: function (value) {
                    return customValidation(value);
                },
            }),
        is_new_price: Yup.boolean(),
    }).required();

    const methods = useForm<IInventoryFormData>({
        resolver: yupResolver(schema) as unknown as Resolver<IInventoryFormData>,
        defaultValues: {
            shop_product_variant_id: '',
            shop_location_id: '',
            cost: null,
            price: null,
            init_quantity: null,
            is_new_price: false,
        },
    });
    const { handleSubmit, control, watch, setValue } = methods;
    const productVariantId = watch('shop_product_variant_id');
    const productLocationtId = watch('shop_location_id');
    const productPrice = watch('price');

    useEffect(() => {
        if (productLocationtId) {
            const foundVariant = inventoryDetails.find((variant: any) => variant.id === productLocationtId);
            const firstVariant = foundVariant.variants[0];
            const variantOptionsArr = foundVariant.variants.map((variant: any) => ({
                label: `${variant.size} - $${variant.inventory.price}`,
                value: variant.id,
            }));
            setVariantOptions(variantOptionsArr);
            if (targetInventory) {
                if (targetInventory.location.id !== productLocationtId) {
                    setValue('shop_product_variant_id', firstVariant.id);
                } else {
                    setValue('shop_product_variant_id', targetInventory.variant.id);
                }
            } else {
                setValue('shop_product_variant_id', firstVariant.id);
            }
        }
    }, [productLocationtId]);

    useEffect(() => {
        if (productVariantId) {
            const location = inventoryDetails.find((variant: any) => variant.id === productLocationtId);
            const foundVariant = location.variants.find((variant: any) => variant.id === productVariantId);
            if (foundVariant && productPrice) {
                const inventoryPrice = +foundVariant.inventory.price;
                const inputPrice = +productPrice;
                setSelectedVariant((prev: any) => ({
                    ...prev,
                    variant: `${foundVariant.size} ${product.unit_of_measure}`,
                    units: foundVariant.inventory.in_stock_quantity,
                    price: productPrice,
                    ischange: inventoryPrice.toFixed(2) === inputPrice.toFixed(2),
                }));
            }
        }
    }, [productVariantId, productPrice]);

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <PackagePlus className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{targetInventory ? t('Update stock') : t('Add a new stock')}</p>
                <span className="text-xs font-normal text-gray-500">{t('Lorem ipsum dolor sit amet')}</span>
            </div>
        </div>
    );

    const handleNext = () => {
        handleSubmit(handleSave)();
    };

    const handleBack = () => {
        handleClose();
    };

    const handleSave = async (data: IInventoryFormData) => {
        setIsLoading(true);
        const shopLocationId = Number(data.shop_location_id);
        const shopVariantId = Number(data.shop_product_variant_id);
        const payload = {
            ...data,
            shop_location_id: shopLocationId,
            shop_product_variant_id: shopVariantId,
        };
        if (targetInventory) {
            await axiosPatch(API.PRODUCT.UPDATEVARIANT, payload, { shop_id: shop.id, id: targetInventory.id })
                .then(async (response) => {
                    getInventoriesByLocation();
                    listInventories();
                    handleClose();
                })
                .finally(() => setIsLoading(false));
        } else {
            await axiosPost(API.PRODUCT.CREATEVARIANT, payload, { shop_id: shop.id, id })
                .then(async (response) => {
                    getInventoriesByLocation();
                    listInventories();
                    handleClose();
                })
                .finally(() => setIsLoading(false));
        }
    };

    return (
        <>
            <PopupModal
                onClose={handleClose}
                size="w-[500px]"
                className="px-5 py-5"
                title={<Title />}
                primaryButton={t('Save Changes')}
                secondaryButton={t('Cancel')}
                acceptAction={handleNext}
                declineAction={handleBack}
                isLoading={isLoading}
            >
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleSave)}>
                        <div className="">
                            <div className="detail-data-main !pt-0 !border-b-0 !pb-0">
                                <div className="data-wrap-block w-full max-w-[666px] flex flex-col gap-4">
                                    <div className="flex flex-col gap-4">
                                        <div className="grid grid-cols-2 gap-4">
                                            <Controller
                                                name="shop_location_id"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="">
                                                        <SelectBox
                                                            name="shop_location_id"
                                                            id="shop_location_id"
                                                            label={t('Location')}
                                                            required
                                                            isClearable={false}
                                                            placeholder={t('Select Location')}
                                                            options={locationOptions}
                                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                            value={locationOptions.find((option: any) => option.value === value)}
                                                            error={!!error}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="shop_product_variant_id"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="">
                                                        <SelectBox
                                                            name="shop_product_variant_id"
                                                            id="shop_product_variant_id"
                                                            label={t('Unit of Measure')}
                                                            required
                                                            isClearable={false}
                                                            placeholder={t('Select Unit of Measure')}
                                                            options={variantOptions}
                                                            onChangeFunc={(option: any) => {
                                                                onChange(option ? option.value : null);
                                                                setSelectedVariant((prev) => ({
                                                                    ...prev,
                                                                    variant: option ? prev.variant : null,
                                                                    units: option ? prev.units : null,
                                                                }));
                                                            }}
                                                            value={variantOptions.find((option: any) => option.value === value)}
                                                            error={!!error}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="cost"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <div>
                                                        <InputWithLabel
                                                            type="number"
                                                            name="cost"
                                                            value={value ?? ''}
                                                            id="cost"
                                                            label={t('Cost')}
                                                            required
                                                            placeholder={t('Enter cost')}
                                                            onChange={onChange}
                                                            error={!!error}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="price"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <div>
                                                        <InputWithLabel
                                                            type="number"
                                                            name="price"
                                                            value={value ?? ''}
                                                            id="price"
                                                            label={t('Price')}
                                                            required
                                                            placeholder={t('Enter price')}
                                                            onChange={(e: any) => {
                                                                onChange(e.target.value);
                                                                setSelectedVariant((prev) => ({
                                                                    ...prev,
                                                                    price: e.target.value ? e.target.value : null,
                                                                }));
                                                            }}
                                                            error={!!error}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="init_quantity"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                    <div>
                                                        <InputWithLabel
                                                            type="number"
                                                            name="init_quantity"
                                                            value={value ?? ''}
                                                            id="init_quantity"
                                                            label={t('New Stock')}
                                                            required
                                                            placeholder={t('Enter new stock')}
                                                            onChange={onChange}
                                                            error={!!error}
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {selectedVariant && selectedVariant.units && +selectedVariant.price > 0 && !selectedVariant.ischange && (
                                <div className="text-xs font-normal text-[#D92D20] flex items-start leading-[18px]  mt-4">
                                    <div className="mr-3">
                                        <Controller
                                            name="is_new_price"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox name="is_new_price" className="focus:outline-none" value={value} checked={value} onChange={onChange} />
                                            )}
                                        />
                                    </div>
                                    {`New sale price detected for ${selectedVariant.variant}. You current have ${selectedVariant.units} units of this product still in stock, would you like to update the sell price of these items to ${selectedVariant.price}$.`}
                                </div>
                            )}
                        </div>
                    </form>
                </FormProvider>
            </PopupModal>
        </>
    );
};

export default InventoryForm;
