import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeft, ArrowRight, CalendarDate, ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import SelectBox, { CustomOption } from '../SelectBox/SelectBox';
import { useSelector } from 'react-redux';
import { getDateRange } from 'src/utils/global-functions';
import CustomButton from '../CustomButton';
import { TimeRanges } from 'src/constants/common';
import ReactSelect from 'react-select';

interface IProps {
    handleDatePickerChange: (val: any, type?: string | undefined) => void;
    startDate: any;
    containerClassName?: any;
    parentClassName?: any;
    endDate: any;
    minDate?: any;
    selectedTimeRange: string;
    allTimeStartDate: any;
    isToday?: boolean;
    isDisplayAsBox?: boolean;
    isShowDropDownIcon?: boolean;
    isPrevDisabled?: boolean;
    isNextDisabled?: boolean;
    showYearDropdown?: boolean;
    previewBoxInnerTitle?: string;
    placeholder?: string;
    textWrapClass?: string;
    calendarClass?: string;
    setStartDate: (val: Date | undefined) => void;
    setEndDate: (val: Date | undefined) => void;
    activeFilter: string;
    setActiveFilter?: (val: any) => void;
}

const DateRangePicker = ({
    handleDatePickerChange,
    startDate,
    endDate,
    selectedTimeRange,
    allTimeStartDate,
    containerClassName,
    minDate,
    textWrapClass,
    calendarClass,
    parentClassName,
    isDisplayAsBox = true,
    isToday = false,
    isShowDropDownIcon = true,
    showYearDropdown = true,
    isPrevDisabled = false,
    isNextDisabled = false,
    previewBoxInnerTitle = '',
    placeholder,
    setStartDate,
    setEndDate,
    activeFilter,
    setActiveFilter,
}: IProps) => {
    const uiState = useSelector((state: any) => state.MobileView);
    const datePickerRef = useRef<HTMLDivElement>(null);
    const [dateRange, setDateRange] = useState<any>([]);
    const [startDateCurrent, setStartDateCurrent] = useState<any>(startDate);
    const [endDateCurrent, setEndDateCurrent] = useState<any>(endDate);
    const [timeRangeCurrent, setTimeRangeCurrent] = useState(selectedTimeRange ?? 'custom');
    const [isOpenDropDown, setIsOpenDropDown] = useState<any>(false);
    const [isSelectedStart, setIsSelectedStart] = useState(true);
    useEffect(() => {
        setStartDateCurrent(startDate);
        setEndDateCurrent(endDate);
    }, [startDate, endDate]);

    const handleClose = () => {
        setIsOpenDropDown(false);
        setStartDateCurrent(startDate);
        setEndDateCurrent(endDate);
    };
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                console.log('handleClickOutside', isOpenDropDown);

                if (isOpenDropDown) {
                    handleClose();
                }
            }
        }
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [datePickerRef]);

    const handleTimeRangeChangesCurrent = (val: any) => {
        if (val !== 'custom') {
            const { start, end } = getDateRange(val, allTimeStartDate);
            setDateRange([start, end]);
            setStartDateCurrent(start);
            setEndDateCurrent(end);
        } else {
            const date = moment().toDate();
            setDateRange([date, date]);
            setStartDateCurrent(date);
            setEndDateCurrent(date);
        }
        setTimeRangeCurrent(val);
    };
    useEffect(() => {
        if (isOpenDropDown) {
            setTimeRangeCurrent(selectedTimeRange);
            setStartDateCurrent(startDate ?? null);
            setEndDateCurrent(endDate ?? null);
        }
    }, [isOpenDropDown, selectedTimeRange]);
    const handleChange = (event: any, actionMeta: any) => {
        handleTimeRangeChangesCurrent(event.value);
        // onChangeFunc(newValue);
        if (actionMeta.action === 'clear') {
            document.querySelectorAll('.fwpo_select__menu').forEach((el) => {
                el.classList.add('d-none');
            });
        }
    };
    const handleFilterTab = (value: string) => () => {
        if (activeFilter === value) getFilterRange();
    };
    useEffect(() => {}, [activeFilter]);
    const handleDateChange = ([start, end]: [any, any]) => {
        setIsSelectedStart(true);

        if (start && end) {
            // If both start and end dates are selected
            setStartDateCurrent(start);
            setEndDateCurrent(end);
            setDateRange([start, end]);

            let days = moment(end).diff(moment(start), 'days');
            console.log(days, 'days', start, 'start', end, 'end');
        } else {
            // Handle case where only the start date is selected or range is being modified
            if (!startDateCurrent || endDateCurrent) {
                // Start new range, resetting end date
                setStartDateCurrent(start);
                setEndDateCurrent(null);
                setDateRange([start, null]);
            } else if (start < startDateCurrent) {
                // If new start is before the current start, update range
                setEndDateCurrent(startDateCurrent);
                setStartDateCurrent(start);
                setDateRange([start, startDateCurrent]);
            } else {
                // Set the new selected date as the end date
                setEndDateCurrent(start);
                setDateRange([startDateCurrent, start]);
            }
        }
        setTimeRangeCurrent('custom');

        // Always update the custom time range selection
    };

    const getFormattedDate = (date: any) => {
        const momentDate = moment(date).startOf('day');
        const today = moment().startOf('day');
        const yesterday = today.clone().subtract(1, 'days');
        const tomorrow = today.clone().add(1, 'days');

        switch (true) {
            case momentDate.isSame(today, 'day'):
                return `Today, ${momentDate.format('MMM Do, YYYY')}`;
            case momentDate.isSame(yesterday, 'day'):
                return `Yesterday, ${momentDate.format('MMM Do, YYYY')}`;
            case momentDate.isSame(tomorrow, 'day'):
                return `Tomorrow, ${momentDate.format('MMM Do, YYYY')}`;
            default:
                return momentDate.format('dddd, MMM Do, YYYY');
        }
    };

    const getFilterRange = (type?: string) => {
        const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        moment.tz.setDefault(browserTimezone);

        var startDT = new Date();
        var endDT = new Date();

        if (type === 'prev') {
            if (activeFilter === 'yearly') {
                startDT = moment(startDate).subtract(1, 'year').toDate();
                endDT = moment(endDate).subtract(1, 'year').toDate();
            } else if (activeFilter === 'weekly') {
                startDT = moment(endDate).subtract(1, 'week').isoWeekday(0).toDate();
                endDT = moment(endDate).subtract(1, 'week').isoWeekday(6).toDate();
            } else if (activeFilter === 'monthly') {
                startDT = moment(startDate).subtract(1, 'months').startOf('month').toDate();
                endDT = moment(startDate).subtract(1, 'months').endOf('month').toDate();
            } else if (activeFilter === 'daily') {
                startDT = moment(startDate).subtract(1, 'days').toDate();
                endDT = moment(endDate).subtract(1, 'days').toDate();
            } else {
                if (selectedTimeRange === 'quarterly') {
                    startDT = moment(moment(startDate).subtract(1, 'quarters').startOf('quarter'), 'YYYY-MM-DD').toDate();
                    endDT = moment(moment(startDate).subtract(1, 'quarters').endOf('quarter'), 'YYYY-MM-DD').toDate();
                } else if (selectedTimeRange === 'last_year') {
                    startDT = moment(startDate).subtract(1, 'year').toDate();
                    endDT = moment(startDate).subtract(1, 'year').toDate();
                } else {
                    const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
                    startDT = moment(startDate).clone().subtract(daysDiff, 'days').toDate();
                    endDT = moment(endDate).clone().subtract(daysDiff, 'days').toDate();
                }
            }
            setStartDate(startDT);
            setEndDate(endDT);
        } else if (type === 'next') {
            if (activeFilter === 'yearly') {
                startDT = moment(startDate).add(1, 'year').toDate();
                endDT = moment(endDate).add(1, 'year').toDate();
            } else if (activeFilter === 'weekly') {
                startDT = moment(endDate).add(1, 'week').isoWeekday(0).toDate();
                endDT = moment(endDate).add(1, 'week').isoWeekday(6).toDate();
            } else if (activeFilter === 'monthly') {
                startDT = moment(startDate).add(1, 'months').startOf('month').toDate();
                endDT = moment(endDate).add(1, 'months').endOf('month').toDate();
            } else if (activeFilter === 'daily') {
                startDT = moment(startDate).add(1, 'days').toDate();
                endDT = moment(endDate).add(1, 'days').toDate();
            } else {
                if (selectedTimeRange === 'quarterly') {
                    startDT = moment(moment(startDate).add(1, 'quarters').startOf('quarter'), 'YYYY-MM-DD').toDate();
                    endDT = moment(moment(startDate).add(1, 'quarters').endOf('quarter'), 'YYYY-MM-DD').toDate();
                } else if (selectedTimeRange === 'last_year') {
                    startDT = moment(startDate).add(1, 'year').toDate();
                    endDT = moment(startDate).add(1, 'year').toDate();
                } else {
                    const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
                    startDT = moment(startDate).clone().add(daysDiff, 'days').toDate();
                    endDT = moment(endDate).clone().add(daysDiff, 'days').toDate();
                }
            }

            setStartDate(startDT);
            setEndDate(endDT);
        }
    };

    const handleButtonEvent = (type: 'today' | 'clear' | 'apply' | 'cancel') => () => {
        switch (type) {
            case 'today':
                handleDateChange([moment().toDate(), moment().toDate()]);
                const dateRangeVal = [moment().toDate(), moment().toDate()];
                setDateRange(dateRangeVal);
                setIsSelectedStart(false);
                break;
            case 'clear':
                handleDateChange([null, null]);
                const dateRangeVal1 = [null, null];
                setDateRange(dateRangeVal1);
                setIsSelectedStart(true);
                break;
            case 'apply':
                handleDatePickerChange(dateRange, timeRangeCurrent);
                setIsOpenDropDown(false);
                break;
            case 'cancel':
                handleClose();
                break;
        }
    };
    console.log('isopneddown', isOpenDropDown);

    return (
        <div className={`relative w-full cursor-pointer ${parentClassName}`} ref={datePickerRef}>
            <div
                className={`w-full xl:px-3.5 px-3 lg:py-[9px] py-2 rounded-lg shadow border border-gray-300 lg:h-[36px] h-[34px] ${
                    isDisplayAsBox ? '' : ''
                } justify-between items-center gap-1.5 flex ${timeRangeCurrent === 'custom' && !isToday && startDate && endDate ? 'bg-gray50' : 'bg-white'}  `}
            >
                {getFilterRange && startDate && (
                    <ArrowLeft
                        className={`xxl:w-4 xxl:h-4 xls:w-3 xls:h-3 w-4 h-4 ${!isPrevDisabled && !startDate ? 'text-grayText cursor-not-allowed' : 'cursor-pointer'}`}
                        onClick={() => {
                            getFilterRange('prev');
                            setIsOpenDropDown(false);
                        }}
                    />
                )}
                <div className={`flex flex-1 justify-center items-center ${textWrapClass} `} onClick={() => setIsOpenDropDown(!isOpenDropDown)}>
                    {previewBoxInnerTitle && <div className="text-textSecondary xl:text-xs xls:text-[11px] text-xs font-medium leading-none mr-4">{previewBoxInnerTitle} : </div>}
                    <CalendarDate className={`w-4 h-4 xl:mr-2 mr-1 ${!startDate && !endDate ? 'text-gray-900' : ''} ${calendarClass}`} />
                    <div className="text-textSecondary xl:text-xs xls:text-[11px] text-xs font-medium leading-none">
                        {startDate && endDate ? (
                            timeRangeCurrent === 'custom' ? (
                                <>
                                    {isToday && moment(startDateCurrent).format('DD-MM-YYYY') === moment(endDateCurrent).format('DD-MM-YYYY')
                                        ? getFormattedDate(startDate)
                                        : `${moment(startDateCurrent).local().format('MMMM Do, YYYY')} -
                                    ${moment(endDateCurrent ? endDateCurrent : startDateCurrent)
                                        .local()
                                        .format('MMM Do, YYYY')}`}
                                </>
                            ) : (
                                <>
                                    {startDateCurrent && moment(startDateCurrent).format('MMM Do, YYYY')} -{' '}
                                    {moment(endDateCurrent ? endDateCurrent : startDateCurrent && startDateCurrent).format('MMM Do, YYYY')}
                                </>
                            )
                        ) : (
                            <div className="text-xs text-gray-500">{placeholder ?? 'No date selected'}</div>
                        )}
                    </div>
                </div>
                {isShowDropDownIcon && <ChevronDown className="w-4 h-4" />}

                {getFilterRange && endDate && (
                    <ArrowRight
                        className={`xxl:w-4 xxl:h-4 xls:w-3 xls:h-3 w-4 h-4 ${!isNextDisabled && !endDate ? 'text-grayText cursor-not-allowed' : 'cursor-pointer'}`}
                        onClick={() => {
                            getFilterRange('next');
                            setIsOpenDropDown(false);
                        }}
                    />
                )}
            </div>
            <div className={`${containerClassName} date-picker-menu bg-white absolute border-utilityGray200 border rounded-xl mt-1 flex z-[2]`}>
                <div className={`wal-hq-select `}>
                    <ReactSelect
                        value={TimeRanges.find((val) => val.value === timeRangeCurrent)}
                        defaultValue={TimeRanges.find((val) => val.value === timeRangeCurrent)}
                        onMenuOpen={() => {
                            setIsOpenDropDown(true);
                        }}
                        onMenuClose={() => {
                            setIsOpenDropDown(true);
                        }}
                        classNamePrefix={'select'}
                        menuIsOpen={isOpenDropDown}
                        menuPlacement="auto"
                        onChange={handleChange}
                        options={TimeRanges}
                        components={{ Option: (prop: any) => <CustomOption {...prop} /> }}
                        className={'hide-select-value-container lg:block hidden'}
                        formatOptionLabel={(option) => (
                            <div className="flex justify-between">
                                <div className="flex">
                                    <span className="flex justify-center items-center font-medium ">{option.label}</span>
                                </div>
                            </div>
                        )}
                        hideSelectedOptions={false}
                    />
                </div>
                {isOpenDropDown && (
                    <div className="w-full left-[61%] custom-time-range lg:border-l border-utilityGray200 flex flex-col">
                        <div className="flex-grow relative w-full  ">
                            {timeRangeCurrent === 'custom' ? (
                                <DatePicker
                                    selected={isSelectedStart ? startDateCurrent : endDateCurrent}
                                    className="form_control w-[132px] "
                                    onChange={(date: any) => handleDateChange(date)}
                                    startDate={startDateCurrent}
                                    endDate={endDateCurrent}
                                    minDate={minDate}
                                    monthsShown={2}
                                    showYearDropdown={showYearDropdown}
                                    selectsRange={true}
                                    inline={isOpenDropDown}
                                />
                            ) : (
                                <DatePicker
                                    selected={new Date(moment(startDateCurrent).format('MMM DD, YYYY'))}
                                    className="form_control w-[132px] "
                                    onChange={(date: any) => handleDateChange(date)}
                                    startDate={new Date(moment(startDateCurrent).format('MMM DD, YYYY'))}
                                    endDate={new Date(moment(endDateCurrent).format('MMM DD, YYYY'))}
                                    minDate={minDate}
                                    monthsShown={2}
                                    showYearDropdown={showYearDropdown}
                                    selectsRange={true}
                                    inline={isOpenDropDown}
                                />
                            )}
                        </div>
                        <div className="w-full p-4 border-t border-gray-200 justify-between items-start flex lg:flex-row flex-col gap-3">
                            <div className="justify-start items-center lg:gap-3 sm:gap-2 gap-1 flex text-xs font-normal leading-tight lg:w-auto w-full">
                                <CustomButton className="lg:flex-auto " secondary onClick={handleButtonEvent('today')}>
                                    Today
                                </CustomButton>
                                <CustomButton className="lg:flex-auto " secondary onClick={handleButtonEvent('clear')}>
                                    Clear
                                </CustomButton>
                            </div>
                            <div className="gap-3 flex lg:w-auto w-full">
                                <CustomButton className="lg:flex-auto flex-1" secondary onClick={handleButtonEvent('cancel')}>
                                    Cancel
                                </CustomButton>
                                <CustomButton className="lg:flex-auto flex-1 " primary onClick={handleButtonEvent('apply')}>
                                    Apply
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DateRangePicker;
