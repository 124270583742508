import React from 'react';
import { useTranslation } from 'react-i18next';

const SStripeAccountDetails = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col border border-[#EAECF0] px-6 w-full rounded-[16px] mt-[32px]">
            <div className="flex flex-row w-full  py-4 pb-4 border-b">
                <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Name :')}</div>
                <div className="w-[390px] flex items-start font-medium text-sm text-[#101828] custom-loading">loading</div>
            </div>
            <div className="flex flex-row w-full  py-4 pb-4 border-b">
                <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Destination accounts:')}</div>
                <div className="w-[390px] flex items-start font-medium text-sm text-[#101828] custom-loading">loading</div>
            </div>
            <div className="flex flex-row w-full  py-4 pb-4 border-b">
                <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Account Number:')}</div>
                <div className="w-[390px] flex items-start font-medium text-sm text-[#101828] custom-loading">loading</div>
            </div>
            <div className="flex flex-row w-full  py-4 pb-4 border-b">
                <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Account verified:')}</div>
                <div className="w-[390px] flex items-start font-medium text-sm text-[#101828] custom-loading">loading</div>
            </div>
            <div className="flex flex-row w-full  py-4 pb-4 border-b">
                <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Social insurance number:')}</div>
                <div className="w-[390px] flex items-start font-medium text-sm text-[#101828] custom-loading">loading</div>
            </div>
            <div className="flex flex-row w-full  py-4 pb-4 border-b">
                <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Date of birth:')}</div>
                <div className="w-[390px] flex items-start font-medium text-sm text-[#101828] custom-loading">loading</div>
            </div>
            <div className="flex flex-row w-full  py-4 pb-4 ">
                <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Phone number:')}</div>
                <div className="w-[390px] flex items-start font-medium text-sm text-[#101828] custom-loading">loading</div>
            </div>
        </div>
    );
};

export default SStripeAccountDetails;
