import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { AppearanceService } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { IFormData, IService, InitServiceInfoData, ServiceProps } from './Service.interface';
import Form from './Form';
import TemplateLayout from '../Layout/Layout';
import { allShopServices, allShopSettings, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import Switch from 'src/components/Switch/Switch';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';

const Service: FC<ServiceProps> = ({ template }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shopSettingInfo: any = useAppSelector(allShopSettings).find((setting) => setting.type === 'template')?.value?.service;
    const shop = useAppSelector(currentShop);
    const shopId = shop.id;
    const [isLoading, setIsLoading] = useState(false);
    const services = useAppSelector(allShopServices);

    const schema = Yup.object({
        status: Yup.bool().required(t('This field is required')),
        is_title: Yup.bool().required(t('This field is required')),
        title: Yup.string()
            .nullable()
            .when('is_title', ([is_title], customSchema) => (is_title ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        is_subtitle: Yup.bool().required(t('This field is required')),
        subtitle: Yup.string()
            .nullable()
            .when('is_subtitle', ([is_subtitle], customSchema) => (is_subtitle ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        services: Yup.array()
            .test({
                name: 'locations',
                message: 'Please add at least one service',
                test: function (value) {
                    const { status } = this.parent;
                    if (status && (!value || value.length === 0)) {
                        return false;
                    }
                    return true;
                },
            })
            .of(
                Yup.object().shape({
                    name: Yup.string().required(t('This field is required')),
                    is_description: Yup.bool().required(t('This field is required')),
                    description: Yup.string()
                        .nullable()
                        .when('is_description', ([is_description], customSchema) => (is_description ? customSchema.required(t('This field is required')) : customSchema.nullable())),
                    price: Yup.string().required(t('This field is required')),
                }),
            )
            .nullable()
            .when('status', ([status], customSchema) => (status ? customSchema.required() : customSchema.nullable())),
    });

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            status: shopSettingInfo?.status || false,
            is_title: shopSettingInfo?.is_title || false,
            title: shopSettingInfo?.title || '',
            is_subtitle: shopSettingInfo?.is_subtitle || false,
            subtitle: shopSettingInfo?.subtitle || '',
            services: shopSettingInfo?.services || [],
        },
    });

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        watch,
        formState: { errors },
    } = methods;
    const isStatus = watch('status');
    const servicesWatch = watch('services');

    const handleChange = async (data: IFormData) => {
        setIsLoading(true);
        const payload = {
            service: data,
            template,
        };
        try {
            const response = await axiosPost(API.THEME.TEMPLATE.SERVICE, payload, {
                shop_id: shopId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(getAllShopSettings({ shop_id: shop.id }));
                return;
            }
            if (response.data.status === errorCode.unprocessable) {
                if (response.data.data) {
                    Object.keys(response.data.data).forEach((field) => {
                        setError(field as keyof IFormData, {
                            type: 'manual',
                            message: response.data.data[field][0],
                        });
                    });
                }
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };
    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };

    const handleSync = (event: any) => {
        event.stopPropagation();
        const updatedServices: IService[] = services?.map((service: IService) => ({
            name: service?.name || InitServiceInfoData?.name,
            is_description: Boolean(service?.description) || InitServiceInfoData?.is_description,
            description: service?.description || InitServiceInfoData?.description,
            price: InitServiceInfoData?.price,
        }));
        setValue('services', updatedServices);
    };

    useFormErrorFocus<IFormData>({ errors, formSectionName: 'service' });

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit(handleChange)}
                className={`h-full ${errors?.services && errors?.services?.message ? 'is-invalid rounded-xl border' : 'rounded-xl border border-borderPrimary'}`}
            >
                <Collapse
                    title={t('Services')}
                    description={t('The Services section lists all the haircuts, shaves, and grooming services offered by your brand.')}
                    isCollapsed={isStatus}
                    isLoading={isLoading}
                    handleStatus={handleStatus}
                    handleSave={handleSubmit(handleChange)}
                    // handleSync={handleSync}
                    // syncButtonTitle={`${services.length !== servicesWatch?.length ? `Synced with services (${services.length})` : ''}`}
                >
                    <TemplateLayout ImgProp={AppearanceService}>
                        <>
                            <div className="flex gap-4 mb-4">
                                <Controller
                                    name={`title`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={t('Title')}
                                                id="service-title"
                                                required
                                                placeholder={t('Enter service title')}
                                                onChange={onChange}
                                                value={value}
                                                name={'title'}
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name={'is_title'}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="subtitle"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={t('Subtitle')}
                                                id="service-subtitle"
                                                required
                                                placeholder={t('Enter service subtitle')}
                                                onChange={onChange}
                                                value={value}
                                                name="subtitle"
                                                error={!!error}
                                                toggle={
                                                    <Controller
                                                        name={'is_subtitle'}
                                                        control={control}
                                                        render={({ field, fieldState }: any) => (
                                                            <>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </>
                                                        )}
                                                    />
                                                }
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>
                            <Form />
                        </>
                        {errors.services && errors?.services?.message && <p className="text-error text-center">{errors?.services?.message}</p>}
                    </TemplateLayout>
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end gap-4">
                        {services.length !== servicesWatch?.length && (
                            <CustomButton type="button" secondary onClick={handleSync}>
                                <span className="h-[8px] w-[8px] bg-success-500 rounded-full"></span>
                                <span className="flex items-center ">{`${services.length !== servicesWatch?.length ? `Sync (${services.length})` : ''}`}</span>
                            </CustomButton>
                        )}
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Service;
