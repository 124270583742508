import React from 'react';
import { formatKpiContentLable } from 'src/utils/global-functions';

const Discount = ({ handleViewClick, discountInfo }: any) => (
    <>
        {/* <div className="px-[6px] w-7/12">
            <div className="bg-white rounded-3xl border border-lineColor flex px-4">
                <div className="w-4/6 border-r py-4">
                    <h3 className="text-xl font-bold -tracking-[0.4px] mb-3">Discount codes uses</h3>

                    <div className="-ms-4">
                        <table className="fl-table w-full">
                            <thead>
                                <tr>
                                    <th>Amount</th>
                                    <th>Code name</th>
                                    <th>Uses</th>
                                </tr>
                            </thead>
                            <tbody> */}

        {/* <tr>
                                        <td>$1,224.16</td>
                                        <td>FLAIR40</td>
                                        <td>564</td>
                                    </tr>
                                    <tr>
                                        <td>$1,912.75</td>
                                        <td>FLAIRXMAS</td>
                                        <td>0</td>
                                    </tr> */}

        {/* </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-2/6 py-4 ps-4">
                    <div className="flex justify-between items-center mb-6">
                        <p className="leading-[140%] -tracking-[0.3px] font-medium text-xs text-secondaryTxtColor">Referral code uses:</p>
                        <p className="font-bold -tracking-[0.3]">0</p>
                    </div>

                    <div className="flex justify-between items-center mb-6">
                        <p className="leading-[140%] -tracking-[0.3px] font-medium text-xs text-secondaryTxtColor">Referral retention rate:</p>
                        <p className="font-bold -tracking-[0.3]">0%</p>
                    </div>

                    <div className="flex justify-between items-center mb-6">
                        <p className="leading-[140%] -tracking-[0.3px] font-medium text-xs text-secondaryTxtColor">Referral Revenue:</p>
                        <p className="font-bold -tracking-[0.3]">$0.00</p>
                    </div>

                    <div className="flex justify-between items-center">
                        <p className="leading-[140%] -tracking-[0.3px] font-medium text-xs text-secondaryTxtColor">Referral discount value:</p>
                        <p className="font-bold -tracking-[0.3]">$0.00</p>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="w-full">
            <div className="bg-white rounded-xl border border-borderSecondary shadow">
                <div className="text-lg font-semibold -tracking-[0.4px] text-mainTextColor border-b border-borderSecondary py-4 px-5">Average statistics</div>
                <div className="grid grid-cols-2 w-full p-5 gap-4">
                    <div className=" flex flex-col gap-2">
                        <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor truncate">Avg. Booking Frequency</p>
                        <h3 className="max-xlm:text-sm text-base font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor">{discountInfo.average_days} days</h3>
                    </div>

                    <div className=" flex flex-col gap-2">
                        <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor">Avg. Client Spend</p>
                        <h3 className="text-base max-xlm:text-sm font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor">${formatKpiContentLable(discountInfo.avg_client_spend) ?? 0}</h3>
                    </div>

                    <div className=" flex flex-col gap-2">
                        <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor">Total tips</p>
                        <h3 className="text-base max-xlm:text-sm font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor">${formatKpiContentLable(discountInfo.tips) ?? 0}</h3>
                    </div>

                    <div className=" flex flex-col gap-2">
                        <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor">Average tip per client</p>
                        <h3 className="text-base max-xlm:text-sm font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor">${formatKpiContentLable(discountInfo.avg_total_tips) ?? 0}</h3>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default Discount;
