import React, { FC } from 'react';
import { IEmptyMsgWithBtn } from './EmptyMsgWithBtn.interface';
import CustomButton from '../CustomButton';

const EmptyMsgWithBtn: FC<IEmptyMsgWithBtn> = ({ title, description, btnLabel, onClick }) => (
    <div className="flex h-80 w-full flex-col justify-center items-center">
        <h4 className="text-base font-bold mb-2">{title}</h4>
        <p className="-tracking-[0.3px] text-sm text-secondaryTxtColor font-normal  text-center">{description}</p>
        {btnLabel && onClick && (
            <CustomButton onClick={onClick} primary className=" mt-5 w-[200px] ">
                <div className="h-full flex items-center justify-center">{btnLabel}</div>
            </CustomButton>
        )}
    </div>
);

export default EmptyMsgWithBtn;
