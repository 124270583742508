import React, { useState } from 'react';

import ForgotPassword from './ForgotPassword/ForgotPassword';
import { IForgotPasswordStep } from './ForgotPassword/ForgotPassword.interface';
import VerifyOtp from './ForgotPasswordVerifyOtp/VerifyOtp';
import ChangePassword from 'src/app/Setting/AccountSettings/ChangePassword';

const Password = () => {
    const [forgotPasswordStep, setForgotPasswordStep] = useState<IForgotPasswordStep>('forgot_password');
    const [authData, setAuthData] = useState<any>(null);
    if (forgotPasswordStep === 'otp') {
        return <VerifyOtp authData={authData} setAuthData={setAuthData} setForgotPasswordStep={setForgotPasswordStep} />;
    } else if (forgotPasswordStep === 'verified') {
        return <ChangePassword forgotPasswordStep={forgotPasswordStep} authData={authData} />;
    } else {
        return <ForgotPassword setForgotPasswordStep={setForgotPasswordStep} setAuthData={setAuthData} authData={authData} />;
    }
};

export default Password;
