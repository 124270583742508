import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GoDotFill } from 'react-icons/go';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import { Download01, Eye, MarkerPin01, Reflect01, RefreshCcw01, RefreshCw01, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { perPageOptions } from 'src/utils/global-variables';

const StaffHeatMap = ({ staff }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(true);
    const [serviceListArray, setServiceListArray] = useState([]);
    const [totalServices, setTotalServices] = useState<number>(0);
    const [expandedRows, setExpandedRows] = useState({});

    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: perPageOptions[0].value,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
        },
    });

    useEffect(() => {
        listServices();
    }, [lazyState, shop]);

    const listServices = async () => {
        setIsLoading(true);
        await axiosGet(API.SERVICE.LIST, { shop_id: shop.id }, lazyState)
            .then((response) => {
                setServiceListArray(response.data.data.data);
                setTotalServices(response.data.data.data.totalRecords);
            })
            .finally(() => setIsLoading(false));
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-gray-700 text-sm flex items-center font-medium leading-5 mr-auto">
                Page {first} of {totalRecords}
            </div>
        ),
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const onRowToggle = (event: any) => {
        setExpandedRows(event.data);
    };

    const allowExpansion = (row: any) => row.variables.length > 0;

    const getType = (row: any) => {
        const color = row.type === 'variable' ? '#17B26A' : '#2E90FA';
        return (
            <p className="flex items-center gap-1 text-xs font-medium border rounded-lg p-1 capitalize w-20">
                <GoDotFill size={12} color={color} />
                {row.type}
            </p>
        );
    };

    const getLocation = (row: any) => row.locations[0].name;

    const rowExpansionTemplate = (data: any) => (
        <DataTable value={data.variables} showHeaders={false}>
            <Column expander={false} style={{ width: '5em' }} />
            <Column field="name" header={t('Service name')} className="text-xs font-medium text-mainTextColor" colSpan={3} style={{ width: '72.45%' }}></Column>
            <Column field="location" header={t('Location')} className="text-xs font-semibold text-primary" body={data.locations[0].name}></Column>
        </DataTable>
    );

    return (
        <div className="datatable-custom h-full">
            {/* <div className="flex justify-between items-center gap-2 mb-3 mt-8">
                <div className="flex flex-col">
                    <div className="flex justify-start items-center gap-2 mb-1">
                        <h2 className="text-base text-gray-900 font-semibold capitalize">Heat Map</h2>
                    </div>
                    <p className="text-sm font-normal text-gray-600 leading-5">{t('View name weekly heat map based on his booking activity.', { name: staff.first_name })}</p>
                </div>
                <div className="flex flex-row gap-4">
                    <div className="location-dropdown">
                        <SelectBox name="page" options={perPageOptions} onChangeFunc={onPageHandle} value={perPageOptions.find((option) => option.value === lazyState.rows)} classComp="w-28" />
                    </div>
                </div>
            </div> */}
            <div className="flex-1 relative">
                <div className="flex flex-col  justify-center min-h-[450px] items-center text-center bg-banner bg-center bg-no-repeat">
                    <div className="flex justify-center border shadow-sm border-gray-200 bg-white items-center h-12 w-12 mb-4 rounded-[10px] mt-12">
                        <SearchLg className="text-gray-700" />
                    </div>
                    <div className="text-gray-900 text-base font-semibold leading-[24px] mb-1">Data collection in progress</div>
                    <span className="text-gray-600 text-sm leading-5 font-normal">You can see data after 90 days of data collection.</span>
                </div>
            </div>
        </div>
    );
};

export default StaffHeatMap;
