import React, { useCallback, useEffect, useRef, useState } from 'react';
import PopupModal from 'src/components/PopupModal';

const TemplateLayout = ({ children, ImgProp }: { children: React.ReactNode; ImgProp: string }) => {
    const divRef = useRef<HTMLDivElement>(null);
    const initAction = {
        image_show: false,
    };
    const [action, setAction] = useState(initAction);

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);
    useEffect(() => {
        const closeOnEscapePressed = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setAction(initAction);
            }
        };
        window.addEventListener('keydown', closeOnEscapePressed);
        return () => window.removeEventListener('keydown', closeOnEscapePressed);
    }, []);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (divRef.current && !divRef.current.contains(event.target as Node)) {
                handleModalClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [divRef]);

    return (
        <>
            <div className="flex w-full items-start gap-[18px] xl:gap-5 xxl:gap-8 p-4 xl:p-5 pb-1 xl:pb-1">
                <div className=" template-theme-block-thumb mb-3">
                    <div className="template-theme-block-thumb-inner cursor-pointer">
                        <img src={ImgProp} alt="appearance_template" onClick={handleAction('image_show')} className="thumb-img" />
                    </div>
                </div>
                <div className="flex-1">{children}</div>
            </div>
            {action.image_show && (
                <PopupModal
                    ref={divRef}
                    onClose={handleModalClose}
                    size="mx-auto"
                    className="px-5 py-5"
                    // title={<Title />}
                >
                    <div className="-mx-2 pt-1">
                        <img src={ImgProp} alt="Popup-img" className="max-w-full max-h-full" />
                    </div>
                </PopupModal>
            )}
        </>
    );
};

export default TemplateLayout;
