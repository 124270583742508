import React from 'react';

import Footer from '../Footer';
import Sidebar from './Sidebar';
import Header from './Header';
import { Outlet } from 'react-router-dom';

const NonLayout = ({ children }: any) => (
    <div className="auth_wrapper flex ">
        <div className="flex flex-col h-full  lg:w-[640px] w-1/2  grow-0 shrink-0">
            <Header />
            <div className="flex-auto overflow-y-scroll scrollbar-hide h-[calc(100%-145px)]">
                <Outlet />
            </div>
            <Footer />
        </div>
        <div className="lg:w-[calc(100%-640px)] w-1/2">
            <Sidebar />
        </div>
    </div>
);

export default NonLayout;
