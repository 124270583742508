import { InfoCircle, PlusCircle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { GoDotFill } from 'react-icons/go';
import KpiCard from 'src/components/KpiCard';

const AdvAnalytics = ({ setAction, handleClose }: any) => {
    console.log('AdvAnalytics');
    const handleExpenseAdd = (actionType: string) => () => {
        console.log('hello', actionType);
        setAction((old: any) => ({ ...old, [actionType]: true }));
    };
    return (
        <>
            <div className=" border border-borderSecondary rounded-l-xl w-[80%]">
                <div className=" px-5 py-4 flex flex-row gap-2 items-center border-b border-borderSecondary">
                    <div className="font-semibold text-xl leading-[30px] text-mainTextColor">Advanced Analytics</div>
                    <div className="pt-[2px]">
                        <div className=" border-[1.5px] border-[#1570EF] px-2 py-[2px] font-medium text-[#1570EF] text-xs rounded-full max-h-[22px min-h-[22px]">This Month</div>
                    </div>
                </div>
                <div className="p-5 grid grid-cols-4 gap-5">
                    <KpiCard headerIcon={`IconMoney`} headerTitle="Total Revenue" contentLable={``} signIcon={'$'} growth={`AnalyticsTotal?.amount_growth`} fromDate={`boxLabel`} isAnalytics={true} />
                    <KpiCard headerIcon={`IconMoney`} headerTitle="Total Revenue" contentLable={``} signIcon={'$'} growth={`AnalyticsTotal?.amount_growth`} fromDate={`boxLabel`} isAnalytics={true} />
                    <KpiCard headerIcon={`IconMoney`} headerTitle="Total Revenue" contentLable={``} signIcon={'$'} growth={`AnalyticsTotal?.amount_growth`} fromDate={`boxLabel`} isAnalytics={true} />
                    <div
                        onClick={handleExpenseAdd('expense')}
                        className="border border-[#5279F0] bg-[#F0F3FF] shadow rounded-lg min-h-[142px] p-4 flex flex-col justify-center items-center relative cursor-pointer"
                    >
                        <div className=" absolute top-[15px] right-[15px] ">
                            <InfoCircle className="w-5 h-5 text-[#475467] " />
                        </div>
                        <div className="flex flex-row gap-1.5 items-center justify-center">
                            <PlusCircle className="w-5 h-5 text-[#0E34A5]" />
                            <p className="text-[#0E34A5] font-semibold text-base">Add Expenses</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-[20%] border border-borderSecondary border-l-0 rounded-r-xl ">
                <div className=" px-5 py-4 flex flex-row justify-between items-center border-dotted border-b-2 border-borderSecondary">
                    <div className="flex flex-row gap-1.5 items-center">
                        <GoDotFill size={12} color="#17B26A" className="max-2xl:h-3 max-2xl:w-3 h-4 w-4 rounded-full flex" />
                        <div className="font-normal text-base max-xlm:text-sm max-2xl:text-[12px] text-secondaryTxtColor">
                            Net Income <span className="max-xlm:text-[11px] max-2xl:text-[10px] text-xs text-[#079455] font-medium ">(00.00%)</span>
                        </div>
                    </div>
                    <div className="font-semibold text-xl text-mainTextColor leading-[30px]">00.00</div>
                </div>
                <div className="p-5">
                    <div className="p-5 bg-[#F2F4F7] shadow rounded-lg min-h-[142px]  flex flex-col justify-center items-center relative">
                        <div className="flex flex-col">
                            <div className=" text-secondaryTxtColor text-center  font-normal semibold text-sm">Add expenses </div>
                            <div className=" text-secondaryTxtColor text-center  font-normal semibold text-sm">to calculate Net Income</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdvAnalytics;
