import { TemplateType } from '../TemplateSettings.interface';

export interface IService {
    name: string;
    price: string;
    is_description: boolean;
    description: string;
}

export interface IFormData {
    status: boolean;
    is_title: boolean;
    title?: string;
    is_subtitle: boolean;
    subtitle?: string;
    services: IService[];
}

export const InitServiceData: IFormData = {
    status: false,
    is_title: false,
    title: '',
    is_subtitle: false,
    subtitle: '',
    services: [] as IService[],
};

export const InitServiceInfoData: IService = {
    name: '',
    price: '',
    is_description: true,
    description: '',
};

export interface ServiceProps {
    template: TemplateType;
}
