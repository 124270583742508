import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Overview from './Overview';
import OrderHistory from './OrderHistory';
import Inventory from './Inventory';

const ProductTab = ({ product }: any) => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState('overview');

    const handleTab = (name: string) => () => {
        setCurrentTab(name);
    };

    const productTab = useMemo(
        () => [
            {
                display_name: t('Overview'),
                name: 'overview',
            },
            {
                display_name: t('Order History'),
                name: 'history',
            },
            {
                display_name: t('Inventory Management'),
                name: 'inventory',
            },
        ],
        [],
    );

    const TabData = ({ tabs, currentProduct }: any) => {
        switch (tabs) {
            case 'overview':
                return <Overview product={currentProduct} setCurrentTab={setCurrentTab} />;
            case 'history':
                return <OrderHistory product={currentProduct} />;
            case 'inventory':
                return <Inventory product={currentProduct} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <div className="fl-tab-btn-view w-full">
                {productTab.map((tab: any, index) => (
                    <button key={index} type="button" onClick={handleTab(tab.name)} className={`w-full fl-tab-link max-w-[180px]  ${tab.name === currentTab ? '!text-primary !bg-blue-50' : ''}`}>
                        {tab.display_name}
                    </button>
                ))}
            </div>
            <TabData tabs={currentTab} currentProduct={product} />
        </>
    );
};

export default memo(ProductTab);
