import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IFormData, initAction } from './PaymentSettings.interface';
import * as Yup from 'yup';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentRole, currentShop, me } from 'src/redux/services/common/Common.slice';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import { Edit02, ChevronDown, InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import DateSelect from 'src/components/DateSelect/DateSelect';
import moment from 'moment';
import { getDayNumber } from 'src/utils/global-functions';
import { Tooltip } from 'flowbite-react';
import { ROLES } from 'src/constants/common';

const PaymentSettings = () => {
    const { t } = useTranslation();
    const role = useAppSelector(currentRole);
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [action, setAction] = useState(initAction);

    const schema = Yup.object({
        commission_frequency: Yup.string().required(t('This field is required')),
        commission_day: Yup.string().when('commission_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) => currentSchema.required(t('This field is required when commission frequency is Weekly or Bi-Weekly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        commission_start_date: Yup.string().when('commission_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) =>
                currentSchema
                    .required(t('This field is required when commission frequency is Weekly or Bi-Weekly'))
                    .test('commission_day', t('The date must be the same as the payment day'), function (value) {
                        const { commission_day: day } = this.parent;
                        if (!value || !day) {
                            return true;
                        }
                        const dayNumber = getDayNumber(day);
                        const currentDay = moment(value).day();
                        return currentDay === dayNumber;
                    }),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        commission_month_day: Yup.string().when('commission_frequency', {
            is: (value: string) => value === 'monthly',
            then: (currentSchema) => currentSchema.required(t('This field is required when commission frequency is Monthly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        rent_frequency: Yup.string().required(t('This field is required')),
        rent_day: Yup.string().when('rent_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) => currentSchema.required(t('This field is required when rent frequency is Weekly or Bi-Weekly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        rent_start_date: Yup.string().when('rent_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) =>
                currentSchema.required(t('This field is required when rent frequency is Weekly or Bi-Weekly')).test('rent_day', t('The date must be the same as the payment day'), function (value) {
                    const { rent_day: day } = this.parent;
                    if (!value || !day) {
                        return true;
                    }
                    const dayNumber = getDayNumber(day);
                    const currentDay = moment(value).day();
                    return currentDay === dayNumber;
                }),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        rent_month_day: Yup.string().when('rent_frequency', {
            is: (value: string) => value === 'monthly',
            then: (currentSchema) => currentSchema.required(t('This field is required when rent frequency is Monthly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        tip_frequency: Yup.string().required(t('This field is required')),
        tip_day: Yup.string().when('tip_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) => currentSchema.required(t('This field is required when tip frequency is Weekly or Bi-Weekly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        tip_start_date: Yup.string().when('tip_frequency', {
            is: (value: string) => value === 'weekly' || value === 'bi_weekly',
            then: (currentSchema) =>
                currentSchema.required(t('This field is required when tip frequency is Weekly or Bi-Weekly')).test('tip_day', t('The date must be the same as the payment day'), function (value) {
                    const { tip_day: day } = this.parent;
                    if (!value || !day) {
                        return true;
                    }
                    const dayNumber = getDayNumber(day);
                    const currentDay = moment(value).day();
                    return currentDay === dayNumber;
                }),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
        tip_month_day: Yup.string().when('tip_frequency', {
            is: (value: string) => value === 'monthly',
            then: (currentSchema) => currentSchema.required(t('This field is required when tip frequency is Monthly')),
            otherwise: (currentSchema) => currentSchema.notRequired(),
        }),
    }).required();
    useEffect(() => {
        resetForm();
    }, [shop]);

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            commission_frequency: shop.shop_payment.commission_frequency || null,
            commission_day: shop.shop_payment.commission_day || null,
            commission_start_date: shop.shop_payment.commission_start_date || null,
            commission_month_day: shop.shop_payment.commission_month_day || null,
            rent_frequency: shop.shop_payment.rent_frequency || null,
            rent_start_date: shop.shop_payment.rent_start_date || null,
            rent_day: shop.shop_payment.rent_day || null,
            rent_month_day: shop.shop_payment.rent_month_day || null,
            tip_frequency: shop.shop_payment.tip_frequency || null,
            tip_day: shop.shop_payment.tip_day || null,
            tip_start_date: shop.shop_payment.tip_start_date || null,
            tip_month_day: shop.shop_payment.tip_month_day || null,
        },
    });

    const { handleSubmit, watch, setError, control, reset } = methods;
    const commissionDay = watch('commission_day');
    const rentDay = watch('rent_day');
    const tipDay = watch('tip_day');

    const commissionFrequency = watch('commission_frequency');
    const rentFrequency = watch('rent_frequency');
    const tipFrequency = watch('tip_frequency');

    const handleSave = async (data: IFormData) => {
        setIsLoading(true);
        await axiosPost(API.PAYMENT.UPDATE, data, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const confirmModalClose = useCallback(
        (type: boolean = false) =>
            () => {
                if (action.update) {
                    setIsEditable(type);
                }
                setAction(initAction);
            },
        [action],
    );

    const isWeekday = (day: string) => (date: Date) => {
        const dayNumber = getDayNumber(day);
        const currentDay = moment(date).day();
        return currentDay === dayNumber;
    };

    const frequencyOptions = useMemo(
        () => [
            {
                value: 'weekly',
                label: t('Weekly'),
            },
            {
                value: 'bi_weekly',
                label: t('Bi-Weekly'),
            },
            {
                value: 'monthly',
                label: t('Monthly'),
            },
        ],
        [t],
    );

    const monthOptions = useMemo(
        () => [
            {
                value: 'first',
                label: t('First day of month'),
            },
            {
                value: 'last',
                label: t('Last day of month'),
            },
        ],
        [t],
    );

    const dayOptions = useMemo(
        () => [
            {
                value: 'sunday',
                label: t('Sunday'),
            },
            {
                value: 'monday',
                label: t('Monday'),
            },
            {
                value: 'tuesday',
                label: t('Tuesday'),
            },
            {
                value: 'wednesday',
                label: t('Wednesday'),
            },
            {
                value: 'thursday',
                label: t('Thursday'),
            },
            {
                value: 'friday',
                label: t('Friday'),
            },
            {
                value: 'saturday',
                label: t('Saturday'),
            },
        ],
        [t],
    );

    const resetForm = () => {
        reset();
    };

    return (
        <div className="w-full pt-6">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSave)} className="flex flex-col gap-5 mb-6">
                    <div className="flex justify-between border-b pb-6 items-center">
                        <div className="flex flex-col gap-1">
                            <h2 className="text-[18px] font-semibold leading-[28px] flex flex-row gap-1.5 items-center">
                                <div className="text-mainTextColor">{t('Payment Structure Settings')}</div>
                                <Tooltip className="bg-black text-white" content={role.name === ROLES.OWNER || role.name === ROLES.INDIVIDUAL ? 'Brand owner' : 'Team Member'}>
                                    <InfoCircle className="text-[#98A2B3] w-5 h-5 custom-tooltip-btn" />
                                </Tooltip>
                            </h2>
                            <p className="text-xs text-secondaryTxtColor leading-[18px] ">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                        </div>
                        {isEditable ? (
                            <div className="flex flex-row gap-3">
                                <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" className="!h-[36px]">
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        ) : (
                            <CustomButton primary type={'button'} isLoading={isLoading} disabled={isLoading} onClick={handleAction('update')}>
                                {t('Edit')}
                            </CustomButton>
                        )}
                    </div>
                    <div className="fl-data-block">
                        <div className="title-block w-[280px]">
                            <h3 className="location-form-label-heading">{t('Commission payout frequency')}</h3>
                            <p className="location-form-label-subheading">{t('Set the frequency at which your staff will automatically be paid based on their commission split')}</p>
                        </div>
                        <div className="data-wrap-block w-full max-w-[666px]">
                            <h3 className="location-form-label-heading">
                                {t('Commission payout frequency:')} <span className="capitalize font-bold text-sm ml-1">{commissionFrequency.replace(/_/g, '-')}</span>
                            </h3>
                            <p className="location-form-label-subheading">
                                {t('Set the commission payout frequency that all staff members on the commission payment structure will follow by default. This enables a consistent payout window.')}
                            </p>
                            <div className="flex flex-row gap-4 mt-8">
                                <Controller
                                    name="commission_frequency"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div className="w-1/2">
                                            <SelectBox
                                                isDisabled={!isEditable}
                                                name="commission_frequency"
                                                label={t('Commission payout frequency')}
                                                options={frequencyOptions}
                                                onChangeFunc={(option: any) => onChange(option.value)}
                                                value={frequencyOptions.find((option) => option.value === value)}
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                            {error && <p className="text-error">{error?.message}</p>}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="tip_frequency"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div className="w-1/2">
                                            <SelectBox
                                                isDisabled={!isEditable}
                                                name="tip_frequency"
                                                label={t('Tip payout frequency')}
                                                options={[{ value: 'daily', label: t('Daily') }].concat(frequencyOptions)}
                                                onChangeFunc={(option: any) => onChange(option.value)}
                                                value={[{ value: 'daily', label: t('Daily') }].concat(frequencyOptions).find((option) => option.value === value)}
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                            {error && <p className="text-error">{error?.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-row gap-4 mt-5">
                                <div className="w-1/2">
                                    {commissionFrequency === 'monthly' && (
                                        <Controller
                                            name="commission_month_day"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <SelectBox
                                                        isDisabled={!isEditable}
                                                        name="commission_month_day"
                                                        label={t('Select day of month')}
                                                        options={monthOptions}
                                                        onChangeFunc={(option: any) => onChange(option.value)}
                                                        value={monthOptions.find((option) => option.value === value)}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    )}
                                    {(commissionFrequency === 'weekly' || commissionFrequency === 'bi_weekly') && (
                                        <div className="w-full">
                                            <Controller
                                                name="commission_day"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div>
                                                        <SelectBox
                                                            isDisabled={!isEditable}
                                                            name="commission_day"
                                                            label={t('Payment day')}
                                                            options={dayOptions}
                                                            onChangeFunc={(option: any) => onChange(option.value)}
                                                            value={dayOptions.find((option) => option.value === value)}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                        />
                                                        {error && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="commission_start_date"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="w-full mt-3">
                                                        <DateSelect
                                                            label={t('Payout window start date')}
                                                            inputName="commission_start_date"
                                                            className={`form_control_date font-medium text-xs relative w-full ${error && 'is-invalid'}`}
                                                            placeholder={t('Select date')}
                                                            dateFormat="yyyy-MM-dd"
                                                            disabled={!isEditable}
                                                            minDate={new Date()}
                                                            value={value}
                                                            onChangeValue={onChange}
                                                            filterDate={isWeekday(commissionDay || '')}
                                                            icon={<ChevronDown className="text-gray-700 w-[22px]" />}
                                                        />
                                                        {error && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="w-1/2">
                                    {tipFrequency === 'monthly' && (
                                        <Controller
                                            name="tip_month_day"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full ">
                                                    <SelectBox
                                                        isDisabled={!isEditable}
                                                        name="tip_month_day"
                                                        label={t('Select day of month')}
                                                        options={monthOptions}
                                                        onChangeFunc={(option: any) => onChange(option.value)}
                                                        value={monthOptions.find((option) => option.value === value)}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    )}
                                    {(tipFrequency === 'weekly' || tipFrequency === 'bi_weekly') && (
                                        <>
                                            <Controller
                                                name="tip_day"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="w-full ">
                                                        <SelectBox
                                                            isDisabled={!isEditable}
                                                            name="tip_day"
                                                            label={t('Tip Payment day')}
                                                            options={dayOptions}
                                                            onChangeFunc={(option: any) => onChange(option.value)}
                                                            value={dayOptions.find((option) => option.value === value)}
                                                            isClearable={false}
                                                            isSearchable={false}
                                                        />
                                                        {error && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                            <Controller
                                                name="tip_start_date"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="w-full mt-3">
                                                        <DateSelect
                                                            label={t('Tip Payout window start date')}
                                                            inputName="tip_start_date"
                                                            className={`form_control_date font-medium text-xs relative w-full ${error && 'is-invalid'}`}
                                                            placeholder={t('Select date')}
                                                            dateFormat="yyyy-MM-dd"
                                                            disabled={!isEditable}
                                                            minDate={new Date()}
                                                            value={value}
                                                            onChangeValue={onChange}
                                                            filterDate={isWeekday(tipDay || '')}
                                                            icon={<ChevronDown className="text-gray-700 w-[22px]" />}
                                                        />
                                                        {error && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="flex flex-row gap-2 mt-5 items-center">
                                <div className="text-xs font-medium text-[#344054] leading-[18px]">{t('Your next payment cycle will be')}</div>
                                <div className="flex justify-center items-center w-max h-[36px] border border-[#EAECF0] bg-[#ECFDF3] px-3 py-2 text-xs leading-[18px] font-medium text-[#344054] rounded-[8px]">
                                    {t('1st January, 2024 - 15th January, 2024')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="divider" />
                    <div className="fl-data-block">
                        <div className="title-block w-[280px]">
                            <h3 className="location-form-label-heading">{t('Rent collection frequency')}</h3>
                            <p className="location-form-label-subheading">{t('Set the frequency at which you wish to automatically collect payments for all of your staff on rent.')}</p>
                        </div>
                        <div className="data-wrap-block w-full max-w-[666px]">
                            <h3 className="location-form-label-heading">
                                {t('Rent collection frequency:')} <span className="capitalize font-bold text-sm ml-1">{rentFrequency.replace(/_/g, '-')}</span>
                            </h3>
                            <p className="location-form-label-subheading">
                                {t('Set the rent collection frequency that all staff members on the rent  payment structure will follow by default. This enables a consistent  collection window.')}
                            </p>
                            <div className="flex flex-row gap-4 mt-5 items-center">
                                <Controller
                                    name="rent_frequency"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <div className="w-1/2">
                                            <SelectBox
                                                isDisabled={!isEditable}
                                                name="rent_frequency"
                                                label={t('Rent collection frequency')}
                                                options={frequencyOptions}
                                                onChangeFunc={(option: any) => onChange(option.value)}
                                                value={frequencyOptions.find((option) => option.value === value)}
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                            {error && <p className="text-error">{error?.message}</p>}
                                        </div>
                                    )}
                                />
                                {rentFrequency === 'monthly' && (
                                    <Controller
                                        name="rent_month_day"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="w-1/2">
                                                <SelectBox
                                                    isDisabled={!isEditable}
                                                    name="rent_month_day"
                                                    label={t('Select day of month')}
                                                    options={monthOptions}
                                                    onChangeFunc={(option: any) => onChange(option.value)}
                                                    value={monthOptions.find((option) => option.value === value)}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                />
                                                {error && <p className="text-error">{error.message}</p>}
                                            </div>
                                        )}
                                    />
                                )}
                                {(rentFrequency === 'weekly' || rentFrequency === 'bi_weekly') && (
                                    <>
                                        <Controller
                                            name="rent_day"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-1/2">
                                                    <SelectBox
                                                        isDisabled={!isEditable}
                                                        name="rent_day"
                                                        label={t('Payment day')}
                                                        options={dayOptions}
                                                        onChangeFunc={(option: any) => onChange(option.value)}
                                                        value={dayOptions.find((option) => option.value === value)}
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name="rent_start_date"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-1/2">
                                                    <DateSelect
                                                        label={t('Payout window start date')}
                                                        inputName="rent_start_date"
                                                        className={`form_control_date font-medium text-xs relative w-full ${error && 'is-invalid'}`}
                                                        placeholder={t('Select date')}
                                                        dateFormat="yyyy-MM-dd"
                                                        disabled={!isEditable}
                                                        minDate={new Date()}
                                                        value={value}
                                                        onChangeValue={onChange}
                                                        filterDate={isWeekday(rentDay || '')}
                                                    />
                                                    {error && <p className="text-error">{error.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </>
                                )}
                            </div>

                            <div className="flex flex-row gap-2 mt-5 items-center">
                                <div className="text-xs font-medium text-[#344054] leading-[18px]">{t('Your next payment cycle will be')}</div>
                                <div className="flex justify-center items-center w-max h-[36px] border border-[#EAECF0] bg-[#ECFDF3] px-3 py-2 text-xs leading-[18px] font-medium text-[#344054] rounded-[8px]">
                                    {t('1st January, 2024 - 15th January, 2024')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="divider" />
                    {isEditable && (
                        <div className="flex flex-row gap-3 justify-end w-full">
                            <CustomButton secondary disabled={isLoading} type="button" onClick={resetForm} className="!h-[36px]">
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" className="!h-[36px]">
                                {t('Save Changes')}
                            </CustomButton>
                        </div>
                    )}
                </form>
            </FormProvider>
            {action.update && (
                <DeletePopupModal
                    primary
                    onClose={confirmModalClose}
                    confirmButtonText={t('Yes')}
                    cancelButtonText={t('No')}
                    size="w-[500px]"
                    title={t('Edit Payment Structure Settings')}
                    description={t('Do you want to edit Configure Payment settings?')}
                    headerIcon={<Edit02 className="w-6 h-6 text-gray-700" />}
                />
            )}
        </div>
    );
};

export default PaymentSettings;
