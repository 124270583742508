/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, Dispatch } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Number from 'src/components/Number';
import InputWithLabel from 'src/components/InputWithLabel';

import DateSelect from 'src/components/DateSelect/DateSelect';
import SelectBox from 'src/components/SelectBox/SelectBox';
import * as Yup from 'yup';
import { changeDateformat, dateFormatTZtoDate, getSelectBoxOptions, getSelectBoxOptionsIdAsValue } from 'src/utils/global-functions';
import { createRole, removeRole, roleList } from '../Staff.slice';
import { useAppDispatch } from 'src/redux/hooks';
import Button from 'src/components/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { locationListAction } from 'src/app/Location/Location.slice';
import CustomSelectDropdown from 'src/components/CustomSelectDropdown';
import { useTranslation } from 'react-i18next';
import { iconDateCalendar, ProfileImg } from 'src/theme/Images';
type FieldKey = 'first_name' | 'last_name' | 'birth_date' | 'phone' | 'phone_country_code' | 'email' | 'account_type' | 'shop_location_id' | 'shop_staff_role_id';

const Info = ({
    staffInfo,
    shopId,
    isEditProfile,
    setIsEditProfile,
    getStaffDetailFun,
    getStaff,
    loading,
    bookingData,
}: {
    staffInfo: any;
    shopId: number;
    getStaff: any;
    getStaffDetailFun: any;
    setIsEditProfile: Dispatch<React.SetStateAction<boolean>>;
    isEditProfile: boolean;
    loading: boolean;
    bookingData: any;
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const optionAccType = [{ label: 'Service Provider', value: 'service_provider' }];
    const [locationOptions, seLocationOptions] = useState([]);
    const [dateValue, setDateValue] = useState(new Date());
    const [createRoleError, setCreateRoleError] = useState('');
    const [roleListArray, setRoleListArray] = useState([]);
    const [selectedValue, setSelectedValue] = useState('Select Role');
    const [handleCloseDropdown, setHandleCloseDropdown] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDateChange = (date: any) => {
        setDateValue(date);
    };
    const genderList = [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Non-binary', value: 'non-binary' },
        { label: 'Prefer not to respond', value: 'prefer not to respond' },
    ];

    useEffect(() => {
        getLocationOptions();
        getRoleList();
    }, [staffInfo]);

    const getLocationOptions = async () => {
        setIsLoading(true);
        const data = {
            shop_id: shopId,
        };
        const result = await dispatch(locationListAction(data));
        if (result.type === locationListAction.fulfilled.toString()) {
            const locationListArray: any = getSelectBoxOptions(result.payload.data, 'id', 'name');

            seLocationOptions(locationListArray);
            const locationId = staffInfo?.locations.map((item: any) => item.id);
            setValue('shop_location_id', locationId);
        }
        setIsLoading(false);
    };

    const schema = Yup.object({
        first_name: Yup.string().required(t('This field is required')),
        last_name: Yup.string().required(t('This field is required')),
        birth_date: Yup.mixed().required(t('This field is required')),
        // account_type: Yup.string().required(t('This field is required')),
        shop_location_id: Yup.array().min(1, t('This field is required')),
        gender: Yup.string().required(t('This field is required')),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        getValues,
    } = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: staffInfo.first_name,
            last_name: staffInfo.last_name,
            email: staffInfo.email,
            gender: staffInfo.gender,
            phone: staffInfo.phone,
            phone_country_code: staffInfo.phone_country_code,

            shop_location_id: staffInfo.shop_location_id ?? [],
            shop_staff_role_id: staffInfo.role?.id,
            birth_date: staffInfo.birth_date ? changeDateformat(staffInfo.birth_date, 'yyyy-MM-DD', 'DD/MM/YYYY') : null,
            pay_structure_type: staffInfo.pay_structure.pay_structure_type,
            start_date: staffInfo.pay_structure.start_date,
            effective_date: staffInfo.pay_structure.effective_date,
            rent_money: staffInfo.pay_structure.rent_money,
            commission: staffInfo.pay_structure.commission,
            account_type: staffInfo.shop_staff ? staffInfo.shop_staff[0].account_type : optionAccType[0].value,
            last_activity: staffInfo.login_at ? dateFormatTZtoDate(staffInfo.login_at) : null,
            date_of_creation: staffInfo.updated_at ? dateFormatTZtoDate(staffInfo.updated_at) : null,
            created_at: staffInfo.created_at,
        },
    });

    const getRoleList = async () => {
        setIsLoading(true);
        const data = {
            shop_id: shopId,
        };
        const result = await dispatch(roleList(data));
        if (result.type === roleList.fulfilled.toString()) {
            const roleData: any = getSelectBoxOptionsIdAsValue(result.payload.data);
            const roleId = getValues().shop_staff_role_id;
            if (roleId) {
                const matchedRole = roleData.find((role: any) => role.id === roleId);
                setSelectedValue(matchedRole?.label);
            }

            setRoleListArray(roleData);
        }
        setIsLoading(false);
    };
    const handleItemClick = (value: any) => {
        setValue('shop_staff_role_id', value.id);
        setHandleCloseDropdown(true);
    };
    const handleAddClick = async (value: string) => {
        const submitData = {
            payload: { name: value },
            shop_id: shopId,
        };
        setCreateRoleError('');
        const result = await dispatch(createRole(submitData));
        if (result.type === createRole.fulfilled.toString()) {
            setValue('shop_staff_role_id', result.payload.data.id);
            getRoleList();
            setHandleCloseDropdown(true);
        }

        if (result.type === createRole.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                setCreateRoleError(response.data.name[0]);
            }
        }
    };

    useEffect(() => {
        const locationOptiondata: any = getSelectBoxOptionsIdAsValue(staffInfo.locations);
        seLocationOptions(locationOptiondata);
    }, []);

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        const parts = data.birth_date ? data.birth_date.split('/') : [];
        if (parts.length > 1) data.birth_date = `${parts[2]}-${parts[1]}-${parts[0]}`;

        const submitData = {
            payload: data,
            param: { shop_id: shopId, id: staffInfo?.id },
        };

        /* const result = await dispatch(updateStaff(submitData));
        if (result.type === updateStaff.fulfilled.toString()) {
            setIsEditProfile(false);
            getStaff();
            getStaffDetailFun();
            // handleClose();
            // reset();
        }

        if (result.type === updateStaff.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                return;
            }
            toast.error(response.message);
        } */
        setIsLoading(false);
    };

    const handleRemoveClick = async (id: number) => {
        const data = {
            shop_id: shopId,
            id: id,
        };
        const result = await dispatch(removeRole(data));
        if (result.type === removeRole.fulfilled.toString()) {
            setValue('shop_staff_role_id', null);
            getRoleList();
        }
    };
    return (
        <>
            <div className="drawer-content-block">
                {!loading && !isLoading && (
                    <>
                        <div className="px-8 py-6 border-b border-lineColor">
                            <div className="flex justify-between flex-wrap ">
                                <div className="grow-0 shrink-0 basis-[172px] mb-5">
                                    <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Expected income')}</p>
                                    <p className="font-bold text-xl -tracking-[0.36px]">$ {bookingData.expected_amount}</p>
                                </div>
                                <div className="grow-0 shrink-0 basis-[172px] mb-5">
                                    <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Total sales')}</p>
                                    <p className="font-bold text-xl -tracking-[0.36px]">$ {bookingData.total_amount}</p>
                                </div>
                                <div className="grow-0 shrink-0 basis-[172px] mb-5">
                                    <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Average bill')}</p>
                                    <p className="font-bold text-xl -tracking-[0.36px]">$ {bookingData.average_amount}</p>
                                </div>
                                <div className="grow-0 shrink-0 basis-[172px]">
                                    <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Clients left')}</p>
                                    <p className="font-bold text-xl -tracking-[0.36px]">{bookingData.users_left}</p>
                                </div>
                                <div className="grow-0 shrink-0 basis-[172px]">
                                    <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Average tip')}</p>
                                    <p className="font-bold text-xl -tracking-[0.36px]">$ {bookingData.average_tips}</p>
                                </div>
                                <div className="grow-0 shrink-0 basis-[172px]">
                                    <p className="text-xs font-medium text-secondaryTxtColor leading-[140%] -tracking-[0.3px] mb-1">{t('Completed Appointments')}</p>
                                    <p className="font-bold text-xl -tracking-[0.36px]">{bookingData.total_completed}</p>
                                </div>
                            </div>
                        </div>
                        <div className="py-6 px-8">
                            <form className="mb-12" onSubmit={handleSubmit(onSubmit)}>
                                {isEditProfile && (
                                    <div className="w-full relative">
                                        <Button isLoading={isLoading} disabled={isLoading} className=" btn_primary w-[200px] h-10 fixed right-[75px] top-3">
                                            {t('Save changes')}
                                        </Button>
                                    </div>
                                )}
                                <div className="flex -mx-[10px] flex-wrap">
                                    <div className="px-[10px] w-1/2 mb-5 grow-0 shrink-0 basis-[50%]">
                                        <Controller
                                            name="first_name"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <InputWithLabel
                                                    label={t('First Name')}
                                                    placeholder={t('Enter member’s First Name')}
                                                    onChange={onChange}
                                                    disabled={!isEditProfile}
                                                    value={value}
                                                    name="first_name"
                                                    error={!!errors.first_name}
                                                />
                                            )}
                                        />
                                        {errors?.first_name && <p className="text-error">{errors?.first_name?.message as React.ReactNode}</p>}
                                    </div>
                                    <div className="px-[10px] w-1/2 mb-5 grow-0 shrink-0 basis-[50%]">
                                        <Controller
                                            name="last_name"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <InputWithLabel
                                                    label={t('Last Name')}
                                                    placeholder={t('Enter Member’s Last Name')}
                                                    onChange={onChange}
                                                    value={value}
                                                    disabled={!isEditProfile}
                                                    name="last_name"
                                                    error={!!errors.last_name}
                                                />
                                            )}
                                        />
                                        {errors?.last_name && <p className="text-error">{errors?.last_name?.message as React.ReactNode}</p>}
                                    </div>

                                    <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                        <label htmlFor="phone" className="fl-field-title-label">
                                            {t('Phone Number')}
                                        </label>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            render={({ field: { onChange } }: any) => (
                                                <div className="flex flex-col ">
                                                    <Number
                                                        getNumber={onChange}
                                                        errors={null}
                                                        onNumberChange={() => {}}
                                                        disabled={true}
                                                        defaultValues={{
                                                            phone: staffInfo.phone,
                                                            phone_country_code: staffInfo.phone_country_code,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                        {errors?.phone && <p className="text-error">{errors?.phone?.message as React.ReactNode}</p>}
                                    </div>
                                    <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <InputWithLabel label={t('E-mail')} placeholder={t('Enter member’s email')} onChange={onChange} value={value} disabled={true} name="email" />
                                            )}
                                        />
                                    </div>
                                    <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                        <Controller
                                            name="gender"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <SelectBox
                                                    name="gender"
                                                    label={t('Gender')}
                                                    options={genderList}
                                                    value={genderList.filter((item: any) => item.value === value)}
                                                    errorText={errors.gender && true}
                                                    onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                                    placeholder={t('Select Gender')}
                                                    isDisabled={!isEditProfile}
                                                />
                                            )}
                                        />
                                        {errors?.gender && <p className="text-error">{errors?.gender?.message as React.ReactNode}</p>}
                                    </div>
                                    <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                        <Controller
                                            name={`birth_date`}
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <>
                                                    <DateSelect
                                                        inputName="birth_date"
                                                        className="form_control_date w-full form_input_control"
                                                        label={t('Date of birth')}
                                                        placeholder="dd-MM-Y"
                                                        value={value}
                                                        dateFormat="dd/MM/Y"
                                                        selected={dateValue}
                                                        disabled={!isEditProfile}
                                                        onChangeValue={onChange}
                                                        icon={<img src={iconDateCalendar} alt="" className="w-5 " />}
                                                    />
                                                </>
                                            )}
                                        />
                                        {errors?.birth_date && <p className="text-error">{errors?.birth_date?.message as React.ReactNode}</p>}
                                    </div>
                                    <div className="px-[10px] mb-5 w-full grow-0 shrink-0 basis-[100%] working_location">
                                        <Controller
                                            name={`shop_location_id`}
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <SelectBox
                                                    label={t('Working location')}
                                                    name="shop_location_id"
                                                    value={locationOptions.filter((item: any) => value.includes(item.id))}
                                                    onChangeFunc={(selectedOption: any) => {
                                                        !value.includes(selectedOption.value) && onChange(Array.isArray(selectedOption) ? selectedOption.map((x: any) => x.value) : []);
                                                    }}
                                                    options={locationOptions}
                                                    classComp=""
                                                    isClearable={true}
                                                    isDisabled={!isEditProfile}
                                                    openOnFocus={true}
                                                    autofocus={true}
                                                    isMulti
                                                />
                                            )}
                                        />
                                        {errors?.shop_location_id && <p className="text-error">{errors?.shop_location_id?.message as React.ReactNode}</p>}
                                    </div>
                                    <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                        <Controller
                                            name="last_activity"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <>
                                                    <DateSelect
                                                        inputName="picktime"
                                                        className="form_control_date w-full form_input_control"
                                                        label={t('Last Activity')}
                                                        placeholder="dd/mm/yyyy"
                                                        dateFormat="dd/mm/YYYY"
                                                        disabled={true}
                                                        value={value}
                                                        onChangeValue={handleDateChange}
                                                        icon={<img src={iconDateCalendar} alt="" className="w-5 " />}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>

                                    <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                        <Controller
                                            name="account_type"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <>
                                                    <SelectBox
                                                        name="account_type"
                                                        label={t('Account type')}
                                                        options={optionAccType}
                                                        // value={optionAccType}
                                                        value={optionAccType.filter((item: any) => item.value === value)}
                                                        isDisabled={!isEditProfile}
                                                        errorText={errors.account_type && true}
                                                        onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                                        placeholder={t('Select account type')}
                                                    />
                                                </>
                                            )}
                                        />
                                        {errors?.account_type && <p className="text-error">{errors?.account_type?.message as React.ReactNode}</p>}
                                    </div>

                                    <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                        <Controller
                                            name={`shop_staff_role_id`}
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <>
                                                    <CustomSelectDropdown
                                                        data={roleListArray}
                                                        className={`bg-inputBg text-sm h-[48px] px-4 py-2 w-full border border-inputBg tracking-tight rounded-full text-center flex items-center focus:border-primary
                                    ${errors.shop_staff_role_id && 'is-invalid'}`}
                                                        // label={selectedValue}
                                                        dropDownMenuClassName="w-full"
                                                        // setselected={setSelectedValue}
                                                        handleItemClick={handleItemClick}
                                                        handleAddClick={(value2: string) => handleAddClick(value2)}
                                                        handleCloseDropdown={handleCloseDropdown}
                                                        createRoleError={createRoleError}
                                                        handleRemoveClick={(id: number) => handleRemoveClick(id)}
                                                        isDisabled={!isEditProfile}
                                                    />
                                                </>
                                            )}
                                        />
                                    </div>
                                    <Controller
                                        name="date_of_creation"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <>
                                                <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                                    <DateSelect
                                                        inputName="date_of_creation"
                                                        className="form_control_date w-full form_input_control"
                                                        label={t('Date of creation')}
                                                        placeholder="dd/mm/yyyy"
                                                        disabled={true}
                                                        dateFormat="dd/mm/YYYY"
                                                        value={value}
                                                        onChangeValue={handleDateChange}
                                                        icon={<img src={iconDateCalendar} alt="" className="w-5 " />}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    />
                                    <div className="px-[10px] mb-5 w-1/2 grow-0 shrink-0 basis-[50%]">
                                        <label className="fl-field-title-label">{t('Invited by')}</label>
                                        <div className="px-4 py-2 bg-inputBg rounded-full h-[48px] flex items-center">
                                            <img src={staffInfo.created_by_admin.profile_image_url ?? ProfileImg} alt="" className="w-8 h-8 grow-0 shrink-0 rounded-full " />
                                            <div className="ml-2 leading-[140%] -tracking-[0.14px]">{`${staffInfo.created_by_admin.first_name ?? ''} ${
                                                staffInfo.created_by_admin.last_name ?? ''
                                            }`}</div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Info;
