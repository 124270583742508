import { TemplateType } from '../TemplateSettings.interface';

export interface IFormData {
    status: boolean;
    is_title: boolean;
    title?: string;
    is_subtitle: boolean;
    subtitle?: string;
    is_button: boolean;
    button?: string;
    is_instgram_button: boolean;
    instgram_button?: string;
    is_reviews: boolean;
    reviews: string;
    reviews_description?: string;
    is_background: boolean;
    background?: {
        file: File | null;
        url: string | null;
        name: string | null;
    } | null;
}

export const REVIEW_STARS = [
    { value: '1', label: '1 Star' },
    { value: '2', label: '2 Star' },
    { value: '3', label: '3 Star' },
    { value: '4', label: '4 Star' },
    { value: '5', label: '5 Star' },
    // Add more options as needed
];
export interface HeroProps {
    template: TemplateType;
}
