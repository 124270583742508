import { CountryCode } from 'libphonenumber-js';

export const initSelectedNumber = {
    // image: require('src/assets/flags/CA.svg').default,
    name: 'CA',
    country: 'CA' as CountryCode,
    code: '1',
    number: '',
};

export type ISelectedNumber = {
    code: string;
    number: string;
    // image: any; // TODO
    name: string;
    country: CountryCode;
};

export type INumberList = {
    code: string;
    // image: any; // TODO
    name: string;
    country: CountryCode;
};
