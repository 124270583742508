import React, { useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import CustomButton from 'src/components/CustomButton';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { Share05, ArrowDown, UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';

const SClientBooking = () => {
    const { t } = useTranslation();
    const renderHeader = (header: any, field: any) => {
        const activeSortIcon = <ArrowDown className="w-4 text-gray-600 opacity-50" />;
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };
    let skeletons = useMemo(
        () => [
            {
                first_name: 'loading',
                booking_date: 'loading',
                total_duration: 'loading',
                shop_services: 'loading',
                total: 'loading',
                status: 'loading',
            },
        ],
        [],
    );
    return (
        <div className="datatable-custom ">
            <div className="flex justify-between items-center gap-2 mb-3 mt-6">
                <div>
                    <h2 className="view-table-title">{t('Recent appointment activity')}</h2>
                    <p className="view-table-subtitle custom-loading">View Charles latest booking activity</p>
                </div>
                <div className="flex gap-4 items-center">
                    <CustomButton secondary outlineSecondary icon={<UploadCloud02 className="w-[18px] h-[18px] mt-[2px]" />} className="flex w-full  items-center">
                        {t('Export')}
                    </CustomButton>
                    <SelectBox isDisabled={true} name="page" value={{ value: 25, label: '25' }} isClearable={false} classComp={`w-[70px]`} />
                </div>
            </div>
            <DataTable value={Array(25).fill(skeletons).flat()} totalRecords={25} dataKey="id" className="rounded-b-md overflow-hidden" paginatorClassName="table-pagination">
                <Column
                    field="first_name"
                    body={<Skeleton />}
                    header={renderHeader(t('Stylist'), 'shop_admins.first_name')}
                    className="font-normal text-mainTextColor truncate"
                    sortable
                    style={{ width: '155px', minWidth: '155px', maxWidth: '155px' }}
                ></Column>
                <Column
                    field="booking_date"
                    header={renderHeader(t('Date and Time'), 'booking_date')}
                    body={<Skeleton />}
                    className="text-xs text-gray-600 font-normal truncate"
                    style={{ width: '125px', minWidth: '125px', maxWidth: '125px' }}
                    sortable
                ></Column>
                <Column
                    field="total_duration"
                    header={renderHeader(t('Duration'), 'total_duration')}
                    body={<Skeleton />}
                    className="text-xs text-gray-600 font-normal truncate"
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    sortable
                ></Column>
                <Column
                    field="shop_services.name"
                    header={renderHeader(t('Service'), 'shop_services.name')}
                    body={<Skeleton />}
                    className="text-xs text-gray-600 font-medium truncate"
                    style={{ width: '110px', minWidth: '110px', maxWidth: '110px' }}
                    sortable
                ></Column>
                <Column
                    field="total"
                    header={renderHeader(t('Total'), 'total')}
                    body={<Skeleton />}
                    style={{ width: '80px', minWidth: '80px', maxWidth: '80px' }}
                    className="text-xs font-medium text-tableText truncate"
                    sortable
                ></Column>
                <Column
                    field="status"
                    header={renderHeader(t('Status'), 'status')}
                    body={<Skeleton />}
                    style={{ width: '100px', minWidth: '100px', maxWidth: '100px' }}
                    className="text-xs text-gray-600 font-normal  truncate"
                    sortable
                ></Column>
            </DataTable>
        </div>
    );
};

export default SClientBooking;
