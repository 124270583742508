import React from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { userMe } from 'src/redux/services/common/Common.slice';
import SocialRegister from './SocialRegister/SocialRegister';
import Regular from './Regular/Regular';

const Register = () => {
    const user = useAppSelector(userMe);
    const social = user.social_id.google || user.social_id.facebook || user.social_id.apple;

    return social ? <SocialRegister /> : <Regular />;
};

export default Register;
