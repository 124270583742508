import { Dispatch, SetStateAction } from 'react';
import { ICountStep } from 'src/components/Stepper/Stepper.interface';

export interface IProps {
    setStep: Dispatch<SetStateAction<ICountStep>>;
    inviteStaffShop?: any;
}

export interface IWorkingHoursValue {
    day: 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
    status: NonNullable<boolean | undefined>;
    from_time_hours: string;
    to_time_hours: string;
    from_time_type: 'am' | 'pm';
    to_time_type: 'am' | 'pm';
}

export const InitWorkingHours: IWorkingHoursValue[] = [
    {
        day: 'monday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'tuesday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'wednesday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'thursday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'friday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'saturday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
    {
        day: 'sunday',
        status: false,
        from_time_hours: '00',
        to_time_hours: '00',
        from_time_type: 'am',
        to_time_type: 'pm',
    },
];

export interface IFormData {
    frequency_booking: string;
    shop_locations: Array<{ shop_location_id: number | null; shop_location_name?: string | null; hours: Array<IWorkingHoursValue> }> | null;
}

export const INTERVAL_OPTIONS = [
    { value: '00:15:00', label: 'Every 15 minutes' },
    { value: '00:30:00', label: 'Every 30 minutes' },
    { value: '00:45:00', label: 'Every 45 minutes' },
    { value: '01:00:00', label: 'Every 1 hour' },
];
