import moment from 'moment';
import React from 'react';
import { capitalizeFirstLetter } from 'src/utils/global-functions';

const PayStructure = ({ paymentStructureInfo, selectedStaff }: any) => (
    <div className="bg-white rounded-3xl border border-lineColor h-full p-4">
        <div className="flex items-center mb-5">
            <h3 className="text-xl font-bold -tracking-[0.4px]">Payment Structure</h3>
        </div>
        {paymentStructureInfo && (
            <div className=" flex justify-center">
                <div className="px-[6px] divide-y w-full">
                    <div className="flex mb-3">
                        {paymentStructureInfo.type === 'rent' ? (
                            <div className="w-full py-[10px]">
                                <p className="-tracking-[0.3] font-medium text-xs text-center mb-2">Rent money </p>
                                <h3 className="text-xl font-bold leading-[140%] -tracking-[0.5px] text-center">${paymentStructureInfo.rent_money}</h3>
                            </div>
                        ) : (
                            <>
                                <div className="w-1/2 border-r">
                                    <p className="-tracking-[0.3] font-medium text-xs text-center mb-2">
                                        Commission split (owner):
                                        {paymentStructureInfo.owner_commission_percentage}%
                                    </p>
                                    <h3 className="text-xl font-bold leading-[140%] -tracking-[0.5px] text-center">${paymentStructureInfo.owner_commission_amount}</h3>
                                </div>
                                <div className="w-1/2">
                                    <p className="-tracking-[0.3] font-medium text-xs text-center mb-2">
                                        Commission split ({selectedStaff.label}
                                        ): {paymentStructureInfo.staff_commission_percentage}%{' '}
                                    </p>
                                    <h3 className="text-xl font-bold leading-[140%] -tracking-[0.5px] text-center">${paymentStructureInfo.staff_commission_amount}</h3>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="w-full py-5">
                        <p className="-tracking-[0.3] font-medium text-xs text-center mb-3">Payment Due Date </p>
                        <h3 className="text-xl font-bold leading-[140%] -tracking-[0.5px] text-center">{moment(paymentStructureInfo.due_date).format('dddd, MMMM Do, YYYY')}</h3>
                    </div>
                    <div className="w-full py-5">
                        <p className="-tracking-[0.3] font-medium text-xs text-center mb-2">Payment Frequency </p>
                        <h3 className="text-xl font-bold leading-[140%] -tracking-[0.5px] text-center">
                            {paymentStructureInfo.payment_frequency === 'bi_weekly' ? 'Bi-weekly' : capitalizeFirstLetter(paymentStructureInfo.payment_frequency)}
                        </h3>
                    </div>
                </div>
            </div>
        )}
    </div>
);

export default PayStructure;
