import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosGet } from 'src/utils/requestClient';
import CurrentInventory from './CurrentInventory';

const Overview = ({ product, setCurrentTab }: any) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const [activeTab, setActiveTab] = useState('30');
    const [productMetrics, setProductMetrics] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (id) {
            let payload: any = {};
            if (activeTab !== 'all') {
                payload = {
                    days: activeTab,
                };
            }
            axiosGet(API.PRODUCT.COUNT, { shop_id: shop.id, id }, payload)
                .then((response) => {
                    const data = response.data.data;
                    setProductMetrics(data);
                })
                .finally(() => setIsLoading(false));
        }
    }, [id, shop, activeTab]);

    const KPIS = useMemo(
        () => [
            {
                name: 'Total Stock Quantity',
                number: `${productMetrics.total_init_quantity || 0}`,
            },
            {
                name: 'Total Sales Quantity',
                number: `${productMetrics.total_booking_quantity || 0}`,
            },
            {
                name: 'Current Stock Quantity',
                number: `${productMetrics.in_stock_quantity || 0}`,
            },
            {
                name: 'Top Selling Unit',
                number: `${productMetrics.top_selling_size || '-'}`,
            },
            {
                name: 'Recent Order',
                number: `${productMetrics.total_recent_quantity || 0}`,
            },
            {
                name: 'Latest Order',
                number: `${productMetrics.total_recent_quantity || 0}`,
            },
        ],
        [productMetrics],
    );

    const tabs = useMemo(
        () => [
            {
                name: '30 days',
                type: '30',
            },
            {
                name: '90 days',
                type: '90',
            },
            {
                name: '180 days',
                type: '180',
            },
            {
                name: '360 days',
                type: '360',
            },
            {
                name: 'All time',
                type: 'all',
            },
        ],
        [],
    );
    const handleTabClick = (type: string) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();

        setActiveTab(type);
    };
    return (
        <div>
            <div className="mb-8">
                <div className="flex justify-between items-center gap-2 mb-4 mt-6">
                    <h2 className="text-lg font-semibold">{t('Analytics')}</h2>
                    <div className="fl-tab-btn-view2  flex !w-auto ">
                        {tabs.map((tab, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`fl-tab-link2 !min-w-[92px] !max-w-[92px] !w-[92px] ${activeTab === tab.type ? 'active' : ''}`}
                                onClick={handleTabClick(tab.type)}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-5">
                    {KPIS.map((kpi: any, index) => (
                        <div key={index} className="border flex flex-col rounded-lg p-3">
                            <p className="text-gray-600 text-xs font-normal mb-1.5">{kpi.name}</p>
                            <p className={`text-gray-900 text-xs font-medium ${isLoading ? 'custom-loading' : ''}`}>{kpi.number}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="mb-8">
                <CurrentInventory product={product} setCurrentTab={setCurrentTab} />
            </div>
        </div>
    );
};

export default Overview;
