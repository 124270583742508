import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import React, { FC, Ref, forwardRef, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { MdOutlineMoreVert } from 'src/assets/customsvg';
import Button from 'src/components/Button';
import DropDownWithIcon from 'src/components/DropDownWithIcon';
import InputWithLabel from 'src/components/InputWithLabel';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';

import * as Yup from 'yup';
import { IBlockTime } from './Sidebar.Interface';
import { blockTimeCreate, blockTimeUpdate, selectCalendarData, setCalendarStep, setData, setOpenSidebar } from '../Calendar.slice';
import moment from 'moment-timezone';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { IoMdClose } from 'react-icons/io';
import { SlArrowRight } from 'react-icons/sl';
import { TfiPencilAlt } from 'react-icons/tfi';
import Toggle from 'src/components/Toggle/Toggle';
import PopupModal from 'src/components/PopupModal';
import { axiosDelete } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { IconChevronRight, IconUserOutline, iconWallClockOutlinePrimary } from 'src/theme/Images';
import { currentShop } from 'src/redux/services/common/Common.slice';
import DateSelect from 'src/components/DateSelect/DateSelect';
import { useTranslation } from 'react-i18next';
import { ChevronDown, DotsVertical, X } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
type FieldKey = 'name';
type ActionType = 'new' | 'edit' | 'delete' | 'info' | null;
const BlockTime: FC<any> = ({ state }) => {
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    // const calendarData: any = useAppSelector(selectCalendarData);
    const [fromDate, setFromDate] = useState(moment(state.selectedDate).toDate());
    const [fromTime, setFromTime] = useState(moment(state.selectedTime).toDate());
    const [toDate, setToDate] = useState(moment(state.selectedDate).toDate());
    const [toTime, setToTime] = useState(moment(state.selectedTime).toDate());
    const [staffId, setStaffId] = useState(null);
    const [deletePopup, setDeletePopup] = useState(false);
    const [action, setAction] = useState<ActionType>(null);
    const shopId = shop.id;
    const { t } = useTranslation();
    const schema = Yup.object().shape({
        name: Yup.string().required('This field is required'),
        fromTime: Yup.date().required('This field is required'),
        toTime: Yup.date().required('This field is required'),
    });
    const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    // useEffect(() => {
    //     if (state.selectedLocation) {
    //         const filteredData = state.locationInfoList.filter((item: any) => item.id === state.selectedLocation.id);
    //         moment.tz.setDefault(filteredData[0].timezone);
    //         setTimeZone(filteredData[0].timezone);
    //     }
    // }, [state.selectedLocation]);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        reset,
    } = useForm<IBlockTime>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            fromTime: fromTime,
            toTime: toTime,
        },
    });
    const [switch2, setSwitch2] = useState(true);
    const handleToggle = () => {
        setSwitch2(!switch2);
    };
    useEffect(() => {
        if (state.bookedSlotInfo !== null) {
            setFromDate(new Date(state.bookedSlotInfo.from));
            setFromTime(new Date(state.bookedSlotInfo.from));
            setToTime(new Date(state.bookedSlotInfo.to));
            setToDate(new Date(state.bookedSlotInfo.to));
            setAction('info');
            setStaffId(state.bookedSlotInfo.staff_id);
            setValue('name', state.bookedSlotInfo.name);
        } else {
            setAction('new');
        }
    }, [state.bookedSlotInfo, timeZone]);

    const onSubmit = async (data: any) => {
        const formattedFromDate = format(fromDate, 'yyyy-MM-dd');
        const formattedFromTime = format(fromTime, 'HH:mm:ss');
        const fromDateTime = `${formattedFromDate} ${formattedFromTime}`;
        const formattedToDate = format(toDate, 'yyyy-MM-dd');
        const formattedToTime = format(toTime, 'HH:mm:ss');
        const toDateTime = `${formattedToDate} ${formattedToTime}`;

        const payload = {
            is_full_day: false,
            name: data.name,
            from: fromDateTime,
            to: toDateTime,
        };

        const submitData = {
            payload: payload,
            params: {
                shop_id: shopId,
                staff_id: state.selectedSlotStaffId,
            },
        };
        if (action === 'edit') {
            const submitData2 = {
                payload: payload,
                params: {
                    shop_id: shopId,
                    staff_id: staffId,
                    id: state.bookedSlotInfo.id,
                },
            };
            const result = await dispatch(blockTimeUpdate(submitData2));
            if (result.type === blockTimeUpdate.fulfilled.toString()) {
                // await getClientList(); // Properly invoke the function
                // await handleSelectChange(result.payload.data);
                // handleClose();
                reset();
                const updateState = {
                    getBookingList: true,
                };
                await dispatch(setData(updateState));
                // getClientList();
            }

            if (result.type === blockTimeUpdate.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) =>
                            setError(field as FieldKey, {
                                type: 'manual',
                                message: response.data[field][0],
                            }),
                        );
                    }
                    return;
                }
                toast.error(response.message);
            }
        } else {
            const result = await dispatch(blockTimeCreate(submitData));
            if (result.type === blockTimeCreate.fulfilled.toString()) {
                // await getClientList(); // Properly invoke the function
                // await handleSelectChange(result.payload.data);
                // handleClose();
                reset();
                const updateState = {
                    getBookingList: true,
                };
                await dispatch(setData(updateState));
                // getClientList();
            }

            if (result.type === blockTimeCreate.rejected.toString()) {
                const response = result.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) =>
                            setError(field as FieldKey, {
                                type: 'manual',
                                message: response.data[field][0],
                            }),
                        );
                    }
                    return;
                }
                toast.error(response.message);
            }
        }
    };
    useEffect(() => {}, []);
    const TimePickerInput = forwardRef((props: any, ref: Ref<HTMLInputElement>) => (
        <span className="text-primary font-medium" onClick={props.onClick}>
            {props.value}
        </span>
    ));
    const CustomDatepickerInput = forwardRef((props: any, ref: Ref<HTMLInputElement>) => (
        <div onClick={props.onClick} className="text-primary font-medium">
            {props.value ? format(new Date(props.value), 'eee, MMM d') : 'Select date'}
        </div>
    ));
    const actionBtnIcon = [
        {
            label: <span>Delete</span>,
            value: 'delete_blocktime',
            icon1: <IoMdClose className="text-dangerText" size={20} />,
            icon1Position: 'left',
            icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            icon2Position: 'right',
        },

        {
            label: <span>Edit </span>,
            value: 'edit_blocktime',
            icon1: <TfiPencilAlt className="text-primary" size={20} />,
            icon1Position: 'left',
            icon2: <SlArrowRight className="text-secondaryTxtColor" />,
            icon2Position: 'right',
        },
    ];
    const handleOption = async (e: any) => {
        if (e.value === 'edit_blocktime') {
            setAction('edit');
        } else if (e.value === 'delete_blocktime') {
            setDeletePopup(true);
        }
    };
    const handleClose = async () => {
        dispatch(setOpenSidebar(false));
    };

    const handleRemoveBlockTime = async () => {
        // setIsLoading(true);

        try {
            const response = await axiosDelete(API.STAFF_BLOCK_TIME.DELETE, undefined, {
                shop_id: shopId,
                id: state.bookedSlotInfo.id,
                staff_id: state.bookedSlotInfo.staff_id,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setDeletePopup(false);
                // const updateState = {
                //     action: { ...initAction, newAppointment: true },
                //     bookingInfo: null,
                //     removeBlockTime: {
                //         isRemoveBlockTime: false,
                //         blockTimeId: null,
                //         staffId: null,
                //     },
                //     getBookingList: true,
                // };
                // await dispatch(setData(updateState));
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            // setIsLoading(false);
        }
    };
    const handleSelectedTime = (type: 'from' | 'to') => async (time: any) => {
        if (type === 'from') {
            setFromTime(moment(time).toDate());
        } else {
            setToTime(moment(time).toDate());
        }
    };
    const handleNewAppointment = () => {
        dispatch(setCalendarStep('newAppointment'));
    };
    return (
        <div className="h-full">
            <form className="h-full flex flex-col">
                <div className="header flex justify-between px-4 py-4 items-center border-b">
                    <div>
                        {action === 'edit' && (
                            <Link className="border border-lineColor rounded-lg p-1 mr-3" to="" onClick={() => setAction('info')}>
                                <img src={IconChevronRight} alt="" className="" />
                            </Link>
                        )}
                        <div className="">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">New Time Block</h1>
                            <div className="text-xs font-normal leading-[18px] text-mainTextColor flex items-center">
                                <div className="flex items-center">
                                    <span className="mr-1.5 text-gray-600 leading-[18px] font-medium text-xs">Or</span>
                                    <div className="text-primary cursor-pointer font-semibold text-xs leading-[18px]" onClick={handleNewAppointment}>
                                        New Appointment
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className="close-btn" type="button" onClick={() => handleClose()}>
                            <X className="text-gray-700" />
                        </button>
                    </div>

                    {action === 'info' && (
                        <DropDownWithIcon
                            dropwDownContainerClass="ml-auto close-btn"
                            className=""
                            labelIcon={<DotsVertical className="w-[18px]" />}
                            labelActiveIcon={<DotsVertical className="w-[18px]" />}
                            dropDownMenuClassName="top-[39px] w-[140px]"
                            dropDownMenuItemClassName="border-b text-left last:border-0"
                            dropDownMenuLinkClassName="pl-2"
                            data={actionBtnIcon}
                            setselected={handleOption}
                        />
                    )}
                </div>
                <div className="flex-1 overflow-y-auto py-4">
                    {action === 'info' && (
                        <div className="">
                            <div className="p-4 border-y border-lineColor">
                                <h3 className="font-bold mb-3">{state.bookedSlotInfo.name}</h3>
                                <div className="flex items-center">
                                    <div className="text-secondaryTxtColor w-full max-w-[88px] grow-0 shrink-0 basis-[88px] pr-3">From:</div>
                                    <p className="font-medium">{`${moment(state.bookedSlotInfo.from, 'YYYY-MM-DD HH:mm:ss').format('ddd, MMM D [at] h:mm A')}`}</p>
                                </div>
                                <div className="flex items-center mt-3">
                                    <div className="text-secondaryTxtColor w-full max-w-[88px] grow-0 shrink-0 basis-[88px] pr-3">To:</div>
                                    <p className="font-medium">{`${moment(state.bookedSlotInfo.to, 'YYYY-MM-DD HH:mm:ss').format('ddd, MMM D [at] h:mm A')}`}</p>
                                </div>
                            </div>
                            <div className="p-4">
                                <div className="flex items-center mb-4">
                                    <i className="mr-3">
                                        <img src={iconWallClockOutlinePrimary} alt="" className="w-4" />
                                    </i>
                                    <div className="font-medium">
                                        <span className="text-secondaryTxtColor mr-1">Created on:</span>
                                        {` ${moment(state.bookedSlotInfo.created_at, 'YYYY-MM-DD HH:mm:ss').format('ddd, MMM D [at] h:mm A')}`}
                                    </div>
                                </div>
                                <div className="flex items-center mb-4">
                                    <i className="mr-3">
                                        <img src={IconUserOutline} alt="" className="w-4" />
                                    </i>
                                    <div className="font-medium">
                                        <span className="text-secondaryTxtColor  mr-1">Created by:</span>
                                        <span className="">Static Name</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {(action === 'edit' || action === 'new') && (
                        <>
                            <div className="px-4">
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="mb-4">
                                            <InputWithLabel label="Title" placeholder="Enter Title" onChange={onChange} value={value} name="name" error={!!errors.name} />
                                            {errors?.name && <p className="text-error">{errors?.name?.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="w-full px-4 mb-4">
                                <DateSelect
                                    inputName="start_date"
                                    label="Select date range"
                                    className={`form_control_date  font-medium text-xs relative pr-7 `}
                                    placeholder={t('Select date')}
                                    dateFormat="EEEE, MMMM do, yyyy"
                                    isFormate={false}
                                    // InputClass={`${fieldState.error && 'is-invalid'}`}
                                    // value={moment(date).toDate()}
                                    onChangeValue={(date) => {
                                        setFromDate(date[0]);
                                        setToDate(date[1]);
                                    }}
                                    isRange
                                    startDate={fromDate}
                                    endDate={toDate}
                                    labelEndIcon={<ChevronDown className="text-gray-700 w-5 ml-1 h-[22px] right-[-6px]" />}
                                />
                            </div>
                            <div className="flex justify-between mb-4 px-4 gap-4 items-center">
                                <div className="">
                                    <DateSelect
                                        inputName="start_date"
                                        label="Select duration"
                                        className={`form_control_date  font-medium text-xs relative w-full `}
                                        placeholder={t('Select date')}
                                        isFormate={false}
                                        isTime={true}
                                        timeIntervals={15}
                                        timeCaption="Time Selected"
                                        dateFormat="h:mm aa"
                                        // InputClass={`${fieldState.error && 'is-invalid'}`}
                                        value={fromTime}
                                        onChangeValue={handleSelectedTime('from')}
                                        labelEndIcon={<ChevronDown className="text-gray-700 w-5 ml-1 h-[22px] right-[-6px]" />}
                                    />
                                </div>
                                <div className="">
                                    <DateSelect
                                        inputName="start_date"
                                        label="Select duration"
                                        labelClassName="opacity-0"
                                        className={`form_control_date  font-medium text-xs relative w-full `}
                                        placeholder={t('Select date')}
                                        isFormate={false}
                                        isTime={true}
                                        timeIntervals={15}
                                        timeCaption="Time Selected"
                                        dateFormat="h:mm aa"
                                        // InputClass={`${fieldState.error && 'is-invalid'}`}
                                        value={toTime}
                                        onChangeValue={handleSelectedTime('to')}
                                        labelEndIcon={<ChevronDown className="text-gray-700 w-5 ml-1 h-[22px] right-[-6px]" />}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-end px-4 border-b pb-4">
                                <div className="flex items-center justify-end">
                                    <p className="text-gray-700 font-medium text-xs leading-[18px]">All Day</p>
                                    <Toggle
                                        // label="Recurring Appointment"
                                        labelClassName="ml-auto"
                                        labelPosition="left"
                                        id="recurring_appointment"
                                        isChecked={switch2}
                                        onChange={handleToggle}
                                        className=""
                                        switchClassName="bg-secondaryTxtColor"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {(action === 'edit' || action === 'new') && (
                    <div className="border-t px-5 py-4">
                        <div className="flex gap-4 w-full">
                            <CustomButton className="w-1/2" secondary onClick={handleClose}>
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton type="button" className="w-1/2" primary onClick={handleSubmit(onSubmit)}>
                                {action === 'edit' ? 'Edit Time Block' : 'Create Time Block'}
                            </CustomButton>
                        </div>
                    </div>
                )}
            </form>
            {deletePopup && (
                <PopupModal title={'Are you sure'} onClose={() => setDeletePopup(false)}>
                    <div className="text-center">
                        <h3 className="mb-5 text-xl font-bold text-mainTextColor -tracking-[0.6px]">Delete time block</h3>
                        <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">Are you sure you want to delete this time block?</p>
                        <div className="w-full">
                            <Button className="w-full fl-btn px-[10px] btn_primary text-base text-center justify-center font-bold mb-3" onClick={() => setDeletePopup(false)}>
                                Not now
                            </Button>
                            <Button className="w-full fl-btn text-base font-bold btn_outline_black px-[10px] justify-center" onClick={handleRemoveBlockTime}>
                                {"Yes, I'm sure"}
                            </Button>
                        </div>
                    </div>
                </PopupModal>
            )}
        </div>
    );
};

export default BlockTime;
