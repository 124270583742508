import { setBreadcrumbs } from 'src/redux/services/common/Common.slice';
import { useAppDispatch } from 'src/redux/hooks';
import { useLocation, useParams } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { useTranslation } from 'react-i18next';
import { PATH } from 'src/constants/path';
import { SETTING_TABS } from 'src/constants/common';

export const useBreadcrumbs = () => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const { id, tab } = useParams();
    const { t } = useTranslation();

    const initBreadcrumbs = () => {
        let breadcrumbs: Array<{ label: string; setting?: string; url?: string }> = [];
        switch (pathname) {
            case ROUTES.DASHBOARD:
                breadcrumbs = [
                    {
                        label: t('Dashboard'),
                    },
                ];
                break;
            case ROUTES.ANALYTICS:
                breadcrumbs = [
                    {
                        label: t('Analytics'),
                    },
                ];
                break;
            case ROUTES.CALENDAR:
                breadcrumbs = [
                    {
                        label: t('Calendar'),
                    },
                ];
                break;
            case ROUTES.STAFF.LIST:
                breadcrumbs = [
                    {
                        label: t('Staff'),
                        setting: `${PATH.SETTINGS}${SETTING_TABS.PAYMENT}`,
                        url: ROUTES.STAFF.LIST,
                    },
                ];
                break;
            case ROUTES.STAFF.CREATE:
                breadcrumbs = [
                    {
                        label: t('Staff'),
                        setting: `${PATH.SETTINGS}${SETTING_TABS.PAYMENT}`,
                        url: ROUTES.STAFF.LIST,
                    },
                    {
                        label: t('Add Staff'),
                    },
                ];
                break;
            case `${PATH.STAFF.UPDATE}/${id}`:
                breadcrumbs = [
                    {
                        label: t('Staff'),
                        setting: `${PATH.SETTINGS}${SETTING_TABS.PAYMENT}`,
                        url: ROUTES.STAFF.LIST,
                    },
                    {
                        label: t('Edit Staff'),
                    },
                ];
                break;
            case `${PATH.STAFF.VIEW}/${id}`:
                breadcrumbs = [
                    {
                        label: t('Staff'),
                        setting: `${PATH.SETTINGS}${SETTING_TABS.PAYMENT}`,
                        url: ROUTES.STAFF.LIST,
                    },
                    {
                        label: t('View Staff'),
                    },
                ];
                break;
            case ROUTES.LOCATION.LIST:
                breadcrumbs = [
                    {
                        label: t('Location'),
                        url: ROUTES.LOCATION.LIST,
                    },
                ];
                break;
            case ROUTES.LOCATION.CREATE:
                breadcrumbs = [
                    {
                        label: t('Location'),
                        url: ROUTES.LOCATION.LIST,
                    },
                    {
                        label: t('Add Location'),
                    },
                ];
                break;
            case `${PATH.LOCATION.UPDATE}/${id}`:
                breadcrumbs = [
                    {
                        label: t('Location'),
                        url: ROUTES.LOCATION.LIST,
                    },
                    {
                        label: t('Edit Location'),
                    },
                ];
                break;
            case `${PATH.LOCATION.VIEW}/${id}`:
                breadcrumbs = [
                    {
                        label: t('Location'),
                        url: ROUTES.LOCATION.LIST,
                    },
                    {
                        label: t('View Location'),
                    },
                ];
                break;
            case ROUTES.SERVICES.LIST:
                breadcrumbs = [
                    {
                        label: t('Service'),
                        url: ROUTES.SERVICES.LIST,
                    },
                ];
                break;
            case ROUTES.SERVICES.CREATE:
                breadcrumbs = [
                    {
                        label: t('Service'),
                        url: ROUTES.SERVICES.LIST,
                    },
                    {
                        label: t('Add Service'),
                    },
                ];
                break;
            case `${PATH.SERVICES.UPDATE}/${id}`:
                breadcrumbs = [
                    {
                        label: t('Service'),
                        url: ROUTES.SERVICES.LIST,
                    },
                    {
                        label: t('Edit Service'),
                    },
                ];
                break;
            case ROUTES.CATEGORY.CREATE:
                breadcrumbs = [
                    {
                        label: t('Category'),
                    },
                    {
                        label: t('Add Category'),
                    },
                ];
                break;
            case ROUTES.APPEARANCE:
                breadcrumbs = [
                    {
                        label: t('Appearance'),
                    },
                ];
                break;
            case PATH.SETTINGS:
            case `${PATH.SETTINGS}${tab}`:
                breadcrumbs = [
                    {
                        label: t('Settings'),
                    },
                ];
                break;

            case ROUTES.PRODUCT.LIST:
                breadcrumbs = [
                    {
                        label: t('Products'),
                        url: ROUTES.PRODUCT.LIST,
                    },
                ];
                break;
            case ROUTES.PRODUCT.CREATE:
                breadcrumbs = [
                    {
                        label: t('Products'),
                        url: ROUTES.PRODUCT.LIST,
                    },
                    {
                        label: t('Add Products'),
                    },
                ];
                break;
            case `${PATH.PRODUCT.UPDATE}/${id}`:
                breadcrumbs = [
                    {
                        label: t('Products'),
                        url: ROUTES.PRODUCT.LIST,
                    },
                    {
                        label: t('Edit Products'),
                    },
                ];
                break;
            case ROUTES.PRODUCT.CATEGORY.CREATE:
                breadcrumbs = [
                    {
                        label: t('Category'),
                    },
                    {
                        label: t('Add Category'),
                    },
                ];
                break;

            default:
                breadcrumbs = [];
                break;
        }
        dispatch(setBreadcrumbs(breadcrumbs));
    };

    return { initBreadcrumbs };
};
