import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa6';
import CustomButton from 'src/components/CustomButton';
import { MobileCardImg } from 'src/theme/Images';

const Overview = ({ setStep }: any) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center text-center w-full  mt-[40px]">
            <h3 className="text-3xl font-semibold mb-2 leading-[38px] text-[#101828]">{t('Get started with FLAIR Pay for an end-to-end payment solution')}</h3>
            <div className="text-base font-normal mb-4 text-[#475467]">{t('Simplify and streamline your payment process today with our comprehensive and easy-to-use payment solution.')}</div>
            <CustomButton primary onClick={() => setStep(2)} className="!min-w-[177px] !font-semibold !text-base  rounded-lg shadow-InputAndButton">
                Continue
            </CustomButton>
            <div className="grid grid-cols-2  gap-28 relative justify-center max-w-[90%] mt-5 xxl:max-w-[80%]">
                <div className="flex w-full absolute items-center justify-center">
                    {/* <img src={MobileCardImg} alt="overview" className="flex justify-center " /> */}
                    <MobileCardImg className="flex justify-center max-xlm:h-[380px] h-[460px]" />
                </div>
                <div className=" bg-white shadow-md rounded-[8px] max-2xl:h-[102px] h-[115px] p-4 max-xlm:mt-[40px] max-xlm:mr-[34px] gap-2 flex-row z-10 mt-[60px] mr-[50px] ml-auto flex justify-end w-[80%] xlm:w-[70%] ">
                    <div className="flex justify-center items-center min-w-[22px] h-[22px] xlm:min-w-[26px] xlm:h-[26px] rounded-full bg-[#ABEFC6]">
                        <FaCheck className="xlm:w-4 xlm:h-4 w-3.5 h-3.5 text-[#067647]" />
                    </div>
                    <div className="flex flex-col text-start gap-1.5">
                        <div className="text-xs 2xl:text-sm font-semibold text-[#101828]">End-to-End Integrated Payment Solution</div>
                        <div className="text-xs 2xl:text-sm font-normal text-[#475467]">
                            Experience a comprehensive payment system covering in-store, online, and mobile platforms, providing flexible payment choices.
                        </div>
                    </div>
                </div>
                <div className="z-10 bg-white shadow-md  rounded-[8px] max-xlm:mt-[47px] max-xlm:ml-[36px] mt-[70px]  ml-[58px]  p-4  gap-2 flex-row flex justify-end  w-[80%] xlm:w-[70%]">
                    <div className="flex justify-center items-center min-w-[22px] h-[22px] xlm:min-w-[26px] xlm:h-[26px] rounded-full bg-[#ABEFC6]">
                        <FaCheck className="xlm:w-4 xlm:h-4 w-3.5 h-3.5 text-[#067647]" />
                    </div>
                    <div className="flex flex-col text-start gap-1.5">
                        <div className="text-xs 2xl:text-sm font-semibold text-[#101828]">Instant Bank Payouts</div>
                        <div className="text-xs 2xl:text-sm font-normal text-[#475467]">
                            Receive payouts directly to your bank account, enabling effective financial management and sustaining cash flow.
                        </div>
                    </div>
                </div>
                <div className="z-10  bg-white shadow-md h-[98px] rounded-[8px] max-xlm:-mt-[70px] max-xlm:mr-[30px] p-4 flex gap-2 flex-row ml-auto mr-[50px] -mt-[46px] justify-end w-[85%] 2xl:w-[90%] xlm:w-[83%]">
                    <div className="flex justify-center items-center min-w-[22px] h-[22px] xlm:min-w-[26px] xlm:h-[26px] rounded-full bg-[#ABEFC6]">
                        <FaCheck className="xlm:w-4 xlm:h-4 w-3.5 h-3.5 text-[#067647]" />
                    </div>
                    <div className="flex flex-col text-start gap-1.5 ">
                        <div className="text-xs 2xl:text-sm font-semibold text-[#101828]">Protect Your Revenue with Automated Booking Policies</div>
                        <div className="text-xs 2xl:text-sm font-normal text-[#475467]">Automatically enforce booking policies to minimize no-shows and last-minute cancellations.</div>
                    </div>
                </div>
                <div className="z-10  top-0 bg-white  shadow-md rounded-[8px] max-xlm:-mt-[81px] max-xlm:ml-[30px] max-2xl:h-[102px] h-[115px] -mt-[70px] ml-[46px] p-4 flex gap-2 flex-row justify-end  w-[90%] 2xl:w-[89%] xlm:w-[74%]">
                    <div className="flex justify-center items-center min-w-[22px] h-[22px] xlm:min-w-[26px] xlm:h-[26px] rounded-full bg-[#ABEFC6]">
                        <FaCheck className="xlm:w-4 xlm:h-4 w-3.5 h-3.5 text-[#067647]" />
                    </div>
                    <div className="flex flex-col text-start gap-1.5">
                        <div className="text-xs 2xl:text-sm font-semibold text-[#101828]">Online and In-Person Payment Options</div>
                        <div className="text-xs 2xl:text-sm font-normal text-[#475467]">
                            Experience a comprehensive payment system covering in-store, online, and mobile platforms, providing flexible payment choices.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
