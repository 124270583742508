import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft } from '@untitled-ui/icons-react/build/cjs';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CustomButton from 'src/components/CustomButton';
import InputWithLabel from 'src/components/InputWithLabel';
import { useTranslation } from 'react-i18next';
import { axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, me } from 'src/redux/services/common/Common.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import SubCategoryForm from './SubCategoryForm';
import { PATH } from 'src/constants/path';
import { PRODUCT_TABS } from 'src/constants/common';
import { ExistingFormData, IFormData } from './Category.interface';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';

const CategoryForm = () => {
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const { t } = useTranslation();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const schema = Yup.object({
        name: Yup.string().required(t('This field is required')),
        description: Yup.string().required(t('This field is required')),
        sub_categories: Yup.array().of(
            Yup.object({
                name: Yup.string().required(t('This field is required')),
                description: Yup.string().required(t('This field is required')),
            }),
        ),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            name: '',
            description: '',
            sub_categories: [{ name: '', description: '' }],
        },
    });

    const {
        handleSubmit,
        setError,
        control,
        watch,
        setValue,
        formState: { errors },
    } = methods;
    const watchName = watch('name');
    const watchDescription = watch('description');

    useEffect(() => {
        if (id) {
            getCategory();
        }
    }, [shop, id]);

    const getCategory = () => {
        setIsLoading(true);
        axiosGet(API.PRODUCT_CATEGORY.GET, { shop_id: shop.id, id })
            .then((response) => {
                const data = response.data.data;
                const existingData: ExistingFormData = {
                    name: data.name,
                    description: data.description,
                    sub_categories: data.sub_categories.map((item: any) => ({
                        name: item.name,
                        description: item.description,
                        id: item.id,
                    })),
                };
                Object.keys(existingData).forEach((key) => {
                    setValue(key as keyof IFormData, existingData[key]);
                });
            })
            .catch(() => navigate(`${PATH.PRODUCT.LIST}/${PRODUCT_TABS.CATEGORY}`))
            .finally(() => setIsLoading(false));
    };

    const handleSave = (data: IFormData) => {
        setIsLoading(true);
        if (id) {
            const payload = {
                ...data,
                is_active: true,
                sub_categories: data.sub_categories.map((subCategory: any) => ({
                    ...subCategory,
                    is_active: true,
                    id: subCategory.id ?? 0,
                })),
            };
            axiosPatch(API.PRODUCT_CATEGORY.UPDATE, payload, { shop_id: shop.id, id })
                .then(async () => {
                    await dispatch(me());
                    navigate(`${PATH.PRODUCT.LIST}/${PRODUCT_TABS.CATEGORY}`);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        } else {
            const payload = {
                ...data,
                is_active: true,
                sub_categories: data.sub_categories.map((sub_category: any) => ({
                    ...sub_category,
                    is_active: true,
                })),
            };
            axiosPost(API.PRODUCT_CATEGORY.CREATE, payload, { shop_id: shop.id })
                .then(async () => {
                    await dispatch(me());
                    navigate(`${PATH.PRODUCT.LIST}/${PRODUCT_TABS.CATEGORY}`);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const resetForm = (event: any) => {
        navigate(`${PATH.PRODUCT.LIST}/${PRODUCT_TABS.CATEGORY}`);
    };
    useFormErrorFocus<IFormData>({ errors });

    return (
        <div className="inner-page-wrape px-5">
            <div className="flex justify-between py-3 gray-banner-bg items-start bg-gray-50 relative h-[130px] side-spaching  text-secondaryTxtColor -mx-5">
                <Link to={`${PATH.PRODUCT.LIST}/${PRODUCT_TABS.CATEGORY}`} className="flex items-center text-sm text-gray-600 font-semibold gap-2">
                    <ArrowLeft className="text-gray-600 w-5" /> {t('Back to category')}
                </Link>
            </div>

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className="flex justify-between items-center py-4 border-b gap-5 ">
                        <div className="flex flex-col gap-[2px] flex-1">
                            <h1 className="text-2xl font-semibold">{watchName || t('Category Name')}</h1>
                            <p className="text-sm font-normal text-secondaryTxtColor">{watchDescription || t('Category description')}</p>
                        </div>
                        <div className="flex gap-3">
                            <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton type="submit" isLoading={isLoading} disabled={isLoading} primary>
                                {t('Save Changes')}
                            </CustomButton>
                        </div>
                    </div>
                    <div className="detail-data-main">
                        <div className="title-block w-[280px]">
                            <h3 className="font-semibold text-sm text-gray-700 ">{t('Category details')}</h3>
                            <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit')}</p>
                        </div>
                        <div className="data-wrap-block w-full max-w-[666px]">
                            <div className="flex flex-col gap-4">
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState }: any) => (
                                        <div>
                                            <InputWithLabel
                                                label={t('Title')}
                                                id="name"
                                                required
                                                placeholder={t('Enter category title')}
                                                onChange={onChange}
                                                value={value}
                                                name="name"
                                                error={!!fieldState.error}
                                            />
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState }: any) => (
                                        <div>
                                            <InputWithLabel
                                                label={t('Description')}
                                                id="description"
                                                placeholder={t('Enter category description')}
                                                onChange={onChange}
                                                value={value}
                                                required
                                                error={!!fieldState.error}
                                                name="description"
                                                textArea
                                                textAearClassName="h-[120px] w-full rounded-lg resize-none"
                                                maxLenght={275}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="detail-data-main">
                        <div className="title-block w-[280px]">
                            <h3 className="font-semibold text-sm text-gray-700 ">{t('Sub Categories details')}</h3>
                            <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit')}</p>
                        </div>
                        <div className="data-wrap-block w-full max-w-[666px]">
                            <div className="flex flex-col gap-4">
                                <SubCategoryForm />
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-3 mt-5 justify-end mb-36">
                        <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                            {t('Cancel')}
                        </CustomButton>
                        <CustomButton type="submit" isLoading={isLoading} disabled={isLoading} primary>
                            {t('Save Changes')}
                        </CustomButton>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default CategoryForm;
