import React, { useEffect, useState } from 'react';
import { currentShop } from 'src/redux/services/common/Common.slice';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { capitalizeFirstLetterAndUnderscore, formatName, getSelectBoxStaffOptionsIdAsValue, getShortName } from 'src/utils/global-functions';
import './../../../utils/Datatable.scss';
import moment from 'moment';
import { staffList } from 'src/app/Staff/Staff.slice';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './../../../utils/prime-react-datatable.scss';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { PiCaretUpDownBold } from 'react-icons/pi';

import PayoutDetail from './PayoutDetail';
import { ROUTES } from 'src/constants/routes';
import { ISelectedLocation } from 'src/app/Staff/Staff.interface';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import CustomDropdownWithDate from 'src/components/CustomDropdownWithDate/CustomDropdownWithDate';
import { mapLocationPin } from 'src/theme/Images';
import CustomButton from 'src/components/CustomButton';

interface IPayload {
    [x: string]: any;
    // type: string;
    location_id?: number;
    end_date?: any;
    start_date?: any;
}
interface ITimeFileterOptions {
    label: string;
    value: string;
    endDate?: any;
    startDate?: any;
}

const timeFileterOptions: ITimeFileterOptions[] = [{ label: 'All time', value: 'all' }];
const RentCollection = ({ activeTab }: any) => {
    const shop = useAppSelector(currentShop);
    const shopId = shop.id;
    const dispatch = useAppDispatch();
    const [locationOptions] = useState<any>([]);
    const [staffOptionList, setStaffOptionList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState<any>();
    const [rentCollectionList, setRentCollectionList] = useState<any>([]);
    const [totalListRecords, settotalListRecords] = useState(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [staffInfo, setStaffInfo] = useState();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<ISelectedLocation | null>(null);
    const [timeFileterSelected, setTimeFileterSelected] = useState(timeFileterOptions[0]);

    const [lazyState, setlazyState] = useState<any>({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            type: { value: 'all' },
        },
    });

    const handleDrawer =
        (open: boolean, staff_id: number | null = null) =>
        () => {
            setIsDrawerOpen(open);
        };

    useEffect(() => {
        if (selectedLocation) {
            getRentList();
        }
    }, [lazyState]);

    const onPage = (event: any) => {
        setlazyState(event);
    };

    const onSort = (event: any) => {
        setlazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setlazyState(event);
    };

    const getLocationOptions = async () => {
        /* const data = {
            shop_id: shopId,
        };
        const result = await dispatch(locationList(data));
        if (result.type === locationList.fulfilled.toString()) {
            let locationData: any = getSelectBoxOptionsIdAsValue(result.payload.data);
            const allOption = { value: null, label: 'All Locations', id: null };
            locationData = [allOption].concat(locationData);
            setlazyState({
                ...lazyState,
                page: 1,
                first: 0,
                filters: {
                    ...lazyState.filters,
                    location_id: { value: locationData[0].value },
                },
            });
            setSelectedLocation(locationData[0]);
            setLocationOptions(locationData);
        } */
    };
    const getStaff = async () => {
        const data = {
            shop_id: shopId,
        };
        const result = await dispatch(staffList(data));
        if (result.type === staffList.fulfilled.toString()) {
            let staffListArray: any = getSelectBoxStaffOptionsIdAsValue(result.payload.data, 'id', 'first_name', 'last_name');
            const allOPtion = { value: null, label: 'All Staffs', id: null };
            staffListArray = [allOPtion].concat(staffListArray);
            setStaffOptionList(staffListArray);
            setSelectedStaff(staffListArray[0]);
        }
    };

    const getRentList = async () => {
        setLoading(true);

        try {
            let payload: IPayload = {
                ...lazyState,
            };

            const response = await axiosGet(
                API.PAYMENT.RENT_LIST,
                {
                    shop_id: shopId,
                },
                payload,
            );
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setRentCollectionList(response.data.data.data);
                settotalListRecords(response.data.data.totalRecords);
                setLoading(false);
                // setDashboardDetails(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                await getLocationOptions();
                await getStaff();
            } catch (error) {
                // Handle errors if necessary
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        if (selectedLocation) {
            getRentList();
        }
    }, [selectedLocation]);

    const GetFullName = (row: any) => {
        const [isImageError, setIsImageError] = useState<boolean>(false);
        return (
            <div className="flex items-center">
                <figure className="NoImgName">
                    {row.staff.profile_image_url && !isImageError ? (
                        <img src={row.staff.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                    ) : (
                        getShortName(`${row.staff.full_name}`)
                    )}
                </figure>
                <div className="text-mainTextColor">
                    <p className="text-sm font-medium mb-[2px] leading-[140%] -tracking-[0.14px]">{row.staff.full_name ? formatName(row.staff.full_name) : ''}</p>
                    <p className="text-xs font-normal leading-[150%] -tracking-[0.12px] text-secondaryTxtColor">{row.staff.role.name ? `${row.staff.role.name}` : ''}</p>
                </div>
            </div>
        );
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink  NextPageLink ',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-gray-700 text-sm flex items-center font-medium leading-5 mr-auto">
                Page {first} of {totalRecords}
            </div>
        ),
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                Previous
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                Next
            </CustomButton>
        ),
    };

    const handleTimeFilter = (value: any) => {
        setTimeFileterSelected(value);

        if (value.value === 'custom') {
            setlazyState({
                ...lazyState,
                page: 1,
                first: 0,
                filters: {
                    ...lazyState.filters,
                    start_date: {
                        value: moment(value.startDate).format('YYYY-MM'),
                    },
                    end_date: {
                        value: moment(value.endDate).format('YYYY-MM'),
                    },
                },
            });
        } else {
            const { start_date: filterStartDate, end_date: filterEndDate, ...updatedFilters } = lazyState.filters;
            setlazyState({
                ...lazyState,
                page: 1,
                first: 0,
                filters: updatedFilters,
            });
        }
    };
    const handleLocationSelect = (value: any) => {
        setSelectedLocation(value);
        setlazyState({
            ...lazyState,
            page: 1,
            first: 0,
            filters: {
                ...lazyState.filters,
                location_id: { value: value.id },
            },
        });
    };
    const handleStaffSelect = (value: any) => {
        setSelectedStaff(value);
        setlazyState({
            ...lazyState,
            page: 1,
            first: 0,
            filters: {
                ...lazyState.filters,
                staff_id: { value: value.id },
            },
        });
    };

    const handleRowClick = (rowData?: any) => {
        if (rowData) setStaffInfo(rowData.data);
        setIsDrawerOpen(true);
    };
    return (
        <div className=" flex-1 flex flex-col">
            <div className="w-full h-full flex flex-col flex-1 rounded-2xl bg-white border-[1px]  border-lineColor ">
                <div className="flex py-5 px-6 xxl:px-8 items-center">
                    <div className="flex items-center flex-1 ">
                        <h2 className=" items-center text-xl leading-[140%] -tracking-[0.4px] font-bold mr-3">{activeTab.name}</h2>
                        <span className="bg-inputBg text-xs text-primary px-4 py-[3px] rounded-full leading-[150%] -tracking-[0.12px]">
                            {capitalizeFirstLetterAndUnderscore(shop.shop_payment.rent_frequency)}
                        </span>
                    </div>
                    <div className="flex justify-end items-center -mx-[6px]">
                        <div className="w-[240px] mx-[6px]">
                            <SelectBox
                                name="location"
                                id="location"
                                value={selectedLocation}
                                options={locationOptions}
                                onChangeFunc={handleLocationSelect}
                                classComp="outline-select-box "
                                allowIcon={<img src={mapLocationPin} alt="map-pin" />}
                            />
                        </div>

                        <div className="w-[158px] mx-[6px]">
                            <SelectBox
                                name="staffId"
                                id="staffId"
                                value={selectedStaff}
                                options={staffOptionList}
                                onChangeFunc={handleStaffSelect}
                                className="w-[324px]"
                                placeholder="Search Staff"
                                classComp="outline-select-box "
                            />
                        </div>
                        <div className="w-[164px] relative mx-[6px]">
                            <div className="w-full py-3 px-2 flex justify-center ">
                                <CustomDropdownWithDate data={timeFileterOptions} handleChangeValue={handleTimeFilter} value={timeFileterSelected} />
                            </div>
                        </div>
                        {/* <Link
                            className="text-primary font-bold text-base leading-[140%] -tracking-[0.18px] mx-[6px]"
                            to=""
                        >
                            View Statement
                        </Link> */}
                    </div>
                </div>

                {!loading && rentCollectionList.length === 0 ? (
                    <div className="flex flex-col flex-1 justify-center items-center">
                        <div className="text-center">
                            <h2 className="mb-3 text-base font-bold leading-[140%] -tracking-[0.4px]">Rent Collection</h2>
                            <p className="mb-8 text-secondaryTxtColor text-xs leading-[150%] -tracking-[0.3px]">Add a barber on a rent, to start using the rent collection.</p>

                            <Link className="fl-btn btn_outline_black text-base font-medium justify-center inline-flex w-[200px]" to={ROUTES.STAFF.LIST}>
                                Add staff
                            </Link>
                        </div>
                    </div>
                ) : (
                    <DataTable
                        paginatorTemplate={customPaginatorTemplate}
                        value={rentCollectionList}
                        lazy
                        paginator={totalListRecords > lazyState.rows}
                        first={lazyState.first}
                        rows={lazyState.rows}
                        totalRecords={totalListRecords}
                        onPage={onPage}
                        onSort={onSort}
                        sortIcon={<PiCaretUpDownBold />}
                        sortOrder={lazyState.sortOrder}
                        sortField={lazyState.sortField}
                        onFilter={onFilter}
                        filters={lazyState.filters}
                        loading={loading}
                        dataKey="id"
                        className="w-full text-sm flex-1 text-mainTextColor rounded-3xl overflow-hidden fl-data-table"
                        selectionMode="single"
                        paginatorClassName="table-pagination"
                        // selection={clientInfo}
                        // onSelectionChange={(e) => setClientInfo(e.value)}
                        onRowClick={handleRowClick}
                        emptyMessage="No records found."
                    >
                        <Column field="first_name" header="Name" body={GetFullName} className="font-medium text-mainTextColor" sortable></Column>
                        <Column field="start_date" header="Period Start Date " body={(row: any) => moment(row.start_date).format('DD/MM/YYYY')} className="font-medium text-mainTextColor"></Column>
                        <Column field="end_date" header="Period End Date " body={(row: any) => moment(row.end_date).format('DD/MM/YYYY')} className="font-medium text-mainTextColor"></Column>
                        <Column field="amount" header="Rent Amount" className="font-medium text-mainTextColor" body={(row: any) => `$${row.amount}`}></Column>
                        <Column field="is_paid" header="Payment Status" className="font-medium text-mainTextColor" body={(row: any) => (row.is_paid ? 'Paid' : 'Unpaid')}></Column>
                    </DataTable>
                )}

                {staffInfo && <PayoutDetail handleDrawer={handleDrawer} isDrawerOpen={isDrawerOpen} staffInfo={staffInfo} setIsDrawerOpen={setIsDrawerOpen} getRentList={getRentList} />}
            </div>
        </div>
    );
};

export default RentCollection;
