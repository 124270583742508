import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Overview from './Overview';
import StaffUsers from './StaffUsers';
import StaffServices from './StaffServices';
import StaffPayment from './StaffPayment';
import StaffHeatMap from './StaffHeatMap';

const StaffTab = ({ staff }: any) => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(staff.pay_structure.pay_structure_type === 'partner' ? 'services' : 'overview');

    const handleTab = (name: string) => () => {
        setCurrentTab(name);
    };

    const TABS = useMemo(() => {
        const commisionTab = [
            {
                display_name: t('Overview'),
                name: 'overview',
            },
            {
                display_name: t('Client List'),
                name: 'client',
            },
            {
                display_name: t('Services'),
                name: 'services',
            },
            {
                display_name: t('Payment History'),
                name: 'payment',
            },
            {
                display_name: t('Heat Map'),
                name: 'heat',
            },
        ];
        const rentTab = [
            {
                display_name: t('Rent Collection History'),
                name: 'overview',
            },
            {
                display_name: t('Services'),
                name: 'services',
            },
        ];
        const partnerTab = [
            {
                display_name: t('Services'),
                name: 'services',
            },
        ];
        const noTrackingTab = [
            {
                display_name: t('Overview'),
                name: 'overview',
            },
            {
                display_name: t('Client List'),
                name: 'client',
            },
            {
                display_name: t('Services'),
                name: 'services',
            },
            {
                display_name: t('Heat Map'),
                name: 'heat',
            },
        ];
        switch (staff.pay_structure.pay_structure_type) {
            case 'commission':
                return commisionTab;
            case 'rent':
                return rentTab;
            case 'partner':
                return partnerTab;
            case 'no_tracking':
                return noTrackingTab;
            default:
                return [];
        }
    }, []);

    const TabData = ({ tabs, currentStaff }: any) => {
        switch (tabs) {
            case 'overview':
                return <Overview staff={currentStaff} />;
            case 'client':
                return <StaffUsers staff={currentStaff} />;
            case 'services':
                return <StaffServices staff={currentStaff} />;
            case 'payment':
                return <StaffPayment staff={currentStaff} />;
            case 'heat':
                return <StaffHeatMap staff={currentStaff} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            <div className="fl-tab-btn-view w-full">
                {TABS.map((tab: any, index) => (
                    <button key={index} type="button" onClick={handleTab(tab.name)} className={`w-full fl-tab-link  ${tab.name === currentTab ? '!text-primary !bg-blue-50' : ''}`}>
                        {tab.display_name}
                    </button>
                ))}
            </div>
            <TabData tabs={currentTab} currentStaff={staff} />
        </>
    );
};

export default memo(StaffTab);
