import React from 'react';
import Profile from './Profile';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from 'src/constants/common';

const AccountSettings = () => (
    <div className="w-[full]  bg-white  ">
        <div className="detail-page-data-block !p-0">
            <div className="data-wrap-block w-full">
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID || ''}>
                    <Profile />
                </GoogleOAuthProvider>
            </div>
        </div>
    </div>
);

export default AccountSettings;
