import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import SStripeAccountDetails from '../Skeleton/Payment/SStripeAccountDetails';

const StripeAccountDetails = ({ FinalizeSetStep }: any) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [sripeDetails, setSripeDetails] = useState<any>(null);

    useEffect(() => {
        getAccountDetails();
    }, []);

    const getAccountDetails = () => {
        axiosGet(API.STRIPE.ACCOUNT_DETAILS)
            .then((response) => {
                setSripeDetails(response.data.data);
            })
            .finally(() => setIsLoading(false));
    };
    return (
        <div className="flex flex-col items-center text-center w-[750px] mt-[64px]">
            <h3 className="text-3xl font-semibold mb-2 leading-[38px] text-[#101828]">{t('Confirm Stripe Configuration')}</h3>
            <div className="text-base font-normal mb-4 text-[#475467]">{t('Review and confirm your Stripe account setup details.')}</div>
            {isLoading ? (
                <SStripeAccountDetails />
            ) : (
                sripeDetails && (
                    <div className="flex flex-col border border-[#EAECF0] px-6 w-full rounded-[16px] mt-[32px]">
                        <div className="flex flex-row w-full  py-4 pb-4 border-b">
                            <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Name :')}</div>
                            <div className="w-[390px] flex items-start font-medium text-sm text-[#101828]">{sripeDetails.name ? sripeDetails.name : '-'}</div>
                        </div>
                        <div className="flex flex-row w-full  py-4 pb-4 border-b">
                            <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Destination accounts:')}</div>
                            <div className="w-[390px] flex items-start font-medium text-sm text-[#101828]">{sripeDetails.destination_accounts ? sripeDetails.destination_accounts : '-'}</div>
                        </div>
                        <div className="flex flex-row w-full  py-4 pb-4 border-b">
                            <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Account Number:')}</div>
                            <div className="w-[390px] flex items-start font-medium text-sm text-[#101828]">{sripeDetails.account_number ? `*****${sripeDetails.account_number}` : '-'}</div>
                        </div>
                        <div className="flex flex-row w-full  py-4 pb-4 border-b">
                            <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Account verified:')}</div>
                            <div className="w-[390px] flex items-start font-medium text-sm text-[#101828]">{sripeDetails.account_verified ? t('Yes') : t('No')}</div>
                        </div>
                        <div className="flex flex-row w-full  py-4 pb-4 border-b">
                            <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Social insurance number:')}</div>
                            <div className="w-[390px] flex items-start font-medium text-sm text-[#101828]">{sripeDetails.social_insurance_number ? t('Provided') : t('Not Provided')}</div>
                        </div>
                        <div className="flex flex-row w-full  py-4 pb-4 border-b">
                            <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Date of birth:')}</div>
                            <div className="w-[390px] flex items-start font-medium text-sm text-[#101828]">
                                {sripeDetails.date_of_birth ? moment(sripeDetails.date_of_birth).format('MMM D, YYYY') : '-'}
                            </div>
                        </div>
                        <div className="flex flex-row w-full  py-4 pb-4 ">
                            <div className="w-[300px] flex items-start font-normal text-sm text-[#475467]">{t('Phone number:')}</div>
                            <div className="w-[390px] flex items-start font-medium text-sm text-[#101828]">{sripeDetails.support_phone ? sripeDetails.support_phone : '-'}</div>
                        </div>
                    </div>
                )
            )}
            <div className="mt-[32px] mb-6">
                <CustomButton primary onClick={() => FinalizeSetStep(4)} className="!w-[177px] !text-base rounded-lg shadow-InputAndButton">
                    Complete Setup
                </CustomButton>
            </div>
        </div>
    );
};

export default StripeAccountDetails;
