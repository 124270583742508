import React from 'react';
import { formatKpiContentLable } from 'src/utils/global-functions';

const NetSalesIncome = ({ handleViewClick, netSalesIncomeDetails }: any) => (
    <>
        <div className="bg-white rounded-xl border border-borderSecondary shadow">
            <div className="flex justify-between items-center py-4 px-5 border-b border-borderSecondary">
                <div className="text-lg font-semibold -tracking-[0.4px] text-mainTextColor ">Net Sales Income</div>
                <h3 className="text-lg font-bold -tracking-[0.5px] text-center">${formatKpiContentLable(netSalesIncomeDetails.net_sales_income) ?? 0}</h3>
            </div>
            <div className="grid grid-cols-2 w-full p-5 gap-4">
                <div className=" flex flex-col gap-2">
                    <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor">Total Gross Revenue:</p>
                    <p className="text-base max-xlm:text-sm font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor">${formatKpiContentLable(netSalesIncomeDetails.total) ?? 0}</p>
                </div>

                <div className=" flex flex-col gap-2">
                    <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor">Taxes:</p>
                    <p className="text-base max-xlm:text-sm font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor"> ${formatKpiContentLable(netSalesIncomeDetails.taxes) ?? 0}</p>
                </div>

                <div className=" flex flex-col gap-2">
                    <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor">Tips:</p>
                    <p className="text-base max-xlm:text-sm font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor">${formatKpiContentLable(netSalesIncomeDetails.tips) ?? 0}</p>
                </div>

                <div className=" flex flex-col gap-2">
                    <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor">Discounts:</p>
                    <p className="text-base max-xlm:text-sm font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor">$0.00</p>
                </div>
            </div>

            {/* <div className="w-1/2 py-4 ps-8">
                <div className="flex flex-col justify-center items-center h-full">
                    <p className="-tracking-[0.3] font-medium text-xs text-center mb-5">Net Sales Income</p>
                    <h3 className="text-[28px] font-bold leading-[140%] -tracking-[0.5px] text-center">${formatKpiContentLable(netSalesIncomeDetails.net_sales_income) ?? 0}</h3>
                </div>
            </div> */}
        </div>
    </>
);

export default NetSalesIncome;
