import React, { useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { allBreadcrumbs, currentShop, userMe } from 'src/redux/services/common/Common.slice';
import { useAppSelector } from 'src/redux/hooks';
import { BreadCrumbIcon, CalenderDate, HeaderSetting, Notification as NotificationIcon } from 'src/theme/Images';
import { getShortName } from 'src/utils/global-functions';
import { initAction } from './Dashboard.interface';
import { RightSideIcon } from 'src/theme/Images';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import _ from 'lodash';
import Notification from 'src/app/Notification/Notification';
import CustomButton from 'src/components/CustomButton';
import { Inbox01 } from '@untitled-ui/icons-react/build/cjs';
import { GoDotFill } from 'react-icons/go';

const Header = () => {
    const { pathname } = useLocation();
    const user = useAppSelector(userMe);
    const shop = useAppSelector(currentShop);
    const allBreadcrumbsList: any = useAppSelector(allBreadcrumbs);
    const navigate = useNavigate();
    const [action, setAction] = useState(initAction);
    const notificationButtonRef = useRef(null);
    const [errorImage, setErrorImage] = useState({
        profile: false,
    });
    const [notificationCount, setNotificationCount] = useState({
        all: 0,
        general: 0,
        appointments: 0,
        statements: 0,
    });

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleClose = () => {
        setAction(initAction);
    };

    const onErrorImage = (name: string) => () => {
        setErrorImage((old) => ({ ...old, [name]: true }));
    };

    return (
        <>
            {/* <header className={`absolute right-[30px] top-[38px] h-[36px]  z-[12]  `}> */}
            {/* <div className="flex justify-between h-[30px]"> */}

            {/* <div className="flex gap-2 items-center h-[18px]">
                        {!errorImage.profile && shop.logo_image_url ? (
                            <img
                                onClick={() => navigate(ROUTES.DASHBOARD)}
                                className="cursor-pointer w-[18px] h-[18px] object-contain rounded"
                                src={shop.logo_image_url}
                                alt={shop.business_name}
                                title={shop.business_name}
                                onError={onErrorImage('profile')}
                            />
                        ) : (
                            <span
                                onClick={() => navigate(ROUTES.DASHBOARD)}
                                className="cursor-pointer text-[#3E3CE1] w-[22px] h-[22px] basis-[22px] justify-center items-center bg-[#F2F4F7] border-[1px] text-[10px] font-medium flex rounded"
                            >
                                {shop.business_name && getShortName(shop.business_name)}
                            </span>
                        )}
                        <div className="flex items-center gap-2">
                            <img className="w-[6px] " src={RightSideIcon} alt="breadcrumb" />
                            <p onClick={() => navigate(ROUTES.DASHBOARD)} className="text-[#475467] text-xs font-medium cursor-pointer">
                                {_.upperFirst(_.toLower(shop.business_name))}
                            </p>
                        </div>
                        {allBreadcrumbsList.length > 0 &&
                            allBreadcrumbsList.map((item: any) => (
                                <div key={item.label} className="flex gap-2 items-center">
                                    <img className="w-[6px] " src={RightSideIcon} alt="breadcrumb" />
                                    <p className="text-[#475467] text-xs font-medium cursor-pointer" onClick={() => navigate(item.url)}>
                                        {item.label}
                                    </p>
                                </div>
                            ))}
                    </div> */}

            {/* <NavLink to={allBreadcrumbsList.length > 0 ? allBreadcrumbsList[0].setting || PATH.SETTINGS : PATH.SETTINGS} className="header_icons">
                            <img className="w-5 h-5" src={HeaderSetting} alt="HeaderSetting" />
                        </NavLink> */}
            {/* <img className="w-5 h-5" src={NotificationIcon} alt="Notification" /> */}
            {/* <div className="">
                    <div className=" cursor-pointer" onClick={handleAction('notification')} ref={notificationButtonRef}>
                        <CustomButton secondary className="  h-[36px]   !min-w-max ">
                            <Inbox01 className="w-5 h-5 !relative text-mainTextColor" />
                            {notificationCount.all} Notifications
                        </CustomButton>
                        <div className="absolute top-[5px] left-[11px] ">
                            <GoDotFill size={16} color="#17B26A" className="h-3.5 w-3.5 rounded-full flex " />
                        </div>
                    </div>
                </div> */}
            {/* </div> */}
            {/* {pathname === '/dashboard' && (
                    <div className="flex justify-between pb-4 mb-5 items-center border-b border-gray-200">
                        <div>
                            <h3 className="title_text_page">Welcome back, {user.first_name}</h3>
                            <p className="subtitle_text_page">Here’s an overview of your week so far</p>
                        </div>
                        <div className="flex items-center gap-1 h-[22px] rounded-md header_icons_border py-[2px] px-1 mt-[6px]">
                            <img className="w-3 h-3" src={CalenderDate} alt="CalenderDate" />
                            <p className="text-xs leading-[18px] font-medium text-gray-700">Saturday, March 30th</p>
                        </div>
                    </div>
                )} */}
            {/* <Notification
                    show={action.notification}
                    notificationButtonRef={notificationButtonRef}
                    notificationCount={notificationCount}
                    handleClose={handleClose}
                    setNotificationCount={setNotificationCount}
                /> */}
            {/* </header> */}
        </>
    );
};

export default Header;
