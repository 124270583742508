import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { IinitialState } from './ForgotPassword.interface';
import { RootState } from 'src/redux/store';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState: IinitialState = {
    data: '',
    loading: false,
};

export const forgotPassword = createAsyncThunk('shop/forgotPassword', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.FORGOT_PASSWORD, payload);
        if (response.data.status === 200) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(forgotPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(forgotPassword.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            })
            .addCase(forgotPassword.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            });
    },
});

export const selectForgotPasswordLoading = (state: RootState) => state.ForgotPassword.loading;
