import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import Button from 'src/components/Button';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { errorCode } from 'src/constants/errorCode';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop, userMe } from 'src/redux/services/common/Common.slice';
import Number from 'src/components/Number';
import { usePhone } from 'src/hooks/usePhone';
import { IFormData } from './SocialRegister.interface';
import VerifyOtp from '../VerifyOtp/VerifyOtp';
import { FaArrowLeft } from 'react-icons/fa6';
import { logout } from 'src/utils/global-functions';
import CustomButton from 'src/components/CustomButton';

const SocialRegister = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const user = useAppSelector(userMe);
    const [isLoading, setIsLoading] = useState(false);
    const { isPhoneValid } = usePhone();
    const [isVerificationScreen, setIsVerificationScreen] = useState(false);

    const schema = Yup.object({
        first_name: Yup.string().required(t('This field is required')),
        last_name: Yup.string().required(t('This field is required')),
        phone_country_code: Yup.string().required(t('This field is required')),
        phone: Yup.string()
            .required(t('This field is required'))
            .test({
                message: t('Invalid phone number'),
                test: function (value) {
                    const { phone_country_code: phoneCountryCode } = this.parent;
                    const isValid = isPhoneValid(value, phoneCountryCode);
                    return isValid;
                },
            }),
    }).required();
    const {
        handleSubmit,
        control,
        setError,
        setValue,
        formState: { errors },
        getValues,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            first_name: user.first_name || '',
            last_name: user.last_name || '',
            phone_country_code: user.phone_country_code || '',
            phone: user.phone || '',
        },
    });

    const handleChange = async (data: IFormData) => {
        setIsLoading(true);
        await axiosPatch(API.USER.SOCIAL_ACCOUNT, data, { shop_id: shop.id })
            .then(async (response) => {
                setIsVerificationScreen(true);
                return;
            })
            .catch((error: any) => {
                const response = error.response.data;

                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = '';
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                            setError(field as keyof IFormData, {
                                type: 'manual',
                                message: message,
                            });
                            return;
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    return isVerificationScreen ? (
        <VerifyOtp info={getValues()} handleChangeNumber={() => setIsVerificationScreen(false)} handleResendOtp={handleSubmit(handleChange)} />
    ) : (
        <div className="content mt-[84px] flex justify-center">
            <div className="w-[380px]">
                <h1 className=" mb-[6px] text-[30px] -tracking-[1.2px] leading-[38px] font-semibold text-mainTextColor">{t('Create an account')}</h1>
                <p className="text-[14px] opacity-70 -tracking-[0.3px] leading-5 text-secondaryTxtColor ">{t("Let's get you setup. Start by creating an account.")}</p>
                <div className="mt-[30px]">
                    <form onSubmit={handleSubmit(handleChange)}>
                        <div className="flex gap-4 flex-wrap">
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-[100%]">
                                        <InputWithLabel
                                            label={t('First Name')}
                                            placeholder={t('Input your first name')}
                                            onChange={onChange}
                                            value={value}
                                            name="first_name"
                                            error={errors.first_name && true}
                                            disabled={isLoading}
                                            isFocus={true}
                                        />
                                        <p className="text-error">{errors.first_name && errors.first_name.message}</p>
                                    </div>
                                )}
                            />
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field: { onChange, value } }: any) => (
                                    <div className="w-[100%]">
                                        <InputWithLabel
                                            label={t('Last Name')}
                                            placeholder={t('Input your last name')}
                                            onChange={onChange}
                                            value={value}
                                            name="last_name"
                                            error={errors.last_name && true}
                                            disabled={isLoading}
                                            // isFocus={true}
                                        />
                                        <p className="text-error">{errors.last_name && errors.last_name.message}</p>
                                    </div>
                                )}
                            />
                            <div className="data-wrap-block w-full max-w-[400px]">
                                <Controller
                                    name="phone"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                        <>
                                            <Number
                                                errors={error}
                                                value={value}
                                                defaultValues={{ phone: getValues('phone'), phone_country_code: getValues('phone_country_code') }}
                                                onNumberChange={(country: any, phone: any, code: any, isValid: any) => {
                                                    setValue('phone', `+${code}${phone}`);
                                                    setValue('phone_country_code', country);
                                                }}
                                            />
                                            {error && <p className="text-error">{error.message}</p>}
                                        </>
                                    )}
                                />
                            </div>
                            <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading} size="w-full" className="!text-base mt-1">
                                {t('Continue')}
                            </CustomButton>
                        </div>
                        <div className="text-center mt-8">
                            <Button type="button" className="text-sm font-semibold" onClick={logout}>
                                <FaArrowLeft size={14} className="mr-2" /> {t('Back to log in')}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SocialRegister;
