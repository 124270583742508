import { Dispatch, SetStateAction } from 'react';
import { ICountStep } from 'src/components/Stepper/Stepper.interface';
import { CountryCode } from 'libphonenumber-js';
import { AlarmClockOff, CoinsStacked01, Percent03, UsersCheck } from '@untitled-ui/icons-react/build/cjs';

export const accountTypeOptions = [
    {
        label: 'Service Provider',
        value: 'service_provider',
    },
];

export const initAction = {
    pay_structure: false,
    policy: false,
    delete: false,
    active: false,
};

export const PAY_STRUCTURE_LIST = [
    {
        name: 'Commission',
        value: 'commission',
        description: 'Commission-based pay.',
        icon: Percent03,
    },
    {
        name: 'Rent',
        value: 'rent',
        description: 'Fixed rent payments.',
        icon: CoinsStacked01,
    },
    {
        name: 'No tracking',
        value: 'no_tracking',
        description: 'No payment tracking.',
        icon: AlarmClockOff,
    },
    {
        name: 'Partner',
        value: 'partner',
        description: 'Rent-free partnership.',
        icon: UsersCheck,
    },
];

export interface IFormData {
    first_name: string;
    last_name: string;
    phone: string;
    phone_country_code: CountryCode | '';
    email: string;
    account_type: 'service_provider' | '';
    shop_staff_role_id: number | null;
    shop_location_id: number[];
    pay_structure_type: 'commission' | 'rent' | 'partner' | 'no_tracking' | null;
    start_date: string;
    commission?: string;
    rent_money?: number | null;
    is_sales_tax?: boolean;
    tax_percentage?: number | undefined;
    tax_number?: string;
}

export interface IPayFormData {
    pay_structure_type: 'commission' | 'rent' | 'partner' | 'no_tracking' | null;
    start_date: string;
    commission?: string;
    rent_money?: number | null;
    is_sales_tax?: boolean;
    tax_percentage?: number;
    tax_number?: string;
}

export interface ExistingFormData {
    [key: string]: any;
}

export interface IProps {
    setStep?: Dispatch<SetStateAction<ICountStep | number>>;
    setFormData?: Dispatch<SetStateAction<IStaffFormData | null>>;
    formData?: any;
    handleService?: () => void;
    editForm?: boolean;
    customError?: string;
    setCustomError?: Dispatch<SetStateAction<string | undefined>>;
    onClick?: any;
    locationDataDetails?: any;
    isLoading?: boolean;
    shop_payment?: {
        rent_payment_day: string;
        commission_payment_day: string;
        commission_frequency: string;
        rent_frequency: string;
    };
}

export interface IInitialState {
    data: any;
    loading: boolean;
    error: { message: string | undefined; status?: string | undefined } | undefined | null;
}

export interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

export interface ISelectedLocation {
    id?: number;
    value: number;
    label: string;
}

export interface IStaffFormData {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    phone_country_code: string;
    account_type: string;
    shop_staff_role_id: number | null;
    shop_location_id: any[];
    pay_structure_type: 'commission' | 'rent';
    commission?: string;
    start_date?: string;
    rent_money?: number | null;
    effective_date?: string;
}
export interface ITimeFileterOptions {
    label: string;
    value: string;
    endDate?: any;
    startDate?: any;
}

export interface IPayload {
    [x: string]: any;
    // type: string;
    location_id?: number;
    end_date?: any;
    start_date?: any;
}
