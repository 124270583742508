import React from 'react';
import { useTranslation } from 'react-i18next';

const ExpenseForm = () => {
    const { t } = useTranslation();
    return (
        <>
            <div>ExpenseForm</div>
        </>
    );
};

export default ExpenseForm;
