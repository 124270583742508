import React, { FC, useEffect, useState } from 'react';
import CustomButton from 'src/components/CustomButton';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IFormData, IProps, ICountryResponse } from './Account.interface';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { checkFileTypeValidation, convertBase64ToFile, getSelectBoxOptions, getShortName } from 'src/utils/global-functions';
import { s3Upload } from 'src/utils/s3Operations';
import { ReactComponent as Cloud } from 'src/assets/svgs/upload 1.svg';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { GLOBALVARIABLE } from 'src/utils/global-variables';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getCountry } from 'src/redux/services/country/Country.slice';
import { LANGUAGE_OPTIONS } from 'src/constants/common';
import { CompleteProfile } from 'src/theme/Images';
import { BackgroundDesign } from 'src/theme/Images';
import { currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';

type FieldKey = 'country_name' | 'language_name' | 'profile_image_name';

const Account: FC<IProps> = ({ setStep }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const user = useAppSelector(userMe);
    const shop = useAppSelector(currentShop);
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [upload, setUpload] = useState(false);
    const [optionCountry, setOptionContry] = useState<ICountryResponse[]>([]);
    const imageUrl = user.profile_image_url ? user.profile_image_url : null;
    const [cropData, setCropData] = useState<any>(imageUrl || '');
    const [image, setImage] = useState<any>(imageUrl || '');

    const defaultCountry = 'CA';
    const defaultLanguage = 'english';
    const schema = Yup.object({
        country_name: Yup.string().required(t('Country required')),
        language_name: Yup.string().required(t('Language required')),
    }).required();

    const onChangeCrop = (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.accountImageSize);
        if (result) {
            clearErrors('profile_image_name');
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            setError('profile_image_name' as 'profile_image_name', {
                type: 'manual',
                message: message,
            });
        }
    };

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            country_name: user.country ?? defaultCountry,
            language_name: user.language ?? defaultLanguage,
            profile_image_name: user.profile_image_name ?? '',
        },
    });
    const getCountryFun = async () => {
        const result = await dispatch(getCountry());
        if (result.type === getCountry.fulfilled.toString()) {
            const contryData: ICountryResponse[] | [] = getSelectBoxOptions(result.payload.data, 'shortname');
            const countriesWithImages = contryData.map((country) => ({
                ...country,
            }));
            const priorityCountries = ['Canada', 'United States'];

            const sortedCountries = countriesWithImages.sort((a, b) => {
                const aIndex = priorityCountries.indexOf(a.label);
                const bIndex = priorityCountries.indexOf(b.label);
                if ((aIndex !== -1 && bIndex !== -1) || (aIndex === -1 && bIndex === -1)) {
                    return a.label.localeCompare(b.label);
                }
                return aIndex !== -1 ? -1 : 1;
            });
            setOptionContry(sortedCountries);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getCountryFun();
    }, []);

    const handleChange = async (data: IFormData) => {
        setIsLoading(true);

        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/admin_profile/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                /* const url = imgUploadResult.data.Location.replace(
                    process.env.REACT_APP_AWS_UPLOAD_URL,
                    process.env.REACT_APP_PUBLIC_URL,
                ); */
                const updatedData = {
                    ...data,
                    logo_image_name: file.filename,
                };
                submitForm(updatedData);
            }
        } else {
            submitForm(data);
        }
    };

    const submitForm = async (data: IFormData) => {
        const payload = {
            country: data.country_name,
            language: data.language_name,
            profile_image_name: data.profile_image_name ?? '',
        };
        axiosPatch(API.USER.SIGNUP_PROFILE, payload, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
                if (user.shop_staff[0].created_by_id) {
                    setStep(4);
                } else {
                    setStep(2);
                }
            })
            .catch((error) => {
                const response = error.payload.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as FieldKey, {
                                type: 'manual',
                                message: response.data[field][0],
                            });
                        });
                    }
                    setIsLoading(false);
                    return;
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };

    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('profile_image_name', fileInfo.filename);
        }
    };

    useFormErrorFocus<IFormData>({ errors });

    return (
        <>
            <div className="w-full flex justify-center relative flex-1">
                <img src={BackgroundDesign} alt="" className="absolute top-0 z-[-1]" />
                <div className="w-[600px] mt-12">
                    <div className="flex flex-col rounded-md items-center mb-4">
                        <div className=" p-[14px] bg-white border border-btnborder rounded-xl">
                            <CompleteProfile className="w-7" />
                        </div>
                    </div>
                    <div className="text-center">
                        <h3 className="fwpo_heading_title">{t('Complete your profile')}</h3>
                        <p className="font-normal leading-[22.4px] mt-[2px] text-base text-secondaryTxtColor">{t('Tell us a bit about yourself')}</p>
                    </div>
                    <div className="mt-6">
                        <form onSubmit={handleSubmit(handleChange)} className="h-full">
                            <div className="flex flex-col">
                                <div className="">
                                    <div className="text-center mb-5">
                                        <div
                                            id="profile_image_name"
                                            className={`h-[56px] w-[56px] mb-3 rounded-xl bg-blueLight-50 mx-auto broder border-inputBg flex justify-center items-center relative   ${
                                                errors.profile_image_name && 'is-invalid'
                                            }`}
                                        >
                                            {upload && (
                                                <CropperModal imageUpload={imageUpload} imageUrl={image} setUpload={setUpload} setCropData={setCropData} isCropType={false} defaultCropType="Square" />
                                            )}
                                            <div className="bg-contentBackground w-full">
                                                <div className="w-[full]">
                                                    <div className="w-auto">
                                                        {!cropData ? (
                                                            <div className="relative overflow-hidden flex justify-center items-center">
                                                                <input type="file" id="fileInput" className="upload-pic-input" onChange={onChangeCrop} />
                                                                <label htmlFor="fileInput" className="upload-pic-label h-full w-full">
                                                                    {''}
                                                                    <div className="flex justify-center items-center h-[56px] w-[56px]">
                                                                        <figure className="basis-full grow-0 shrink-0 rounded-md text-lg font-medium  text-primary  flex justify-center items-center h-[56px] w-[56px]">
                                                                            {user.profile_image_url ? (
                                                                                <img src={user.profile_image_url} alt="client" className="w-full h-full rounded-md object-cover" />
                                                                            ) : (
                                                                                getShortName(user.full_name)
                                                                            )}
                                                                        </figure>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        ) : (
                                                            <div className={``}>
                                                                <div className="w-full h-full rounded-md relative overflow-hidden">
                                                                    <img src={cropData} alt="" className="w-full h-full" />
                                                                </div>
                                                                <div className="  absolute top-0 left-0 w-full h-full  flex justify-center items-center  ">
                                                                    <div className=" p-2 w-14 h-14 rounded-full flex justify-center items-center">
                                                                        <Cloud width={20} height={20} />
                                                                    </div>
                                                                </div>
                                                                <label htmlFor="fileInput" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                                                    {''}
                                                                    <input
                                                                        type="file"
                                                                        id="fileInput"
                                                                        className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr"
                                                                        style={{
                                                                            visibility: 'hidden',
                                                                        }}
                                                                        onChange={onChangeCrop}
                                                                    />
                                                                </label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="text-base text-textGray700 font-semibold">{t('Upload a profile picture')}</p>
                                            <p className="text-base text-secondaryTxtColor font-normal">{t('Upload JPG or PNG file')}</p>
                                        </div>
                                        <p className="text-error">{errors.profile_image_name && errors.profile_image_name.message}</p>
                                    </div>
                                    <div className="flex gap-6">
                                        <div className="w-full">
                                            <Controller
                                                name="country_name"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <>
                                                        <SelectBox
                                                            name="timezone"
                                                            id="country_name"
                                                            label={t('Select country')}
                                                            placeholder={t('Select country')}
                                                            options={optionCountry}
                                                            onChangeFunc={(option: any) => onChange(option ? option.value : null)}
                                                            value={optionCountry.find((option) => option.value === value)}
                                                            error={!!error}
                                                            className="h-[40px] border-hover-custom"
                                                            isClearable={false}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="w-full">
                                            <Controller
                                                name="language_name"
                                                control={control}
                                                render={({ field: { onChange, value } }: any) => (
                                                    <div id="language_name">
                                                        <SelectBox
                                                            name="country"
                                                            id="handlingUnit"
                                                            label={t('Default language')}
                                                            placeholder={t('Default language')}
                                                            options={LANGUAGE_OPTIONS}
                                                            onChangeFunc={(selectedOption: any) => {
                                                                onChange(selectedOption.value);
                                                            }}
                                                            value={LANGUAGE_OPTIONS.find((option) => option?.value === value)}
                                                            errorText={errors.language_name && true}
                                                            isDisabled={isLoading}
                                                            isSearchable
                                                            isClearable={false}
                                                        />
                                                        <p className="text-error mt-2 absolute text-sm">{errors.language_name && errors.language_name.message}</p>
                                                    </div>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full mt-5">
                                    <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" size="w-full" className="fl-btn">
                                        {t('Continue')}
                                    </CustomButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Account;
