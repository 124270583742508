import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { ArrowLeft } from '@untitled-ui/icons-react/build/cjs';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { defaultImage } from 'src/theme/Images';
import { onError } from 'src/utils/global-functions';
import CustomButton from 'src/components/CustomButton';
import InputWithLabel from 'src/components/InputWithLabel';
import Number from 'src/components/Number';
import DateSelect from 'src/components/DateSelect/DateSelect';
import { Label, Radio } from 'flowbite-react';
import { axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { toast } from 'react-toastify';
import moment from 'moment';
import { IFormData } from './Client.interface';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import { PATH } from 'src/constants/path';

const ClientForm = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [profileImageUrl, setProfileImageUrl] = useState(defaultImage);

    const schema = Yup.object({
        first_name: Yup.string().required(`${t('This field is required')}`),
        last_name: Yup.string().required(`${t('This field is required')}`),
        email: Yup.string().email(t('Please enter valid email address')).required(t('This field is required')),
        phone: Yup.string().required(`${t('This field is required')}`),
        login_at: Yup.string(),
        created_date: Yup.string(),
        phone_country_code: Yup.string().required(`${t('This field is required')}`),
        is_book_with_card: Yup.boolean().required(t('Account type required')),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            phone_country_code: 'US',
            login_at: '',
            created_date: '',
            is_book_with_card: true,
        },
    });

    const {
        handleSubmit,
        setValue,
        getValues,
        watch,
        setError,
        trigger,
        control,
        formState: { errors },
    } = methods;

    const watchFirstName = watch('first_name');
    const watchLastName = watch('last_name');

    useEffect(() => {
        if (id) {
            getClient();
        }
    }, [id, shop]);

    const getClient = async () => {
        setIsLoading(true);
        await axiosGet(API.CLIENT.GET, { shop_id: shop.id, id })
            .then((response) => {
                const data = response.data.data;
                setProfileImageUrl(data.profile_image_url);
                const existingData: IFormData = {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    phone_country_code: data.phone_country_code,
                    phone: data.phone,
                    created_date: data.created_at ? moment(data.created_at).format('YYYY-MM-DD') : '',
                    login_at: data.login_at ? moment(data.login_at).format('YYYY-MM-DD') : '',
                    is_book_with_card: data.shop_client.is_book_with_card,
                };
                Object.keys(existingData).forEach((key) => {
                    setValue(key as keyof IFormData, existingData[key as keyof IFormData]);
                });
            })
            .catch(() => navigate(ROUTES.CLIENT.LIST))
            .finally(() => setIsLoading(false));
    };

    const handleSave = async (data: IFormData) => {
        setIsLoading(true);
        const payload = {
            ...data,
        };

        if (id) {
            await axiosPatch(API.CLIENT.UPDATE, payload, { shop_id: shop.id, id })
                .then(async () => {
                    navigate(ROUTES.CLIENT.LIST);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        } else {
            await axiosPost(API.CLIENT.CREATE, payload, { shop_id: shop.id })
                .then(async () => {
                    navigate(ROUTES.CLIENT.LIST);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const resetForm = (event: any) => {
        navigate(`${PATH.CLIENT.VIEW}/${id}`);
    };

    useFormErrorFocus<IFormData>({ errors });

    return (
        <div className="inner-page-wrape !px-0">
            <div className="flex-1 flex flex-col mb-4">
                <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                    <Link to={ROUTES.CLIENT.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5">
                        <ArrowLeft className="w-4 cursor-pointer text-gray-600" /> {t('Back to Client')}
                    </Link>
                </div>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(handleSave)} className="">
                        <div className="relative mb-[26px] w-full xl:pt-[10px] xlm:px-8 px-5 pt-2 pb-2 flex sms:gap-4 gap-2 xs:items-end items-start">
                            <img
                                src={profileImageUrl || defaultImage}
                                alt={watchFirstName || t('Client Name')}
                                title={watchFirstName || t('Client Name')}
                                onError={onError}
                                className="w-[144px] h-[144px] rounded-xl object-cover border-4 border-white drop-shadow-lg flex-none -mt-[62px]"
                            />
                            <div className="">
                                <h1 className="mr-10 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px]">
                                    {watchFirstName || watchLastName ? `${watchFirstName} ${watchLastName}` : t('Client Name')}
                                </h1>
                            </div>
                            <div className="flex-1 text-right flex justify-end">
                                <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading} className="mr-4">
                                    {t('Cancel')}
                                </CustomButton>
                                <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                    {t('Save Changes')}
                                </CustomButton>
                            </div>
                        </div>
                        <div className="detail-data-main xlm:px-8 px-5 border-t ">
                            <div className="title-block">
                                <h3>{t('Client details')}</h3>
                                <p>{t('Update your profile photoThis will be displayed on your profile. and details here.')}</p>
                            </div>
                            <div className="data-wrap-block max-w-[512px] xxl:max-w-[666px]">
                                <div className="grid grid-cols-2 gap-4">
                                    <Controller
                                        name="first_name"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <>
                                                <InputWithLabel
                                                    label={t('First Name')}
                                                    id="first_name"
                                                    placeholder={t('Enter client first name')}
                                                    onChange={onChange}
                                                    value={value}
                                                    required
                                                    name="first_name"
                                                    error={!!errors.first_name}
                                                    isFocus={true}
                                                />
                                            </>
                                        )}
                                    />
                                    <Controller
                                        name="last_name"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <>
                                                <InputWithLabel
                                                    label={t('Last Name')}
                                                    id="last_name"
                                                    placeholder={t('Enter client last name')}
                                                    onChange={onChange}
                                                    value={value}
                                                    required
                                                    name="last_name"
                                                    error={!!errors.last_name}
                                                />
                                            </>
                                        )}
                                    />
                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field: { value } }: any) => (
                                            <div>
                                                <label className="fl-field-title-label">
                                                    {t('Phone number')}
                                                    <span className="asterisk">*</span>
                                                </label>
                                                <Number
                                                    disabled={!!id}
                                                    errors={errors}
                                                    value={value}
                                                    id="phone"
                                                    defaultValues={{ phone: getValues('phone'), phone_country_code: getValues('phone_country_code') }}
                                                    onNumberChange={(country: any, phone: any, code: any, isValid: any) => {
                                                        setValue('phone', `+${code}${phone}`);
                                                        setValue('phone_country_code', country);
                                                        trigger(['phone', 'phone_country_code']);
                                                    }}
                                                />
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <div>
                                                <InputWithLabel
                                                    name="email"
                                                    id="email"
                                                    label={t('Email address')}
                                                    required
                                                    placeholder={t('Enter client email address')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!fieldState.error}
                                                    readOnly={!!id}
                                                />
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="login_at"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <div className="relative">
                                                <span className="text-xs font-medium text-gray-700 flex capitalize">{t('Last Active')}</span>
                                                <div className="relative">
                                                    <DateSelect
                                                        id="login_at"
                                                        inputName="start_date"
                                                        className={`form_control_date  font-medium text-xs relative w-full `}
                                                        placeholder={t('Select date')}
                                                        dateFormat="yyyy-MM-dd"
                                                        // minDate={minDate}
                                                        InputClass={`${fieldState.error && 'is-invalid'}`}
                                                        value={value}
                                                        onChangeValue={onChange}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    />
                                    <Controller
                                        name="created_date"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState }: any) => (
                                            <div className="relative">
                                                <span className="text-xs font-medium text-gray-700 flex capitalize">{t('Account creation')}</span>
                                                <div className="relative">
                                                    <DateSelect
                                                        inputName="start_date"
                                                        className={`form_control_date  font-medium text-xs relative w-full `}
                                                        placeholder={t('Select date')}
                                                        dateFormat="yyyy-MM-dd"
                                                        // minDate={minDate}
                                                        InputClass={`${fieldState.error && 'is-invalid'}`}
                                                        value={value}
                                                        onChangeValue={onChange}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="detail-data-main xlm:px-8 px-5 ">
                            <div className="title-block">
                                <h3>
                                    {t('Appointment Settings')}
                                    <span className="asterisk">*</span>
                                </h3>
                                <p>{t('Manually override the general booking payment requirements for this specific client.')}</p>
                            </div>
                            <div className="data-wrap-block max-w-[512px] xxl:max-w-[666px]">
                                <Controller
                                    name={`is_book_with_card`}
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <>
                                            <div
                                                id="is_book_with_card"
                                                className={`flex mb-4 cursor-pointer p-4 border border-gray-200 rounded-xl ${value === false ? 'border border-blue-600' : 'border-gray-200'}`}
                                            >
                                                <Radio
                                                    className="custom-round-radio h-4 w-4 mt-[3px]"
                                                    id="logo-type-logo"
                                                    name="is_book_with_card"
                                                    value="false"
                                                    onChange={() => onChange(false)}
                                                    checked={value === false}
                                                />
                                                <Label htmlFor="logo-type-logo" className="ml-2 cursor-pointer text-sm text-gray-900 leading-5 font-semibold">
                                                    {t('Book without card')}
                                                    <span className="flex text-xs text-gray-600 leading-5 font-normal ">
                                                        {t(
                                                            'Enable book without card in order to not require clients to have a card on file to reserve an appointment. This doesnt protect your team from cancelations and no shows.',
                                                        )}
                                                    </span>
                                                </Label>
                                            </div>

                                            <div className={`flex border cursor-pointer border-gray-200 rounded-xl p-4 ${value === true ? 'border border-blue-600' : 'border-gray-200'}`}>
                                                <Radio
                                                    className="custom-round-radio h-4 w-4 mt-[3px]"
                                                    id="logo-type-logo-text"
                                                    name="is_book_with_card"
                                                    value="true"
                                                    onChange={() => onChange(true)}
                                                    checked={value === true}
                                                />
                                                <Label htmlFor="logo-type-logo-text" className="ml-2 cursor-pointer text-sm text-gray-900 leading-5 font-semibold">
                                                    {t('Book with card')}
                                                    <span className="flex text-xs text-gray-600 leading-5 font-normal ">
                                                        {t(
                                                            'Enable book with card to protect your client and yourself from last minute cancelations and no shows. Your clients will need to confirm their appointment by entering their card details but wont be charged until the service is completed..',
                                                        )}
                                                    </span>
                                                </Label>
                                            </div>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex-1 text-right pt-5 flex justify-end mb-36 gap-4 xlm:px-8 px-5 ">
                            <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading} className="mr-4">
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                {t('Save Changes')}
                            </CustomButton>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
};

export default ClientForm;
