import React, { useEffect, useMemo, useState } from 'react';
import AccountSettings from './AccountSettings/AccountSettings';
import BusinessDetails from './BusinessDetails/BusinessDetails';
import Support from './Support/Support';
import Hyperlinks from './Hyperlinks/Hyperlinks';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SETTING_TABS } from 'src/constants/common';
import PageHeader from 'src/components/PageHeader';
import PaymentSettings from './PaymentSettings/PaymentSettings';
import { PATH } from 'src/constants/path';
import LoyaltyReferral from './LoyaltyReferral/LoyaltyReferral';
import Calendar from './Calendar/Calendar';
import Payment from './Payment/Payment';
import ClientNotifications from './ClientNotifications/ClientNotifications';
import Notifications from './Notifications/Notifications';
import Product from './Product/Product';

const Setting = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { tab: currentTab } = useParams();
    const navigate = useNavigate();
    const [isLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(<BusinessDetails />);
    useEffect(() => {
        switch (currentTab) {
            case SETTING_TABS.BUSINESS:
                setActiveTab(<BusinessDetails />);
                break;
            case SETTING_TABS.ACCOUNT:
                setActiveTab(<AccountSettings />);
                break;
            case SETTING_TABS.LOYALTY:
                setActiveTab(<LoyaltyReferral />);
                break;
            case SETTING_TABS.CALENDAR:
                setActiveTab(<Calendar />);
                break;
            case SETTING_TABS.CLIENT_NOTIFICATION:
                setActiveTab(<ClientNotifications />);
                break;
            case SETTING_TABS.PAYMENT:
                setActiveTab(<Payment />);
                break;
            case SETTING_TABS.TEAM:
                setActiveTab(<PaymentSettings />);
                break;
            // case SETTING_TABS.LOYALTY:
            //     setActiveTab(<LoyaltyReferral />);
            //     break;
            case SETTING_TABS.HYPERLINK:
                setActiveTab(<Hyperlinks />);
                break;
            case SETTING_TABS.SUPPORT:
                setActiveTab(<Support />);
                break;
            case SETTING_TABS.NOTIFICATION:
                setActiveTab(<Notifications />);
                break;
            case SETTING_TABS.PRODUCT:
                setActiveTab(<Product />);
                break;
            default:
                break;
        }
    }, [currentTab]);

    // const tabs = useMemo(
    //     () => [
    //         {
    //             name: t('Account settings'),
    //             slug: SETTING_TABS.ACCOUNT,
    //         },
    //         {
    //             name: t('Business details'),
    //             slug: SETTING_TABS.BUSINESS,
    //         },
    //         {
    //             name: t('Calendar Settings'),
    //             slug: SETTING_TABS.CALENDAR,
    //         },
    //         {
    //             name: t('Notifications'),
    //             slug: SETTING_TABS.NOTIFICATION,
    //         },
    //         {
    //             name: t('Team Management'),
    //             slug: SETTING_TABS.PAYMENT,
    //         },
    //         {
    //             name: t('Loyalty & Referral'),
    //             slug: SETTING_TABS.LOYALTY,
    //         },
    //         // {
    //         //     name: t('Loyalty & Referral new'),
    //         //     slug: SETTING_TABS.New,
    //         // },
    //         {
    //             name: t('Hyperlinks'),
    //             slug: SETTING_TABS.HYPERLINK,
    //         },
    //         {
    //             name: t('Support'),
    //             slug: SETTING_TABS.SUPPORT,
    //         },
    //     ],
    //     [],
    // );

    const tabs = useMemo(
        () => [
            {
                name: t('Brand Settings'),
                slug: SETTING_TABS.BUSINESS,
            },
            {
                name: t('Account settings'),
                slug: SETTING_TABS.ACCOUNT,
            },
            {
                name: t('Loyalty & Referral'),
                slug: SETTING_TABS.LOYALTY,
            },
            {
                name: t('Calendar Settings'),
                slug: SETTING_TABS.CALENDAR,
            },
            {
                name: t('Client Notifications'),
                slug: SETTING_TABS.CLIENT_NOTIFICATION,
            },
            {
                name: t('Product Settings'),
                slug: SETTING_TABS.PRODUCT,
            },
            // {
            //     name: t('Loyalty & Referral new'),
            //     slug: SETTING_TABS.New,
            // },
            {
                name: t('Payment Settings'),
                slug: SETTING_TABS.PAYMENT,
            },
            {
                name: t('Notifications'),
                slug: SETTING_TABS.NOTIFICATION,
            },
            {
                name: t('Team Management'),
                slug: SETTING_TABS.TEAM,
            },
        ],
        [],
    );

    const handleTabClick = (slug: string) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        navigate(`${PATH.SETTINGS}${slug}`);
    };

    return (
        <div className="inner-page-wrape ">
            <div className="flex flex-col flex-1">
                <PageHeader title={t('Settings')} subtitle="Manage your settings" />
                <div className="side-spaching flex flex-1 flex-col w-full">
                    <div className="fl-tab-btn-view w-full">
                        {tabs.map((tab, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`fl-tab-link !px-1 2xl:!px-3 !text-[10px] xlm:!text-xs ${
                                    currentTab === tab.slug || (pathname === '/settings' && tab.slug === SETTING_TABS.BUSINESS) || (pathname === '/settings/' && tab.slug === SETTING_TABS.BUSINESS)
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={handleTabClick(tab.slug)}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </div>
                    {activeTab}
                </div>
            </div>
        </div>
    );
};

export default Setting;
