import React from 'react';
import { APP_FULL_NAME } from 'src/constants/common';
import { Logo } from 'src/theme/Images';

const Header = () => (
    <header className="px-8 pt-8">
        <img src={Logo} className="w-[108px]" alt={APP_FULL_NAME} title={APP_FULL_NAME} />
    </header>
);

export default Header;
