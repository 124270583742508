import React, { useEffect, useState } from 'react';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { locationListAction } from 'src/app/Location/Location.slice';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { capitalizeFirstLetter, formatName, formateAmount, getSelectBoxOptions, getSelectBoxStaffOptionsIdAsValue, getShortName } from 'src/utils/global-functions';
import './../../../utils/Datatable.scss';
import { staffList } from 'src/app/Staff/Staff.slice';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './../../../utils/prime-react-datatable.scss';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { PiCaretUpDownBold } from 'react-icons/pi';

import { Tooltip } from 'flowbite-react';
import { RxInfoCircled } from 'react-icons/rx';
import { ROUTES } from 'src/constants/routes';
import { Link } from 'react-router-dom';
import { transactionHistoryList } from './TransactionHistory.slice';
import moment from 'moment';
import CustomDropdownWithDate from 'src/components/CustomDropdownWithDate/CustomDropdownWithDate';
import { mapLocationPin } from 'src/theme/Images';

interface ITimeFileterOptions {
    label: string;
    value: string;
    endDate?: any;
    startDate?: any;
}

export interface ISelectedLocation {
    id?: number;
    value: number;
    label: string;
}
const timeFileterOptions: ITimeFileterOptions[] = [
    { label: 'All time', value: 'all' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Last Month', value: 'last_month' },
];

const TransactionHistory = ({ activeTab }: any) => {
    const shop = useAppSelector(currentShop);
    const shopId = shop.id;
    const dispatch = useAppDispatch();
    const [locationList, setLocationList] = useState<any>([]);
    const [selectedLocation, setSelectedLocation] = useState<any>();
    const [staffOptionList, setStaffOptionList] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState<any>();
    const [transactionList, setTransactionList] = useState<any>([]);
    const [totalTransaction, setTotalTransaction] = useState(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [timeFileterSelected, setTimeFileterSelected] = useState(timeFileterOptions[0]);

    const [lazyState, setlazyState] = useState<any>({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            location_id: { value: null },
            type: { value: timeFileterSelected?.value },
            staff_id: { value: null },
        },
    });

    useEffect(() => {
        selectedLocation && loadLazyData();
    }, [lazyState]);

    useEffect(() => {
        let payload: any = {
            type: {
                value: timeFileterSelected.value,
            },
        };

        if (timeFileterSelected.value === 'custom') {
            payload = {
                ...payload,
                start_date: {
                    value: moment(timeFileterSelected.startDate).format('YYYY-MM-DD'),
                },
                end_date: {
                    value: moment(timeFileterSelected.endDate).format('YYYY-MM-DD'),
                },
            };
        }

        setlazyState((prevState: { filters: any }) => ({
            ...prevState,
            page: 1,
            first: 0,
            filters: {
                ...prevState.filters,
                ...payload,
            },
        }));
    }, [timeFileterSelected]);
    const loadLazyData = async () => {
        setLoading(true);

        const payloadData = {
            data: lazyState,
            shop_id: shopId,
        };
        const result = await dispatch(transactionHistoryList(payloadData));
        if (result.type === transactionHistoryList.fulfilled.toString()) {
            setTransactionList(result.payload.data.data);
            setTotalTransaction(result.payload.data.totalRecords);
            setLoading(false);
        }
    };

    const onPage = (event: any) => {
        setlazyState(event);
    };

    const onSort = (event: any) => {
        setlazyState(event);
    };
    const onFilter = (event: any) => {
        event.first = 0;
        setlazyState(event);
    };

    const getLocationList = async () => {
        const data = {
            shop_id: shopId,
        };
        const result = await dispatch(locationListAction(data));
        if (result.type === locationListAction.fulfilled.toString()) {
            let locationListArray: any = getSelectBoxOptions(result.payload.data, 'id', 'name');
            const allOption = { value: null, label: 'All Locations' };
            locationListArray = [allOption].concat(locationListArray);
            setLocationList(locationListArray);
            setSelectedLocation(locationListArray[0]);
            setlazyState({
                ...lazyState,
                page: 1,
                first: 0,
                filters: {
                    ...lazyState.filters,

                    location_id: { value: locationListArray[0]?.id },
                },
            });
        }
    };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink',
        CurrentPageReport: ({ first, last, totalRecords }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-mainTextColor flex items-center font-[SF-Pro] text-base mr-auto">
                <span>
                    {first} - {last} of {totalRecords}
                </span>
            </div>
        ),
    };

    const getStaff = async () => {
        const data = {
            shop_id: shopId,
        };
        const result = await dispatch(staffList(data));
        if (result.type === staffList.fulfilled.toString()) {
            let staffListArray: any = getSelectBoxStaffOptionsIdAsValue(result.payload.data, 'id', 'first_name', 'last_name');
            const allOPtion = { value: null, label: 'All Staffs', id: null };
            staffListArray = [allOPtion].concat(staffListArray);
            setStaffOptionList(staffListArray);
            setSelectedStaff(staffListArray[0]);
        }
    };

    useEffect(() => {
        getLocationList();
        getStaff();
    }, []);

    const GetFullName = (row: any) => {
        const [isImageError, setIsImageError] = useState<boolean>(false);
        return (
            <div className="flex items-center">
                <figure className="NoImgName">
                    {row.user.profile_image_url && !isImageError ? (
                        <img src={row.user.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                    ) : (
                        getShortName(`${row.user.full_name}`)
                    )}
                </figure>
                <div className="text-mainTextColor">
                    <p className="text-sm font-medium mb-[2px] leading-[140%] -tracking-[0.12px]">{row.user.full_name ? formatName(row.user.full_name) : ''}</p>
                    <p className="text-xs font-normal leading-[150%] -tracking-[0.12px] text-secondaryTxtColor">
                        {moment(`${row.booking_date}' ' ${row.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY • hh:mmA')}
                    </p>
                </div>
            </div>
        );
    };

    const getServices = (row: any) => {
        const serviceNames = row.booking_services.map((item: any) => item.staff_service.service.name);
        const servicesString = serviceNames.join(', ');
        return (
            <div className="text-sm font-normal leading-[140%] -tracking-[0.12px] flex items-center">
                <div className="mr-1">{serviceNames[0].length > 10 ? `${serviceNames[0].slice(0, 10)} ...` : serviceNames[0]}</div>
                {serviceNames.length > 1 || serviceNames[0].length > 10 ? (
                    <Tooltip className="bg-mainTextColor text-xs" content={servicesString}>
                        <RxInfoCircled className="text-sm" />
                    </Tooltip>
                ) : (
                    ''
                )}
            </div>
        );
    };

    const getLocation = (row: any) => (
        <div className="text-sm font-normal leading-[140%] -tracking-[0.12px] flex items-center">
            <p className="text-sm text-primary font-medium leading-[140%] -tracking-[0.12px] flex items-center">
                <i className="mr-1 w-4 h-4">
                    <img src={mapLocationPin} alt="map-pin" className="w-4 h-4" />
                </i>
                {row.location && row.location.name.length > 10 ? `${row.location.name.slice(0, 10)} ...` : row.location.name}
            </p>
            {row.location && row.location.name.length > 10 ? (
                <Tooltip
                    className="bg-mainTextColor text-xs"
                    // style="dark"
                    content={row.location.name}
                >
                    <RxInfoCircled className="text-sm" />
                </Tooltip>
            ) : (
                ''
            )}
        </div>
    );

    const filterByStaff = (event: any) => {
        setSelectedStaff(event);
        setlazyState({
            ...lazyState,
            page: 1,
            first: 0,
            filters: {
                ...lazyState.filters,
                staff_id: { value: event.value },
            },
        });
    };

    const filterByLocation = (event: any) => {
        setSelectedLocation(event);
        setlazyState({
            ...lazyState,
            page: 1,
            first: 0,
            filters: {
                ...lazyState.filters,
                location_id: { value: event.value },
            },
        });
    };

    return (
        <div className=" flex-1 flex flex-col">
            <div className="w-full h-full flex flex-col flex-1 rounded-2xl bg-white border-[1px]  border-lineColor ">
                <div className="flex py-5 px-6 xxl:px-8 items-center">
                    <div className="flex items-center flex-1 ">
                        <h2 className="items-center text-xl leading-[140%] -tracking-[0.4px] font-bold mr-3 ">{activeTab.name}</h2>
                    </div>
                    <div className="flex justify-end items-center -mx-[6px]">
                        <div className="w-[240px] mx-[6px]">
                            <SelectBox
                                name="location"
                                id="location"
                                options={locationList}
                                value={selectedLocation}
                                onChangeFunc={filterByLocation}
                                classComp="outline-select-box "
                                allowIcon={<img src={mapLocationPin} alt="map-pin" />}
                            />
                        </div>

                        <div className="w-[158px] mx-[6px]">
                            <SelectBox
                                name="staffId"
                                id="staffId"
                                value={selectedStaff}
                                options={staffOptionList}
                                onChangeFunc={filterByStaff}
                                className="w-[324px]"
                                placeholder="Search Staff"
                                classComp="outline-select-box "
                            />
                        </div>

                        <div className="w-[164px] relative mx-[6px]">
                            <div className="w-full py-3 px-2 flex justify-center ">
                                <CustomDropdownWithDate
                                    data={timeFileterOptions}
                                    handleChangeValue={(e: any) => {
                                        setTimeFileterSelected(e);
                                    }}
                                    value={timeFileterSelected}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {!loading && transactionList.length === 0 ? (
                    <div className="flex flex-col flex-1 justify-center items-center">
                        <div className="text-center">
                            <h2 className="mb-3 text-base font-bold leading-[140%] -tracking-[0.4px]">Transactions History</h2>
                            <p className="mb-8 text-secondaryTxtColor text-xs leading-[150%] -tracking-[0.3px]">Make a customer service payment to start using the transactions histore.</p>

                            <Link className="fl-btn btn_outline_black text-base font-medium justify-center inline-flex w-[200px]" to={ROUTES.CALENDAR}>
                                Go to calendar
                            </Link>
                        </div>
                    </div>
                ) : (
                    <DataTable
                        paginatorTemplate={customPaginatorTemplate}
                        paginatorClassName="flex relative justify-end"
                        value={transactionList}
                        lazy
                        paginator={totalTransaction > lazyState.rows}
                        first={lazyState.first}
                        rows={lazyState.rows}
                        totalRecords={totalTransaction}
                        onPage={onPage}
                        onSort={onSort}
                        sortIcon={<PiCaretUpDownBold />}
                        sortOrder={lazyState.sortOrder}
                        sortField={lazyState.sortField}
                        onFilter={onFilter}
                        filters={lazyState.filters}
                        loading={loading}
                        dataKey="id"
                        className="w-full text-sm flex-1 text-mainTextColor rounded-3xl overflow-hidden fl-data-table"
                        emptyMessage="No records found."
                    >
                        <Column field="first_name" header="Name" body={GetFullName} className="text-sm font-normal text-mainTextColor" sortable></Column>
                        <Column field="service" header="Service" body={getServices} className="text-sm font-normal text-mainTextColor"></Column>
                        <Column field="location" header="Location" body={getLocation} className="text-sm font-normal text-mainTextColor"></Column>
                        <Column
                            field="professional"
                            header="Professional"
                            body={(row: any) =>
                                `${capitalizeFirstLetter(row.staff.first_name)}
                            ${row.staff.last_name ? `${row.staff.last_name.charAt(0).toUpperCase()}.` : ''}`
                            }
                            className="text-sm font-normal text-mainTextColor"
                        ></Column>
                        <Column field="payment_type" header="Payment Type" className="text-sm font-medium text-mainTextColor"></Column>
                        <Column field="subtotal " header="Sub Total" className="text-sm font-medium text-mainTextColor" body={(row: any) => `$${row.subtotal}`}></Column>
                        <Column field="tips" header="Tips" className="text-sm font-medium text-mainTextColor" body={(row: any) => row.tips && JSON.parse(row.tips).join(', ')}></Column>
                        <Column field="total" header="Total" className="text-sm font-medium text-mainTextColor" body={(row: any) => (row.total ? `${formateAmount(row.total)}` : '')} sortable></Column>
                    </DataTable>
                )}
            </div>
        </div>
    );
};

export default TransactionHistory;
