export const NOTIFICATION_TABS = {
    GENERAL: 'general',
    APPOINTMENT: 'appointment',
};

export interface INotificationSettings {
    push: boolean;
    email: boolean;
    sms: boolean;
}
export interface IFormData {
    daily_checkout: INotificationSettings;
    new_user: INotificationSettings;
    monthly_statement: INotificationSettings;
    commission_statement: INotificationSettings;
    rent_statement: INotificationSettings;
    staff_completed_registration: INotificationSettings;
    cancellations: INotificationSettings;
    cancellation_past_limit: INotificationSettings;
    reschedule: INotificationSettings;
    reschedule_confirmation: INotificationSettings;
    user_update_booking: INotificationSettings;
    staff_update_booking: INotificationSettings;
    update_booking: INotificationSettings;
    new_booking: INotificationSettings;
    new_booking_confirmation: INotificationSettings;
    new_user_booking: INotificationSettings;
    new_user_booking_referral: INotificationSettings;
    create_waitlist: INotificationSettings;
    login: INotificationSettings;
    enables_automated_rent_collection: INotificationSettings;
    rent_to_commission: INotificationSettings;
    commission_to_rent: INotificationSettings;
}
