import React from 'react';
import { useTranslation } from 'react-i18next';

const SChart = () => {
    const { t } = useTranslation();
    return (
        <div className={`custom-loading graph-loading h-full w-full flex justify-center items-center object-cover`}>
            <div className="flex flex-col w-full gap-[38px] relative">
                <div className="custom-loading h-[1px]"></div>
                <div className="custom-loading h-[1px]"></div>
                <div className="custom-loading h-[1px]"></div>
                <div className="custom-loading h-[1px]"></div>
                <div className="custom-loading h-[1px]"></div>
                <div className="custom-loading h-[1px]"></div>
                <div className="absolute w-full  h-full custom-svgs">
                    <svg width="100%" height="100%" id="svg" viewBox="0 0 1920 226" xmlns="http://www.w3.org/2000/svg" className="transition custom-loading duration-300 ease-in-out delay-150">
                        <path
                            d="M 0,400 L 0,150 C 60,130 120,110 180,120 C 240,130 300,160 360,180 C 420,200 480,210 540,200 C 600,190 660,150 720,140 C 780,130 840,140 900,160 C 960,180 1020,200 1080,190 C 1140,180 1200,140 1260,120 C 1320,100 1380,160 1440,140 C 1500,120 1560,100 1620,120 C 1680,140 1740,200 1800,180 C 1860,160 1920,226 1920,226 L 1920,350 L 1920,350 Z"
                            stroke="none"
                            fill="#d1d5db"
                            className="transition-all duration-300 ease-in-out delay-150 path-0"
                        ></path>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default SChart;
