import { createSlice } from '@reduxjs/toolkit';
import { IInitialState } from './Notification.interface';
import { RootState } from 'src/redux/store';

const initialState: IInitialState = {
    data: {
        notificationToken: null,
    },
    loading: false,
    error: null,
};

export const NotificationSlice = createSlice({
    name: 'shop/notification',
    initialState,
    reducers: {
        setNotificationToken: (state, { payload }) => {
            state.data.notificationToken = payload;
        },
    },
});

export const { setNotificationToken } = NotificationSlice.actions;
export const notificationInfo = (state: RootState) => state.Notification.data;
