import React, { useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.module.css';
import { Calendar } from '@untitled-ui/icons-react/build/cjs';

const RangeDateSelect = React.forwardRef(
    (
        { datePickerRef, label, labelClassName, classInput, name, onChange, selected, minDate, startDate, endDate, selectsRange, labelEndIcon, labelIconClassName, className, ...props }: any,
        ref: React.ForwardedRef<any>,
    ) => (
        <>
            <div className={`flex items-center gap-[10px] mb-[6px] ${className}`}>
                <label htmlFor={name} className={`fl-field-title-label leading-[18px] mb-0 ${labelClassName}`}>
                    {label}
                    <span className="asterisk">*</span>
                </label>
                {/* {switchValue !== undefined && handleSwitchOnChange && <Switch className="ml-2" onChange={handleSwitchChange} isChecked={switchValue} />} */}
            </div>
            <div className={`input-group relative date-field-block w-full ${classInput}`}>
                <div className={`input-group-prepend text-textSecondary ${labelIconClassName}`}>
                    <span className="text">
                        <Calendar width={16} />
                    </span>
                </div>

                <ReactDatePicker
                    // customInput={<CustomDatepickerInput />}
                    onChange={onChange}
                    selected={selected}
                    minDate={minDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange={selectsRange}
                    className="datepicker_control pl-0"
                />
                {labelEndIcon && (
                    <div
                        className="input-group-append"
                        onClick={() => {
                            if (datePickerRef.current) {
                                datePickerRef.current.setOpen(true);
                            }
                        }}
                    >
                        <span className="text">{labelEndIcon}</span>
                    </div>
                )}
            </div>
        </>
    ),
);

export default RangeDateSelect;
