import { IWorkingHoursValue, InitWorkingHours } from 'src/app/Location/Location.interface';
import { TemplateType } from '../TemplateSettings.interface';

export interface ILocationInfoData {
    is_title: boolean;
    title: string;
    is_contact: boolean;
    contact: string;
    country_code: string;
    is_image: boolean;
    image: { name: string; file: File; url: string } | null;
    hours: IWorkingHoursValue[] | null;
}
export interface ILocationData {
    status: boolean;
    locations: ILocationInfoData[] | null;
}
export interface ExistingFormData {
    [key: string]: any;
}
export const InitLocationData: ILocationData = {
    status: false,
    locations: [],
};
export const InitAppendLocationData: ILocationInfoData = {
    is_title: false,
    title: '',
    is_contact: false,
    contact: '',
    country_code: '',
    is_image: false,
    image: null,
    hours: InitWorkingHours,
};
export interface LocationProps {
    template: TemplateType;
}
