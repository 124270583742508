import React, { useMemo, useState } from 'react';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { INotification } from './Notification.interface';
import CustomButton from 'src/components/CustomButton';
import NotificationGeneral from './NotificationGeneral';
import NotificationAppointment from './NotificationAppointment';
import NotificationGeneralAll from './NotificationGeneralAll';
import { DataTable } from 'primereact/datatable';
import { axiosPatch } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { DoubleCheck } from 'src/theme/Images';
import NotificationStatements from './NotificationStatements';
import NoDataMessage from 'src/components/NoDataMessage';
import { Inbox01, SearchLg } from '@untitled-ui/icons-react/build/cjs';

const NotificationNew = ({ type, notifications, notificationsRecords, setlazyState, lazyState, isLoading, fetchNotifications, viewDetail, filters }: any) => {
    const { t } = useTranslation();
    const onPage = (event: any) => {
        setlazyState(event);
    };

    const [isMarkingAsRead, setIsMarkingAsRead] = useState(false);

    const checkNotificationStatus =
        (payloadType: string, data: any = null) =>
        async () => {
            if (data && data.is_read) {
                return;
            }
            setIsMarkingAsRead(true);
            const payload: any = {
                type: payloadType,
                ...(data && { id: data.id }),
            };

            await axiosPatch(API.NOTIFICATION.READ, payload)
                .then(async (response) => {
                    fetchNotifications();
                    return;
                })
                .finally(() => setIsMarkingAsRead(false));
        };

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink',
        PrevPageLink: ({ onClick }: any) =>
            !isLoading[type] &&
            notificationsRecords[type] > lazyState.rows && (
                <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                    {t('Previous')}
                </CustomButton>
            ),
        NextPageLink: ({ onClick }: any) =>
            notificationsRecords[type] > lazyState.rows && (
                <CustomButton secondary onClick={onClick}>
                    {t('Next')}
                </CustomButton>
            ),
        CurrentPageReport: ({ currentPage, totalPages }: { currentPage: number; totalPages: number }) => (
            <div className="text-gray-700 flex items-center text-sm font-medium mr-auto gap-3">
                <CustomButton isLoading={isMarkingAsRead} disabled={isMarkingAsRead} secondary onClick={checkNotificationStatus('all')}>
                    <DoubleCheck />
                    {t('Mark as all read')}
                </CustomButton>
                {!isLoading[type] && notificationsRecords[type] > lazyState.rows && (
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                )}
            </div>
        ),
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                name: 'loading',
                description: 'loading',
                created_at: '2024-09-13T11:23:34.000000Z',
                is_read: true,
                type: 'general',
            })),
        [lazyState],
    );

    const renderColumnBody = (rowData: INotification, index: any) => {
        if (type === 'all') {
            return (
                <NotificationGeneralAll
                    notificationRecord={rowData}
                    isLoading={isLoading}
                    viewDetail={viewDetail}
                    checkNotificationStatus={checkNotificationStatus}
                    isMarkingAsRead={isMarkingAsRead}
                />
            );
        } else if (type === 'general') {
            return (
                <NotificationGeneral
                    key={`${rowData.name}_${rowData.id}`}
                    notificationRecord={rowData}
                    isLoading={isLoading}
                    viewDetail={viewDetail}
                    checkNotificationStatus={checkNotificationStatus}
                    isMarkingAsRead={isMarkingAsRead}
                />
            );
        } else if (type === 'appointment') {
            return <NotificationAppointment key={`${rowData.name}_${rowData.id}`} notificationRecord={rowData} isLoading={isLoading} />;
        } else if (type === 'statement') {
            return <NotificationStatements key={`${rowData.name}_${rowData.id}`} notificationRecord={rowData} isLoading={isLoading} />;
        }
        return null;
    };
    return (
        <div
            className={`flex flex-col flex-1 relative overflow-y-auto overflow-hidden scrollbar-hide notification_modal w-full rounded-b-xl  ${
                notifications[type].length === 0 ? 'datatable-full-height ' : ''
            }`}
        >
            <DataTable
                scrollable
                scrollHeight="flex"
                value={isLoading ? skeletons : notifications[type]}
                paginator
                lazy
                first={lazyState.first}
                rows={lazyState.rows}
                paginatorTemplate={customPaginatorTemplate}
                paginatorClassName="  !bottom-0 !bg-white !z-10 !w-full !border-t !border-borderSecondary py-3 mt-1.5 rounded-b-xl"
                totalRecords={notificationsRecords[type]}
                onPage={onPage}
                sortOrder={lazyState.sortOrder}
                filters={lazyState.filters}
                dataKey="id"
                className=" text-sm flex-1 text-mainTextColor overflow-hidden   absolute w-full "
                showHeaders={false}
                onRowClick={(e) => checkNotificationStatus('single', e.data)()}
                emptyMessage={
                    <div className="flex flex-col mb-[50px] flex-1 justify-center items-center ">
                        <NoDataMessage
                            title={`${filters.location_id.value ? t('No notifications found.') : t('No notifications added.')}`}
                            description={`${
                                filters.location_id.value
                                    ? t('Try adjusting your filters to view more specific notifications.')
                                    : t('You have no notifications. Check back later for updates or new messages.')
                            }`}
                            iconComponent={filters.location_id.value ? <SearchLg className="text-gray-700" /> : <Inbox01 className="text-gray-700" />}
                        />
                    </div>
                }
            >
                <Column body={renderColumnBody} />
            </DataTable>
        </div>
    );
};

export default NotificationNew;
