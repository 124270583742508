export const IMAGES = 'images/';

export const s3Path = {
    ADMIN_PROFILE: `${IMAGES}admin_profile/`,
    APP_LOGOS: `${IMAGES}app_logos/`,
    BUSINESS_LOGOS: `${IMAGES}business_logos/`,
    GROUP_ICON: `${IMAGES}group_icon/`,
    LOCATION_LOGOS: `${IMAGES}location_logos/`,
    LOCATION_PROFILES: `${IMAGES}location_profiles/`,
    STAFF_GALLERY: `${IMAGES}staff_gallery/`,
    SHOP_FAVICONS: `${IMAGES}shop_favicons/`,
    USER_PROFILE: `${IMAGES}user_profile/`,
    SHOP_ABOUT: `${IMAGES}shop_about/`,
    SHOP_GALLERY: `${IMAGES}shop_gallery/`,
    SHOP_TESTIMONIAL: `${IMAGES}shop_testimonials/`,
    SHOP_ACADEMY: `${IMAGES}shop_academy/`,
    SHOP_PRODUCT: `${IMAGES}shop_products/`,
    SHOP_HERO_BACKGROUND: `${IMAGES}shop_hero_background/`,
};
