import React, { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import { me, userMe } from 'src/redux/services/common/Common.slice';
import CustomButton from 'src/components/CustomButton';
import { IFormData, IFormDataRequest } from './Brand.interface';
import { checkFileTypeValidation, convertBase64ToFile } from 'src/utils/global-functions';
import { GLOBALVARIABLE } from 'src/utils/global-variables';
import { s3Upload } from 'src/utils/s3Operations';
import CropperModal from 'src/components/CropperModal/CropperModal';
import InputWithLabel from 'src/components/InputWithLabel';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { STAFF_MEMBERS } from 'src/constants/common';
import { useRolePermission } from 'src/hooks/useRolePermission';
import Tags from '@yaireo/tagify/dist/react.tagify';
import '@yaireo/tagify/dist/tagify.css';
import { brand } from './Brand.slice';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { Edit, GoogleIcon, InstagramColor, SocialIcon, Upload, Url } from 'src/theme/Images';
import { BackgroundDesign } from 'src/theme/Images';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';
import useImageOrientation from 'src/hooks/useImageOrientation';

type FieldKey = 'logo_image_name';

const Brand: FC<any> = ({ setStep }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const user = useAppSelector(userMe);
    const shopId = user.shops[0].id;
    const shopInfo = user.shops[0];
    const [upload, setUpload] = useState(false);
    const completeAccountStep = user.shop_staff[0].account_step.complete_account;
    const imageUrl = completeAccountStep && user.logo_image_name ? user.logo_image_name : null;
    const { getRoleByShop } = useRolePermission();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [cropData, setCropData] = useState<any>(imageUrl || '');
    const [image, setImage] = useState<any>(imageUrl || '');
    const [file, setFile] = useState<any>({ convertedFile: '', filename: '' });
    const [isLoading, setIsLoading] = useState(false);
    const schema = Yup.object({
        logo_image_name: Yup.string().required(t('This field is required')),
        business_name: Yup.string().required(t('This field is required')),
        slug: Yup.string()
            .required(t('This field is required'))
            .matches(/^[a-zA-Z0-9-_]+$/, t('Special characters or space not allowed')),
        google_review: Yup.string(),
        facebook_page: Yup.string(),
        instagram_page: Yup.string(),
        admin_emails: Yup.array()
            .of(
                Yup.object().shape({
                    value: Yup.string().matches(emailRegex, t('Invalid email format')).required(t('Email is required')),
                }),
            )
            .notRequired(),
        staff_size: Yup.string(),
    }).required();

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            logo_image_name: shopInfo.logo_image_name ?? '',
            business_name: shopInfo.business_name ?? '',
            slug: shopInfo.slug ?? '',
            staff_size: shopInfo.staff_size ?? STAFF_MEMBERS[0].value,
            google_review: shopInfo.google_review ?? '',
            facebook_page: shopInfo.facebook_page ?? '',
            instagram_page: shopInfo.instagram_page ?? '',
            admin_emails: user.shop_admin_invites.map((item: any) => item.email) ?? [],
        },
    });

    useEffect(() => {}, []);
    const onChangeCrop = (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.accountImageSize);
        if (result) {
            clearErrors('logo_image_name');
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage('');
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            setError('logo_image_name' as 'logo_image_name', {
                type: 'manual',
                message: message,
            });
        }
    };

    const handleChange = async (data: IFormData) => {
        setIsLoading(true);

        if (file.convertedFile && file?.filename) {
            const imgUploadResult: any = await s3Upload(file?.convertedFile, `images/business_logos/${file?.filename}`);
            if (imgUploadResult.status === 201) {
                /* const url = imgUploadResult.data.Location.replace(
                    process.env.REACT_APP_AWS_UPLOAD_URL,
                    process.env.REACT_APP_PUBLIC_URL,
                ); */
                const updatedData = {
                    ...data,
                    logo_image_name: file.filename,
                };
                submitForm(updatedData);
            }
        } else {
            submitForm(data);
        }
    };
    const imageUpload = (data: any) => {
        const fileInfo = convertBase64ToFile(data);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('logo_image_name', fileInfo.filename);
        }
    };

    const submitForm = async (data: IFormData) => {
        let adminEmailsPayload = [];
        if (Array.isArray(data.admin_emails)) {
            adminEmailsPayload = data.admin_emails.map((item: any) => item.value);
        }
        const cread: IFormDataRequest = {
            logo_image_name: data.logo_image_name,
            business_name: data.business_name,
            slug: data.slug,
            staff_size: data.staff_size,
            shop_id: shopId,
            google_review: data.google_review,
            facebook_page: data.facebook_page,
            instagram_page: data.instagram_page,
            admin_emails: adminEmailsPayload,
        };

        const result = await dispatch(brand(cread));
        if (result.type === brand.fulfilled.toString()) {
            await dispatch(me());
            setStep(4);
            setIsLoading(false);
        }

        if (result.type === brand.rejected.toString()) {
            const response = result.payload;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                }
                setIsLoading(false);

                return;
            }
            toast.error(response.message);
        }
        setIsLoading(false);
    };

    useFormErrorFocus<IFormData>({ errors });

    const { isImageHorizontal, isHorizontal } = useImageOrientation();

    useEffect(() => {
        if (shopInfo.logo_image_url) {
            isImageHorizontal(shopInfo.logo_image_url);
        }
    }, [shopInfo]);

    return (
        <>
            <div className="w-full flex justify-center relative">
                <img src={BackgroundDesign} alt="" className="absolute top-0 z-[-1]" />
                <div className="w-[600px] mt-10 pb-5">
                    <div className="flex flex-col rounded-md items-center mb-4">
                        <div className="p-[14px] bg-white border border-btnborder rounded-xl">
                            <Edit />
                        </div>
                    </div>
                    <div className="text-center">
                        <h3 className="fwpo_heading_title">{t('Configure your brand')}</h3>
                        <p className="font-normal leading-[22.4px] mt-[2px] text-base text-secondaryTxtColor">{t('Tell us a bit about your business')}.</p>
                    </div>
                    <div className="mt-6">
                        <form onSubmit={handleSubmit(handleChange)} className="h-full w-full">
                            <div className="flex flex-col">
                                <div className="mb-6 text-center">
                                    <div
                                        className={`mb-4 rounded-lg bg-gray-50 mx-auto border-2 border-dashed border-gray-300 flex justify-center items-center relative   ${
                                            errors.logo_image_name && 'is-invalid'
                                        } ${isHorizontal ? 'w-[80px] min-w-[80px] max-w-[80px] h-[56px] object-contain' : ' w-[56px] min-w-[56px] max-w-[56px] h-[56px] object-contain'}`}
                                    >
                                        {upload && (
                                            <CropperModal
                                                imageUpload={imageUpload}
                                                imageUrl={image}
                                                setUpload={setUpload}
                                                setCropData={setCropData}
                                                defaultCropType="Square"
                                                title={t('Crop header image')}
                                                description={t('Upload a 1600 x 480px image for best results.')}
                                            />
                                        )}
                                        <div className="bg-contentBackground w-full">
                                            <div id="logo_image_name" className="w-[full]">
                                                <div className="w-auto">
                                                    {!cropData ? (
                                                        <div>
                                                            <div className="relative overflow-hidden">
                                                                <input type="file" id="fileInput" className="upload-pic-input" onChange={onChangeCrop} />
                                                                <label htmlFor="fileInput" className="upload-pic-label h-full w-full rounded-md relative flex justify-center items-center">
                                                                    {''}
                                                                    <div className="flex justify-center items-center">
                                                                        <figure className="rounded-md font-medium text-primary flex justify-center items-center">
                                                                            {shopInfo.logo_image_url ? (
                                                                                <img
                                                                                    src={shopInfo.logo_image_url}
                                                                                    alt="client"
                                                                                    className={`w-full h-full rounded-md ${
                                                                                        isHorizontal
                                                                                            ? 'w-[80px] min-w-[80px] max-w-[80px] h-[80px] object-contain'
                                                                                            : ' w-[56px] min-w-[56px] max-w-[56px] h-[56px] object-contain'
                                                                                    }`}
                                                                                />
                                                                            ) : (
                                                                                <div className="">
                                                                                    <Upload className="w-7 h-7" />
                                                                                </div>
                                                                            )}
                                                                        </figure>
                                                                    </div>
                                                                    {/* <div className="text-center">
                                                                    <Upload width={20} height={20} />
                                                                    </div> */}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className={``}>
                                                            <div className="w-full h-full rounded-md relative overflow-hidden">
                                                                <img src={cropData} alt="" className="w-full h-full" />
                                                            </div>
                                                            <div className="  absolute top-0 left-0 w-full h-full  flex justify-center items-center pr-[8px] ">
                                                                {/* <div className="bg-inputBg p-2 w-14 h-14 rounded-full flex justify-center items-center">
                                                                    <Cloud width={20} height={20} />
                                                                </div> */}
                                                            </div>
                                                            <label htmlFor="fileInput" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                                                {''}
                                                                <input
                                                                    type="file"
                                                                    id="fileInput"
                                                                    className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr"
                                                                    style={{
                                                                        visibility: 'hidden',
                                                                    }}
                                                                    onChange={onChangeCrop}
                                                                />
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-base text-gray-700 font-semibold mb-[2px]">{t('Upload your brand logo')}</p>
                                        <p className="text-base text-gray-600 font-normal m-0">{t('Upload JPG or PNG file')}</p>
                                    </div>
                                    {/* <p className="text-error m-0">{errors.logo_image_name && errors.logo_image_name.message}</p> */}
                                </div>
                            </div>
                            <div className="flex w-full gap-x-8 justify-center mb-4">
                                <div className="w-[600px] flex gap-x-4">
                                    <div className="w-1/2">
                                        <Controller
                                            name="business_name"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        id="business_name"
                                                        label="Brand name"
                                                        placeholder="Enter brand name"
                                                        onChange={onChange}
                                                        required
                                                        value={value}
                                                        inputControlClassName="h-[36px]"
                                                        name="business_name"
                                                        isFocus={true}
                                                        error={!!errors.business_name}
                                                    />
                                                    {/* {errors && errors?.business_name && <p className="text-error">{errors?.business_name?.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="w-1/2">
                                        <Controller
                                            name="slug"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <div className="w-full">
                                                    <div className="relative rounded-md">
                                                        <InputWithLabel
                                                            id="slug"
                                                            label="Website URL"
                                                            placeholder="Enter Website URL"
                                                            onChange={onChange}
                                                            value={value}
                                                            required
                                                            labelIcon={<Url />}
                                                            inputControlClassName="pl-1 h-[36px]"
                                                            name="slug"
                                                            isFocus={true}
                                                            error={!!errors.slug}
                                                            labelEndIcon={'.getflair.ca'}
                                                        />
                                                        {/* {errors && errors?.slug && <p className="text-error">{errors?.slug?.message}</p>} */}
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            {getRoleByShop(shopId) && getRoleByShop(shopId)?.name === 'owner' && (
                                <div className="flex flex-col  space-y-4 w-full justify-center items-center mb-4">
                                    <div className="relative">
                                        <Controller
                                            name="staff_size"
                                            control={control}
                                            render={({ field: { onChange, value } }: any) => (
                                                <div id="staff_size" className="w-[600px]">
                                                    <SelectBox
                                                        name="staff_size"
                                                        id="handlstaffSize"
                                                        label="Team size"
                                                        className="h-[32px]"
                                                        errorText={errors.staff_size ? true : undefined}
                                                        options={STAFF_MEMBERS}
                                                        value={STAFF_MEMBERS.find((item: any) => item.value === value)}
                                                        onChangeFunc={(selectedOption: { value: string; label: string }) => onChange(selectedOption?.value || STAFF_MEMBERS[0].value)}
                                                        placeholder="Select Staff"
                                                        isClearable={false}
                                                    />
                                                    {errors.staff_size && <p className="text-error">{errors.staff_size.message}</p>}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="flex flex-col w-full justify-center items-center">
                                <div className="relative w-full  pb-4">
                                    <Controller
                                        name="instagram_page"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    label="Brand socials"
                                                    placeholder="Username"
                                                    onChange={onChange}
                                                    value={value}
                                                    name="instagram_page"
                                                    isFocus={true}
                                                    labelIcon={
                                                        <div className="flex flex-row gap-1 items-center">
                                                            <InstagramColor />
                                                            <p>instagram.com/</p>
                                                        </div>
                                                    }
                                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                                    inputControlClassName="pl-3"
                                                    error={!!errors.instagram_page}
                                                />

                                                {errors && errors?.instagram_page && <p className="text-error">{errors?.instagram_page?.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full justify-center items-center">
                                <div className="relative w-full  pb-4">
                                    <Controller
                                        name="facebook_page"
                                        control={control}
                                        render={({ field: { onChange, value } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    label=""
                                                    placeholder="Username or page "
                                                    onChange={onChange}
                                                    value={value}
                                                    name="facebook_page"
                                                    isFocus={true}
                                                    labelIcon={
                                                        <div className="flex flex-row gap-1 items-center">
                                                            <SocialIcon />
                                                            <p>facebook.com/</p>
                                                        </div>
                                                    }
                                                    labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                                    inputControlClassName="pl-3"
                                                    error={!!errors.facebook_page}
                                                />
                                                {errors && errors?.facebook_page && <p className="text-error">{errors?.facebook_page?.message}</p>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col w-full justify-center items-center">
                                <Controller
                                    name="google_review"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div className="relative w-full brand_social pb-4">
                                            <InputWithLabel
                                                label=""
                                                placeholder="Google review page"
                                                onChange={onChange}
                                                value={value}
                                                name="google_review"
                                                isFocus={true}
                                                labelIcon={
                                                    <div className="flex flex-row gap-1 items-center">
                                                        <GoogleIcon />
                                                        <p>google.com/</p>
                                                    </div>
                                                }
                                                labelIconClassName="border-r min-w-[140px] border-secondaryBorder text-xs text-gray-500"
                                                inputControlClassName="pl-3"
                                                error={!!errors.google_review}
                                            />
                                            {errors && errors?.google_review && <p className="text-error">{errors?.google_review?.message}</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col w-full justify-center items-center">
                                <Controller
                                    name="admin_emails"
                                    control={control}
                                    render={({ field: { onChange, value } }: any) => (
                                        <div id="admin_emails" className="w-[600px]">
                                            <label htmlFor="additionalAdminUsers" className="fl-field-title-label">
                                                Invite additional admin users
                                            </label>
                                            <Tags
                                                placeholder="Add admin emails"
                                                settings={{
                                                    maxTags: 4,
                                                    // pattern: emailRegex,
                                                    dropdown: {
                                                        enabled: 0, // always show suggestions dropdown
                                                    },
                                                    callbacks: {
                                                        add: (e) => {
                                                            onChange(e.detail.tagify.value);
                                                        },
                                                        remove: (e) => {
                                                            onChange(e.detail.tagify.value);
                                                        },
                                                    },
                                                }}
                                                value={value}
                                                className="rounded-lg h-[36px]"
                                                /* onChange={(e: any) => {
                                                    const newResult = e.target.tagify.value;
                                                    if (Array.isArray(newResult)) {
                                                        const finalResult = newResult.map((item: any) => item.value);
                                                        onChange(finalResult);
                                                    }
                                                }} */
                                            />
                                            {errors.admin_emails && <p className="text-error">Invalid email format</p>}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex w-full justify-center mt-5">
                                <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" size="w-full" className="fl-btn">
                                    {t('Continue')}
                                </CustomButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Brand;
