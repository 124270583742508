import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosPatch } from 'src/utils/requestClient';
import { ErrorType, IInitialState } from './Brand.interface';
import { RootState } from 'src/redux/store';
import { errorCode } from 'src/constants/errorCode';

const initialState: IInitialState = {
    data: null,
    loading: false,
    error: null,
};
// /shop/{shop_id}/profile
export const brand = createAsyncThunk('/shop/brand', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPatch(API.USER.BRAND, payload, { shop_id: payload.shop_id });
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const accountSlice = createSlice({
    name: 'shop/details',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(brand.pending, (state) => {
                state.loading = true;
            })
            .addCase(brand.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = {
                    ...state.data,
                    logo_image_name: payload.data.app_logo_image_name,
                    business_name: payload.data.business_name,
                    slug: payload.data.slug,
                    // staff_size: payload.data.staff_size,
                    google_review: payload.data.google_review,
                    facebook_page: payload.data.facebook_page,
                    instagram_page: payload.data.instagram_page,
                    admin_emails: payload.data.admin_emails,
                };
            })
            .addCase(brand.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});
export const { reducer: accountReducer } = accountSlice;

export const selectAccountData = (state: RootState) => state.AccountDetails.data;
export const selectAccountLoading = (state: RootState) => state.AccountDetails.loading;
export const selectAccountError = (state: RootState) => state.AccountDetails.error;
