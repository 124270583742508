import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getShortName } from 'src/utils/global-functions';
import { Trash02, Edit05, Plus, SearchLg, FileSearch02 } from '@untitled-ui/icons-react/build/cjs';
import { axiosDelete, axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import DeletePopupModal from 'src/components/DeletePopupModal/DeletePopupModal';
import NoteForm from './NoteForm';
import moment from 'moment';
import { noteInitAction } from './Client.interface';
import SNote from '../Skeleton/Client/SNote';
import NoDataMessage from 'src/components/NoDataMessage';
import SearchBar from 'src/components/SearchBar/SearchBar';
import CustomButton from 'src/components/CustomButton';

const Notes = ({ client }: any) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMap, setErrorMap] = useState(new Map());
    const [action, setAction] = useState(noteInitAction);
    const [clientNotesDetail, setClientNotesDetail] = useState<any>([]);
    const [filteredNotes, setFilteredNotes] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        getNoteList();
    }, []);

    const getNoteList = async () => {
        setIsLoading(true);
        await axiosGet(API.CLIENT.NOTE, {
            shop_id: shop.id,
            client_id: client.id,
        })
            .then((response) => {
                setClientNotesDetail(response.data.data);
                setFilteredNotes(response.data.data);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (searchValue) {
            const list = clientNotesDetail.filter((obj: any) => {
                const name = obj.description.toLowerCase();
                const searchParam = searchValue.toLowerCase();
                return name.includes(searchParam);
            });
            setFilteredNotes(list);
        } else {
            setFilteredNotes(clientNotesDetail);
        }
    }, [searchValue, clientNotesDetail]);

    const handleModalClose = useCallback(
        (type: boolean = false) =>
            async () => {
                if (type) {
                    if (action.noteData && action.noteData.id && action.delete) {
                        setIsLoading(true);
                        const params = {
                            shop_id: shop.id,
                            client_id: client.id,
                            id: action.noteData.id,
                        };
                        await axiosDelete(API.CLIENT.NOTEDELETE, {}, params)
                            .then(() => getNoteList())
                            .finally(() => setIsLoading(false));
                    }
                }
                setAction(noteInitAction);
            },
        [action],
    );

    const handleNote =
        (type: string, noteData: any = null) =>
        () => {
            setAction((old) => ({ ...old, [type]: true, noteData }));
        };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    return (
        <div className="mt-6">
            <div className="flex justify-between items-center mb-4">
                <h2 className="view-table-title">{t('Client Notes')}</h2>
                <CustomButton className="!text-sm text-btnBg font-semibold flex items-center gap-1.5 h-[20px] shadow-none pr-0.5" onClick={handleNote('create')} icon={<Plus className="w-4" />}>
                    Add a note
                </CustomButton>
            </div>
            <SearchBar labelIconClassName="text-gray-500" placeholder={t('Search')} onChange={handleSearch} className="form-control-md " />
            {!isLoading && filteredNotes.length === 0 ? (
                <div className="border border-gray-200 rounded-xl p-4 mt-5 min-h-[380px] flex-1 flex justify-center items-center">
                    <NoDataMessage
                        title={`${searchValue ? 'No notes found.' : 'No notes added.'}`}
                        description={`${searchValue ? 'Try adjusting your filters or add new notes.' : 'Add a notes, to start using the notes collection.'}`}
                        iconComponent={searchValue ? <SearchLg className="text-gray-700" /> : <FileSearch02 className="text-gray-700" />}
                    />
                </div>
            ) : isLoading ? (
                <SNote />
            ) : (
                <>
                    {filteredNotes &&
                        filteredNotes.map((clientNote: any, index: any) => (
                            <div key={index} className="flex items-start border border-gray-200 flex-col mt-4 p-4 bg-white rounded-lg">
                                <div className="flex w-full mb-3">
                                    <figure className="NoImgName">
                                        {clientNote.shop_admin_id ? (
                                            clientNote.shop_admin.profile_image_url && !errorMap.get(clientNote.shop_admin_id) ? (
                                                <img
                                                    src={clientNote.shop_admin.profile_image_url}
                                                    alt="client"
                                                    className="w-full h-full object-cover"
                                                    onError={() => {
                                                        setErrorMap((prevErrorMap) => new Map(prevErrorMap.set(clientNote.shop_admin_id, true)));
                                                    }}
                                                />
                                            ) : (
                                                getShortName(`${clientNote.shop_admin.first_name} ${clientNote.shop_admin.last_name}`)
                                            )
                                        ) : clientNote.staff.profile_image_url && !errorMap.get(clientNote.staff_id) ? (
                                            <img
                                                src={clientNote.staff.profile_image_url}
                                                alt="client"
                                                className="w-full h-full object-cover"
                                                onError={() => {
                                                    setErrorMap((prevErrorMap) => new Map(prevErrorMap.set(clientNote.staff_id, true)));
                                                }}
                                            />
                                        ) : (
                                            getShortName(`${clientNote.staff.first_name} ${clientNote.staff.last_name}`)
                                        )}
                                    </figure>
                                    <div className="flex-grow">
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <h3 className="font-semibold text-gray-900">
                                                    {clientNote.shop_admin_id
                                                        ? `${clientNote.shop_admin.first_name} ${clientNote.shop_admin.last_name}`
                                                        : `${clientNote.staff.first_name} ${clientNote.staff.last_name}`}
                                                </h3>
                                                <p className="text-xs leading-[18px] font-normal text-gray-600">
                                                    {clientNote.created_at ? moment(clientNote.created_at).format('DD MMM YYYY - hh:mmA') : '-'}
                                                </p>
                                            </div>
                                            <div className="flex items-center gap-3">
                                                <button onClick={handleNote('delete', clientNote)} className="border border-gray-300 h-8 flex justify-center items-center w-8 rounded-lg">
                                                    <Trash02 width={18} className=" ml-1.5 mx-auto text-gray-500" />
                                                </button>
                                                <button onClick={handleNote('update', clientNote)} className="border border-gray-300 h-8 flex justify-center items-center w-8 rounded-lg">
                                                    <Edit05 width={18} className=" ml-1.5 mx-auto text-gray-500" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-gray-600 font-normal leading-5 text-sm">{clientNote.description}</p>
                            </div>
                        ))}
                </>
            )}

            {action.delete && (
                <DeletePopupModal
                    isLoading={isLoading}
                    onClose={handleModalClose}
                    size="w-[400px]"
                    title={t('Delete Note')}
                    description={t('Are you sure you want to delete this notes? This action cannot be undone.')}
                />
            )}
            {(action.create || action.update) && (
                <NoteForm handleClose={handleModalClose()} noteData={action.noteData && action.noteData.id ? action.noteData : null} setAction={setAction} client={client} getNoteList={getNoteList} />
            )}
        </div>
    );
};

export default Notes;
