import { useEffect } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { get, isArray, isObject } from 'lodash';

interface UseFormErrorFocusProps<T extends FieldValues> {
    errors: FieldErrors<T>;
    formSectionName?: string;
}

function useFormErrorFocus<T extends FieldValues>({ errors, formSectionName }: UseFormErrorFocusProps<T>) {
    useEffect(() => {
        const firstErrorField = Object.keys(errors).reduce<string | null>((field, key) => {
            const errorKey = key as keyof T;
            return errors[errorKey] ? key : field;
        }, null);
        if (firstErrorField) {
            const errorObject = get(errors, firstErrorField);
            const errorElement = findErrorElement(errorObject, firstErrorField, formSectionName);
            if (errorElement) {
                scrollAndFocusElement(errorElement);
            }
        }
    }, [errors, formSectionName]);
}

function findErrorElement(errorObject: any, basePath: string, formSectionName: string | undefined): HTMLElement | null {
    if (isArray(errorObject)) {
        for (let i = 0; i < errorObject.length; i++) {
            if (isObject(errorObject[i])) {
                const firstKey = Object.keys(errorObject[i])[0];
                if (firstKey) {
                    const nestedArray = errorObject[i][firstKey];
                    let elementId: any;
                    if (isArray(nestedArray)) {
                        for (let j = 0; j < nestedArray.length; j++) {
                            if (isObject(nestedArray[j])) {
                                const nestedArrayFirstKey = Object.keys(nestedArray[j])[0];
                                elementId = `${basePath}.${i}.${firstKey}.${j}.${nestedArrayFirstKey}`;
                            }
                        }
                    } else {
                        elementId = `${basePath}.${i}.${firstKey}`;
                    }
                    const element = document.getElementById(elementId);
                    if (element) return element;
                }
            }
        }
    } else {
        if (formSectionName) {
            return document.getElementById(`${formSectionName}-${basePath}`);
        } else {
            return document.getElementById(basePath);
        }
    }
    return null;
}

function scrollAndFocusElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
        setTimeout(() => {
            element.focus({ preventScroll: true });
        }, 300);
    }
}

export default useFormErrorFocus;
