import React from 'react';
import { useTranslation } from 'react-i18next';
import PopupModal from 'src/components/PopupModal';
import { getCardTypeImage, getShortName } from 'src/utils/global-functions';
import moment from 'moment';
import { Wallet02 } from '@untitled-ui/icons-react/build/cjs';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { GoDotFill } from 'react-icons/go';

const BookingDetails = ({ handleClose, bookingData }: any) => {
    const { t } = useTranslation();
    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <Wallet02 className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{t('Appointment information')}</p>
                <span className="text-xs font-normal leading-[18px]">{t('View detailed appointment information.')}</span>
            </div>
        </div>
    );

    const navigate = useNavigate();

    const handleNext = () => {
        navigate(ROUTES.CALENDAR);
    };

    return (
        <>
            <PopupModal
                onClose={handleClose}
                size="w-[500px]"
                className="px-5 py-5"
                title={<Title />}
                primaryButton={t('View in calendar')}
                secondaryButton={t('Cancel')}
                acceptAction={handleNext}
                declineAction={handleClose}
            >
                <div className="mx-auto bg-white overflow-hidden">
                    <div className="p-0">
                        <div className="flex items-center justify-between">
                            <h3 className="text-sm font-bold">Appointment #{bookingData.receipt_code}</h3>
                            <span className="px-[6px] pl-0.5 py-[2px] border flex shadow items-center justify-start rounded-md border-borderPrimary text-center text-[10px] leading-[14px] font-medium capitalize gap-0.5 w-max">
                                <GoDotFill size={12} className="text-[#067647]" />
                                {bookingData.status}
                            </span>
                        </div>
                        <img className="w-full h-[120px] rounded-md  object-cover mt-4" src={bookingData.location.location_image_url} alt="Salon" />
                        <div className="mt-4">
                            <h4 className="text-base font-semibold">{bookingData.location.name}</h4>
                            <div className="flex items-center mt-4 mb-4">
                                <figure className="NoImgName !w-[40px]">
                                    {bookingData.staff.profile_image_url ? (
                                        <img src={bookingData.staff.profile_image_url} alt="client" className="min-w-[40px] h-[40px] object-cover" />
                                    ) : (
                                        getShortName(bookingData.staff.full_name)
                                    )}
                                </figure>
                                <div className="ml-1">
                                    <p className="text-sm font-semibold">{bookingData.staff.full_name}</p>
                                    <p className="text-sm text-gray-500">{bookingData.staff.staff_role.name}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="mt-4 mb-4">
                                <h5 className="text-base font-semibold">Services +{bookingData.booking_services.length}</h5>
                                <ul className="mt-2 text-sm text-gray-600">
                                    {bookingData.booking_services.map((bookingServices: any, index: any) => (
                                        <li className="mt-1.5" key={index}>
                                            <div className="flex items-center justify-between gap-1.5">
                                                <div className="flex gap-2.5">
                                                    <div className="w-4 h-4 bg-[#EFF1F5] text-[#1F293C] text-xs font-medium rounded-[3px] flex items-center justify-center"> {index + 1}</div>{' '}
                                                    <div className="text-xs font-normal text-[#475467]">{bookingServices.service.name}</div>
                                                </div>{' '}
                                                <div className="text-xs font-normal text-[#475467]"> ${bookingServices.price}</div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <p className="mt-4  text-[#475467] text-xs font-normal">
                                    {`${moment(bookingData.booking_start_time, 'HH:mm:ss').format('hh:mm A')} - ${moment(bookingData.booking_end_time, 'HH:mm:ss').format('hh:mm A')} (${moment
                                        .duration(bookingData.total_duration)
                                        .asMinutes()} min)`}
                                </p>
                                <p className="text-xs font-normal text-[#98A2B3]">{`(${moment(bookingData.booking_date).format('dddd')}, ${moment(bookingData.booking_date).format(
                                    'DD MMMM YYYY',
                                )})`}</p>
                            </div>
                            <hr />
                            <div className="mt-4 mb-4">
                                <h5 className="text-base font-semibold">Payment method</h5>
                                {bookingData.booking_transaction.map((transactionData: any, index: any) =>
                                    transactionData.payment_method === 'stripe' ? (
                                        <div className="flex gap-3 items-center mt-2" key={index}>
                                            <img src={getCardTypeImage(transactionData.card_brand)} alt="Visa" className="min-w-[40px] h-[40px] object-cover   justify-center items-center flex" />
                                            <div className="flex flex-col">
                                                <p className="text-sm text-[#1F293C]">Card on file</p>
                                                <p className="text-xs text-[#8C9CBB]">{`${transactionData.card_brand} ending in ${transactionData.card_last_digit}`}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <p key={index} className="text-sm text-gray-600 capitalize">
                                            {transactionData.payment_method}
                                        </p>
                                    ),
                                )}
                            </div>
                            <hr />
                            <div className="mt-4">
                                <div className="mb-4">
                                    <div className="flex justify-between  text-[#475467] text-base font-semibold">
                                        <p>Subtotal</p>
                                        <p>${bookingData.subtotal}</p>
                                    </div>
                                    <div className="flex justify-between text-xs font-normal text-[#475467] mt-2">
                                        <div className="flex items-center gap-2">
                                            <p className="w-[4px] h-[4px] bg-[#475467] rounded-full"></p>
                                            <p>Taxes and fees</p>
                                        </div>
                                        <p>${bookingData.taxes_and_fees}</p>
                                    </div>
                                    <div className="flex justify-between text-xs font-normal text-[#475467] mt-1">
                                        {bookingData.tip_transaction.length > 0 && (
                                            <>
                                                <div className="flex items-center gap-2">
                                                    <p className="w-[4px] h-[4px] bg-[#475467] rounded-full"></p>
                                                    <p>Tips</p>
                                                </div>
                                                <p>${bookingData.tip_transaction.reduce((sum: number, currentValue: number) => sum + currentValue, 0)}</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <hr />
                                <div className="flex justify-between text-base font-bold mt-4">
                                    <p>Total Amount</p>
                                    <p>${bookingData.total}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PopupModal>
        </>
    );
};

export default BookingDetails;
