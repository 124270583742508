import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PopupModal from 'src/components/PopupModal';
import * as Yup from 'yup';
import ExpenseLocation from './ExpenseLocation';
import ExpenseForm from './ExpenseForm';
import { Wallet02, MarkerPin01, ChartBreakoutCircle } from '@untitled-ui/icons-react/build/cjs';

export interface IAddExpense {
    location: string;
    last_name: string;
}

const AddExpense = ({ handleClose }: any) => {
    const { t } = useTranslation();
    const [step, setStep] = useState<number>(1);
    const [title, setTitle] = useState(t('Select a Location'));
    const [description, setDescription] = useState(t('Select your location'));
    const [icon, setIcon] = useState(<MarkerPin01 className="text-gray-700" />);

    useEffect(() => {
        switch (step) {
            case 1:
                setTitle(t('Select a Location'));
                setDescription(t('Select your location'));
                setIcon(<MarkerPin01 className="text-gray-700" />);
                break;
            case 2:
                setTitle(t('Add Expenses'));
                setDescription(t('Add your expenses to get your Net Profit.'));
                setIcon(<ChartBreakoutCircle className="text-gray-700" />);
                break;
            default:
                break;
        }
    }, [step]);

    const validationSchema = [
        Yup.object({
            location: Yup.string().required(t('This field is required')),
        }).required(),

        Yup.object({
            last_name: Yup.string().required(t('This field is required')),
        }).required(),
    ];

    const schema = validationSchema[step - 1] as Yup.ObjectSchema<any>;
    const methods = useForm<IAddExpense>({
        resolver: yupResolver(schema) as Resolver<IAddExpense>,
        defaultValues: {
            location: '',
            last_name: '',
        },
    });
    const { getValues, control, watch, trigger } = methods;

    const newLocation = watch('location');
    console.log('newLocation', newLocation);

    const handleNext = async () => {
        const isValid = await trigger();

        if (step === 2) {
            handleClose();
        } else {
            setStep((old) => old + 1);
        }

        // if (isValid) {
        //     if (step === 3) {
        //         const data = getValues();
        //         setPayStructure(data);
        //         handleClose();
        //     } else {
        //         payStructureType === 'partner' || payStructureType === 'no_tracking' ? setStep((old) => old + 2) : setStep((old) => old + 1);
        //     }
        // }
        console.log('hwllo');
    };

    const handleBack = () => {
        if (step === 2) {
            setStep((old) => old - 1);
        } else {
            handleClose();
        }
    };

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">{icon}</div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{title}</p>

                <span className="text-xs font-normal text-gray-500">{description}</span>
            </div>
        </div>
    );

    return (
        <>
            <PopupModal
                onClose={handleClose}
                size="w-[500px]"
                className="px-5 py-5"
                title={<Title />}
                primaryButton={t('Continue')}
                secondaryButton={t('Cancel')}
                acceptAction={handleNext}
                declineAction={handleBack}
            >
                <FormProvider {...methods}>
                    {step === 1 && <ExpenseLocation />}
                    {step === 2 && <ExpenseForm />}
                </FormProvider>
            </PopupModal>
        </>
    );
};

export default AddExpense;
