import React, { FC } from 'react';
import { ImageInputProps } from './Image.interface';
import { UploadCloud01 } from '@untitled-ui/icons-react/build/cjs';

const ImageInput: FC<ImageInputProps> = ({ error, onChangeCrop, name, title, id, imageMultiple = true }) => {
    const random = Math.random();
    return (
        <div id={id} className="flex items-center justify-center  w-[109px] h-[109px] xl:w-[120px] xl:h-[120px] xxl:w-[150px] xxl:h-[150px] relative">
            <label
                className={`flex flex-col items-center justify-center w-full h-full border-2 border-dashed border-borderPrimary rounded-lg cursor-pointer bg-gray-100 ${error && 'is-invalid'}`}
                htmlFor={`${name}_upload_${random}`}
            >
                <i className="p-[10px] mb-[10px]">
                    <UploadCloud01 width="20" className="text-secondaryTxtColor" />
                </i>
                <p className="text-xs text-secondaryTxtColor font-semibold leading-[18px]">Click to upload</p>
                <p className="text-xs text-secondaryTxtColor leading-[18px]">{title}</p>
            </label>
            <input
                type="file"
                name={name}
                id={`${name}_upload_${random}`}
                className="cursor-pointer absolute opacity-0 w-full h-full left-0 top-0"
                onChange={onChangeCrop(name)}
                multiple={imageMultiple}
            />
        </div>
    );
};
export default ImageInput;
