import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, me } from 'src/redux/services/common/Common.slice';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ExistingFormData, IFormData } from './Category.interface';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { axiosGet, axiosPatch, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import CustomButton from 'src/components/CustomButton';
import { ArrowLeft } from '@untitled-ui/icons-react/build/cjs';
import { LANGUAGE, LANGUAGES } from 'src/constants/common';

const CategoryForm = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const [language, setLanguage] = useState(LANGUAGE);

    const schema = Yup.object({
        ...(language === 'en'
            ? {
                  name: Yup.string().required('This field is required'),
                  description: Yup.string().required('This field is required'),
              }
            : {
                  fr_name: Yup.string().required('This field is required'),
                  fr_description: Yup.string().required('This field is required'),
              }),
    }).required();

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            name: '',
            description: '',
            fr_name: '',
            fr_description: '',
        },
    });

    const { handleSubmit, setValue, setError, control, watch } = methods;

    useEffect(() => {
        if (id) {
            getCategory();
        }
    }, [id, shop]);

    const getCategory = async () => {
        setIsLoading(true);
        await axiosGet(API.CATEGORY.GET, { shop_id: shop.id, id })
            .then((response) => {
                const data = response.data.data;

                const existingData: ExistingFormData = {
                    name: data.name || '',
                    description: data.description || '',
                    fr_name: data.fr_name || '',
                    fr_description: data.fr_description || '',
                };
                Object.keys(existingData).forEach((key) => {
                    setValue(key as keyof IFormData, existingData[key]);
                });
            })
            .catch(() => navigate(ROUTES.SERVICES.LIST))
            .finally(() => setIsLoading(false));
    };

    const handleSave = async (data: IFormData) => {
        setIsLoading(true);
        if (id) {
            await axiosPatch(API.CATEGORY.UPDATE, data, { shop_id: shop.id, id })
                .then(async () => {
                    await dispatch(me());
                    navigate(ROUTES.SERVICES.LIST);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        } else {
            await axiosPost(API.CATEGORY.CREATE, data, { shop_id: shop.id })
                .then(async () => {
                    await dispatch(me());
                    navigate(ROUTES.SERVICES.LIST);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IFormData, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const resetForm = (event: any) => {
        navigate(ROUTES.SERVICES.LIST);
        /* event.preventDefault();
        reset(); */
    };

    const handleLanguage = (lang: string) => () => {
        setLanguage(lang);
    };

    const watchName = watch('name');
    const watchDescription = watch('description');

    return (
        <div className="inner-page-wrape !px-0">
            <div className="flex justify-between gray-banner-bg bg-gray-50 relative h-[130px] text-secondaryTxtColor pt-5 pb-3 xlm:px-[30px] px-5  gap-1.5 rounded-tl-3xl items-start">
                <Link to={ROUTES.SERVICES.LIST} className="flex items-center cursor-pointer text-gray-600 h-[36px] text-xs font-semibold gap-1.5">
                    <ArrowLeft className="w-4 cursor-pointer text-gray-600" /> {t('Back to Service')}
                </Link>
            </div>

            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSave)}>
                    <div className="relative mb-[10px] w-full xl:pt-[10px] xlm:px-8 px-5 pt-2 pb-2 flex sms:gap-4 gap-2 items-center">
                        <div className="flex flex-col flex-1">
                            <h1 className="mr-3 text-gray-900 xlm:text-3xl sm:text-xl text-base inline align-middle font-semibold tracking-[-0.72px]">{watchName || t('Category Name')}</h1>
                            <p className="flex gap-2.5 text-gray-600 sm:text-sm text-xs mt-1 font-normal items-center">{watchDescription || t('Category description')}</p>
                        </div>
                        <div className="flex gap-3">
                            <CustomButton secondary type="reset" onClick={resetForm} disabled={isLoading}>
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading}>
                                {t('Save Changes')}
                            </CustomButton>
                        </div>
                    </div>
                    <div className="xlm:px-8 px-5 border-t">
                        <div className="flex justify-start h-[38px] gap-2 bg-gray-50 p-1 rounded-lg border border-gray-200 mt-5 ">
                            {LANGUAGES.map((lang: any, index) => (
                                <CustomButton
                                    key={index}
                                    type="button"
                                    onClick={handleLanguage(lang.shortName)}
                                    className={`px-14 h-[28px] py-2 w-full max-w-[252px] shadow-none text-sm font-bold ${lang.shortName === language ? '!text-blue-700 !bg-blue-50' : 'text-gray-500'}`}
                                >
                                    {lang.name}
                                </CustomButton>
                            ))}
                        </div>
                        {language === 'en' ? (
                            <div className="flex gap-5 py-6  ">
                                <div className="title-block w-[280px]">
                                    <h3 className="font-semibold text-sm text-gray-700 ">{t('Category details')}</h3>
                                    <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal">
                                        {t('Describe the general details of your service category to categorize and organize your brand services.')}
                                    </p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[400px]">
                                    <div className="flex flex-col gap-4">
                                        <Controller
                                            name="name"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState }: any) => (
                                                <div>
                                                    <InputWithLabel
                                                        label={t('Category name')}
                                                        placeholder={t('Enter category name')}
                                                        onChange={onChange}
                                                        value={value}
                                                        name="name"
                                                        required
                                                        error={!!fieldState.error}
                                                    />
                                                    {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name="description"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState }: any) => (
                                                <div>
                                                    <InputWithLabel
                                                        label={t('Description')}
                                                        placeholder={t('Enter category description')}
                                                        onChange={onChange}
                                                        value={value}
                                                        textArea
                                                        textAearClassName="h-[100px]"
                                                        name="description"
                                                        required
                                                        error={!!fieldState.error}
                                                    />
                                                    {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex gap-5 py-6 ">
                                <div className="title-block w-[280px]">
                                    <h3 className="font-semibold text-sm text-gray-700">Détails de la catégorie</h3>
                                    <p className="text-xs leading-[18px] text-secondaryTxtColor font-normal ">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit')}</p>
                                </div>
                                <div className="data-wrap-block w-full max-w-[400px]">
                                    <div className="flex flex-col gap-4">
                                        <Controller
                                            name="fr_name"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState }: any) => (
                                                <div>
                                                    <InputWithLabel
                                                        label="Nom de catégorie"
                                                        placeholder="Ajouter le nom de la catégorie"
                                                        onChange={onChange}
                                                        value={value}
                                                        name="fr_name"
                                                        required
                                                        error={!!fieldState.error}
                                                    />
                                                    {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name="fr_description"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState }: any) => (
                                                <div>
                                                    <InputWithLabel
                                                        label="Description"
                                                        placeholder="Description de la catégorie"
                                                        onChange={onChange}
                                                        value={value}
                                                        textArea
                                                        textAearClassName="h-[100px]"
                                                        name="fr_description"
                                                        required
                                                        error={!!fieldState.error}
                                                    />
                                                    {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

export default CategoryForm;
