import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

const Forbidden = () => (
    <div className=" h-[calc(100vh-122px)] bg-white border border-lineColor rounded-2xl flex justify-center items-center">
        <div className="w-full flex flex-col justify-center items-center p-4">
            <h4 className="m-0 mb-1 text-[26px] text-mainTextColor">Access Denied</h4>
            <p className="mb-8 text-sm font-normal text-secondaryTxtColor -tracking-[0.3px]">
                We are sorry, but your account does not have the necessary permissions to view this content or perform this action.
            </p>
            <NavLink to={ROUTES.HOME} className="bg-primary p-2 rounded-lg w-[150px] text-base font-semibold text-white text-center">
                Home
            </NavLink>
        </div>
    </div>
);

export default Forbidden;
