import React from 'react';
import { ImSpinner2 } from 'react-icons/im';

const Button = ({ onClick, children, className, form, disabled, isLoading = false, type = 'submit', primary = false, secondary = false }: any) => (
    <button
        disabled={disabled}
        onClick={onClick}
        className={`text-base  ${className} ${isLoading ? ' justify-center items-center ' : ''}${(disabled || isLoading) && 'bg-disable text-disableText border border-btnborder hover:bg-disable '} ${
            primary ? 'bg-btnBg text-white font-bold rounded-lg px-6 py-2' : ''
        }
        ${secondary ? 'bg-white text-black font-bold rounded-md px-6 py-2 border' : ''}`}
        type={type}
        form={form || null}
    >
        <div className="relative h-full flex items-center justify-center">
            {isLoading && (
                <i className="relative btn-spin-icon">
                    <ImSpinner2
                        style={{
                            color: '#808080',
                        }}
                    />
                </i>
            )}
            {children}
        </div>
    </button>
);

export default Button;
