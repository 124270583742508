import React from 'react';
import { formatKpiContentLable } from 'src/utils/global-functions';

const NetIncome = ({ handleViewClick, netIncomeDetails }: any) => (
    <div className="bg-white rounded-xl border border-borderSecondary shadow">
        <div className="flex justify-between items-center py-4 px-5 border-b border-borderSecondary">
            <div className="text-lg font-semibold -tracking-[0.4px] text-mainTextColor ">Net Income</div>
            <h3 className="text-lg font-bold -tracking-[0.5px] text-center">${formatKpiContentLable(netIncomeDetails.net_income) ?? 0}</h3>
        </div>
        <div className="grid grid-cols-2 w-full p-5 gap-4">
            <div className=" flex flex-col gap-2">
                <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor">Net Sales Income:</p>
                <p className="text-base max-xlm:text-sm font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor">${formatKpiContentLable(netIncomeDetails.net_sales_income) ?? 0}</p>
            </div>

            <div className=" flex flex-col gap-2">
                <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor">Commission payments:</p>
                <p className="text-base max-xlm:text-sm font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor">${formatKpiContentLable(netIncomeDetails.commission_payments) ?? 0}</p>
            </div>

            <div className=" flex flex-col gap-2">
                <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor">Rent:</p>
                <p className="text-base max-xlm:text-sm font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor">${formatKpiContentLable(netIncomeDetails.rent) ?? 0}</p>
            </div>

            <div className=" flex flex-col gap-2">
                <p className="-tracking-[0.3] font-normal max-xlm:text-xs text-sm text-start text-secondaryTxtColor">Rent:</p>
                <p className="text-base max-xlm:text-sm font-bold  -tracking-[0.5px] text-start text-secondaryTxtColor">${formatKpiContentLable(netIncomeDetails.rent) ?? 0}</p>
            </div>
        </div>

        {/* <div className="w-1/2 py-4 ps-8">
            <div className="flex flex-col justify-center items-center h-full">
                <p className="-tracking-[0.3] font-medium text-xs text-center mb-5">Net Sales Income</p>
                <h3 className="text-[28px] font-bold leading-[140%] -tracking-[0.5px] text-center">${formatKpiContentLable(netIncomeDetails.net_income) ?? 0}</h3>
            </div>
        </div> */}
    </div>
);

export default NetIncome;
