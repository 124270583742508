import React, { FC } from 'react';

const ActiveClient: FC<any> = ({ iconColor }) => (
    <svg width="44" height="44" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="className={`w-[48px] object-contain">
        <path d="M4.80005 4C4.80005 1.79086 6.59091 0 8.80005 0H28.8L43.2001 14.4V44C43.2001 46.2091 41.4092 48 39.2001 48H8.80005C6.59091 48 4.80005 46.2091 4.80005 44V4Z" fill={iconColor} />
        <path opacity="0.3" d="M28.8 0L43.2001 14.4H32.8001C30.5909 14.4 28.8 12.6091 28.8 10.4V0Z" fill="white" />
        <g clipPath="url(#clip0_17828_17652)">
            <path
                d="M32.3333 39.5V37.8333C32.3333 36.2801 31.271 34.9751 29.8333 34.605M26.9166 24.7423C28.1382 25.2368 29 26.4344 29 27.8333C29 29.2322 28.1382 30.4299 26.9166 30.9244M28.1666 39.5C28.1666 37.9469 28.1666 37.1703 27.9129 36.5577C27.5746 35.741 26.9257 35.092 26.1089 34.7537C25.4963 34.5 24.7198 34.5 23.1666 34.5H20.6666C19.1135 34.5 18.3369 34.5 17.7243 34.7537C16.9076 35.092 16.2587 35.741 15.9204 36.5577C15.6666 37.1703 15.6666 37.9469 15.6666 39.5M25.25 27.8333C25.25 29.6743 23.7576 31.1667 21.9166 31.1667C20.0757 31.1667 18.5833 29.6743 18.5833 27.8333C18.5833 25.9924 20.0757 24.5 21.9166 24.5C23.7576 24.5 25.25 25.9924 25.25 27.8333Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_17828_17652">
                <rect width="20" height="20" fill="white" transform="translate(14 22)" />
            </clipPath>
        </defs>
    </svg>
);

export default ActiveClient;
