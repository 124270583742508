import React, { useEffect, useRef, useState } from 'react';
import { MapStyle } from './MapStyle';

interface MapProps {
    latitude: string;
    longitude: string;
    id?: string;
    onReverseAddressFunc?: any;
    draggable?: boolean;
    height?: number;
    isCurrentLocation?: boolean;
}

const initialMapAddressVal = {
    fullAddress: '',
    address1: '',
    city: '',
    state: '',
    country: '',
    postal: '',
    latitude: null,
    longitude: null,
};

const Map: React.FC<MapProps> = ({ latitude, longitude, id = 'map', onReverseAddressFunc, draggable = false, height = 300, isCurrentLocation = false }) => {
    const mapRef = useRef(null);
    const [initialize, setInitaize] = useState(isCurrentLocation);

    let lat = parseFloat(latitude);
    let lng = parseFloat(longitude);

    const initializeMap = () => {
        if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
            const mapOptions = {
                center: { lat, lng },
                zoom: 15,
                gestureHandling: 'cooperative',
                styles: MapStyle,
            };

            const map = new window.google.maps.Map(mapRef.current, mapOptions);

            const marker = new window.google.maps.Marker({
                position: { lat, lng },
                map: map,
                draggable: draggable,
            });
            if (initialize) {
                const position = marker.getPosition();
                getAddress(position.lat(), position.lng());
                setInitaize(false);
            }
            window.google.maps.event.addListener(marker, 'dragend', () => {
                const position = marker.getPosition();
                getAddress(position.lat(), position.lng());
            });
        }
    };

    const getAddress = (lats: number, lngs: number) => {
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ location: { lat: lats, lng: lngs } }, (results: any, status: any) => {
            if (status === 'OK') {
                if (results[0]) {
                    const addressObject = results[0];
                    lat = addressObject.geometry.location.lat();
                    lng = addressObject.geometry.location.lng();
                    let city = '';
                    let state = '';
                    let postcode = '';
                    let address1 = '';
                    let country = '';
                    let stateCode = '';
                    let countryCode = '';

                    addressObject.address_components.forEach((component: any) => {
                        const componentType = component.types;

                        if (componentType.includes('premise')) {
                            address1 += `${component.long_name} `;
                        } else if (componentType.includes('street_number')) {
                            address1 += `${component.long_name} `;
                        } else if (componentType.includes('route')) {
                            address1 += `${component.short_name} `;
                        } else if (componentType.includes('sublocality')) {
                            address1 += `${component.long_name} `;
                        } else if (componentType.includes('postal_code')) {
                            postcode = `${component.long_name}${postcode}`;
                        } else if (componentType.includes('postal_code_suffix')) {
                            postcode = `${postcode}-${component.long_name}`;
                        } else if (componentType.includes('locality')) {
                            city = component.long_name;
                        } else if (componentType.includes('administrative_area_level_1')) {
                            state = component.long_name;
                            stateCode = component.short_name;
                        } else if (componentType.includes('country')) {
                            country = component.long_name;
                            countryCode = component.short_name;
                        }
                    });

                    const query = addressObject.formatted_address;

                    let addressObj = {
                        fullAddress: query,
                        address1,
                        city: city,
                        state: state,
                        country: country,
                        postal: postcode,
                        latitude: lat,
                        longitude: lng,
                        stateCode: stateCode,
                        countryCode: countryCode,
                    };
                    onReverseAddressFunc(addressObj);
                } else {
                    onReverseAddressFunc(initialMapAddressVal);
                }
            } else {
                onReverseAddressFunc(initialMapAddressVal);
            }
        });
    };

    useEffect(() => {
        if (window.google && window.google.maps) {
            initializeMap();
        }
    }, [latitude, longitude]);

    return <div id={id} ref={mapRef} className={`rounded-xl bg-secondaryTxtColor overflow-hidden w-full h-[${height}px]`} style={{ width: '100%', height: `${height}px` }} />;
};

export default Map;
