import React, { useMemo } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { ArrowDown } from '@untitled-ui/icons-react/build/cjs';

const SLocationBooking = () => {
    const { t } = useTranslation();
    const renderHeader = (header: any, field: any) => {
        const activeSortIcon = <ArrowDown className="w-4 text-gray-600 opacity-50" />;
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };
    let skeletons = useMemo(
        () => [
            {
                first_name: 'loading',
                booking_date: 'loading',
                total_duration: 'loading',
                shop_services: 'loading',
                total: 'loading',
                status: 'loading',
            },
        ],
        [],
    );
    return (
        <>
            <div className="flex justify-between items-center gap-2 mb-3">
                <div>
                    <h2 className="view-table-title">{t('Latest booking activity')}</h2>
                    <p className="view-table-subtitle">{t('View Bloor Street East latest booking activity')}</p>
                </div>
                <div>
                    <SelectBox isDisabled={true} name="page" value={{ value: 25, label: '25' }} isClearable={false} classComp="w-[70px]" />
                </div>
            </div>
            <DataTable value={Array(25).fill(skeletons).flat()} totalRecords={25} dataKey="id" className="overflow-hidden" paginatorClassName="table-pagination">
                <Column
                    field="first_name"
                    header={renderHeader(t('Client'), 'users.first_name')}
                    body={<Skeleton />}
                    style={{ width: '160px', minWidth: '160px', maxWidth: '160px' }}
                    className="font-normal text-mainTextColor truncate"
                    sortable
                ></Column>
                <Column
                    field="booking_date"
                    header={renderHeader(t('Date and Time'), 'booking_date')}
                    style={{ width: '130px', minWidth: '130px', maxWidth: '130px' }}
                    body={<Skeleton />}
                    className="text-xs leading-[18px] font-normal text-secondaryTxtColor truncate"
                    sortable
                ></Column>
                <Column
                    field="total_duration"
                    header={renderHeader(t('Duration'), 'total_duration')}
                    style={{ width: '85px', minWidth: '85px', maxWidth: '85px' }}
                    body={<Skeleton />}
                    className="text-xs leading-[18px] font-normal text-secondaryTxtColor truncate"
                    sortable
                ></Column>
                <Column
                    field="shop_services"
                    header={renderHeader(t('Service'), 'shop_services.name')}
                    style={{ width: '110px', minWidth: '110px', maxWidth: '110px' }}
                    body={<Skeleton />}
                    className="text-xs leading-[18px] font-medium text-secondaryTxtColor truncate"
                    sortable
                ></Column>
                <Column
                    field="total"
                    header={renderHeader(t('Price'), 'total')}
                    style={{ width: '70px', minWidth: '70px', maxWidth: '70px' }}
                    body={<Skeleton />}
                    className="text-xs leading-[18px] font-medium text-tableText truncate"
                    sortable
                ></Column>
                <Column
                    field="status"
                    header={renderHeader(t('Status'), 'status')}
                    style={{ width: '90px', minWidth: '90px', maxWidth: '90px' }}
                    className="text-xs leading-[18px] font-medium"
                    body={<Skeleton />}
                    sortable
                ></Column>
            </DataTable>
        </>
    );
};

export default SLocationBooking;
