import { TemplateType } from '../TemplateSettings.interface';

export interface IProduct {
    name: string;
    category: string;
    price: string;
    button: string;
    is_description: boolean;
    description: string;
    images: IImage[] | null;
}
export interface IImage {
    name: string;
    file: File;
    url: string;
}
export interface IFormData {
    status: boolean;
    is_title: boolean;
    title: string;
    is_subtitle: boolean;
    subtitle: string;
    products: IProduct[];
}

export const InitServiceData: IFormData = {
    status: false,
    is_title: false,
    title: '',
    is_subtitle: false,
    subtitle: '',
    products: [] as IProduct[],
};

export const InitServiceInfoData: IProduct = {
    name: '',
    category: '',
    price: '',
    button: '',
    is_description: true,
    description: '',
    images: null,
};
export interface ProductProps {
    template: TemplateType;
}
