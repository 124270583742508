import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Steper from './Steper';
import PageHeader from 'src/components/PageHeader';
import Overview from './Overview';
import StripeConnect from './StripeConnect';
import MainPayment from './MainPayment';
import StripeAccountDetails from './StripeAccountDetails';
import { useAppSelector } from 'src/redux/hooks';
import { userMe } from 'src/redux/services/common/Common.slice';

type IStepNum = 1 | 2 | 3 | 4;
type IStep = {
    name: string;
    description: string;
    step: number;
};

const PaymentNew = () => {
    const { t } = useTranslation();
    const user = useAppSelector(userMe);
    const stripeStep = user.shop_staff[0].account_step.stripe_account;
    const [step, setStep] = useState<IStepNum>(1);

    const STEPS: IStep[] = useMemo(
        () => [
            {
                name: t('Overview'),
                description: t('Discover FLAIR Pay'),
                step: 1,
            },
            {
                name: t('Connect'),
                description: t('Connect to Stripe'),
                step: 2,
            },
            {
                name: t('Finalize'),
                description: t('Confirm your details'),
                step: 3,
            },
        ],
        [],
    );
    const renderStepComponent = () => {
        if (step === 1) {
            return <Overview setStep={setStep} />;
        } else if (step === 2) {
            return <StripeConnect setStep={setStep} />;
        } else if (step === 3) {
            return <StripeAccountDetails FinalizeSetStep={setStep} />;
        } else if (step === 4) {
            return <MainPayment setStep={setStep} />;
        } else {
            return null;
        }
    };

    return (
        <div className="inner-page-wrape ">
            <div className="flex-1 flex flex-col ">
                <PageHeader title={t('Payments')} subtitle="View real-time balances, track transaction history, and configure booking policies."></PageHeader>
                {user.is_verified_stripe_account || stripeStep || stripeStep === 'pending' ? (
                    <MainPayment setStep={setStep} />
                ) : (
                    <div className="flex flex-1 flex-col items-center  w-full">
                        {step !== 4 && (
                            <div className="mt-7 w-[750px] 2xl:w-[800px] xlm:w-[992px]  h-[80px]">
                                <Steper activeStep={step} steps={STEPS} />
                            </div>
                        )}
                        {renderStepComponent()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentNew;
