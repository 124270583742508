import React, { useState } from 'react';
import InputWithLabel from 'src/components/InputWithLabel';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChevronDown, ChevronRight, Plus, X } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import { XClose } from '@untitled-ui/icons-react/build/cjs';

const TypeForm = ({ language }: any) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();
    const type = watch('type');
    const [isShow, setIsShow] = useState<any>({ 0: true });

    const {
        fields: optionFields,
        append: appendOption,
        remove: removeOption,
    } = useFieldArray({
        control,
        name: 'options',
    });

    const {
        fields: frOptionFields,
        append: appendFROption,
        remove: removeFROption,
    } = useFieldArray({
        control,
        name: 'fr_options',
    });

    const handleCollapse = (index: number) => () => {
        setIsShow((old: any) => ({ ...old, [index]: !old[index] }));
    };

    return (
        <>
            {language === 'en' ? (
                <div className="w-full">
                    {type === 'variable' ? (
                        <div className="flex flex-col gap-5">
                            {optionFields.map((item, index) => (
                                <div key={item.id} className="border rounded-lg">
                                    <div className="flex items-center justify-between p-4 cursor-pointer" onClick={handleCollapse(index)}>
                                        <p className="text-sm font-medium">{watch(`options.${index}.title`) || `${t('Variant Title')}`}</p>
                                        <div className="flex items-center gap-3">
                                            <div className="text-xs font-semibold text-red-700">
                                                <span className="text-error-700 cursor-pointer" onClick={() => removeOption(index)}>
                                                    <XClose width={18} className="text-error-700" />
                                                </span>
                                            </div>
                                            <button type="button" className=" text-3xl">
                                                {isShow[index] ? <ChevronDown className="text-gray-600" /> : <ChevronRight className="text-gray-600" />}
                                            </button>
                                        </div>
                                    </div>
                                    <div className={`flex flex-col gap-5 border-t p-4 ${!isShow[index] ? 'd-none' : ''}`}>
                                        <Controller
                                            name={`options.${index}.title`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div>
                                                    <InputWithLabel
                                                        name={`options.${index}.title`}
                                                        label={t('Variant Title')}
                                                        id={`options.${index}.title`}
                                                        required
                                                        placeholder={t('Enter service Variant Title')}
                                                        onChange={onChange}
                                                        value={value}
                                                        error={!!error}
                                                    />
                                                    {/* {error && <p className="text-error">{error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name={`options.${index}.description`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div>
                                                    <InputWithLabel
                                                        label={t('Variant Description')}
                                                        id={`options.${index}.description`}
                                                        required
                                                        placeholder={t('Enter service Variant Description')}
                                                        onChange={onChange}
                                                        value={value}
                                                        textArea
                                                        name={`options.${index}.description`}
                                                        error={!!error}
                                                        textAearClassName="h-[120px] w-full rounded-lg resize-none"
                                                    />
                                                    {/* {error && <p className="text-error">{error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className="flex justify-end text-primary text-xs font-semibold">
                                <CustomButton onClick={() => appendOption({ title: '', description: '' })} icon={<Plus className="h-4 w-4" />} className="shadow-none !p-0">
                                    {t('Add new variant')}
                                </CustomButton>
                            </div>
                        </div>
                    ) : (
                        <Controller
                            name="description"
                            control={control}
                            render={({ field: { onChange, value }, fieldState }: any) => (
                                <div>
                                    <InputWithLabel
                                        textArea
                                        label={t('Description')}
                                        id="description"
                                        required
                                        placeholder={t('Enter service description')}
                                        onChange={onChange}
                                        value={value}
                                        name="description"
                                        error={!!fieldState.error}
                                        textAearClassName="h-[120px] w-full rounded-lg resize-none"
                                    />
                                    {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                </div>
                            )}
                        />
                    )}
                </div>
            ) : (
                <div className="w-full">
                    {type === 'variable' ? (
                        <div className="flex flex-col gap-5">
                            {frOptionFields.map((item, index) => (
                                <div key={item.id} className="border rounded-lg">
                                    <div className="flex items-center justify-between p-4 cursor-pointer" onClick={handleCollapse(index)}>
                                        <p className="text-sm font-medium text-gray-700">{watch(`fr_options.${index}.fr_title`) || `${t('Variant Title')}`}</p>
                                        <div className="flex items-center gap-3">
                                            <div className="text-xs font-semibold text-red-700">
                                                <span className="text-error-700 cursor-pointer" onClick={() => removeFROption(index)}>
                                                    <XClose width={18} className="text-error-700" />
                                                </span>
                                            </div>
                                            <button type="button" className="text-3xl">
                                                {isShow[index] ? <ChevronDown className="text-gray-600" /> : <ChevronRight className="text-gray-600" />}
                                            </button>
                                        </div>
                                    </div>
                                    <div className={`flex flex-col gap-5 border-t px-4 pt-4 pb-4 ${!isShow[index] ? 'd-none' : ''}`}>
                                        <Controller
                                            name={`fr_options.${index}.fr_title`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div>
                                                    <InputWithLabel
                                                        name={`fr_options.${index}.fr_title`}
                                                        id={`fr_options.${index}.fr_title`}
                                                        label={t('Title')}
                                                        required
                                                        placeholder={t('Title')}
                                                        onChange={onChange}
                                                        value={value}
                                                        error={!!error}
                                                    />
                                                    {/* {error && <p className="text-error">{error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name={`fr_options.${index}.fr_description`}
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <div>
                                                    <InputWithLabel
                                                        label="Description"
                                                        id={`fr_options.${index}.fr_description`}
                                                        required
                                                        placeholder="Description du service"
                                                        onChange={onChange}
                                                        value={value}
                                                        textArea
                                                        name={`fr_options.${index}.fr_description`}
                                                        error={!!error}
                                                        textAearClassName="h-[120px] w-full rounded-lg resize-none"
                                                    />
                                                    {/* {error && <p className="text-error">{error.message}</p>} */}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className="flex justify-end text-primary text-xs font-semibold">
                                <CustomButton onClick={() => appendFROption({ fr_title: '', fr_description: '' })} icon={<Plus className="h-4 w-4" />} className="shadow-none !p-0">
                                    Ajouter plus d&apos;options
                                </CustomButton>
                            </div>
                        </div>
                    ) : (
                        <Controller
                            name="fr_description"
                            control={control}
                            render={({ field: { onChange, value }, fieldState }: any) => (
                                <div>
                                    <InputWithLabel
                                        textArea
                                        label="Description"
                                        id="fr_description"
                                        required
                                        placeholder="Description du service"
                                        onChange={onChange}
                                        value={value}
                                        name="fr_description"
                                        error={!!fieldState.error}
                                        textAearClassName="h-[120px] w-full rounded-lg resize-none"
                                    />
                                    {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                </div>
                            )}
                        />
                    )}
                </div>
            )}
        </>
    );
};
export default TypeForm;
