import { Dispatch, SetStateAction } from 'react';

export interface IForgotPassword {
    emailPhone: any;
}
export interface IForgotPasswordCredential {
    phone: any;
    phone_country_code: any;
}
export interface IinitialState {
    data: any;
    loading: boolean;
}

export type IForgotPasswordStep = 'forgot_password' | 'otp' | 'verified' | 'change_password';

export interface IForgotPasswordProps {
    setForgotPasswordStep: Dispatch<SetStateAction<IForgotPasswordStep>>;
    setAuthData: any;
    authData: any;
}
export interface IAuthData {
    isEmail: boolean;
    email: any;
    phone: any;
}
export const AuthDataInit: IAuthData = {
    isEmail: true,
    email: null,
    phone: null,
};
