import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import { ErrorType, IInitialState } from './Common.interface';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';
import { LOCAL_STORAGE_KEYS } from 'src/constants/common';
import { getShortName } from 'src/utils/global-functions';

const initialState: IInitialState = {
    user: '',
    role: {},
    shop: {},
    shop_me: {},
    staff: [],
    services: [],
    locations: [],
    roles: [],
    settings: [
        {
            id: 0,
            shop_id: 0,
            type: '',
            value: {
                name: '',
                favicon: '',
                logo_type: '',
                font_family: '',
                font_weight: '',
                is_same_as_brand_logo: false,
            },
            description: '',
            status: false,
            deleted_at: null,
            created_at: '',
            updated_at: '',
        },
    ],
    breadcrumbs: [],
    loading: false,
    error: null,
};

export const me = createAsyncThunk('user/me', async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
        const response = await axiosGet(API.USER.ME);
        if (response.data.status === 200) {
            const user = response.data.data;
            const { Common }: any = getState();
            let shopStaff = user.shop_staff.find((shop_staff: any) => shop_staff.shop.created_by === user.id || shop_staff.is_accepted_invitation);
            if (!shopStaff) {
                shopStaff = user.shop_staff[0];
            }
            let shop = user.shops.find((item: any) => shopStaff.shop_id === item.id);
            if (Object.keys(Common.shop).length) {
                shop = user.shops.find((item: any) => item.id === Common.shop.id) || shop;
            }
            if (!Common.role || !Object.keys(Common.role).length) {
                const role = user.roles.find((item: any) => item.pivot.shop_id === shop.id);
                if (role) {
                    dispatch(changeRole(role));
                }
            }
            await dispatch(changeShop(shop));
            return response.data.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const changeShop = createAsyncThunk('shop/change', async (shop: any, { dispatch, getState, rejectWithValue }) => {
    try {
        const payload = {
            shop_id: shop.id,
        };

        const { Common }: any = getState();

        const roles = Common.user.roles;
        let role: any = {};

        if (Common.role && Object.keys(Common.role).length && roles && roles.length) {
            role = roles.find((item: any) => item.pivot.shop_id === shop.id);
        }
        if (role && Object.keys(role).length) {
            // Common.role.id !== role.id
            dispatch(changeRole(role));
        }

        await dispatch(getAllShopLocations(payload));
        await dispatch(getAllShopStaff(payload));
        await dispatch(getAllShopRoles(payload));
        await dispatch(getAllShopServices(payload));
        await dispatch(getAllShopSettings(payload));

        return shop;
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopStaff = createAsyncThunk('staff/all', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.STAFF.ALL, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopServices = createAsyncThunk('service/all', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.SERVICE.ALL, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopLocations = createAsyncThunk('location/all', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.LOCATION.LIST, payload);
        if (response.data.status === errorCode.success) {
            const data = response.data;
            const locations = data.data.map((item: any) => ({
                ...item,
                value: item.id,
                label: item.name,
                image: item?.profile_image_url,
                shortName: getShortName(item.name),
            }));

            return { ...data, data: locations };
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopRoles = createAsyncThunk('role/all', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.STAFF_ROLE.LIST, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllSalesTaxes = createAsyncThunk('sales-taxes/all', async (payload, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.SALES.LIST);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopCategory = createAsyncThunk('category/all', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.CATEGORY.LIST, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopSettings = createAsyncThunk('setting/all', async (payload: any, { rejectWithValue, getState }) => {
    try {
        const response = await axiosGet(API.SETTING.GET, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const shopMe = createAsyncThunk('shop/me', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.USER.SHOP_ME, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        changeRole: (state, { payload }) => {
            state.role = payload;
            localStorage.setItem(LOCAL_STORAGE_KEYS.currentRole, payload.name);
        },
        setBreadcrumbs: (state, { payload }) => {
            state.breadcrumbs = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(me.pending, (state) => {
                state.loading = true;
            })
            .addCase(me.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.user = payload;
            })
            .addCase(me.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(changeShop.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeShop.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.shop = payload;
            })
            .addCase(changeShop.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getAllShopStaff.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllShopStaff.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.staff = payload.data;
            })
            .addCase(getAllShopStaff.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getAllShopServices.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllShopServices.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.services = payload.data;
            })
            .addCase(getAllShopServices.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getAllShopLocations.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllShopLocations.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.locations = payload.data;
            })
            .addCase(getAllShopLocations.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getAllShopRoles.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllShopRoles.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.roles = payload.data;
            })
            .addCase(getAllShopRoles.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getAllShopSettings.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllShopSettings.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.settings = payload.data;
            })
            .addCase(getAllShopSettings.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(shopMe.pending, (state) => {
                state.loading = true;
            })
            .addCase(shopMe.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.shop_me = payload.data;
            })
            .addCase(shopMe.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});
export const { changeRole, setBreadcrumbs } = commonSlice.actions;
export const userMe = (state: RootState) => state.Common.user;
export const currentRole = (state: RootState) => state.Common.role || {};
export const currentShop = (state: RootState) => state.Common.shop || {};
export const allShopStaff = (state: RootState) => state.Common.staff || [];
export const allShopServices = (state: RootState) => state.Common.services || [];
export const allShopLocations = (state: RootState) => state.Common.locations || [];
export const allShopRoles = (state: RootState) => state.Common.roles || [];
export const allShopSettings = (state: RootState) => state.Common.settings;
export const currentShopMe = (state: RootState) => state.Common.shop_me;
export const allBreadcrumbs = (state: RootState) => state.Common.breadcrumbs || [];
