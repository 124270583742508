import React, { ReactNode } from 'react';
import { Button } from 'primereact/button';

interface IProps {
    onClick?: (event: any) => void;
    children?: ReactNode;
    className?: string;
    type?: 'submit' | 'reset' | 'button' | undefined;
    disabled?: boolean;
    isLoading?: boolean;
    primary?: boolean;
    secondary?: boolean;
    outlinePrimary?: boolean;
    outlineSecondary?: boolean;
    icon?: ReactNode | undefined;
    rightIcon?: ReactNode | undefined;
    size?: string;
    borderClass?: string;
    iconOnlyClass?: string;
}

const CustomButton = ({
    onClick,
    children,
    className = '',
    disabled = false,
    isLoading = false,
    type = 'button',
    primary = false,
    secondary = false,
    outlinePrimary = false,
    outlineSecondary = false,
    icon = undefined,
    rightIcon = undefined,
    size = '',
    borderClass = '',
    iconOnlyClass = '',
}: IProps) => (
    <Button
        type={type}
        loading={isLoading}
        disabled={disabled}
        onClick={onClick}
        className={`btn min-w-fit flex justify-center gap-[6px] text-xs leading-[18px] font-semibold px-3 py-[9px] transition-all delay-100 ${className} ${size} ${borderClass} ${iconOnlyClass} ${
            primary
                ? 'h-[36px] bg-brand-500 px-3 py-2 rounded-lg border border-brand-500 font-semibold text-white hover:border-[unset] hover:shadow-sm  hover:bg-brand-600 transition-all delay-100'
                : ''
        } ${outlinePrimary ? 'text-blue-500 bg-white  border border-blue-300 h-[36px] hover:bg-primary hover:text-white hover:border-primary' : ''} ${
            secondary ? 'bg-white hover:bg-[#f9fafb] hover:border-black  border shadow-sm h-[36px] text-gray-700' : ''
        } ${outlineSecondary ? 'text-btnText  border border-borderGray  ' : ''} ${disabled && secondary ? '!bg-gray-100 opacity-50 border border-gray-200' : ''} shadow-InputAndButton`}
    >
        {icon}
        {children}
        {rightIcon}
    </Button>
);

export default CustomButton;
