import React, { FC, useState } from 'react';
import { useForm, Controller, useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { IPops } from './AccountType.interface';
import { useTranslation } from 'react-i18next';
import { currentShop, me, userMe } from 'src/redux/services/common/Common.slice';
import { Label, Radio } from 'flowbite-react';
import CustomButton from 'src/components/CustomButton';
import { accountType } from './AccountType.slice';
import { IFormData, IFormDataRequest } from './AccountType.interface';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { useRolePermission } from 'src/hooks/useRolePermission';
import { SingalProfile } from 'src/theme/Images';
import { BackgroundDesign } from 'src/theme/Images';
import { IoList } from 'react-icons/io5';
import UsersCheck from '@untitled-ui/icons-react/build/cjs/UsersCheck';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';

type FieldKey = 'type';

const AccountType: FC<IPops> = ({ setStep }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const user = useAppSelector(userMe);
    const [isLoading, setIsLoading] = useState(false);
    const {
        trigger,
        formState: { errors },
        control,
        setValue,
    } = useFormContext();

    useFormErrorFocus<IFormData>({ errors });
    const handleContinue = async () => {
        const isValid = await trigger();
        if (isValid) {
            setStep(2);
        }
    };

    return (
        <>
            <div className="w-full flex justify-center relative flex-1 items-center flex-col text-center bg-banner bg-top bg-no-repeat">
                {/* <img src={BackgroundDesign} alt="" className="absolute top-0 z-[-1]" /> */}
                <div className="w-[600px] ">
                    <div className="flex flex-col rounded-md items-center mb-4">
                        <div className="p-[14px] bg-white border border-btnborder rounded-xl">
                            <IoList className="w-7 h-7" />
                        </div>
                    </div>
                    <div className="text-center">
                        <h3 className="fwpo_heading_title">{t('Select account type')}</h3>
                        <p className="font-normal leading-[22.4px] mt-[2px] text-base text-secondaryTxtColor">
                            {t('Welcome! Let us know how you intend on using.', { name: user.first_name, shop_name: process.env.REACT_APP_FULL_NAME })}
                        </p>
                    </div>
                    <div className="mt-8">
                        {/* <p className="text-error mt-2 absolute text-sm">{errors.account_type && errors.account_type.message}</p> */}

                        <Controller
                            name="type"
                            control={control}
                            render={({ field: { onChange, value } }: any) => (
                                <>
                                    <div id="type" className={`flex flex-col mb-4 ${value === 'owner' ? 'account_active' : ''}`}>
                                        <Label
                                            htmlFor="owner"
                                            className={`flex justify-between items-center px-4 py-3 border  rounded-lg bg-white cursor-pointer ${
                                                value === 'owner' ? 'border-[#5279F0]' : 'border-gray-300'
                                            }`}
                                        >
                                            <div className="flex items-center">
                                                <div className="me-3 w-11 h-11 border border-gray-200 rounded-lg flex justify-center items-center">
                                                    <UsersCheck />
                                                </div>
                                                <div className="flex flex-col">
                                                    <h4 className="text-base font-medium text-black mb-1 text-justify">{t('I am a brand owner')}</h4>
                                                    <span className="text-sm text-gray-500">{t('I own a brand and manage a team of professionals')}</span>
                                                </div>
                                            </div>
                                            <Radio className="orm-radio h-5 w-5 text-blue-600 cursor-pointer" id="owner" name="type" value={'owner'} onChange={onChange} checked={value === 'owner'} />
                                        </Label>
                                    </div>
                                    <div className={`flex flex-col ${value === 'individual' ? 'account_active' : ''}`}>
                                        <Label
                                            htmlFor="individual"
                                            className={`flex justify-between items-center px-4 py-3 border rounded-lg bg-white cursor-pointer ${
                                                value === 'individual' ? 'border-[#5279F0]' : 'border-gray-300'
                                            }`}
                                        >
                                            <div className="flex items-center">
                                                <div className="me-3 w-11 h-11 border border-gray-200 rounded-lg flex justify-center items-center">
                                                    <SingalProfile />
                                                </div>
                                                <div className="flex flex-col">
                                                    <h4 className="text-base font-medium text-black mb-1 text-justify">{t('I am independent')}</h4>
                                                    <span className="text-sm text-gray-500">{t('I provide personal care services and work for myself')}</span>
                                                </div>
                                            </div>
                                            <Radio
                                                className="orm-radio h-5 w-5 text-blue-600 cursor-pointer"
                                                id="individual"
                                                name="type"
                                                value={'individual'}
                                                onChange={onChange}
                                                checked={value === 'individual'}
                                            />
                                        </Label>
                                    </div>
                                </>
                            )}
                        />
                        <CustomButton primary isLoading={isLoading} disabled={isLoading} onClick={handleContinue} type="button" size="w-full" className="fl-btn mt-5">
                            {t('Continue')}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountType;
