/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import Button from 'src/components/Button';
import { HiCheck, HiX } from 'react-icons/hi';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { ChoosePlan } from './MyPlan.slice';
import { userMe } from 'src/redux/services/common/Common.slice';
import Popup from 'src/components/Popup';
import PlanUpgrade from './PlanUpgrade';

const MyPlan = ({ activeTab }: any) => {
    const dispatch = useAppDispatch();
    const user: any = useAppSelector(userMe);
    const selectedPlan = user?.plan_id;
    const [getDataLoading, setGetDataLoading] = useState(false);
    const [action, setAction] = useState(false);
    const [selectedPlanInfo, setSelectedPlanInfo] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(ChoosePlan());
            setGetDataLoading(false);
        };
        setGetDataLoading(true);
        fetchData();
    }, []);

    let plandetails: any = useAppSelector((state) => state.MyPlan.plandetails);
    plandetails = plandetails.filter((plan: any) => plan.id <= 2);

    const handlePlan = (planInfo: any) => async () => {
        setSelectedPlanInfo(planInfo);
        setAction(true);
    };

    const handleModalClose = () => {
        setAction(false);
        setSelectedPlanInfo(null);
    };
    return (
        <>
            <div className=" flex-1 flex flex-col">
                <div className="w-full h-full flex flex-col flex-1 rounded-2xl bg-white border-[1px]  border-lineColor ">
                    <div className="py-5 px-6 xxl:px-8 border-b border-lineColor">
                        <h2 className="flex items-center text-xl leading-[140%] -tracking-[0.4px] font-bold mr-2 ">{activeTab.name}</h2>
                    </div>
                    {!getDataLoading && (
                        <div className="flex flex-1 p-6 px-6 xxl:px-8 -mx-2">
                            {plandetails.map((plan: any, index: any) => (
                                <div key={index} className="px-2 grow-0 shrink-0 basis-[33.3%] w-[33.3%]">
                                    <div className="p-4 border border-lineColor rounded-3xl h-full flex flex-col box-border ">
                                        <h4 className="font-bold mb-2 text-base leading-[22px] -tracking-[0.32px]">{plan.name}</h4>
                                        <p className="text-xs leading-[150%] -tracking-[0.13px] mb-3 text-secondaryTxtColor">{plan.description}</p>
                                        <div className="pb-3 mb-3 border-lineColor border-b">
                                            <span className="text-[40px] leading-[124%] font-bold">{`$${plan.price}`}</span>
                                            <sub className="text-secondaryTxtColor font-medium text-base leading-[140%] -tracking-[0.16px]">{`.00`}</sub>
                                        </div>
                                        <div className="mb-3 pb-3 border-lineColor border-b">
                                            <p className="text-sm font-medium leading-[140%] -tracking-[0.14px] mb-2">Services:</p>
                                            <ul className="fl-listing">
                                                {plan.services.map((services: any, servicesIndex: any) => (
                                                    <li
                                                        key={servicesIndex}
                                                        // className="flex"
                                                        className={services.is_available ? '' : 'text-secondaryTxtColor'}
                                                    >
                                                        {services.is_available ? (
                                                            <span>
                                                                <HiCheck size={16} className="text-primary icon" />
                                                            </span>
                                                        ) : (
                                                            <span>
                                                                <HiX size={16} className="icon" />
                                                            </span>
                                                        )}
                                                        <div className="fl-text">{services.name}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <p className="text-sm font-medium leading-[140%] -tracking-[0.14px] mb-2">Features:</p>
                                        <ul className="fl-listing text-xs flex-1">
                                            {plan.features.map((features: any, featuresIndex: any) => (
                                                <li key={featuresIndex} className={features.is_available ? '' : 'text-secondaryTxtColor'}>
                                                    {features.is_available ? (
                                                        <span>
                                                            <HiCheck size={16} className="text-primary icon" />
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <HiX size={16} className="icon" />
                                                        </span>
                                                    )}
                                                    <div className="fl-text">{features.name}</div>
                                                </li>
                                            ))}
                                        </ul>
                                        {selectedPlan === plan.id ? (
                                            <Button className="btn_outline_black justify-center hover:bg-transparent hover:text-black font-semibold w-full mb-0 mt-5 h-[44px]" disabled>
                                                Current Plan
                                            </Button>
                                        ) : (
                                            <Button onClick={handlePlan(plan)} className="btn_primary w-full mb-0 mt-5 h-[44px]">
                                                Select Plan
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            ))}

                            <div className="px-2 grow-0 shrink-0 basis-[33.3%] w-[33.3%]">
                                <div className="p-4 border border-lineColor rounded-3xl h-full flex flex-col box-border ">
                                    <h4 className="font-bold mb-2 text-base leading-[22px] -tracking-[0.16px] flex  items-center text-secondaryTxtColor">
                                        Premium
                                        <span className="bg-blue-500 text-xs text-white px-3 py-[2px] rounded-full ml-2">Plus</span>
                                    </h4>
                                    <p className="text-xs leading-[150%] -tracking-[0.13px] mb-3 text-secondaryTxtColor ">
                                        Augmenting the Premium package with a set of AI powered algorithms and automations focused on profitability.
                                    </p>
                                    <div className="pb-3 mb-3 border-lineColor border-b">
                                        <span className="text-[40px] leading-[124%] font-bold text-secondaryTxtColor">$150</span>
                                        <sub className="text-secondaryTxtColor font-medium text-base leading-[140%] -tracking-[0.16px]">/month</sub>
                                    </div>
                                    <div className="mb-3 pb-3 border-lineColor border-b">
                                        <p className="text-sm font-medium leading-[140%] -tracking-[0.14px] mb-2 text-secondaryTxtColor">Services:</p>
                                        <ul className="fl-listing text-secondaryTxtColor">
                                            <li className="">
                                                <HiCheck size={16} className="text-secondaryTxtColor" />
                                                <div className="fl-text">FLAIR Manager</div>
                                            </li>
                                            <li className="">
                                                <HiCheck size={16} className="text-secondaryTxtColor" />
                                                <div className="fl-text">FLAIR Mobile</div>
                                            </li>
                                            <li className="">
                                                <HiCheck size={16} className="text-secondaryTxtColor" />
                                                <div className="fl-text">Branded Web Applications</div>
                                            </li>
                                            <li className="">
                                                <HiX size={16} className="icon" />
                                                <div className="fl-text">FLAIR POS</div>
                                            </li>
                                            <li className=" ">
                                                <HiX size={16} className="icon" />
                                                <div className="fl-text">Branded Mobile Applications</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className="text-sm font-medium leading-[140%] -tracking-[0.14px] mb-2 text-secondaryTxtColor">Features:</p>
                                    <ul className="fl-listing text-xs flex-1 text-secondaryTxtColor">
                                        <li className=" ">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Artificial Intelligence powered automated campaigns</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Automated Workflows</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Mobile Pay</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Flexible Payment Options</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Complete Operation Management</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Reporting & Analytics</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Manual Marketing Campaigns</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Promotion Management</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">SmartFill</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Waitlists</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Automated Payroll & Rent</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Loyalty & Referral Program</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Client Insights</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Preset & custom client groups</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">Client & Group Blasts</div>
                                        </li>
                                        <li className="">
                                            <HiCheck size={16} className="text-secondaryTxtColor" />
                                            <div className="fl-text">No Show & Cancellation Policy</div>
                                        </li>
                                    </ul>
                                    <Button className="btn_primary w-full mb-0 mt-5 h-[44px]">Select Plan</Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {action && (
                <Popup
                    size="max-w-[600px]"
                    className="p-8 pb-10"
                    handleClose={handleModalClose}
                    content={<PlanUpgrade planInfo={selectedPlanInfo} currentPlan={user.plan} handleModalClose={handleModalClose} />}
                />
            )}
        </>
    );
};

export default MyPlan;
