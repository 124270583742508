export const ADMIN = 'admin';
export const USER = 'user';

export const ADMIN_PERMISSIONS: IRole = {
    name: ADMIN,
    defaultView: 'rolePermission',
    permissions: [
        'newPassword.read',
        'register.read',
        'accountStepPlan.read',
        'accountStepAppLogo.read',
        'accountStepBookingPayment.read',
        'accountStepBranding.read',
        'accountStepCompleteAccount.read',
        'accountStepContract.read',
        'accountStepLocation.read',
        'accountStepStripeAccount.read',
        'signup.read',
        'accountStepFlairPaymentReturn.read',
        'dashboard.read',
        'admin.read',
        'client.read',
        'payment.read',
        'setting.read',
        'test.read',
        'location.read',
        'staff.read',
        'services.read',
        'appearance.read',
        'calendar.read',
        'analytics.read',
        'marketing.read',
        'store.read',
    ],
};

export const USER_PERMISSIONS: IRole = {
    name: USER,
    defaultView: 'calendar',
    permissions: ['calendar.read', 'calendar.read'],
};

export interface IRole {
    name: string;
    permissions: string[];
    defaultView: string;
}

export interface IRoleName {}
