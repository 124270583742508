import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { currentShop, me } from 'src/redux/services/common/Common.slice';
import { Controller, useFormContext } from 'react-hook-form';
import InputWithLabel from 'src/components/InputWithLabel';
import { convertBase64ToFile } from 'src/utils/global-functions';
import CropperModal from 'src/components/CropperModal/CropperModal';
import { useTranslation } from 'react-i18next';
import { RightGreen, Url } from 'src/theme/Images';
import { UploadCloud02 } from '@untitled-ui/icons-react/build/cjs';
import CustomButton from 'src/components/CustomButton';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';

const BusinessInfo = ({ errors, setFile, cropData, setCropData }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);

    const { watch, control, setValue } = useFormContext();
    const slugValue = watch('slug');
    const [image, setImage] = useState<any>('');
    const [upload, setUpload] = useState(false);
    const [publishLoading, setPublishLoading] = useState(false);

    const imageUpload = (data: any) => {
        const appLogoImage = data;
        const fileInfo = convertBase64ToFile(appLogoImage);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue('logo_image_name', fileInfo.filename);
        }
    };
    useEffect(() => {
        setImage(shop.logo_image_url);
    }, [shop]);

    const onChangeCrop = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onloadstart = () => {
            setImage('');
            setUpload(false);
        };
        reader.onloadend = () => {
            setImage(reader.result as any);
            setUpload(true);
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null;
    };

    const handlepublished = () => {
        setPublishLoading(true);
        axiosPost(API.THEME.SLUG, { is_slug_active: !shop.is_slug_active }, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
            })
            .catch((error: any) => toast.error(error?.message))
            .finally(() => setPublishLoading(false));
    };

    const onImageDownload = (Id: string) => {
        const svg = document.getElementById(Id);

        if (svg) {
            const svgData = new XMLSerializer().serializeToString(svg);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (ctx) {
                const img = new Image();

                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);
                    const pngFile = canvas.toDataURL('image/png');
                    const downloadLink = document.createElement('a');
                    downloadLink.download = Id;
                    downloadLink.href = `${pngFile}`;
                    downloadLink.click();
                };

                img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
            } else {
                console.error('2D context not available');
            }
        } else {
            console.error('SVG element not found');
        }
    };

    return (
        <>
            <div className="w-full flex flex-row  gap-[32px] border-b pt-5 pb-5">
                <div className=" w-[280px]">
                    <p className="text-gray-700 font-semibold text-sm ">{t('Brand Name')}</p>
                </div>
                <div className="flex flex-row w-[512px] gap-4">
                    <Controller
                        name="business_name"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="w-[310px]">
                                <InputWithLabel name="business_name" placeholder={t('Business name')} value={value} onChange={onChange} error={!!error} />
                            </div>
                        )}
                    />
                </div>
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b pt-5 pb-5">
                <div className=" w-[280px]">
                    <p className="text-gray-700 font-semibold text-sm ">{t('Booking URL')}</p>
                </div>
                <div className="w-[310px]">
                    <Controller
                        name={`slug`}
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="data-wrap-block max-w-[512px] gap-4 flex xxl:max-w-[700px]">
                                <div className="w-full max-w-[310px]">
                                    <InputWithLabel
                                        labelIcon={<Url />}
                                        inputControlClassName="pl-0"
                                        labelEndIcon={
                                            <div className="flex text-xs leading-[18px] font-semibold text-[#344054] flex-row gap-1 items-center">
                                                {'.getflair.ca'}
                                                <RightGreen />
                                            </div>
                                        }
                                        onChange={onChange}
                                        value={value}
                                        name="slug"
                                        error={!!error}
                                    />
                                </div>
                            </div>
                        )}
                    />
                </div>
                <CustomButton outlinePrimary isLoading={publishLoading} disabled={publishLoading} onClick={handlepublished} className="h-[36px]">
                    {shop.is_slug_active ? 'Unpublished' : 'Published'}
                </CustomButton>
                {/* <div className="w-[310px]">
                    <Controller
                        name="slug"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-full">
                                <div className="relative rounded-md">
                                    <InputWithLabel
                                        id="slug"
                                        placeholder="Enter Website URL"
                                        onChange={onChange}
                                        value={value}
                                        required
                                        labelIcon={<Url />}
                                        inputControlClassName="pl-1 h-[36px] "
                                        name="slug"
                                        error={!!errors.slug}
                                        labelEndIcon={
                                            <div className="flex text-xs leading-[18px] font-semibold text-[#344054] flex-row gap-1 items-center">
                                                {'.getflair.ca'}
                                                <RightGreen />
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    />
                </div>
                <CustomButton outlinePrimary className=" h-[36px]">
                    Published
                </CustomButton> */}
            </div>
            <div className="w-full flex flex-row  gap-[32px] border-b pt-5 pb-5">
                <div className=" w-[280px]">
                    <p className="text-gray-700 font-semibold text-sm ">{t('Hyperlinks')}</p>
                    <p className="text-[#475467] font-normal text-xs ">{t('Lorem ipsum dolor sit amet, consectetur.')}</p>
                </div>
                <div className="flex items-center gap-5 w-[310px]">
                    {slugValue && <QRCode className="min-w-[120px]  w-[120px] h-[120px] inline-block object-cover" value={slugValue} level="H" id="WebQRCode" />}
                    <CustomButton outlinePrimary onClick={() => onImageDownload('WebQRCode')} className=" h-[36px]">
                        Download
                    </CustomButton>
                </div>
            </div>
            <div className="w-full flex flex-row gap-[32px] border-b pt-5 pb-5">
                <div className=" w-[280px]  flex flex-col">
                    <p className="text-gray-700 font-semibold text-sm ">{t('Brand Logo')}</p>
                    <p className="text-[#475467] font-normal text-xs ">{t('This will be displayed on your profile.')}</p>
                </div>
                <div className="flex flex-row w-[512px] gap-4">
                    {cropData && <img src={cropData} alt="avatar" className="w-16 min-w-16 h-16  rounded-[10px] object-contain" />}
                    <div
                        className={`  w-[428px] h-[122px] rounded-xl border border-[#d0d5dd] hover:border-[#7b7e85] cursor-pointer flex-auto items-center justify-center py-4 px-6 ${
                            errors.profile_image_name ? 'is-invalid' : ''
                        }`}
                    >
                        <div className="relative overflow-hidden cursor-pointer">
                            <input type="file" id="profile_image_name" className="upload-pic-input" onChange={onChangeCrop} accept="image/png, image/jpg, image/jpeg" />
                            <label htmlFor="profile_image_name" className=" cursor-pointer   text-center ">
                                {''}

                                <div className="mx-auto mb-3 w-10 h-10 border border-[#EAECF0] bg-white rounded-lg flex items-center justify-center">
                                    {/* <img src={AddImgIcon} alt="" className="mb-2 w-10 h-10 inline-block" /> */}
                                    <UploadCloud02 width={20} height={20} />
                                </div>
                                <div className="text-grayLight600 text-xs font-normal">
                                    <p>
                                        <span className="text-[#2422DD] font-semibold pr-1">Click to upload </span>or drag and drop
                                    </p>
                                    <p className="text-[11px] leading-[18px]">SVG, PNG or JPG (max. 800x400px)</p>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={`h-[180px] w-[180px] rounded-full bg-inputBg mx-auto broder border-inputBg flex justify-center items-center relative ${errors.logo_image_name && 'is-invalid'}`}>
                <div className="bg-contentBackground w-full">
                    <div className="w-[full]">
                        <div className="w-auto">
                            {!cropData ? (
                                <div className="relative overflow-hidden">
                                    <input type="file" id="logo_image_name" className="upload-pic-input" onChange={onChangeCrop} accept="image/png, image/jpg" />
                                    <label htmlFor="logo_image_name" className="upload-pic-label h-full w-full rounded-full relative flex justify-center items-center">
                                        {''}
                                        <div className="text-center">
                                            <img src={AddImgIcon} alt="" className="mb-2 w-10 h-10 inline-block" />
                                            <p className="font-bold text-sm mb-1">Your logo</p>
                                            <p className="text-secondaryTxtColor ">
                                                JPG or PNG.
                                                <br /> Max size of 4MB
                                            </p>
                                        </div>
                                    </label>
                                </div>
                            ) : (
                                <>
                                    <div className="w-full h-full rounded-full relative overflow-hidden">
                                        <img src={cropData} alt="" className="w-full h-full" />
                                    </div>
                                    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center pr-[8px]">
                                        <div className="bg-inputBg p-2 w-14 h-14 rounded-full flex justify-center items-center">
                                            <Cloud width={20} height={20} />
                                        </div>
                                    </div>
                                    <label htmlFor="logo_image_name" className="absolute left-0 top-0 z-20 cursor-pointer rounded-md px-8 py-2 overflow-hidden h-full w-full">
                                        {''}
                                        <input
                                            type="file"
                                            id="logo_image_name"
                                            className="imgupload  w-full rounded-md text-mainTextColor px-8 py-2 mt-8 bg-btnclr"
                                            style={{
                                                visibility: 'hidden',
                                            }}
                                            onChange={onChangeCrop}
                                            accept="image/png, image/jpg"
                                        />
                                    </label>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <div className="flex flex-col mt-8">
                <Controller
                    name="country"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="mb-5">
                            <SelectBox
                                name="country"
                                label={t('Country')}
                                options={optionCountry}
                                onChangeFunc={(selectedOption: any) => {
                                    getStateFun(selectedOption.id);
                                    onChange(selectedOption.value);
                                }}
                                value={optionCountry.find((option) => option?.value === value)}
                            />
                            {errors.country && <p className="text-error">{errors.country.message}</p>}
                        </div>
                    )}
                />
                <div className="flex -mx-[10px]">
                    <Controller
                        name="state"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-1/2 mb-5 px-[10px]">
                                <SelectBox
                                    name="state"
                                    label={t('Province/State')}
                                    options={optionState}
                                    onChangeFunc={(selectedOption: any) => {
                                        getCityFun(selectedOption.id);
                                        onChange(selectedOption.value);
                                    }}
                                    value={value ? optionState.find((option) => option.value === value) : null}
                                />
                                {errors.state && <p className="text-error">{errors.state.message}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="city"
                        control={control}
                        render={({ field: { onChange, value } }: any) => (
                            <div className="w-1/2 mb-5 px-[10px]">
                                <SelectBox
                                    name="city"
                                    label={t('City')}
                                    options={optionCity}
                                    onChangeFunc={(selectedOption: any) => onChange(selectedOption.value)}
                                    value={value ? optionCity.find((option) => option.value === value) : null}
                                />
                                {errors.city && <p className="text-error">{errors.city.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <Controller
                    name="address"
                    control={control}
                    render={({ field: { onChange, value } }: any) => (
                        <div className="mb-5">
                            <InputWithLabel label={t('Business Address')} placeholder={t('Enter Address')} onChange={onChange} value={value} name="address" error={!!errors.address} />
                            {errors.address && <p className="text-error">{errors.address.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="staff_size"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <div>
                            <div className="fl-field-title-label">{t('Team Size')}</div>
                            <ul className="list-none inline-flex ">
                                <li className="mr-3">
                                    <input type="radio" id="independent" value="independent" className="hidden peer" onChange={() => onChange('independent')} checked={value === 'independent'} />
                                    <label
                                        htmlFor="independent"
                                        className="inline-flex items-center justify-between p-3 px-5 text-secondaryTxtColor bg-inputBg border border-transparent rounded-full cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary"
                                    >
                                        <div className="block text-sm">{t('Independent')}</div>
                                    </label>
                                </li>
                                <li className="mr-3">
                                    <input
                                        type="radio"
                                        id="block2to5"
                                        name="staff_size"
                                        value="block2to5"
                                        className="hidden peer"
                                        onChange={() => onChange('block2to5')}
                                        checked={value === 'block2to5'}
                                    />
                                    <label
                                        htmlFor="block2to5"
                                        className="inline-flex items-center justify-between  p-3 px-5 text-secondaryTxtColor bg-inputBg border border-transparent rounded-full cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary "
                                    >
                                        <div className="block text-sm whitespace-nowrap">{t('2-5')}</div>
                                    </label>
                                </li>
                                <li>
                                    <input
                                        type="radio"
                                        id="block6plus"
                                        name="staff_size"
                                        value="block6plus"
                                        className="hidden peer"
                                        onChange={() => onChange('block6plus')}
                                        checked={value === 'block6plus'}
                                    />
                                    <label
                                        htmlFor="block6plus"
                                        className="inline-flex items-center justify-between  p-3 px-5 text-secondaryTxtColor bg-inputBg border border-transparent rounded-full cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary "
                                    >
                                        <div className="block text-sm">{t('6+')}</div>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    )}
                />
            </div> */}
            {upload && <CropperModal defaultCropType="Rounded" imageUrl={image} imageUpload={imageUpload} setUpload={setUpload} setCropData={setCropData} />}
        </>
    );
};

export default BusinessInfo;
