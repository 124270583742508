import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import { ErrorType, IInitialState } from './City.interface';
import { errorCode } from 'src/constants/errorCode';

const initialState: IInitialState = {
    data: null,
    loading: false, // Add the loading property here
    error: null,
};

export const getCity = createAsyncThunk('/shop/city', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.LOCATION.CITY, {}, payload);
        if (response.data.status === errorCode.success || response.data.status === errorCode.updateSuccess) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const CitySlice = createSlice({
    name: 'shop/city',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCity.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCity.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(getCity.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});
export const { reducer: accountReducer } = CitySlice;
