import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import InputWithLabel from 'src/components/InputWithLabel';
import InputWithToggle from 'src/components/InputWithToggle';
import Switch from 'src/components/Switch/Switch';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import * as Yup from 'yup';
import { allShopSettings, currentRole, currentShop, me } from 'src/redux/services/common/Common.slice';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { ILoyalty } from './Loyalty.interface';
import { Tooltip } from 'flowbite-react';
import { ROLES } from 'src/constants/common';

const LoyaltyReferral = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const role = useAppSelector(currentRole);
    const shop = useAppSelector(currentShop);
    const shopSetting: any = useAppSelector(allShopSettings);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [toggle, setToggle] = useState({
        refferer_type: '$',
        referee_type: '$',
    });

    const conditionalSchema = (conditionFieldName: string | string[]) =>
        Yup.number()
            .typeError('This field not allowed null value')
            .when(conditionFieldName, {
                is: true,
                then: (schema: any) => schema.moreThan(0, 'Amount must be greater than zero').max(100, 'Amount must be less than 100').required('This field is required'),
                otherwise: (schema: any) => schema.notRequired(),
            });

    const schema = Yup.object({
        is_loyalty: Yup.boolean(),
        spend_amount: conditionalSchema('is_loyalty'),
        receive_point: conditionalSchema('is_loyalty'),
        spend_point: conditionalSchema('is_loyalty'),
        receive_amount: conditionalSchema('is_loyalty'),
        is_referral: Yup.boolean(),
        refferer: conditionalSchema('is_referral'),
        referee: conditionalSchema('is_referral'),
    }).required();

    const { handleSubmit, control, watch, setError, setValue, reset } = useForm<ILoyalty>({
        resolver: yupResolver(schema) as unknown as Resolver<ILoyalty>,
        defaultValues: {
            is_loyalty: false,
            receive_amount: 0,
            receive_point: 0,
            spend_point: 0,
            spend_amount: 0,
            is_referral: false,
            refferer: 0,
            referee: 0,
        },
    });

    const isLoyalty = watch('is_loyalty');
    const isReferral = watch('is_referral');

    const extractLoyaltyData = (loyaltyData: any) => {
        setValue('is_loyalty', loyaltyData.status);
        setValue('receive_amount', loyaltyData.value?.spend.amount);
        setValue('receive_point', loyaltyData.value?.receive.point);
        setValue('spend_point', loyaltyData.value?.spend.point);
        setValue('spend_amount', loyaltyData.value?.receive.amount);
    };

    const extractReferData = (referData: any) => {
        setValue('is_referral', referData.status);
        setValue('refferer', referData.value?.refferer);
        setValue('referee', referData.value?.referee);
        setToggle((prev: any) => ({
            ...prev,
            refferer_type: referData.value?.refferer_type === 'value' ? '$' : '%',
            referee_type: referData.value?.referee_type === 'value' ? '$' : '%',
        }));
    };

    useEffect(() => {
        if (shopSetting) {
            const loyalty = shopSetting.find((setting: any) => setting.type === 'loyalty');
            const refer = shopSetting.find((setting: any) => setting.type === 'refer');

            if (!loyalty && !refer) {
                resetForm();
            }
            if (loyalty) {
                extractLoyaltyData(loyalty);
            }
            if (refer) {
                extractReferData(refer);
            }
        } else {
            resetForm();
        }
    }, [shopSetting]);

    const handleInputChange = (fieldName: string, value: string) => {
        setToggle((old) => ({ ...old, ...{ [fieldName]: value } }));
    };

    const handleSave = (data: any) => {
        setIsLoading(true);
        const {
            is_loyalty: isLoyaltyPayload,
            spend_amount: spendAmount,
            receive_point: receivePoint,
            spend_point: spendPoint,
            receive_amount: receiveAmount,
            is_referral: isReferralPayload,
            refferer,
            referee,
        } = data;
        let payload = {};
        if (isLoyalty && isReferral) {
            payload = {
                is_loyalty: isLoyaltyPayload,
                spend_amount: spendAmount,
                receive_point: receivePoint,
                spend_point: spendPoint,
                receive_amount: receiveAmount,
                is_referral: isReferralPayload,
                refferer,
                referee,
                refferer_type: toggle.refferer_type === '%' ? 'percentage' : 'value',
                referee_type: toggle.referee_type === '%' ? 'percentage' : 'value',
            };
        } else if (isLoyalty) {
            payload = {
                is_loyalty: isLoyaltyPayload,
                is_referral: isReferralPayload,
                spend_amount: spendAmount,
                receive_point: receivePoint,
                spend_point: spendPoint,
                receive_amount: receiveAmount,
            };
        } else if (isReferral) {
            payload = {
                is_loyalty: isLoyaltyPayload,
                is_referral: isReferralPayload,
                refferer,
                referee,
                refferer_type: toggle.refferer_type === '%' ? 'percentage' : 'value',
                referee_type: toggle.referee_type === '%' ? 'percentage' : 'value',
            };
        } else {
            payload = {
                is_loyalty: isLoyaltyPayload,
                is_referral: isReferralPayload,
            };
        }

        axiosPost(API.REFER.CREATE_OR_UPDATE, payload, { shop_id: shop.id })
            .then(async (response) => {
                await dispatch(me());
            })
            .catch((error) => {
                if (error.data.status === errorCode.unprocessable) {
                    if (error.data.data) {
                        Object.keys(error.data.data).forEach((field) => {
                            setError(field as keyof ILoyalty, {
                                type: 'manual',
                                message: error.data.data[field][0],
                            });
                        });
                    }
                    return;
                }
            })
            .finally(() => setIsLoading(false));
    };

    const resetForm = () => {
        if (shopSetting) {
            const loyalty = shopSetting.find((setting: any) => setting.type === 'loyalty');
            const refer = shopSetting.find((setting: any) => setting.type === 'refer');
            if (loyalty || refer) {
                if (loyalty) {
                    extractLoyaltyData(loyalty);
                }
                if (refer) {
                    extractReferData(refer);
                }
            } else {
                reset();
            }
        } else {
            reset();
        }
    };
    return (
        <div className="w-full flex flex-col flex-1">
            <form onSubmit={handleSubmit(handleSave)}>
                <div className="w-[full] flex-1 flex flex-col  bg-white  ">
                    <div className="detail-page-title-block !py-[24px] !px-0">
                        <div className="flex flex-col gap-1">
                            <h2 className="text-[18px] font-semibold leading-[28px] flex flex-row gap-1.5 items-center">
                                <div className="text-mainTextColor">{t('Loyalty & Referral')}</div>
                                <Tooltip className="bg-black text-white" content={role.name === ROLES.OWNER || role.name === ROLES.INDIVIDUAL ? 'Brand owner' : 'Team Member'}>
                                    <InfoCircle className="text-[#98A2B3] w-5 h-5 custom-tooltip-btn" />
                                </Tooltip>
                            </h2>
                            <p className="text-xs text-secondaryTxtColor leading-[18px] ">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                        </div>
                        <div className="flex flex-row gap-3">
                            <CustomButton secondary disabled={isLoading} onClick={resetForm} className="!h-[36px]">
                                {t('Cancel')}
                            </CustomButton>
                            <CustomButton primary isLoading={isLoading} disabled={isLoading} type="submit" className="!h-[36px]">
                                {t('Save Changes')}
                            </CustomButton>
                        </div>
                    </div>
                    <div className="w-full flex flex-row  gap-[32px] border-b pt-5 pb-5">
                        <div className=" w-[280px]">
                            <p className="text-gray-700 font-semibold text-sm ">{t('Configure loyalty points')}</p>
                            <p className="text-secondaryTxtColor font-normal text-xs">{t('Customers can use the earned loyalty points to receive the service.')}</p>
                        </div>
                        <div className="flex flex-row w-[512px] gap-4">
                            <div className="flex items-start gap-2 text-sm font-medium text-gray-700">
                                <Controller
                                    name={`is_loyalty`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div className="flex items-center gap-2">
                                            <Switch onChange={onChange} isChecked={value} />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {isLoyalty && (
                        <>
                            <div className="w-full flex flex-row  gap-[32px] border-b pt-5 pb-5">
                                <div className=" w-[280px]">
                                    <p className="text-gray-700 font-semibold text-sm ">{t('Receiving loyalty points')}</p>
                                    <p className="text-secondaryTxtColor font-normal text-xs">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                                </div>
                                <div className="flex flex-row w-[636px] gap-4">
                                    <div className="  w-1/2">
                                        <Controller
                                            name="spend_amount"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        name="spend_amount"
                                                        id="spend_amount"
                                                        label={t('Client spend')}
                                                        type="number"
                                                        placeholder={t('Enter Client spend')}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={!!error}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="  w-1/2">
                                        <Controller
                                            name="receive_point"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        name="receive_point"
                                                        id="receive_point"
                                                        label={t('Loyalty points')}
                                                        placeholder={t('Enter loyalty points')}
                                                        value={value}
                                                        type="number"
                                                        onChange={onChange}
                                                        error={!!error}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex flex-row  gap-[32px] border-b pt-5 pb-5">
                                <div className=" w-[280px]">
                                    <p className="text-gray-700 font-semibold text-sm ">{t('Spending loyalty points')}</p>
                                    <p className="text-secondaryTxtColor font-normal text-xs">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                                </div>
                                <div className="flex flex-row w-[636px] gap-4">
                                    <div className="  w-1/2">
                                        <Controller
                                            name="spend_point"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        name="spend_point"
                                                        id="spend_point"
                                                        type="number"
                                                        label={t('Amount of points')}
                                                        placeholder={t('Amount of points')}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={!!error}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="  w-1/2">
                                        <Controller
                                            name="receive_amount"
                                            control={control}
                                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                <div className="w-full">
                                                    <InputWithLabel
                                                        name="receive_amount"
                                                        id="receive_amount"
                                                        type="number"
                                                        label={t('Equals this worth')}
                                                        placeholder={t('Equals this worth')}
                                                        value={value}
                                                        onChange={onChange}
                                                        error={!!error}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="w-full flex flex-row  gap-[32px] border-b pt-5 pb-5">
                        <div className=" w-[280px]">
                            <p className="text-gray-700 font-semibold text-sm ">{t('Enable client referrals')}</p>
                            <p className="text-secondaryTxtColor font-normal text-xs">{t('Customers can use the earned loyalty points to receive the service.')}</p>
                        </div>
                        <div className="flex flex-row w-[512px] gap-4">
                            <div className="flex items-start gap-2 text-sm font-medium text-gray-700">
                                <Controller
                                    name={`is_referral`}
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <div className="flex items-center gap-2">
                                            <Switch onChange={onChange} isChecked={value} />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {isReferral && (
                        <>
                            <div className="w-full flex flex-row  gap-[32px] border-b pt-5 pb-5">
                                <div className=" w-[280px]">
                                    <p className="text-gray-700 font-semibold text-sm ">{t("How much do Refferer's get discount")}</p>
                                    <p className="text-secondaryTxtColor font-normal text-xs">{t('CLorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                                </div>
                                <div className="flex flex-row w-[310px] gap-4">
                                    <Controller
                                        name="refferer"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithToggle
                                                    label={t('Refferer_discount_label')}
                                                    type="number"
                                                    name="refferer"
                                                    placeholder={t('Enter spend')}
                                                    toggleClassName="bg-blue-500 text-white drop-shadow-cardShadow"
                                                    value={value}
                                                    toggleValue={toggle.refferer_type}
                                                    setselected={(e: any) => {
                                                        handleInputChange('refferer_type', e);
                                                    }}
                                                    onChange={onChange}
                                                    error={!!error}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="w-full flex flex-row  gap-[32px] border-b pt-5 pb-5">
                                <div className=" w-[280px]">
                                    <p className="text-gray-700 font-semibold text-sm ">{t('How much do Referees get for their first appointment')}</p>
                                    <p className="text-secondaryTxtColor font-normal text-xs">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit.')}</p>
                                </div>
                                <div className="flex flex-row w-[310px] gap-4">
                                    <Controller
                                        name="referee"
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithToggle
                                                    label={t('Referees_appointment_label')}
                                                    type="number"
                                                    name="referee"
                                                    placeholder={t('Enter points')}
                                                    toggleClassName="bg-blue-500 text-white drop-shadow-cardShadow"
                                                    value={value}
                                                    toggleValue={toggle.referee_type}
                                                    setselected={(e: any) => {
                                                        handleInputChange('referee_type', e);
                                                    }}
                                                    onChange={onChange}
                                                    error={!!error}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="flex flex-row gap-3 w-full justify-end py-5 bottom-0">
                    <CustomButton secondary disabled={isLoading} onClick={resetForm} className="!h-[36px]">
                        {t('Cancel')}
                    </CustomButton>
                    <CustomButton primary type="submit" isLoading={isLoading} disabled={isLoading} className="!h-[36px]">
                        {t('Save Changes')}
                    </CustomButton>
                </div>
            </form>
        </div>
    );
};

export default LoyaltyReferral;
