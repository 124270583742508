import React, { useEffect, useMemo, useState } from 'react';
import General from './General/General';
import Logo from './Logo/Logo';
import Template from './Template/Template';
import PageHeader from 'src/components/PageHeader';
import { useTranslation } from 'react-i18next';

const Appearance = () => {
    const { t } = useTranslation();
    useEffect(() => {}, []);

    const tabs = useMemo(
        () => [
            {
                name: 'General',
                type: 'general',
            },
            {
                name: 'Logo',
                type: 'logo',
            },
            {
                name: 'Template',
                type: 'template',
            },
        ],
        [],
    );
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const handleTabClick = (tab: any) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab(tab);
    };

    return (
        <div className="inner-page-wrape ">
            <PageHeader title={t('Appearance')} subtitle="Configure the appearance, branding and template settings of your live website." />
            <div className="flex flex-col px-5 mt-1">
                <div className="fl-tab-btn-view w-full">
                    {tabs.map((tab, index) => (
                        <button key={index} type="button" className={`fl-tab-link ${activeTab.name === tab.name ? 'active' : ''}`} onClick={handleTabClick(tab)}>
                            {tab.name}
                        </button>
                    ))}
                </div>
                {activeTab.type === 'general' && <General />}
                {activeTab.type === 'logo' && <Logo />}
                {activeTab.type === 'template' && <Template />}
            </div>
        </div>
    );
};

export default Appearance;
