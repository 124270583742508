import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DateRangeFormatFunction, Calendar as ReactCalendar, SlotInfo, View, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';
import 'react-datepicker/dist/react-datepicker.css';
import Toolbar from '../Calendar/Toolbar';
import ResourceHeader from './ResourceHeader';
import './Calendar.scss';
import { IActionType, IResourceMap } from './Calendar.Interface';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { addEvent, initAction, selectCalendarData, setClearBooking, setData, setGetBookingList } from './Calendar.slice';
import { generateTimeRanges, getResourceOptions, getShortName, splitBookingTime } from 'src/utils/global-functions';
import Sidebar from './Sidebar/Sidebar';
import moment from 'moment-timezone';
import { axiosGet, axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import EventCard from './EventCard';
import usePrevious from 'src/hooks/usePrevious';
import { isEqual } from 'lodash';
import { allShopLocations, currentShop } from 'src/redux/services/common/Common.slice';
import Loader from 'src/components/Loader/Loader';
import { IStaff } from 'src/redux/services/common/Common.interface';
import PageHeader from 'src/components/PageHeader';
import CustomButton from 'src/components/CustomButton';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import { GoDotFill } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';

const Calendar = () => {
    const localizer = momentLocalizer(moment);
    const dispatch = useAppDispatch();
    const shop: any = useAppSelector(currentShop);
    const calendarData: any = useAppSelector(selectCalendarData);
    const shopId = shop?.id;
    const shopLocationList = useAppSelector(allShopLocations);
    const prevStateValue = usePrevious(calendarData);
    const calendarRef = useRef<HTMLDivElement>(null);
    const [appointmentEventList, setAppointmentEventList] = useState<any>([]);
    const [filter, setFilter] = useState(['confirmed', 'new', 'completed', 'no_show']); // Initialize filter as an array
    const [selectedEvent, setSelectedEvent] = useState<any>(null);

    const isNewEventAvailable = () => calendarData.appointmentList.some((event: any) => event.status === 'new');

    const updatedCalendarStep = (activeKey: keyof IActionType) =>
        Object.keys(initAction).reduce((acc, key) => {
            acc[key as keyof IActionType] = key === activeKey; // Only the activeKey is true, others false
            return acc;
        }, {} as IActionType);
    const handleClose = async () => {
        dispatch(setData({ action: initAction }));
    };

    const getStaff = async (staffArray: IStaff[]) => {
        if (staffArray?.length > 0) {
            let staffOptionData =
                staffArray?.length > 0
                    ? staffArray?.map((item: any) => ({
                          ...item,
                          value: item.id,
                          label: item.full_name,
                          image: item?.profile_image_url,
                          shortName: getShortName(item.full_name),
                      }))
                    : [];

            const allOption = { value: null, label: 'All team members', id: null };
            if (calendarData.view === 'day') {
                staffOptionData = [allOption].concat(staffOptionData);
            }

            const staffListResourceArray: any = await getResourceOptions(staffArray);
            dispatch(
                setData({
                    resourceList: staffListResourceArray,
                    staffOptions: staffOptionData,
                    isResourceListLoad: false,
                    selectedStaff: staffOptionData.length > 1 ? (calendarData.view === 'day' ? allOption : staffOptionData[0]) : null,
                }),
            );
        }
    };

    const getLocation = async () => {
        const updateState = {
            locationOptions: shopLocationList,
            selectedLocation: shopLocationList.length > 0 ? shopLocationList[0] : null,
        };
        await dispatch(setData(updateState));
    };

    const messages = {
        previous: 'Previous',
        today: 'Today',
        next: 'Next',
    };
    const handleNavigate = (date: Date) => {
        dispatch(setData({ calendarDate: moment(date).toDate() }));
    };
    const handleRangeChange = (range: Date[] | { start: Date; end: Date }) => {
        if (Array.isArray(range)) {
            dispatch(setData({ getBookingCalendarDate: moment(range[0]).toDate() }));
        } else {
            dispatch(setData({ getBookingCalendarDate: moment(range.start).toDate() }));
        }
    };

    const handleSelectSlot = async (slotInfo: SlotInfo) => {
        if (slotInfo.action === 'select') {
            try {
                const formattedDate = calendarData.timeZone && slotInfo.start.toLocaleString('en-US', { timeZone: calendarData.timeZone });
                dispatch(setData({ isLoading: true, calendarStep: updatedCalendarStep('newAppointment') }));
                dispatch(setClearBooking());
                const isNewAppoinment = await isNewEventAvailable();
                let updatedEvents = calendarData.appointmentList;
                if (isNewAppoinment) {
                    updatedEvents = calendarData.appointmentList.filter((event: any) => event.status !== 'new');
                }

                const isTimeslotOccupied = updatedEvents.some((event: any) => {
                    const eventStart = moment(`${event.booking_date} ${event.booking_start_time}`, 'YYYY-MM-DD HH:mm:ss').toDate();
                    const eventEnd = moment(`${event.booking_date} ${event.booking_end_time}`, 'YYYY-MM-DD HH:mm:ss').toDate();

                    return event.staff_id === slotInfo.resourceId && ((eventStart >= slotInfo.start && eventStart < slotInfo.end) || (eventEnd > slotInfo.start && eventEnd <= slotInfo.end));
                });

                if (!isTimeslotOccupied) {
                    const newSlotInfo = {
                        status: 'new',
                        booking_date: moment(slotInfo.start).format('YYYY-MM-DD'),
                        booking_start_time: moment(slotInfo.start).format('HH:mm:ss'),
                        booking_end_time: moment(slotInfo.end).format('HH:mm:ss'),
                        staff_id: slotInfo.resourceId ?? calendarData.selectedStaff.id,
                    };

                    dispatch(
                        setData({
                            newSlotInfo: slotInfo,
                            selectedDate: moment(formattedDate).toDate(),
                            selectedTime: moment(formattedDate).toDate(),
                            selectedSlotStaffId: Number(slotInfo.resourceId ?? calendarData.selectedStaff.id),
                            isSidebarOpen: true,
                            appointmentList: [...updatedEvents, newSlotInfo],
                        }),
                    );

                    dispatch(addEvent(newSlotInfo));
                    setTimeout(() => {
                        setSelectedEvent(newSlotInfo);
                    }, 2000);
                } else {
                    dispatch(setData({ selectedStaff: Number(slotInfo.resourceId ?? calendarData.selectedStaff.id), isSidebarOpen: true, calendarStep: initAction }));
                }
            } catch (error) {
                console.error('Error handling slot selection:', error);
            } finally {
                dispatch(setData({ isLoading: false }));
            }
        } else {
            await handleClearBooking();
            return;
        }
    };

    const handleClearBooking = async () => {
        const updatedEvents = calendarData.appointmentList.filter((event: any) => event.status !== 'new');

        setSelectedEvent(null);
        dispatch(setData({ appointmentList: updatedEvents, isSidebarOpen: false }));
        return;
    };

    const getBookingInfo = async (id: number) => {
        let payload = {
            shop_id: shopId,
            id: id,
        };

        try {
            const response = await axiosGet(API.BOOKING.GET, payload, {});
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                return response.data.data;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
            return null;
        } finally {
            dispatch(setData({ isLoading: false }));
        }
    };
    const handleSelectEvent = async (handleSelectEventInto: any) => {
        if (handleSelectEventInto.status === 'new') {
            return;
        }
        dispatch(setData({ isLoading: true }));

        const isNewAppoinment = isNewEventAvailable();
        if (isNewAppoinment) {
            const updatedEvents = calendarData.appointmentList.filter((event: any) => event.status !== 'new');
            dispatch(setData({ appointmentList: updatedEvents }));
        } else if (handleSelectEventInto.status !== 'block_time') {
            handleSelectEventInto = await getBookingInfo(handleSelectEventInto.id);
        }

        dispatch(
            setData({
                isSidebarOpen: true,
                calendarStep: updatedCalendarStep(handleSelectEventInto.status === 'block_time' ? 'blockTime' : 'bookedAppointment'),
                bookedSlotInfo: handleSelectEventInto,
                selectedSlotStaffId: Number(handleSelectEventInto.staff_id),
            }),
        );
        setTimeout(() => {
            setSelectedEvent(handleSelectEventInto);
        }, 200);
    };
    const getBookingList = async () => {
        if (calendarData.selectedLocation) {
            const payload: {
                location_id: number;
                booking_date: string;
                type: View;
                shop_id: number;
                staff_id?: number;
            } = {
                location_id: calendarData.selectedLocation.id,
                booking_date: moment(calendarData.getBookingCalendarDate).format('YYYY-MM-DD'),
                type: calendarData.view,
                shop_id: shopId,
            };

            if (calendarData.selectedStaff && calendarData.selectedStaff.id) {
                payload.staff_id = calendarData.selectedStaff.id;
            }

            try {
                const response = await axiosPost(API.BOOKING.LIST_BY_DATE, payload, {
                    shop_id: shopId,
                });
                if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                    const newArray = response.data.data.block_times.flatMap((item: any) => generateTimeRanges(item.from, item.to, item));
                    const bookingArray = response.data.data.bookings.flatMap((item: any) => splitBookingTime(item.booking_date, item.booking_start_time, item.booking_end_time, item));

                    const events = [...bookingArray, ...newArray];
                    dispatch(setData({ appointmentList: events }));
                    return events;
                }
            } catch (err: any) {
                toast.error(err?.message);
                return err;
            } finally {
                dispatch(setGetBookingList(false));
            }
        } else {
            dispatch(setData({ appointmentList: [] }));
        }
    };

    useEffect(() => {
        if (calendarData.selectedLocation) {
            const selectedLocation = shopLocationList?.find(({ id }) => id === calendarData.selectedLocation.id);
            if (selectedLocation) {
                const { timezone } = selectedLocation;
                moment.tz.setDefault(timezone);
                const defaultDate = moment().tz(timezone).toDate();
                dispatch(setData({ timeZone: timezone, defaultDate }));
            }
        }
    }, [calendarData.selectedLocation]);
    useEffect(() => {
        if (
            !isEqual(prevStateValue?.selectedStaff, calendarData.selectedStaff) ||
            !isEqual(prevStateValue?.selectedLocation, calendarData.selectedLocation) ||
            !isEqual(prevStateValue?.view, calendarData.view) ||
            !isEqual(prevStateValue?.getBookingCalendarDate, calendarData.getBookingCalendarDate)
        ) {
            getBookingList();
        }
    }, [calendarData.selectedStaff, calendarData.selectedLocation, calendarData.view, calendarData.getBookingCalendarDate]);
    useEffect(() => {
        if (calendarData.selectedLocation) {
            getStaff(calendarData.selectedLocation.shop_staff);
        }
    }, [calendarData.selectedLocation, calendarData.view]);

    useEffect(() => {
        if (calendarData.getBookingList) {
            getBookingList();
        }
    }, [calendarData.getBookingList]);

    useEffect(() => {
        const fetchData = async () => {
            await getLocation();
        };
        fetchData();
        return () => {
            handleClose();
            dispatch(setClearBooking());
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const currentTimeIndicator = calendarRef.current?.querySelector('.rbc-current-time-indicator');
            if (currentTimeIndicator) {
                currentTimeIndicator.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                });
                clearInterval(interval); // Stop polling once the element is found
            }
        }, 500); // Check every 100 milliseconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [calendarData.view]);
    useEffect(() => {
        handleAppointmentStatus(filter);
    }, [calendarData.appointmentList]);

    const handleChangeFilter = async (selectedOptions: any) => {
        const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
        setFilter(selectedValues);
        handleAppointmentStatus(selectedValues);
    };

    // const handleAppointmentStatus = (status: any) => {
    //     setFilter(status);
    //     const filteredData = status === 'all' ? calendarData.appointmentList : calendarData.appointmentList.filter((item: any) => item.status === status || item.status === 'new');
    //     setAppointmentEventList(filteredData);
    // };

    const handleAppointmentStatus = (statuses: string[]) => {
        const filteredData = calendarData.appointmentList.filter((item: any) => statuses.includes(item.status) || statuses.includes('new'));
        setAppointmentEventList(filteredData);
    };
    const selectRangeFormat: DateRangeFormatFunction = (range, culture, localizer1) => {
        const start = localizer1!.format(range.start, 'MMMM D', culture); // Format start date
        const end = localizer1!.format(range.end, 'MMMM D', culture); // Format end date

        return 'New';
    };
    localizer.formats = {
        ...localizer.formats,
        selectRangeFormat,
    };
    const onKeyPressEvent = async (event: any, keypressEvent: any) => {
        if (keypressEvent.keyCode === 27) {
            await handleClearBooking();
            return;
        }
    };
    const navigate = useNavigate();
    return (
        <div className="inner-page-wrape">
            <PageHeader title={'Calendar'} subtitle="Here’s a detailed overview of your completed and upcoming appointments.">
                <CustomButton
                    outlineSecondary
                    icon={<GoDotFill size={12} color="#17B26A" className="h-4 w-4 rounded-full flex " />}
                    className="w-full rounded-lg shadow-InputAndButton min-w-max pl-[10px]"
                >
                    Terminal Connected
                </CustomButton>
                <CustomButton primary icon={<Plus width="16" />} className="!px-4 py-[9px] w-full rounded-lg shadow-InputAndButton">
                    New Checkout
                </CustomButton>
            </PageHeader>
            <div className="side-spaching flex flex-1 flex-col w-full">
                <div ref={calendarRef} className={`calendar-block flex-1 ${calendarData.isSidebarOpen ? 'open_calendar' : 'close_calendar'}`}>
                    <ReactCalendar
                        events={appointmentEventList}
                        startAccessor={(event: any) => {
                            if (event.status === 'block_time') {
                                return moment(event.startTime).toDate();
                            } else {
                                return moment(`${event.booking_date} ${event.booking_start_time}`).toDate();
                            }
                        }}
                        endAccessor={(event: any) => {
                            if (event.status === 'block_time') {
                                return moment(event.endTime).toDate();
                            } else {
                                return moment(`${event.booking_date} ${event.booking_end_time}`).toDate();
                            }
                        }}
                        scrollToTime={moment().toDate()}
                        enableAutoScroll={true}
                        resources={calendarData.view === 'day' ? calendarData.resourceList : undefined}
                        onNavigate={handleNavigate}
                        resourceIdAccessor="staff_id"
                        resourceAccessor={(resource: IResourceMap) => resource.staff_id}
                        showMultiDayTimes={false}
                        localizer={localizer}
                        view={calendarData.view}
                        onView={() => {}}
                        messages={messages}
                        timeslots={4}
                        className="custom-calendar"
                        step={15}
                        dayLayoutAlgorithm={'no-overlap'}
                        onRangeChange={handleRangeChange}
                        defaultView={Views.DAY}
                        showAllEvents={false}
                        onSelectEvent={handleSelectEvent}
                        onDrillDown={(e) => console.log(e)}
                        onSelectSlot={handleSelectSlot}
                        selectable
                        selected={selectedEvent}
                        onKeyPressEvent={onKeyPressEvent}
                        date={calendarData.calendarDate}
                        defaultDate={calendarData.defaultDate}
                        components={{
                            resourceHeader: (eventInfo: any) => <ResourceHeader eventInfo={eventInfo} />,
                            timeGutterWrapper:
                                calendarData.isResourceListLoad && calendarData.resourceList.length === 0 && calendarData.view === 'day'
                                    ? () => (
                                          <div className="flex h-full w-full justify-center items-center">
                                              <Loader />
                                          </div>
                                      )
                                    : calendarData.view === 'day' && calendarData.resourceList.length === 0
                                    ? () => (
                                          <div className="flex h-full w-full justify-center items-center">
                                              <EmptyMsgWithBtn
                                                  title="No Staff Data Available"
                                                  description="Staff data will be available once Staff added."
                                                  btnLabel="Create staff"
                                                  onClick={() => navigate(ROUTES.STAFF.CREATE)}
                                              />
                                          </div>
                                      )
                                    : undefined,

                            week: {
                                header: (e) => (
                                    <>
                                        <div className="day-name ">{moment(e.date).format('ddd')}</div>
                                        <div className="day-date">{moment(e.date).format('D')}</div>
                                    </>
                                ),
                            },
                            event: (props: any) => <EventCard eventInfo={props} />,
                            toolbar: (props) => (
                                <Toolbar date={props.date} view={props.view} onNavigate={props.onNavigate} onView={props.onView} handleAppointmentStatus={handleChangeFilter} filter={filter} />
                            ),
                        }}
                    />
                </div>

                <Sidebar slotInfo={calendarData} isLoading={calendarData.isLoading} setState={() => {}} handleClose={handleClose} />
            </div>
        </div>
    );
};

export default Calendar;
