import React from 'react';
import { image1Location } from 'src/theme/Images';
import { Trash02, Edit05 } from '@untitled-ui/icons-react/build/cjs';

const SNote = () => (
    <>
        {[...Array(4)].map((_$, index) => (
            <div key={index} className="flex items-start mb-4 p-4 bg-white rounded shadow">
                <figure className="NoImgName custom-loading">
                    <img src={image1Location} alt="client" className="w-full h-full object-cover" />
                </figure>
                <div className="flex-grow">
                    <div className="flex justify-between items-center mb-2">
                        <div>
                            <span className="font-semibold custom-loading">loading loading</span>
                            <p className="text-sm text-gray-500 custom-loading">16 Jul 2024 - 02:37PM</p>
                        </div>
                        <div className="flex items-center">
                            <button className="text-gray-500 hover:text-gray-700 mr-2 custom-loading">
                                <Trash02 />
                            </button>
                            <button className="text-gray-500 hover:text-gray-700 custom-loading">
                                <Edit05 />
                            </button>
                        </div>
                    </div>
                    <span className="text-gray-700 custom-loading">loading loading loading loading loading loading</span>
                </div>
            </div>
        ))}
    </>
);

export default SNote;
