import { IPaymentValidationState } from '../../Calendar.Interface';

export interface IPaymentValidation {
    paymentMethod: IPaymentValidationState;
    tip: IPaymentValidationState;
    returnAmount: IPaymentValidationState;
    chargeAmount: IPaymentValidationState;
    otherPaymentMethod: IPaymentValidationState;
}

export const initValidation: IPaymentValidation = {
    paymentMethod: {
        status: false,
        message: '',
    },
    tip: {
        status: false,
        message: '',
    },
    returnAmount: {
        status: false,
        message: '',
    },
    chargeAmount: {
        status: false,
        message: '',
    },
    otherPaymentMethod: {
        status: false,
        message: '',
    },
};

export interface IInitPaymentStatus {
    processing: boolean;
    success: boolean;
    error: boolean;
    retry: boolean;
}
