import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import { FONT_FAMILY, FONT_WEIGHT, LogoDisplaySettingsProps } from './Logo.interface';

const LogoDisplaySettings: FC<LogoDisplaySettingsProps> = ({ setFormDetails }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    return (
        <div className={`flex gap-4 mb-3`}>
            <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value: nameValue }, fieldState: { error } }: any) => (
                    <div className="w-[198px]">
                        <InputWithLabel
                            label=""
                            placeholder="Please Enter text here"
                            inputControlClassName="text-xs form-control-md"
                            onChange={(event: any) => {
                                setFormDetails((prevFormDetails: any) => ({
                                    ...prevFormDetails,
                                    name: event.target.value,
                                }));
                                onChange(event.target.value);
                            }}
                            value={nameValue}
                            name="name"
                            isFocus={true}
                            error={!!error}
                        />
                        {error && <p className="text-error">{error?.message}</p>}
                    </div>
                )}
            />
            <Controller
                name="logo_font_family"
                control={control}
                render={({ field: { onChange, value: valueFontFamily }, fieldState: { error } }: any) => (
                    <div className="w-[160px]">
                        <SelectBox
                            value={FONT_FAMILY.filter((item: any) => item.value === valueFontFamily)}
                            name="logo_font_family"
                            id="logo_font_family"
                            options={FONT_FAMILY}
                            onChangeFunc={(selectedOption: any) => {
                                setFormDetails((prevFormDetails: any) => ({
                                    ...prevFormDetails,
                                    logo_font_family: selectedOption ? selectedOption.value : '',
                                }));
                                onChange(selectedOption ? selectedOption.value : null);
                            }}
                            placeholder="Font family"
                            errorText={!!error}
                        />
                        {error && <p className="text-error">{error?.message}</p>}
                    </div>
                )}
            />
            <Controller
                name="font_weight"
                control={control}
                render={({ field: { onChange, value: valueFontWeight }, fieldState: { error } }: any) => (
                    <div className="w-[150px]">
                        <SelectBox
                            value={FONT_WEIGHT.filter((item: any) => item.value === valueFontWeight)}
                            name="font_weight"
                            id="font_weight"
                            options={FONT_WEIGHT}
                            onChangeFunc={(selectedOption: any) => {
                                setFormDetails((prevFormDetails: any) => ({
                                    ...prevFormDetails,
                                    font_weight: selectedOption ? selectedOption.value : '',
                                }));
                                onChange(selectedOption ? selectedOption.value : null);
                            }}
                            placeholder="Font weight"
                            errorText={!!error}
                        />
                        {error && <p className="text-error">{error?.message}</p>}
                    </div>
                )}
            />
        </div>
    );
};

export default LogoDisplaySettings;
