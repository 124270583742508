import React, { useEffect } from 'react';
import { RouteProps, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { userMe } from 'src/redux/services/common/Common.slice';
import Forbidden from 'src/components/Error/403';
import { APP_FULL_NAME } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import { useRolePermission } from 'src/hooks/useRolePermission';
import { useAppSelector } from 'src/redux/hooks';
import { handleNavigate } from 'src/utils/global-functions';
import { useBreadcrumbs } from 'src/hooks/useBreadcrumbs';

type GuardedRouteProps = {
    Component: any;
    title?: string;
    view?: string;
    permission: string;
} & RouteProps;

const SIGNUP_ROUTES = [ROUTES.REGISTER, ROUTES.SIGNUP, ROUTES.INVITE];

const GuardedRoute: React.FC<GuardedRouteProps> = ({ Component, title, permission, ...props }): JSX.Element => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { hasPermission } = useRolePermission();
    const { initBreadcrumbs } = useBreadcrumbs();
    const user = useAppSelector(userMe);

    useEffect(() => {
        document.title = `${title} - ${APP_FULL_NAME}`;
    }, [title]);

    useEffect(() => {
        initBreadcrumbs();
    }, [pathname]);

    useEffect(() => {
        if (user) {
            if (!SIGNUP_ROUTES.includes(pathname)) {
                document.body.classList.add('app-page');
            } else {
                document.body.classList.remove('app-page');
            }
            navigate(handleNavigate(user, pathname));
        }
    }, []);

    return user ? hasPermission(permission) || permission === 'allow' ? <Component {...props} /> : <Forbidden /> : <Navigate to={ROUTES.HOME} />;
};

export default GuardedRoute;
