import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateSelect from 'src/components/DateSelect/DateSelect';
import { ArrowUpRight, ChevronDown, PieChart01, PieChart02 } from '@untitled-ui/icons-react/build/cjs';
import SelectBox from 'src/components/SelectBox/SelectBox';
import PageHeader from 'src/components/PageHeader';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, allShopStaff, currentShop } from 'src/redux/services/common/Common.slice';
import { getDateRange, getSelectBoxOptions } from 'src/utils/global-functions';
import moment from 'moment';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { IAnalyticsDetails } from '../Analytics.interface';
import OverviewNew from './OverviewNew';
import TeamPerformance from './TeamPerformance';
import Totalsaleschart from './Totalsaleschart';
import ClientSplit from './ClientSplit';
import DateRangePicker from 'src/components/DateRangePicker/DateRangePicker';
import AdvAnalytics from './AdvAnalytics';
import AddExpense from './AddExpense';
import ServiceChartPie from './ServiceChartPie';
import { ITimeFileterOptions } from 'src/app/Staff/Staff.interface';
import ProductChartPie from './ProductChartPie';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';

interface IGraphOptions {
    label: string;
    value: string;
    valueXField: string;
    seriesArray: any[];
}
const initAction = {
    expense: false,
};

const timeFileterOptions: ITimeFileterOptions[] = [
    { label: 'All time', value: 'all' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Last Month', value: 'last_month' },
];
const Analytics = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const shopLocationList: any = useAppSelector(allShopLocations);
    const allShopStaffList = useAppSelector(allShopStaff);
    const [isLoading, setIsLoading] = useState(false);
    const [AnalyticsDetails, setAnalyticsDetails] = useState<IAnalyticsDetails | null>(null);
    const [currentTab, setCurrentTab] = useState('daily');
    const [locationOptions, setLocationOptions] = useState<any[]>([]);
    const [staffOptions, setStaffOptions] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<Date | undefined>(moment().toDate());
    const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate());
    const [selectedTimeRange, setSelectedTimeRange] = useState<string>('custom');
    const [selectedFilter, setSelectedFilter] = useState({
        location_id: null,
        shop_admin_id: null,
    });
    const [boxLabel, setBoxLabel] = useState('Yesterday');
    const [action, setAction] = useState(initAction);

    const graphOptions: IGraphOptions = {
        label: 'Product Sales',
        value: 'product_amount',
        valueXField: 'booking_date',
        seriesArray: [
            { label: 'Product Sales', value: 'product_amount' },
            { label: 'Service Sales', value: 'service_amount' },
        ],
    };
    const timePeriodTabs = useMemo(
        () => [
            {
                name: t('Daily'),
                value: 'daily',
            },
            {
                name: t('Weekly'),
                value: 'weekly',
            },
            {
                name: t('Monthly'),
                value: 'monthly',
            },
            {
                name: t('Yearly'),
                value: 'yearly',
            },
        ],
        [],
    );

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions(locationData);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        setStaffOptions(staff);
    }, []);

    useEffect(() => {
        console.log('AnalyticsDetails', AnalyticsDetails);
    }, [AnalyticsDetails]);

    useEffect(() => {
        const fetchAnalytics = async () => {
            setIsLoading(true);
            const payload = {
                start_date: moment(startDate).format('YYYY-MM-DD'),
                end_date: moment(endDate).format('YYYY-MM-DD'),
                type: 'custom',
                ...(selectedFilter.location_id !== null && { location_id: selectedFilter.location_id }),
                ...(selectedFilter.shop_admin_id !== null && { staff_id: selectedFilter.shop_admin_id }),
            };
            await getAnalytics(payload);
        };
        fetchAnalytics();
    }, [selectedFilter, startDate, endDate]);

    useEffect(() => {
        if (startDate && endDate) {
            if (selectedTimeRange === 'all_time') {
                setBoxLabel('Previous period');
            } else if (selectedTimeRange === 'quarterly') {
                setBoxLabel('last quarter');
            } else if (currentTab === '') {
                let days = moment(endDate).diff(moment(startDate), 'days');
                days = selectedTimeRange === 'last_30_days' || selectedTimeRange === 'last_90_days' || selectedTimeRange === 'last_7_days' || selectedTimeRange === 'custom' ? days + 1 : days;

                setBoxLabel(`Previous ${days} days`);
            }
        }
    }, [startDate, endDate]);

    const getAnalytics = async (payload: any) => {
        await axiosGet(API.ANALYTICS.GET, { shop_id: shop.id }, payload)
            .then((response) => {
                const data = response.data.data;
                setAnalyticsDetails(data);
            })
            .finally(() => setIsLoading(false));
    };

    const handleTab = (val: string) => () => {
        setCurrentTab(val);
    };

    useEffect(() => {
        if (currentTab !== '') {
            const { start, end } = getDateRange(currentTab);
            setStartDate(start);
            setEndDate(end);
        }
        if (currentTab === 'weekly') {
            setBoxLabel('last week');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'monthly') {
            setBoxLabel('last month');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'yearly') {
            setBoxLabel('last year');
            setSelectedTimeRange(currentTab);
        } else if (currentTab === 'daily') {
            setBoxLabel('yesterday');
            setSelectedTimeRange('custom');
        }
    }, [currentTab]);
    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setSelectedFilter((old: any) => ({ ...old, [filter]: value }));
    };
    const handleDatePickerChange = (date: any, timeRange?: string) => {
        if (timeRange === 'weekly' || timeRange === 'monthly' || timeRange === 'yearly') {
            setCurrentTab(timeRange);
        } else {
            setCurrentTab('');
            const [start, end] = date;

            setStartDate(start);
            setEndDate(end);
            if (!end) {
                setEndDate(start);
            }
        }
        if (timeRange) {
            setSelectedTimeRange(timeRange);
        }
    };

    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    return (
        <div className="inner-page-wrape">
            <PageHeader title={'Analytics'} subtitle="Manage your client and their account permissions here."></PageHeader>
            <div className=" side-spaching flex flex-1 flex-col w-full">
                <div className="flex flex-row justify-between  mb-5">
                    <div className="flex flex-row gap-3">
                        <div className="fl-tab-btn-view2 min-h-[36px] w-full">
                            {timePeriodTabs.map((tab: any, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    onClick={handleTab(tab.value)}
                                    className={`w-full fl-tab-link2 justify-center items-center min-h-[34px] ${tab.value === currentTab ? 'active' : ''}`}
                                >
                                    {tab.name}
                                </button>
                            ))}
                        </div>

                        <DateRangePicker
                            isDisplayAsBox={false}
                            handleDatePickerChange={handleDatePickerChange}
                            selectedTimeRange={selectedTimeRange}
                            startDate={startDate}
                            endDate={endDate}
                            allTimeStartDate={shop.created_at}
                            showYearDropdown={false}
                            isToday={currentTab === 'daily'}
                            isShowDropDownIcon={false}
                            containerClassName="left-0 "
                            parentClassName="sales-datepicker w-[340px] min-w-[340px]"
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            activeFilter={currentTab}
                            setActiveFilter={setCurrentTab}
                        />
                    </div>
                    <div className="flex flex-row gap-3">
                        <div className="2xl:w-[200px] w-[160px]">
                            <SelectBox
                                options={locationOptions}
                                value={selectedFilter.location_id ? locationOptions.find((option) => option.value === selectedFilter.location_id) : null}
                                noOptionsMessage="No Locations Found"
                                placeholder={t('All locations')}
                                onChangeFunc={handleFilter('location_id')}
                                classComp="outline-select-box"
                            />
                        </div>
                        <div className="2xl:w-[200px] w-[160px]">
                            <SelectBox
                                options={staffOptions}
                                noOptionsMessage="No Staff Found"
                                value={selectedFilter.location_id ? staffOptions.find((option) => option.value === selectedFilter.shop_admin_id) : null}
                                placeholder={t('All staff members')}
                                onChangeFunc={handleFilter('shop_admin_id')}
                                classComp="outline-select-box"
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full flex max-2xl:flex-col flex-row gap-3 mb-4 flex-1">
                    <div className=" flex flex-col gap-3  w-[calc(73%-12px)] xlm:w-[70%]  max-2xl:w-full">
                        <OverviewNew AnalyticsDetails={AnalyticsDetails} boxLabel={boxLabel} />
                        {AnalyticsDetails?.graph.length ? (
                            <Totalsaleschart
                                salesLineGraph={AnalyticsDetails?.graph || []}
                                seriesArray={graphOptions.seriesArray}
                                graphType={graphOptions.value}
                                valueXField={graphOptions.valueXField}
                                activeFilter={currentTab}
                            />
                        ) : (
                            <>
                                <div className="border border-borderSecondary rounded-xl shadow mb-4 p-5">
                                    <EmptyMsgWithBtn title={t('No Total Sales Data')} description={t('No_Sales_Data_description')} />
                                </div>
                            </>
                        )}
                    </div>
                    <div className="w-[27%] xlm:w-[30%] max-2xl:w-full flex flex-1 analytics">
                        <div className="w-full border border-borderSecondary rounded-xl flex flex-1 min-h-[696px] max-h-[696px] flex-col  shadow ">
                            <div className="px-5 py-5  flex justify-between items-center">
                                <div className="font-semibold text-lg 2xl:text-base xlm:text-xl text-mainTextColor">Team Performance</div>
                                <div className="flex flex-row gap-1">
                                    <h4 className="font-semibold text-xs text-[#143CB3]">View All</h4>
                                    <ArrowUpRight className="w-4 h-4  text-[#143CB3]" />
                                </div>
                            </div>
                            {/* <TeamPerformance /> */}
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-row gap-5 mb-4">
                    <div className=" flex flex-col gap-5 w-[50%]">
                        <div
                            className={`flex flex-row gap-4 w-full border border-borderSecondary rounded-lg ${
                                AnalyticsDetails?.products_graph && AnalyticsDetails?.products_graph.length > 0 ? 'min-h-[172px] max-h-[172px]' : 'h-full'
                            }  p-4`}
                        >
                            <div
                                className={`flex items-center  ${
                                    AnalyticsDetails?.products_graph && AnalyticsDetails?.products_graph.length > 0 ? 'min-w-[132px] max-w-[132px]' : 'min-w-[196px] max-w-[196px]'
                                } `}
                            >
                                {AnalyticsDetails && <ServiceChartPie salesIncome={AnalyticsDetails?.services_graph} timeFileterSelected={timeFileterOptions[0]} />}
                            </div>
                            <div className="flex w-full">
                                <h1>INFO</h1>
                            </div>
                        </div>
                        {AnalyticsDetails?.products_graph && AnalyticsDetails?.products_graph.length > 0 && (
                            <div className="flex flex-row gap-4 w-full border border-borderSecondary rounded-lg min-h-[172px] max-h-[172px] p-4">
                                <div className="flex items-center min-w-[132px] max-w-[132px]">
                                    {AnalyticsDetails && <ProductChartPie salesIncome={AnalyticsDetails?.products_graph} timeFileterSelected={timeFileterOptions[0]} />}
                                </div>
                                <div className="flex w-full">
                                    <h1>INFO</h1>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="w-[50%]">
                        <ClientSplit />
                    </div>
                </div>
                <div className="w-full flex flex-row mb-5">
                    <AdvAnalytics setAction={setAction} />
                </div>
            </div>
            {action.expense && <AddExpense handleClose={handleModalClose} />}
        </div>
    );
};

export default Analytics;
