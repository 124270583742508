import { TemplateType } from '../TemplateSettings.interface';

export interface IStaff {
    name: string;
    is_instgram_button: boolean;
    instgram_button?: string;
    role: any;
    image?: {
        file: File | null;
        url: string | null;
        name: string | null;
    };
    location: string[];
}

export interface IFormData {
    status: boolean;
    is_title: boolean;
    title?: string;
    is_subtitle: boolean;
    subtitle?: string;
    is_staff: boolean;
    staff: IStaff[];
}

export const InitTeamData: IFormData = {
    status: false,
    is_title: false,
    title: '',
    is_subtitle: false,
    is_staff: false,
    subtitle: '',
    staff: [] as IStaff[],
};

export const InitStaffData: IStaff = {
    name: '',
    is_instgram_button: false,
    instgram_button: '',
    role: null,
    image: {
        file: null,
        url: null,
        name: null,
    },
    location: [],
};
export const InitRoleOptions = [{ id: 0, label: 'Select Role', value: null }];
export interface TeamProps {
    template: TemplateType;
}
