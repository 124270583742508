import React, { useEffect, useMemo, useState } from 'react';
import CustomDropdownWithDate from 'src/components/CustomDropdownWithDate/CustomDropdownWithDate';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { useAppSelector } from 'src/redux/hooks';
import { allShopLocations, allShopStaff, currentShop } from 'src/redux/services/common/Common.slice';
import { formatName, getSelectBoxOptions, getShortName } from 'src/utils/global-functions';
import moment from 'moment';
import { toast } from 'react-toastify';
import { errorCode } from 'src/constants/errorCode';
import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import CustomButton from 'src/components/CustomButton';
import { Column } from 'primereact/column';
import { IPayload, ITimeFileterOptions } from './Staff.interface';
import NoDataMessage from 'src/components/NoDataMessage';
import { ROUTES } from 'src/constants/routes';
import SearchBar from 'src/components/SearchBar/SearchBar';
import { useNavigate } from 'react-router-dom';
import { perPageOptions } from 'src/utils/global-variables';
import { ArrowUp, ArrowDown, SearchLg, Users01 } from '@untitled-ui/icons-react/build/cjs';
import { Skeleton } from 'primereact/skeleton';

const timeFileterOptions: ITimeFileterOptions[] = [{ label: 'All time', value: 'all' }];

const StaffRentTable = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const shopLocationList: any = useAppSelector(allShopLocations);
    const allShopStaffList = useAppSelector(allShopStaff);
    const [loading, setLoading] = useState<boolean>(true);
    const [lazyState, setLazyState] = useState<any>({
        first: 0,
        rows: 25,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            global: { value: null },
            type: { value: 'all' },
            shop_location_id: { value: null },
            shop_admin_id: { value: null },
        },
    });
    const [filters, setFilters] = useState<any>({
        global: { value: null },
        type: { value: 'all' },
        shop_location_id: { value: null },
        shop_admin_id: { value: null },
    });

    const [locationOptions, setLocationOptions] = useState([{ label: 'All locations', value: '' }]);
    const [staffOptions, setStaffOptions] = useState([{ label: 'All staff members', value: '' }]);
    const [timeFileterSelected, setTimeFileterSelected] = useState(timeFileterOptions[0]);
    const [selectedFilter, setSelectedFilter] = useState({
        shop_location_id: null,
        shop_admin_id: null,
    });
    const [rentCollectionList, setRentCollectionList] = useState<any>([]);
    const [totalListRecords, settotalListRecords] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const locationData = getSelectBoxOptions(shopLocationList, 'id', 'name', 'id', 'shortname', 'profile_image_url');
        setLocationOptions(locationData);

        const staff = getSelectBoxOptions(allShopStaffList, 'id', 'full_name', 'id', 'full_name', 'profile_image_url');
        setStaffOptions(staff);
    }, []);

    const handleFilter = (filter: string) => (event: any) => {
        const value = event ? event.value : event;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, [filter]: { value } } }));
        setSelectedFilter((old: any) => ({ ...old, [filter]: event }));
    };

    const handleTimeFilter = (value: any) => {
        setTimeFileterSelected(value);

        if (value.value === 'custom') {
            setLazyState({
                ...lazyState,
                page: 1,
                first: 0,
                filters: {
                    ...lazyState.filters,
                    start_date: {
                        value: moment(value.startDate).format('YYYY-MM'),
                    },
                    end_date: {
                        value: moment(value.endDate).format('YYYY-MM'),
                    },
                },
            });
        } else {
            const { start_date: filterStartDate, end_date: filterEndDate, ...updatedFilters } = lazyState.filters;
            setLazyState({
                ...lazyState,
                filters: updatedFilters,
            });
        }
    };

    const getRentList = async () => {
        setLoading(true);

        try {
            let payload: IPayload = {
                ...lazyState,
            };

            const response = await axiosGet(
                API.PAYMENT.RENT_LIST,
                {
                    shop_id: shop.id,
                },
                payload,
            );
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setRentCollectionList(response.data.data.data);
                settotalListRecords(response.data.data.totalRecords);
                setLoading(false);
                // setDashboardDetails(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setFilters(lazyState.filters);
            setLoading(false);
        }
    };

    useEffect(() => {
        getRentList();
    }, [lazyState]);

    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink NextPageLink ',
        PrevPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick} className="mr-3 text-sm">
                {t('Previous')}
            </CustomButton>
        ),
        NextPageLink: ({ onClick }: any) => (
            <CustomButton secondary onClick={onClick}>
                {t('Next')}
            </CustomButton>
        ),
        CurrentPageReport: ({ first, rows, totalRecords }: { first: number; rows: number; totalRecords: number }) => {
            const currentPage = Math.floor(first / rows) + 1;
            const totalPages = Math.ceil(totalRecords / rows);

            return (
                <div className="text-gray-700 flex items-center text-sm font-medium mr-auto">
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                </div>
            );
        },
    };

    const handleSearch = (event: any) => {
        const value = event.target.value;
        setLazyState((old: any) => ({ ...old, filters: { ...old.filters, global: { value } } }));
    };

    const onPageHandle = (event: any) => {
        setLazyState((old: any) => ({ ...old, rows: event.value }));
    };

    const handleClick = () => {
        navigate(ROUTES.STAFF.CREATE);
    };

    const onPage = (event: any) => {
        setLazyState(event);
    };

    const onSort = (event: any) => {
        setLazyState(event);
    };

    const onFilter = (event: any) => {
        event.first = 0;
        setLazyState(event);
    };

    const handleRowClick = (rowData?: any) => {
        // if (rowData) setStaffInfo(rowData.data);
        setIsDrawerOpen(true);
    };

    const GetFullName = (row: any) => {
        const [isImageError, setIsImageError] = useState<boolean>(false);
        return (
            <div className="flex items-center">
                <figure className="NoImgName">
                    {row.staff.profile_image_url && !isImageError ? (
                        <img src={row.staff.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                    ) : (
                        getShortName(`${row.staff.full_name}`)
                    )}
                </figure>
                <div className="text-mainTextColor">
                    <p className="text-xs font-medium mb-[1px] leading-[140%] -tracking-[0.14px]">{row.staff.full_name ? formatName(row.staff.full_name) : ''}</p>
                    <p className="text-xs font-normal leading-[150%] -tracking-[0.12px] text-secondaryTxtColor">{row.staff.staff_role.name ? `${row.staff.staff_role.name}` : ''}</p>
                </div>
            </div>
        );
    };

    const GetTotal = (row: any) => `$${row.amount}`;
    const GetStatus = (row: any) => (row.is_paid ? 'Paid' : 'Unpaid');
    const GetStartDate = (row: any) => moment(row.start_date).format('DD/MM/YYYY');
    const GetEndDate = (row: any) => moment(row.end_date).format('DD/MM/YYYY');

    const renderHeader = (header: any, field: any) => {
        const activeSortIcon =
            lazyState.sortField === field ? (
                lazyState.sortOrder === 1 ? (
                    <ArrowUp className="w-4 text-gray-600" />
                ) : (
                    <ArrowDown className="w-4 text-gray-600" />
                )
            ) : (
                <ArrowDown className="w-4 text-gray-600 opacity-50" />
            );
        return (
            <>
                {header} {activeSortIcon}
            </>
        );
    };

    const skeletons = useMemo(
        () =>
            Array.from({ length: lazyState.rows }).map(() => ({
                start_date: 'loading',
                end_date: 'loading',
                amount: 'loading',
                is_paid: 'loading',
                first_name: 'loading',
            })),
        [lazyState],
    );
    return (
        <div className="w-full border rounded-xl mt-4 flex-1  flex-col mb-5 flex shadow">
            <div className={`w-full justify-between items-center flex-col flex flex-1 ${rentCollectionList.length === 0 ? 'datatable-full-height' : ''}`}>
                <div className={`w-full justify-between items-center border-b border-gray-200 `}>
                    <div className="flex items-center px-5 py-3 justify-between w-full border-b border-gray-200 gap-4">
                        <div className="flex flex-col flex-1">
                            <div className="flex justify-start items-center gap-1.5">
                                <h2 className="table-title">{t('All Rent Collections')}</h2>
                                {/* <p
                                    className={`pl-[4px] pr-[6px] py-[2px] text text-xs text-gray-700 font-medium  border inline-flex shadow items-center justify-start rounded-md border-gray-300 ${
                                        isLoading ? 'custom-loading' : ''
                                    }`}
                                >
                                    <GoDotFill size={12} color="#17B26A" className="h-3 w-3 rounded-full flex mr-0.5" />
                                    {totalListRecords} {t('Members')}
                                </p> */}
                            </div>
                            <p className="table-subtitle">{t('Track and manage rent collection history from your rent-based team members.')}</p>
                        </div>
                        <div className=" flex relative">
                            {/* <CustomButton primary onClick={handleClick} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                                {t('Add team member')}
                            </CustomButton> */}
                        </div>
                    </div>
                    <div className="flex items-center px-5 py-3 justify-between w-full ">
                        <div className="table-searchInput">
                            <SearchBar placeholder={t('Search')} className="form-control-md " onChange={handleSearch} />
                        </div>
                        <div className="flex gap-3">
                            <div className="w-[200px]">
                                <SelectBox
                                    options={locationOptions}
                                    placeholder={t('All locations')}
                                    noOptionsMessage="No Locations Found"
                                    value={selectedFilter.shop_location_id}
                                    onChangeFunc={handleFilter('shop_location_id')}
                                    classComp="outline-select-box"
                                />
                            </div>

                            <div className="w-[200px]">
                                <SelectBox
                                    options={staffOptions}
                                    noOptionsMessage="No Staff Found"
                                    value={selectedFilter.shop_admin_id}
                                    placeholder={t('All staff members')}
                                    onChangeFunc={handleFilter('shop_admin_id')}
                                    classComp="outline-select-box"
                                />
                            </div>
                            <div className="w-[200px] location-dropdown">
                                <div className="w-full flex justify-center">
                                    <CustomDropdownWithDate data={timeFileterOptions} handleChangeValue={handleTimeFilter} value={timeFileterSelected} />
                                </div>
                            </div>
                            <div className="w-[70px] xl:w-[70px] page-dropdown">
                                <SelectBox
                                    name="page"
                                    options={perPageOptions}
                                    isClearable={false}
                                    isSearchable={false}
                                    onChangeFunc={onPageHandle}
                                    value={perPageOptions.find((option) => option.value === lazyState.rows)}
                                    classComp="outline-select-box"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <DataTable
                    paginatorTemplate={customPaginatorTemplate}
                    paginatorClassName="table-pagination"
                    value={loading ? skeletons : rentCollectionList}
                    lazy
                    paginator={!loading && totalListRecords > lazyState.rows}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    totalRecords={totalListRecords}
                    onPage={onPage}
                    onSort={onSort}
                    sortOrder={lazyState.sortOrder}
                    sortField={lazyState.sortField}
                    onFilter={onFilter}
                    filters={lazyState.filters}
                    dataKey="id"
                    className="w-full text-sm flex-1 text-mainTextColor rounded-b-xl overflow-hidden fl-data-table"
                    selectionMode="single"
                    // selection={clientInfo}
                    // onSelectionChange={(e) => setClientInfo(e.value)}
                    onRowClick={handleRowClick}
                    emptyMessage={
                        <NoDataMessage
                            title={`${
                                filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value ? 'No rent collection records found.' : 'No rent collection records found.'
                            }`}
                            description={`${
                                filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value
                                    ? t('No statements found matching your search criteria. Try adjusting your filters or record new transactions.')
                                    : t('Add a team member with a rent-based payment structure to start tracking rent collection statements.')
                            }`}
                            iconComponent={
                                filters.global.value || filters.shop_location_id.value || filters.shop_admin_id.value ? <SearchLg className="text-gray-700" /> : <Users01 className="text-gray-700" />
                            }
                        />
                    }
                >
                    <Column
                        field="first_name"
                        header={renderHeader(t('Name'), 'first_name')}
                        style={{ width: '25%' }}
                        body={loading ? <Skeleton /> : GetFullName}
                        className="font-medium text-mainTextColor"
                        sortable
                    ></Column>
                    <Column
                        field="start_date"
                        header={renderHeader(t('Period Start Date'), 'start_date')}
                        body={loading ? <Skeleton /> : GetStartDate}
                        className="font-normal text-xs text-secondaryTxtColor"
                        sortable
                    ></Column>
                    <Column
                        field="end_date"
                        header={renderHeader(t('Period End Date'), 'end_date')}
                        body={loading ? <Skeleton /> : GetEndDate}
                        className="font-normal text-xs text-secondaryTxtColor"
                        sortable
                    ></Column>
                    <Column
                        field="amount"
                        header={renderHeader(t('Rent Amount'), 'amount')}
                        className="font-normal text-xs text-secondaryTxtColor"
                        body={loading ? <Skeleton /> : GetTotal}
                        sortable
                    ></Column>
                    <Column
                        field="is_paid"
                        header={renderHeader(t('Payment Status'), 'is_paid')}
                        className="font-normal text-xs text-secondaryTxtColor"
                        body={loading ? <Skeleton /> : GetStatus}
                        sortable
                    ></Column>
                </DataTable>
            </div>
        </div>
    );
};

export default StaffRentTable;
