import { useState } from 'react';

const useImageOrientation = () => {
    const [isHorizontal, setIsHorizontal] = useState(false);
    const isImageHorizontal = (imageUrl: any) => {
        if (imageUrl) {
            const img = new Image();
            img.src = imageUrl;

            img.onload = () => {
                const { width, height } = img;
                setIsHorizontal(width > height);
            };

            img.onerror = () => {
                console.error('Failed to load image');
                setIsHorizontal(false);
            };

            return isHorizontal;
        }
    };

    return { isImageHorizontal, isHorizontal };
};

export default useImageOrientation;
