import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './../../utils/Datatable.scss';
import './../../utils/prime-react-datatable.scss';
import { Label, Radio } from 'flowbite-react';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { IPayFormData, PAY_STRUCTURE_LIST, initAction } from './Staff.interface';
import { yupResolver } from '@hookform/resolvers/yup';
import InputWithLabel from 'src/components/InputWithLabel';
import PopupModal from 'src/components/PopupModal';
import { Slider } from 'primereact/slider';
import DateSelect from 'src/components/DateSelect/DateSelect';
import moment from 'moment';
import StaffPolicy from './StaffPolicy';
import { ChevronDown, Edit05, Wallet02 } from '@untitled-ui/icons-react/build/cjs';
import { CheckCircleIcon } from 'src/theme/Images';
import Switch from 'src/components/Switch/Switch';
import { DATE_FORMAT } from 'src/constants/common';
import { getDayNumber } from 'src/utils/global-functions';
interface ICalculateDueDateAndRent {
    paymentType: string;
    shopPayment: any;
    moneywatch: any;
    currentDateTime: moment.Moment;
}

const calculateDueDateAndRent = ({ currentDateTime, paymentType, shopPayment, moneywatch }: ICalculateDueDateAndRent) => {
    const weekNumber = Number(currentDateTime.week());
    let dueDate;
    let rent;

    if (paymentType === 'commission') {
        // const weekNumber = currentDateTime.week();
        const commissionDay = shopPayment.commission_day;
        const commissionDayNumber = getDayNumber(commissionDay);
        const currentDateDayNumber = getDayNumber(currentDateTime.format('dddd').toLowerCase());

        const commissionStartDate = Number(moment(shopPayment.commission_start_date).week());
        const isEvenWeek = commissionStartDate % 2 === 0;
        const isEvenCurrentWeek = weekNumber % 2 === 0;

        // weekly
        if (shopPayment?.commission_frequency === 'weekly') {
            if (commissionDay === currentDateTime.format('dddd').toLowerCase()) {
                dueDate = currentDateTime.add(0, 'days');
            } else {
                dueDate = currentDateTime.clone().day(commissionDayNumber);
                if (dueDate.isBefore(currentDateTime, 'day')) {
                    dueDate.add(7, 'days');
                }
            }
        }
        // bi-weekly
        else if (shopPayment?.commission_frequency === 'bi_weekly') {
            if (isEvenCurrentWeek === isEvenWeek) {
                if (currentDateDayNumber <= commissionDayNumber) {
                    dueDate = currentDateTime.clone().startOf('isoWeek').day(commissionDay);
                } else {
                    dueDate = currentDateTime.clone().add(2, 'week').startOf('isoWeek').day(commissionDay);
                }
            } else {
                dueDate = currentDateTime.clone().add(1, 'week').startOf('isoWeek').day(commissionDay);
            }

            if (currentDateTime.format('dddd') === 'Sunday') {
                dueDate = dueDate.clone().add(1, 'week');
            }
        }
        // month
        else {
            if (shopPayment?.commission_month_day === 'first') {
                dueDate = currentDateTime.clone().add(1, 'month').startOf('month');
            } else {
                dueDate = currentDateTime.clone().endOf('month');
            }
        }
    } else {
        const rentDay = shopPayment.rent_day;
        const rentDayNumber = getDayNumber(rentDay);
        const currentDateDayNumber = getDayNumber(currentDateTime.format('dddd').toLowerCase());

        const rentStartDate = Number(moment(shopPayment.rent_start_date).week());
        const isEvenWeek = rentStartDate % 2 === 0;
        const isEvenCurrentWeek = weekNumber % 2 === 0;
        // weekly
        if (shopPayment?.rent_frequency === 'weekly') {
            if (rentDay === currentDateTime.format('dddd').toLowerCase()) {
                dueDate = currentDateTime.add(0, 'days');
            } else {
                dueDate = currentDateTime.clone().day(rentDay);
                if (dueDate.isBefore(currentDateTime, 'day')) {
                    dueDate.add(7, 'days');
                }
            }
        }
        // bi-weekly
        else if (shopPayment?.rent_frequency === 'bi_weekly') {
            // const weekNumber = currentDateTime.week();
            if (isEvenCurrentWeek === isEvenWeek) {
                if (currentDateDayNumber <= rentDayNumber) {
                    dueDate = currentDateTime.clone().startOf('isoWeek').day(rentDay);
                } else {
                    dueDate = currentDateTime.clone().add(2, 'week').startOf('isoWeek').day(rentDay);
                }
            } else {
                dueDate = currentDateTime.clone().add(1, 'week').startOf('isoWeek').day(rentDay);
            }

            if (currentDateTime.format('dddd') === 'Sunday') {
                dueDate = dueDate.clone().add(1, 'week');
            }
        } else {
            if (shopPayment?.rent_month_day === 'first') {
                dueDate = currentDateTime.clone().add(1, 'month').startOf('month');
            } else {
                dueDate = currentDateTime.clone().endOf('month');
            }
        }
        if (dueDate) {
            const differenceInDays = dueDate.diff(currentDateTime, 'days');
            if (!isNaN(differenceInDays)) {
                if (shopPayment?.rent_frequency === 'weekly') {
                    rent = (moneywatch * (differenceInDays + 1)) / 7;
                } else if (shopPayment?.rent_frequency === 'bi_weekly') {
                    rent = (moneywatch * (differenceInDays + 1)) / 14;
                } else {
                    const daysInMonth = moment(currentDateTime, 'YYYY-MM').daysInMonth();
                    const dateOfSelectedValue = currentDateTime.date();
                    let pendingDays = daysInMonth - dateOfSelectedValue;
                    pendingDays += 1;
                    rent = (pendingDays * moneywatch) / daysInMonth;
                }
            }
        }
    }

    return { dueDate, rent };
};

const StaffPaystructure = ({ handleClose, setPayStructure, payStructure, shopPayment, firstName }: any) => {
    const { t } = useTranslation();
    const [step, setStep] = useState<number>(1);
    const [title, setTitle] = useState(t('Select a pay structure'));
    const [description, setDescription] = useState(t('How do we handle staff payments?'));
    const [action, setAction] = useState(initAction);
    const [dueDateAndRent, setDueDateAndRent] = useState<any>({
        dueDate: null,
        rent: 0,
    });

    const validationSchema = [
        Yup.object({
            pay_structure_type: Yup.string().required(t('This field is required')),
        }).required(),

        Yup.object({
            commission: Yup.string().when('pay_structure_type', ([pay_structure_type], customSchema) =>
                pay_structure_type === 'commission' ? customSchema.required(t('This field is required')) : customSchema.nullable(),
            ),
            rent_money: Yup.number().when('pay_structure_type', ([pay_structure_type], customSchema) =>
                pay_structure_type === 'rent'
                    ? customSchema.required(t('This field is required')).positive(t('Rent must be a positive value')).typeError('Rent must be a number')
                    : customSchema.nullable(),
            ),
            tax_percentage: Yup.number()
                .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
                .when('is_sales_tax', ([is_sales_tax], customSchema) =>
                    is_sales_tax
                        ? customSchema
                              .required(t('This field is required'))
                              .min(0, t('Percentage must be at least 0%'))
                              .max(100, t('Percentage cannot be more than 100%'))
                              .typeError('Percentage must be a number')
                        : customSchema.nullable(),
                ),
            tax_number: Yup.string().when('is_sales_tax', ([is_sales_tax], customSchema) => (is_sales_tax ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        }).required(),

        Yup.object({
            start_date: Yup.string().required(t('This field is required')),
        }).required(),
    ];

    const schema = validationSchema[step - 1] as Yup.ObjectSchema<any>;

    const methods = useForm<IPayFormData>({
        resolver: yupResolver(schema) as Resolver<IPayFormData>,
        defaultValues: payStructure,
    });

    const { getValues, control, watch, trigger } = methods;
    const payStructureType = watch('pay_structure_type');
    const commission = watch('commission');
    const rentMoney = watch('rent_money');
    const startDate = watch('start_date');
    const isSalesTax = watch('is_sales_tax');
    useEffect(() => {
        if (payStructureType && startDate) {
            const data = {
                paymentType: payStructureType,
                shopPayment: shopPayment,
                moneywatch: rentMoney ?? 0,
                currentDateTime: startDate ? moment(startDate) : moment(),
            };
            const test = calculateDueDateAndRent(data);
            setDueDateAndRent({ dueDate: test.dueDate, rent: test.rent });
        }
    }, [shopPayment, rentMoney, startDate, payStructureType]);

    const date = new Date(payStructureType === 'commission' ? shopPayment.commission_start_date : shopPayment.rent_start_date);
    const currentDate = new Date();
    const minDate = currentDate > date ? currentDate : date;

    useEffect(() => {
        switch (step) {
            case 1:
                setTitle(t('Select team member start date'));
                break;
            case 2:
                if (payStructureType === 'rent') {
                    setTitle(t('Set rent amount:'));
                    setDescription(t('Specify the rent amount this team member will pay.'));
                }
                if (payStructureType === 'commission') {
                    setTitle(t('Set commission split:'));
                    setDescription(t('Define the commission percentage for this team member’s services.'));
                }
                break;
            case 3:
                setTitle(t('Set team member start date:'));
                setDescription(t('Select the start date for this team member’s employment.'));

                break;
            default:
                break;
        }
    }, [step, payStructureType]);

    const handleNext = async () => {
        const isValid = await trigger();

        if (isValid) {
            if (step === 3) {
                const data = getValues();
                setPayStructure(data);
                handleClose();
            } else {
                payStructureType === 'partner' || payStructureType === 'no_tracking' ? setStep((old) => old + 2) : setStep((old) => old + 1);
            }
        }
    };

    const handleBack = () => {
        if (step === 1) {
            handleClose();
        } else {
            payStructureType === 'partner' || payStructureType === 'no_tracking' ? setStep((old) => old - 2) : setStep((old) => old - 1);
        }
    };

    const handleAction = (type: string) => () => {
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleModalClose = useCallback(() => {
        setAction(initAction);
    }, []);

    const CommissionDetails = () => (
        <div>
            <div className="border-t pt-5">
                <h6 className="text-sm font-semibold items-center text-gray-900 gap-2.5 flex mb-0.5">{t('Commission payout details')}</h6>
                <p className="text-xs leading-[18px] font-normal text-gray-600">{t('Set the frequency at which your staff will automatically be paid based on their commission split')}</p>
            </div>
            <div className="flex flex-col justify-between pt-3">
                <div className="flex justify-between mb-4 gap-1">
                    <div className="flex flex-1 gap-2 items-center">
                        <span className=" bg-[#2E90FA] ml-[5px] rounded-full h-1.5 w-1.5 flex"></span>
                        <p className="text-gray-600 text-sm font-medium">{t('Commission payout frequency')}</p>
                    </div>
                    <div>
                        <p className="font-medium flex justify-end text-sm capitalize text-gray-900">{shopPayment.commission_frequency.replace(/_/g, '-')}</p>
                    </div>
                </div>
                <div className="flex justify-between mb-4 gap-1">
                    <div className="flex flex-1 gap-2 items-center">
                        <span className=" bg-[#2E90FA] ml-[5px] rounded-full h-1.5 w-1.5 flex"></span>
                        <p className="text-gray-600 text-sm font-medium">{t('Payment day')}</p>
                    </div>
                    <div>
                        <p className="font-medium text-sm flex justify-end capitalize-first text-gray-900">
                            {shopPayment.commission_frequency !== 'monthly' ? shopPayment.commission_day : t(`${shopPayment.commission_month_day} day of the month`)}
                        </p>
                    </div>
                </div>
                <div className="flex justify-between gap-1">
                    <div className="flex flex-1 gap-2 items-center">
                        <span className=" bg-[#2E90FA] ml-[5px] rounded-full h-1.5 w-1.5 flex"></span>
                        <p className="text-gray-600 text-sm font-medium">{t('Tip payout frequency')}</p>
                    </div>
                    <div>
                        <p className="font-medium text-sm flex justify-end capitalize text-gray-900">{shopPayment.tip_frequency.replace(/_/g, '-')}</p>
                    </div>
                </div>
            </div>
        </div>
    );

    const RentDetails = () => (
        <div>
            <div className="border-t pt-5">
                <h6 className="text-sm font-semibold items-center text-gray-900 gap-2.5 flex mb-0.5">{t('Rent collection details')}</h6>
                <p className="text-xs leading-[18px] font-normal text-gray-600">{t('Set the frequency at which you wish to automatically collect payments for all of your staff on rent')}</p>
            </div>
            <div className="flex flex-col justify-between gap-4 pt-3">
                <div className="flex justify-between gap-1">
                    <div className="flex flex-1 gap-2 items-center">
                        <span className=" bg-[#2E90FA] ml-[5px] rounded-full h-1.5 w-1.5 flex"></span>
                        <p className="text-gray-600 text-sm font-medium">{t('Aggregate first payment')}</p>
                    </div>
                    <div>
                        <p className="font-medium text-sm flex justify-end capitalize text-gray-900">
                            $
                            {step === 3 && dueDateAndRent.rent !== null ? dueDateAndRent.rent.toFixed(2) : rentMoney !== null ? (typeof rentMoney === 'string' && rentMoney !== '' ? rentMoney : 0) : 0}
                        </p>
                    </div>
                </div>
                <div className="flex justify-between gap-1">
                    <div className="flex flex-1 gap-2 items-center">
                        <span className=" bg-[#2E90FA] ml-[5px] rounded-full h-1.5 w-1.5 flex"></span>
                        <p className="text-gray-600 text-sm font-medium">{t('Rent collection frequency')}</p>
                    </div>
                    <div>
                        <p className="font-medium text-sm flex justify-end capitalize-first text-gray-900 ">{shopPayment.rent_frequency.replace(/_/g, '-')}</p>
                    </div>
                </div>
                <div className="flex justify-between gap-1">
                    <div className="flex flex-1 gap-2 items-center">
                        <span className=" bg-[#2E90FA] ml-[5px] rounded-full h-1.5 w-1.5 flex"></span>
                        <p className="text-gray-600 text-sm  font-medium">{t('Payment day')}</p>
                    </div>
                    <div>
                        <p className="font-medium text-sm flex justify-end capitalize-first text-gray-900 ">
                            {shopPayment.rent_frequency !== 'monthly' ? shopPayment.rent_day : t(`${shopPayment.rent_month_day} day of the month`)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );

    const Title = () => (
        <div className="flex flex-row gap-3 ">
            <div className="flex justify-center border shadow-sm border-gray-200 items-center h-12 w-12 rounded-[10px]">
                <Wallet02 className="text-gray-700" />
            </div>
            <div className="flex flex-col">
                <p className="text-lg font-semibold text-gray-900 ">{title}</p>
                {step === 1 ? (
                    <span className="text-xs font-normal cursor-pointer leading-[18px] text-blue-600 underline" onClick={handleAction('policy')}>
                        {description}
                    </span>
                ) : (
                    <span className="text-xs font-normal text-gray-500">{description}</span>
                )}
            </div>
        </div>
    );

    return (
        <>
            <PopupModal
                onClose={handleClose}
                size="w-[500px]"
                className="px-5 py-5"
                title={<Title />}
                primaryButton={t('Continue')}
                secondaryButton={step === 1 ? t('Cancel') : t('Back')}
                acceptAction={handleNext}
                declineAction={handleBack}
            >
                <FormProvider {...methods}>
                    <div className="-mx-1 pt-6  ">
                        {step === 1 && (
                            <Controller
                                name="pay_structure_type"
                                control={control}
                                render={({ field: { onChange, value }, fieldState }: any) => (
                                    <>
                                        <div className="grid grid-cols-2 gap-4">
                                            {PAY_STRUCTURE_LIST.map((item: any, index: number) => (
                                                <Label
                                                    key={index}
                                                    htmlFor={item.value}
                                                    className={`flex flex-col relative justify-center items-center px-2 py-1 border rounded-md w-full h-[148px] cursor-pointer ${
                                                        value === item.value ? 'border-primary' : ''
                                                    }`}
                                                >
                                                    <div className="flex justify-center border shadow-sm border-gray-200 items-center h-11 w-11 rounded-[10px]">
                                                        <item.icon className="text-gray-700 w-[22px]" />
                                                    </div>
                                                    <span className="text-sm font-semibold text-gray-900 mt-3">{t(item.name)}</span>
                                                    <p className="text-xs font-normal text-gray-600 mt-1.5">{t(item.description)}</p>
                                                    <Radio
                                                        className="custom-round-radio d-none"
                                                        name="pay_structure_type "
                                                        id={item.value}
                                                        value={item.value}
                                                        checked={value === item.value}
                                                        onChange={onChange}
                                                    />
                                                    <div className="absolute top-3 right-3">
                                                        {value === item.value ? (
                                                            <img src={CheckCircleIcon} alt="Location Img" className={` w-5 h-5 `} />
                                                        ) : (
                                                            <span className="block border w-5 h-5 rounded-full"></span>
                                                        )}
                                                    </div>
                                                </Label>
                                            ))}
                                        </div>
                                        {fieldState.error && <p className="text-error">{fieldState.error.message}</p>}
                                    </>
                                )}
                            />
                        )}
                        {step === 2 && (
                            <>
                                {payStructureType === 'commission' && (
                                    <div>
                                        <div className="flex flex-col items-center justify-center pb-5">
                                            <h1 className="font-bold text-6xl leading-[72px] my-10 text-gray-900 -tracking-tight">{commission}%</h1>
                                            <Controller
                                                name="commission"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState }: any) => (
                                                    <div className="w-full rang-slider-custom">
                                                        <Slider name="commission" value={value} onChange={(e) => onChange(e.value)} />
                                                        {fieldState.error && <p className="text-error">{fieldState.error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="flex flex-col justify-between gap-4 border-t pt-5 mt-6">
                                            <div className="flex justify-between gap-1">
                                                <div className="flex flex-1 gap-2 items-center">
                                                    <span className=" bg-[#2E90FA] ml-[5px] rounded-full h-1.5 w-1.5 flex"></span>
                                                    <p className="font-medium text-base text-gray-600">{t('Staff Commission')}</p>
                                                </div>
                                                <div>
                                                    <p className="font-bold text-base flex text-gray-900">{commission}%</p>
                                                </div>
                                            </div>
                                            <div className="flex justify-between gap-1">
                                                <div className="flex flex-1 gap-2 items-center">
                                                    <span className=" bg-[#2E90FA] ml-[5px] rounded-full h-1.5 w-1.5 flex"></span>
                                                    <p className="font-medium text-base text-gray-600">{t('Brand Commission')}</p>
                                                </div>
                                                <div>
                                                    <p className="font-bold flex text-base text-gray-900">{commission ? 100 - parseInt(commission) : 0}%</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {payStructureType === 'rent' && (
                                    <div>
                                        <div className="flex flex-col items-center justify-center pb-4">
                                            <h1 className="font-bold text-6xl leading-[72px] my-10 text-gray-900 -tracking-tight truncate max-w-[460px]">
                                                ${rentMoney !== null ? (typeof rentMoney === 'string' && rentMoney !== '' ? rentMoney : 0) : 0}
                                            </h1>
                                            <Controller
                                                name="rent_money"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState }: any) => (
                                                    <div className="w-full">
                                                        <span className="text-xs leading-[18px] font-medium text-gray-700 mb-1.5 flex">
                                                            Enter rent amount<span className="asterisk">*</span>
                                                        </span>
                                                        <InputWithLabel
                                                            name="rent_money"
                                                            type="number"
                                                            required
                                                            placeholder={t('Please enter rent money')}
                                                            onChange={onChange}
                                                            inputWrapClassName="w-full"
                                                            value={value}
                                                            error={!!fieldState.error}
                                                        />
                                                        {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="flex items-center gap-2 pb-4">
                                            <Controller
                                                name="is_sales_tax"
                                                control={control}
                                                render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                    <div className="w-full items-center flex gap-2">
                                                        <Switch onChange={onChange} isChecked={value} />
                                                        <span className="text-gray-700 font-medium text-sm">{t('Add Sales Tax')}</span>
                                                        {error && <p className="text-error">{error.message}</p>}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {isSalesTax && (
                                            <div className="flex gap-4 pb-5">
                                                <Controller
                                                    name="tax_percentage"
                                                    control={control}
                                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                        <div className="w-full max-w-[122px]">
                                                            <span className="text-xs leading-[18px] font-medium text-gray-700 mb-1.5 flex">
                                                                {t('Tax percentage')}
                                                                <span className="asterisk">*</span>
                                                            </span>
                                                            <InputWithLabel
                                                                type="number"
                                                                name="tax_percentage"
                                                                placeholder={t('Percentage')}
                                                                onChange={onChange}
                                                                inputWrapClassName="w-full "
                                                                value={value}
                                                                error={!!error}
                                                            />
                                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                                        </div>
                                                    )}
                                                />
                                                <Controller
                                                    name="tax_number"
                                                    control={control}
                                                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                                        <div className="w-full">
                                                            <span className="text-xs leading-[18px] font-medium text-gray-700 mb-1.5 flex">
                                                                {t('Tax number')}
                                                                <span className="asterisk">*</span>
                                                            </span>
                                                            <InputWithLabel name="tax_number" placeholder={t('Number')} onChange={onChange} inputWrapClassName="w-full" value={value} error={!!error} />
                                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                                        </div>
                                                    )}
                                                />
                                            </div>
                                        )}
                                        <RentDetails />
                                    </div>
                                )}
                            </>
                        )}
                        {step === 3 && (
                            <div>
                                <Controller
                                    name="start_date"
                                    control={control}
                                    render={({ field: { onChange, value }, fieldState }: any) => (
                                        <div className="relative">
                                            <span className="text-xs font-medium text-gray-700 flex capitalize">
                                                {t('Employee start date')}
                                                <span className="text-red-600">*</span>
                                            </span>
                                            <div className="relative">
                                                <DateSelect
                                                    inputName="start_date"
                                                    className={`form_control_date  font-medium text-xs relative w-full `}
                                                    placeholder={t('Select date')}
                                                    dateFormat="yyyy-MM-dd"
                                                    minDate={minDate}
                                                    InputClass={`${fieldState.error && 'is-invalid'}`}
                                                    value={value}
                                                    onChangeValue={onChange}
                                                    labelEndIcon={<ChevronDown className="text-gray-700 w-[22px]" />}
                                                />
                                            </div>
                                            {/* {fieldState.error && <p className="text-error">{fieldState.error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <p className="text-xs font-normal text-primary mt-3 mb-5">
                                    {payStructureType === 'commission' &&
                                        startDate &&
                                        t('staff_pay_structure_commission_description', {
                                            first_name: firstName || 'staff',
                                            formattedCommissionDate: dueDateAndRent.dueDate ? moment(dueDateAndRent.dueDate).format(DATE_FORMAT) : '',
                                        })}

                                    <span className="text-brand-400">
                                        {payStructureType === 'rent' &&
                                            startDate &&
                                            t('staff_pay_structure_rent_description', {
                                                rentPendingPrice: `$${dueDateAndRent.rent.toFixed(2)}`,
                                                first_name: firstName || 'staff',
                                                formattedRentDate: dueDateAndRent.dueDate ? moment(dueDateAndRent.dueDate).format(DATE_FORMAT) : '',
                                            })}
                                    </span>
                                </p>
                                {payStructureType === 'commission' && <CommissionDetails />}
                                {payStructureType === 'rent' && <RentDetails />}
                            </div>
                        )}
                    </div>
                </FormProvider>
            </PopupModal>
            {action.policy && <StaffPolicy handleClose={handleModalClose} />}
        </>
    );
};

export default StaffPaystructure;
