import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'src/components/InputWithLabel';
import SelectBox from 'src/components/SelectBox/SelectBox';
import Switch from 'src/components/Switch/Switch';
import { AppearanceFooter } from 'src/theme/Images';
import { Collapse } from '../Collapse';
import * as Yup from 'yup';
import { Controller, FormProvider, Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { axiosPost } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import { FooterProps, ICountryResponse, IFormData } from './Footer.interface';
import TemplateLayout from '../Layout/Layout';
import { usePhone } from 'src/hooks/usePhone';
import Number from 'src/components/Number';
import { getCountry } from 'src/redux/services/country/Country.slice';
import { getSelectBoxOptions } from 'src/utils/global-functions';
import { getState } from 'src/redux/services/state/State.slice';
import { allShopSettings, currentShop, getAllShopSettings } from 'src/redux/services/common/Common.slice';
import CustomButton from 'src/components/CustomButton';
import useFormErrorFocus from 'src/hooks/useFormErrorFocus';

const Footer: FC<FooterProps> = ({ template }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shopSettingInfo: any = useAppSelector(allShopSettings).find((setting: any) => setting.type === 'template')?.value?.footer;
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState(false);
    const shopId = shop.id;
    const { isPhoneValid, phoneInfo } = usePhone();
    const [optionCountry, setOptionContry] = useState<ICountryResponse[]>([]);
    const [optionState, setOptionState] = useState<ICountryResponse[]>([]);

    const schema = Yup.object({
        status: Yup.boolean().required('This field is required'),
        address_1: Yup.string().required('This field is required'),
        address_2: Yup.string().required('This field is required'),
        city: Yup.string().required('This field is required'),
        state: Yup.string().required('This field is required'),
        country: Yup.string().required('This field is required'),
        zip_code: Yup.string().required('This field is required'),
        newsletter_button: Yup.string().required('This field is required'),
        start_day: Yup.string().required('This field is required'),
        end_day: Yup.string().required('This field is required'),
        start_time: Yup.string().required('This field is required'),
        end_time: Yup.string().required('This field is required'),
        special_day: Yup.string().required('This field is required'),
        special_start_time: Yup.string().required('This field is required'),
        special_end_time: Yup.string().required('This field is required'),
        is_phone: Yup.boolean().required('This field is required'),

        country_code: Yup.string()
            .nullable()
            .when('is_phone', ([is_phone], customSchema) => (is_phone ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        phone: Yup.string()
            .nullable()
            .test({
                message: t('Invalid phone number'),
                test: function (value) {
                    const { country_code: countryCode, is_phone: isPhoneNumber } = this.parent;
                    if (value) {
                        const phoneInfo2 = phoneInfo(value, countryCode);
                        if (phoneInfo2) {
                            const isValid = isPhoneValid(value, countryCode);
                            return isValid;
                        }
                    }
                    return !isPhoneNumber;
                },
            }),
        is_email: Yup.boolean().required(t('This field is required')),
        email: Yup.string()
            .nullable()
            .when('is_email', ([is_phone], customSchema) => (is_phone ? customSchema.required(t('This field is required')) : customSchema.nullable())),
        is_newsletter: Yup.bool().required(t('This field is required')),
        newsletter: Yup.string()
            .nullable()
            .when('is_newsletter', ([is_newsletter], customSchema) => (is_newsletter ? customSchema.required(t('This field is required')) : customSchema.nullable())),
    });

    const methods = useForm<IFormData>({
        resolver: yupResolver(schema) as Resolver<IFormData>,
        defaultValues: {
            status: shopSettingInfo?.status || true,
            address_1: shopSettingInfo?.address_1 || '',
            address_2: shopSettingInfo?.address_2 || '',
            city: shopSettingInfo?.city || '',
            state: shopSettingInfo?.state || '',
            country: shopSettingInfo?.country || '',
            zip_code: shopSettingInfo?.zip_code || '',
            is_email: shopSettingInfo?.is_email || false,
            email: shopSettingInfo?.email || '',
            is_phone: shopSettingInfo?.is_phone || false,
            phone: shopSettingInfo?.phone || '',
            country_code: shopSettingInfo?.country_code || '',
            is_newsletter: shopSettingInfo?.is_newsletter || false,
            newsletter: shopSettingInfo?.newsletter || '',
            newsletter_button: shopSettingInfo?.newsletter_button || '',
            start_day: shopSettingInfo?.start_day || '',
            end_day: shopSettingInfo?.end_day || '',
            start_time: shopSettingInfo?.start_time || '',
            end_time: shopSettingInfo?.end_time || '',
            special_day: shopSettingInfo?.special_day || '',
            special_start_time: shopSettingInfo?.special_start_time || '',
            special_end_time: shopSettingInfo?.special_end_time || '',
        },
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        watch,
        getValues,
    } = methods;
    const isStatus = watch('status');

    const handleChange = async (data: IFormData) => {
        setIsLoading(true);
        const payload = {
            footer: data,
            template,
        };
        try {
            const response = await axiosPost(API.THEME.TEMPLATE.FOOTER, payload, {
                shop_id: shopId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                await dispatch(getAllShopSettings({ shop_id: shop.id }));
                return;
            }
            if (response.data.status === errorCode.unprocessable) {
                if (response.data.data) {
                    Object.keys(response.data.data).forEach((field) => {
                        setError(field as keyof IFormData, {
                            type: 'manual',
                            message: response.data.data[field][0],
                        });
                    });
                }
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setIsLoading(false);
        }
    };
    const handleStatus = (status: boolean) => {
        setValue('status', status);
    };
    const getCountryFun = async () => {
        const result = await dispatch(getCountry());
        if (result.type === getCountry.fulfilled.toString()) {
            const contryData: ICountryResponse[] | [] = getSelectBoxOptions(result.payload.data);
            const countriesWithImages = contryData.map((country) => ({
                ...country,
                image: require(`src/assets/flags/${country.shortName}.svg`),
            }));
            const priorityCountries = ['Canada', 'United States'];

            const sortedCountries = countriesWithImages.sort((a, b) => {
                const aIndex = priorityCountries.indexOf(a.label);
                const bIndex = priorityCountries.indexOf(b.label);
                if ((aIndex !== -1 && bIndex !== -1) || (aIndex === -1 && bIndex === -1)) {
                    return a.label.localeCompare(b.label);
                }
                return aIndex !== -1 ? -1 : 1;
            });
            const countyId = shopSettingInfo?.country ? contryData.find((option) => option?.value === shopSettingInfo?.country) : null;
            setOptionContry(sortedCountries);
            if (countyId) {
                setValue('country', countyId.value);
                getStateFun(countyId.id);
            }
            setIsLoading(false);
        }
    };

    const getStateFun = async (country_id: number) => {
        if (country_id) {
            const data = {
                country_id: country_id,
            };
            const result = await dispatch(getState(data));
            if (result.type === getState.fulfilled.toString()) {
                const stateData: ICountryResponse[] | [] = getSelectBoxOptions(result.payload.data);
                setOptionState(stateData);

                const stateDataId = shopSettingInfo?.state ? stateData.find((option) => option?.value === shopSettingInfo?.state) : null;

                if (stateDataId) {
                    setValue('state', stateDataId.value);
                } else {
                    setValue('state', '');
                }
            }
        } else {
            setOptionState([]);
        }
    };
    useEffect(() => {
        getCountryFun();
    }, []);
    useFormErrorFocus<IFormData>({ errors, formSectionName: 'footer' });
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleChange)} className="h-full">
                <Collapse title={t('Footer')} description={t('Footer description')} isCollapsed={isStatus} isLoading={isLoading} handleStatus={handleStatus} handleSave={handleSubmit(handleChange)}>
                    <TemplateLayout ImgProp={AppearanceFooter}>
                        <div>
                            <div className="text-sm font-semibold text-gray-700 mb-4">Contact Detail</div>
                            <div className="flex justify-between items-start mb-4 gap-4">
                                <Controller
                                    name={`address_1`}
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={t('Address line 1')}
                                                id="footer-address_1"
                                                required
                                                placeholder={t('Enter footer Address line 1')}
                                                onChange={onChange}
                                                value={value}
                                                name={'address_1'}
                                                error={!!error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="address_2"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={t('Address line 2')}
                                                id="footer-address_2"
                                                required
                                                placeholder={t('Enter footer Address line 2')}
                                                onChange={onChange}
                                                value={value}
                                                name="address_2"
                                                error={!!error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex justify-between items-start mb-4 gap-4">
                                <Controller
                                    name="country"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div id="footer-country" className="w-full">
                                            <SelectBox
                                                name="country"
                                                id="handlingUnit"
                                                label={t('Select Country')}
                                                required
                                                placeholder={t('Select country')}
                                                options={optionCountry}
                                                onChangeFunc={(selectedOption: any) => {
                                                    getStateFun(selectedOption ? selectedOption.id : null);
                                                    onChange(selectedOption ? selectedOption.value : null);
                                                }}
                                                className="border-hover-custom"
                                                value={optionCountry.find((option) => option?.value === value)}
                                                errorText={errors.country && true}
                                                isDisabled={isLoading}
                                                error={error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div id="footer-state" className="w-full">
                                            <SelectBox
                                                name="state"
                                                id="handlingUnit"
                                                label={t('State')}
                                                required
                                                placeholder={t('Select State')}
                                                options={optionState}
                                                onChangeFunc={(selectedOption: any) => {
                                                    onChange(selectedOption ? selectedOption.value : null);
                                                }}
                                                value={value ? optionState.find((option) => option.value === value) : null}
                                                errorText={errors.state && true}
                                                isDisabled={isLoading}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex justify-between items-start mb-4 gap-4">
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id="footer-city"
                                                label={t('City')}
                                                required
                                                placeholder={t('Enter footer city')}
                                                onChange={onChange}
                                                value={value}
                                                name="city"
                                                error={!!error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="zip_code"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id="footer-zip_code"
                                                label={t('Zip Code')}
                                                required
                                                placeholder={t('Enter footer Zip code')}
                                                onChange={onChange}
                                                value={value}
                                                name="zip_code"
                                                error={!!error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex gap-4 mb-4 ">
                                <div className="w-1/2">
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    label={t('Email')}
                                                    id="footer-email"
                                                    required
                                                    placeholder={t('Enter footer email...')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                    name="email"
                                                    toggle={
                                                        <Controller
                                                            name="is_email"
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {/* {error && <p className="text-error">{error.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="w-1/2">
                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div id="footer-country_code">
                                                <Controller
                                                    name="is_phone"
                                                    control={control}
                                                    render={({ field, fieldState }: any) => (
                                                        <>
                                                            <div className="flex items-center gap-[10px] mb-[6px]" color="gray">
                                                                <label htmlFor="" className="fl-field-title-label mb-0">
                                                                    {t('Contact')}
                                                                    <span className="asterisk">*</span>
                                                                </label>
                                                                <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                            </div>
                                                        </>
                                                    )}
                                                />

                                                <Number
                                                    errors={error}
                                                    id="footer-phone"
                                                    name="phone"
                                                    value={value}
                                                    defaultValues={{ phone: getValues('phone'), phone_country_code: getValues('country_code') }}
                                                    onNumberChange={(country: any, phone: any, code: any, isValid: any) => {
                                                        setValue(`phone`, `+${code}${phone}`);
                                                        setValue(`country_code`, country);
                                                    }}
                                                />
                                                {/* {error && <p className="text-error">{error.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex gap-4 mb-4">
                                <div className="w-3/5 xl:w-4/5">
                                    <Controller
                                        name="newsletter"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    id="footer-newsletter"
                                                    label={t('News Letter')}
                                                    required
                                                    placeholder={t('Enter footer news letter...')}
                                                    onChange={onChange}
                                                    value={value}
                                                    error={!!error}
                                                    name="newsletter"
                                                    toggle={
                                                        <Controller
                                                            name="is_newsletter"
                                                            control={control}
                                                            render={({ field, fieldState }: any) => (
                                                                <>
                                                                    <Switch className="ml-2" onChange={field.onChange} isChecked={field.value} />
                                                                    {fieldState.error && <p className="text-errors">{fieldState.error.message}</p>}
                                                                </>
                                                            )}
                                                        />
                                                    }
                                                />
                                                {/* {error && <p className="text-error">{error.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="w-2/5 xl:w-1/5">
                                    <Controller
                                        name="newsletter_button"
                                        control={control}
                                        render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                            <div className="w-full">
                                                <InputWithLabel
                                                    id="footer-newsletter_button"
                                                    label={t('Newsletter Button')}
                                                    required
                                                    placeholder={t('Enter your news letter button')}
                                                    onChange={onChange}
                                                    value={value}
                                                    name="newsletter_button"
                                                    error={!!error}
                                                    className={'!mb-2'}
                                                />
                                                {/* {error && <p className="text-error">{error.message}</p>} */}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="text-sm font-semibold text-gray-700 mb-4">Week days and timing detail</div>
                            <div className="flex gap-4 mb-4">
                                <Controller
                                    name="start_day"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id="footer-start_day"
                                                label={t('Start day')}
                                                required
                                                placeholder={t('Enter footer start day')}
                                                onChange={onChange}
                                                value={value}
                                                name="start_day"
                                                error={!!error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="end_day"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id="footer-end_day"
                                                label={t('End day')}
                                                required
                                                placeholder={t('Enter footer end day')}
                                                onChange={onChange}
                                                value={value}
                                                name="end_day"
                                                error={!!error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="start_time"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                label={t('Start time')}
                                                id="footer-start_time"
                                                required
                                                placeholder={t('Enter footer start time')}
                                                onChange={onChange}
                                                value={value}
                                                name="start_time"
                                                error={!!error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="end_time"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id="footer-end_time"
                                                label={t('End time')}
                                                required
                                                placeholder={t('Enter footer end time')}
                                                onChange={onChange}
                                                value={value}
                                                name="end_time"
                                                error={!!error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex gap-4 mb-4">
                                <Controller
                                    name="special_day"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id="footer-special_day"
                                                label={t('Special day')}
                                                required
                                                placeholder={t('Enter footer special day')}
                                                onChange={onChange}
                                                value={value}
                                                name="special_day"
                                                error={!!error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="special_start_time"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id="footer-special_start_time"
                                                label={t('Special start time')}
                                                required
                                                placeholder={t('Enter footer special start time')}
                                                onChange={onChange}
                                                value={value}
                                                name="special_start_time"
                                                error={!!error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                                <Controller
                                    name="special_end_time"
                                    control={control}
                                    render={({ field: { value, onChange }, fieldState: { error } }: any) => (
                                        <div className="w-full">
                                            <InputWithLabel
                                                id="footer-special_end_time"
                                                label={t('Special end time')}
                                                required
                                                placeholder={t('Enter footer special end time')}
                                                onChange={onChange}
                                                value={value}
                                                name="special_end_time"
                                                error={!!error}
                                            />
                                            {/* {error && <p className="text-error">{error.message}</p>} */}
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </TemplateLayout>
                    <div className="pb-3 pt-3 flex px-5 border-t w-full justify-end">
                        <CustomButton primary type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('Save changes')}
                        </CustomButton>
                    </div>
                </Collapse>
            </form>
        </FormProvider>
    );
};

export default Footer;
