import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import CustomButton from 'src/components/CustomButton';
import InputPasswordWithLabel from 'src/components/InputPasswordWithLabel';
import { errorCode } from 'src/constants/errorCode';
import { IPasswordField, IChangePasswordProps } from './ChangePassword.interface';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { Right, Cross } from 'src/theme/Images';
import { toast } from 'react-toastify';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';

const ChangePassword: FC<IChangePasswordProps> = ({ forgotPasswordStep, authData }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const schema = Yup.object({
        old_password: !forgotPasswordStep ? Yup.string().required(t('This field is required')) : Yup.string(),
        password: !forgotPasswordStep ? Yup.string().required(t('This field is required')) : Yup.string(),
        password_confirmation: Yup.string()
            .required(t('This field is required'))
            .oneOf([Yup.ref('password')], t('Passwords do not match')),
    }).required();

    const { handleSubmit, control, setError, reset } = useForm<IPasswordField>({
        resolver: yupResolver(schema),
        defaultValues: {
            old_password: '',
            password: '',
            password_confirmation: '',
        },
    });
    const [validations, setValidations] = useState({
        minLength: false,
        hasDigit: false,
        hasNonDigit: false,
    });
    const handleValidation = (value: string) => {
        setValidations({
            minLength: value.length >= 8,
            hasDigit: /\d/.test(value),
            hasNonDigit: /\D/.test(value),
        });
    };
    const validationsPassed = validations.minLength && validations.hasDigit && validations.hasNonDigit;

    const handleSave = (data: IPasswordField) => {
        setLoading(true);
        if (forgotPasswordStep) {
            const payload = {
                ...(authData.isEmail ? { email: authData.email } : { phone: `+${authData.phone.code}${authData.phone.number}` }),
                password: data.password,
                password_confirmation: data.password_confirmation,
                token: authData.token,
            };
            axiosPost(API.USER.NEW_PASSWORD, payload)
                .then(() => {
                    navigate(ROUTES.HOME);
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IPasswordField, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setLoading(false));
        } else {
            axiosPost(API.USER.CHANGE_PASSWORD, data)
                .then(() => {
                    reset();
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            Object.keys(response.data).forEach((field) => {
                                setError(field as keyof IPasswordField, {
                                    type: 'manual',
                                    message: response.data[field][0],
                                });
                            });
                        }
                        return;
                    }
                    toast.error(response.message);
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <div className="content pt-[84px] pl-[100px] pr-[100px] w-full">
            <h5 className="text-3xl leading-[38px] -tracking-[1.2px] text-mainTextColor  font-semibold">{t('Change password')}</h5>
            <p className="mt-1 opacity-70 -tracking-[0.4px] text-sm leading-5 font-normal text-secondaryTxtColor ">
                {t('Your password must be at least 8 characters long, and contain at least one digit and one non-digit character')}
            </p>
            <form onSubmit={handleSubmit(handleSave)}>
                <div className="flex flex-wrap">
                    {!forgotPasswordStep && (
                        <Controller
                            name="old_password"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="flex flex-col w-full">
                                    <InputPasswordWithLabel
                                        name="old_password"
                                        required
                                        label={t('Current password')}
                                        placeholder={t('Current password')}
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                    />
                                    {error && <p className="text-error">{error.message}</p>}
                                </div>
                            )}
                        />
                    )}
                    <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full mt-4">
                                <InputPasswordWithLabel
                                    name="password"
                                    label={t('New password')}
                                    placeholder={forgotPasswordStep ? t('Password') : t('New password')}
                                    value={value}
                                    required
                                    onChange={(e: { target: { value: string } }) => {
                                        onChange(e);
                                        handleValidation(e.target.value);
                                    }}
                                    error={!!error}
                                />
                                {error && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                    <Controller
                        name="password_confirmation"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full mt-5">
                                <InputPasswordWithLabel
                                    name="password_confirmation"
                                    label={t('Confirm password')}
                                    placeholder={t('Confirm password')}
                                    value={value}
                                    required
                                    inputClass={`${error ? 'is-invalid' : ''}`}
                                    onChange={onChange}
                                    error={!!error}
                                />
                                {error && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                </div>
                <CustomButton primary type="submit" size="w-full" className="mt-5" isLoading={loading} disabled={loading || !validationsPassed}>
                    {t('Save changes')}
                </CustomButton>
                {forgotPasswordStep && (
                    <div className="mt-5">
                        <div className="flex items-center  justify-center text-sm font-normal text-secondaryTxtColor">
                            <span className="mr-[6px]">{validations.minLength ? <Right /> : <Cross />}</span>
                            {t('Has at least 8 characters?')}
                        </div>
                        <div className="flex items-center mt-4 justify-center text-sm font-normal text-secondaryTxtColor">
                            <span className="mr-[6px]">{validations.hasDigit ? <Right /> : <Cross />}</span>
                            {t('Has one digit?')}
                        </div>
                        <div className="flex items-center mt-4 justify-center text-sm font-normal text-secondaryTxtColor">
                            <span className="mr-[6px]">{validations.hasNonDigit ? <Right /> : <Cross />}</span>
                            {t('Has one non-digit character?')}
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
};

export default ChangePassword;
