import React, { FC } from 'react';
import Button from 'src/components/Button';
import { useForm, Controller } from 'react-hook-form';
import { usePhone } from 'src/hooks/usePhone';
import { IForgotPassword, IForgotPasswordProps, AuthDataInit } from './ForgotPassword.interface';
import { forgotPassword, selectForgotPasswordLoading } from './ForgotPassword.slice';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useTranslation } from 'react-i18next';
import { errorCode } from 'src/constants/errorCode';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import PhoneOrEmail from 'src/components/PhoneOrEmail';
import { FaArrowLeft } from 'react-icons/fa6';
import CustomButton from 'src/components/CustomButton';

const ForgotPassword: FC<IForgotPasswordProps> = ({ setForgotPasswordStep, setAuthData, authData }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loading = useAppSelector(selectForgotPasswordLoading);

    const dispatch = useAppDispatch();
    const { setFormatPhone } = usePhone();
    const inputType = authData?.email && !authData?.phone ? 'phone' : authData?.phone && !authData?.email ? 'email' : 'both';

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<IForgotPassword>({
        defaultValues: {
            emailPhone: '',
        },
    });

    const handleChange = async (data: any) => {
        if (data.emailPhone === '') {
            setError('emailPhone', {
                type: 'manual',
                message: t('Enter phone number or email'),
            });
            return;
        }
        const info = data.emailPhone;
        if (info.error) {
            setError('emailPhone', {
                type: 'manual',
                message: info.error,
            });
            return;
        }
        let payload = {};
        if (info.isEmail) {
            payload = {
                email: info.data,
            };
        } else {
            const mainNumber = `+${info.data.code}${info.data.number}`;
            payload = {
                phone: mainNumber,
                phone_country_code: info.data.country,
            };
        }
        let authDetail = { ...AuthDataInit, isEmail: info.isEmail };
        if (!info.isEmail) {
            const phoneInfo = info.data;
            const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
            const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
            authDetail = {
                ...authDetail,
                phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
            };
        } else {
            authDetail = {
                ...authDetail,
                email: info.data,
            };
        }
        const result = await dispatch(forgotPassword(payload));
        if (result.type === forgotPassword.fulfilled.toString()) {
            if (!info.isEmail) {
                const phoneInfo = info.data;
                const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
                const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
                authDetail = {
                    ...authDetail,
                    phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
                };
            } else {
                authDetail = {
                    ...authDetail,
                    email: info.data,
                };
            }
            setAuthData(authDetail);
            setForgotPasswordStep('otp');
        }
        if (result.type === forgotPassword.rejected.toString()) {
            const response = result.payload;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError('emailPhone', {
                            type: 'manual',
                            message: response.data[field][0],
                        });
                    });
                } else {
                    Object.keys(response).forEach((field) => {
                        setError('emailPhone', {
                            type: 'manual',
                            message: response.message,
                        });
                    });
                }
            }
        }
    };

    return (
        <>
            <div className="content pt-[84px] pl-[100px] pr-[100px] w-full">
                <h5 className="text-3xl leading-[38px]  -tracking-[1.2px] text-mainTextColor font-semibold">{t('Forgot Password?')}</h5>
                <p className="mt-1 -tracking-[0.4px] text-sm leading-5 text-secondaryTxtColor ">{t('forgot_password_description')}</p>
                <div className="mt-8">
                    <form onSubmit={handleSubmit(handleChange)}>
                        <label htmlFor="phone" className="font-medium text-darkblue hover:shadow-none text-xs leading-[18px] mb-2 text-labelTxt">
                            {t('Enter phone number or email')} <span className="asterisk">*</span>
                        </label>
                        <Controller
                            name="emailPhone"
                            control={control}
                            render={({ field: { onChange } }: any) => (
                                <div className="flex flex-col mt-[6px]">
                                    <PhoneOrEmail onNumberChange={onChange} errors={errors.emailPhone} inputType={inputType} />
                                </div>
                            )}
                        />
                        <CustomButton primary type="submit" isLoading={loading} disabled={loading} size="w-full" className="!text-base mt-[20px]">
                            {t('Continue')}
                        </CustomButton>
                        <Button className="mt-3 text-sm font-bold text-center w-full" onClick={() => navigate(ROUTES.HOME)} disabled={loading}>
                            <FaArrowLeft size={14} className="mr-2" /> {t('Back to log in')}
                        </Button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
