import React from 'react';
import NotificationGeneral from './NotificationGeneral';
import NotificationAppointment from './NotificationAppointment';
import NotificationStatements from './NotificationStatements';

const NotificationGeneralAll = ({ notificationRecord, isLoading = false, viewDetail, checkNotificationStatus, isMarkingAsRead }: any) => (
    <>
        {notificationRecord.type === 'general' ? (
            <NotificationGeneral
                key={`${notificationRecord.name}_${notificationRecord.id}`}
                notificationRecord={notificationRecord}
                isLoading={isLoading}
                viewDetail={viewDetail}
                checkNotificationStatus={checkNotificationStatus}
                isMarkingAsRead={isMarkingAsRead}
            />
        ) : notificationRecord.type === 'appointment' ? (
            <NotificationAppointment key={`${notificationRecord.name}_${notificationRecord.id}`} notificationRecord={notificationRecord} isLoading={isLoading} />
        ) : notificationRecord.type === 'statement' ? (
            <NotificationStatements key={`${notificationRecord.name}_${notificationRecord.id}`} notificationRecord={notificationRecord} isLoading={isLoading} />
        ) : (
            <></>
        )}
    </>
);

export default NotificationGeneralAll;
