import React, { useEffect, useState } from 'react';
import { HiXMark } from 'react-icons/hi2';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { capitalizeFirstLetterSpace, getCardTypeImage, getShortName } from 'src/utils/global-functions';
import { usePhone } from 'src/hooks/usePhone';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { selectCalendarData, setData } from '../../Calendar.slice';
import { ChevronDown, ChevronUp } from '@untitled-ui/icons-react/build/cjs';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';

const ClientDetail = ({ isNewAppoinment = true }: any) => {
    const dispatch = useAppDispatch();
    const calendarData = useAppSelector(selectCalendarData);
    const isEditable = calendarData.calendarStep.newAppointment;
    const [isColapse, setIsColapse] = useState(true);
    const handleCollapse = () => {
        setIsColapse((prev) => !prev);
    };
    const [defaultCard, setDefaultCard] = useState<any>(null);
    const shop = useAppSelector(currentShop);
    const shopId = shop.id;
    const [isImageError, setIsImageError] = useState<boolean>(false);
    const getClientCard = async () => {
        const data = {
            shop_id: shopId,
            id: calendarData?.selectedClientInfo?.id,
        };

        await axiosGet(API.CLIENT.CARD_LIST, data).then((response) => {
            let filterData = response.data.data.cards.filter((items: any) => items.id === response.data.data.default_card_id);
            setDefaultCard(filterData[0]);
            dispatch(setData({ selectedClientInfo: { ...calendarData?.selectedClientInfo, default_card: filterData[0] } }));
        });

        // const result = await dispatch(clientCardList(data));
        // if (result.type === clientCardList.fulfilled.toString()) {
        //     let filterData = result.payload.data.cards.filter((items: any) => items.id === result.payload.data.default_card_id);
        //     setDefaultCard(filterData[0]);
        // }
    };
    const fullName =
        calendarData?.selectedClientInfo.first_name && calendarData?.selectedClientInfo.last_name
            ? `${calendarData?.selectedClientInfo.first_name} ${calendarData?.selectedClientInfo.last_name}`
            : null;
    const { getCustomFormatPhone } = usePhone();
    const getphoneinfo = getCustomFormatPhone(calendarData?.selectedClientInfo?.phone, calendarData?.selectedClientInfo?.phone_country_code);
    useEffect(() => {
        getClientCard();
    }, []);

    return (
        <div className={`${isEditable ? 'mb-4 border border-gray-300 rounded-lg flex flex-col' : 'flex flex-col border-b'}`}>
            <div className={` ${isEditable ? 'flex w-full  py-2 px-3 items-center' : 'flex w-full items-center'}`}>
                <figure className="NoImgName w-[22px] h-[22px] mr-2 text-[8px]">
                    {calendarData?.selectedClientInfo.profile_image_url && !isImageError ? (
                        <img src={calendarData?.selectedClientInfo.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                    ) : fullName ? (
                        getShortName(fullName)
                    ) : (
                        '+'
                    )}
                </figure>
                <div className="flex-1">
                    <p className="font-medium text-xs text-gray-900 leading-[18px]">{fullName ? capitalizeFirstLetterSpace(fullName) : getphoneinfo}</p>
                    {/* <p className="font-medium text-xs text-gray-600 ">Client since {format(new Date(calendarData?.selectedClientInfo.created_at), 'MMMM yyyy')}</p> */}
                </div>
                {calendarData.calendarStep.newAppointment && (
                    <div className="flex flex-row gap-1.5 items-center">
                        <HiXMark
                            size={18}
                            onClick={() => {
                                dispatch(setData({ selectedClientInfo: null }));
                            }}
                            className="text-gray-400 cursor-pointer "
                        />
                        <div className={`cursor-pointer ${isEditable ? 'flex' : 'hidden'}`} onClick={handleCollapse}>
                            {isColapse ? <ChevronUp className="text-gray-700 w-[20px] cursor-pointer " /> : <ChevronDown className="text-gray-700 w-[20px] cursor-pointer" />}
                        </div>
                    </div>
                )}
            </div>
            <div className={` ${isEditable ? 'py-2 px-3 gap-1.5 flex flex-col border-t border-gray-300' : 'pt-2 pb-3 gap-1.5 flex flex-col'}  ${!isColapse ? 'hidden' : ''} `}>
                {getphoneinfo && (
                    <div className="flex items-center">
                        <span className="text-xs font-semibold leading-[18px] text-gray-600 mr-1.5">Phone :</span>
                        <span className="font-normal text-xs leading-[18px] text-gray-600 flex-1 truncate">{getphoneinfo}</span>
                    </div>
                )}
                {calendarData?.selectedClientInfo.email && (
                    <div className="flex items-center">
                        <span className="text-xs font-semibold leading-[18px] text-gray-600 mr-1.5">Email :</span>
                        <span className="font-normal text-xs leading-[18px] text-gray-600 flex-1 truncate">{calendarData?.selectedClientInfo.email}</span>
                    </div>
                )}

                {defaultCard && (
                    <div className="flex items-center">
                        <span className="text-xs font-semibold leading-[18px] text-gray-600 mr-1.5">Credit Card :</span>
                        <i className="icon w-[30px] mr-1">
                            <img src={getCardTypeImage(defaultCard?.card?.brand)} alt="" className="w-full h-5 object-contain" />
                        </i>
                        <span className="font-normal text-xs leading-[18px] text-gray-600 flex-1 truncate"> ⭑⭑⭑⭑ - ⭑⭑⭑⭑ - ⭑⭑⭑⭑ - {defaultCard.card.last4}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ClientDetail;
