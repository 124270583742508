import React from 'react';
import { ISwitch } from './Switch.interface';
import { InputSwitch } from 'primereact/inputswitch';

const Switch: React.FC<ISwitch> = ({ error, onChange, className, isDisabled, isChecked, readOnly = false, id }): JSX.Element => (
    <InputSwitch
        pt={{ input: { className: 'appearance-none w-full h-full' } }}
        onClick={(e: any) => e.stopPropagation()}
        checked={isChecked}
        disabled={isDisabled}
        onChange={onChange}
        readOnly={readOnly}
        id={id}
    />
);

export default Switch;
