export interface IFormData {
    name: string;
    description: string;
    sub_categories: Array<{ name: string; description: string }>;
}

export interface ExistingFormData {
    [key: string]: any;
}

export const initAction = {
    id: null,
    delete: false,
};
