import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { API } from 'src/constants/api';
import { axiosGet } from 'src/utils/requestClient';
import { errorCode } from 'src/constants/errorCode';
import { RootState } from 'src/redux/store';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState: any = {
    list: [],
    loading: false,
    error: null,
};

export const locationListAction = createAsyncThunk('location/list', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.LOCATION.LIST, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const locationSlice = createSlice({
    name: 'shop/location',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(locationListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(locationListAction.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.list = payload.data;
            })
            .addCase(locationListAction.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export const selectListing = (state: RootState) => state.Location.list;
export const selectLoading = (state: RootState) => state.Location.loading;
export const selectError = (state: RootState) => state.Location.error;
