import React from 'react';
import { GoDotFill } from 'react-icons/go';

const ClientSplit = () => {
    console.log('ClientSplit');
    return (
        <>
            <div className="w-full flex flex-row gap-5">
                <div className="w-[50%] border border-borderSecondary rounded-lg max-h-[364px] min-h-[364px] p-4">
                    <div className="font-semibold text-xl text-mainTextColor  -tracking-[0.3] max-2xl:text-lg">Payment Method Split</div>
                    <div className="flex flex-row justify-between items-center max-xlm:pb-6 max-xlm:pt-5 py-5 ">
                        <div className="flex flex-row items-center gap-1">
                            <GoDotFill size={12} color="#0E34A5" className="max-2xl:h-3 max-2xl:w-3 h-4 w-4 rounded-full flex" />
                            <div className="font-normal text-base max-xlm:text-sm max-2xl:text-[12px] text-secondaryTxtColor">
                                Card on file <span className="max-xlm:text-[11px] max-2xl:text-[10px] text-xs text-[#079455] font-medium ">(52.57%)</span>
                            </div>
                        </div>
                        <div className="font-semibold text-base max-2xl:text-[12px] max-xlm:text-sm text-secondaryTxtColor">$8,044.29</div>
                    </div>
                    <div className="flex flex-row justify-between items-center  max-xlm:pb-6 max-xlm:pt-5 py-5">
                        <div className="flex flex-row items-center gap-1">
                            <GoDotFill size={12} color="#1642C5" className="h-4 w-4 rounded-full flex max-2xl:h-3 max-2xl:w-3" />
                            <div className="font-normal text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">
                                Cash <span className="text-xs text-[#079455] font-medium max-xlm:text-[11px] max-2xl:text-[10px]">(52.57%)</span>
                            </div>
                        </div>
                        <div className="font-semibold text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">$4,312.1</div>
                    </div>
                    <div className="flex flex-row justify-between items-center  max-xlm:pb-6 max-xlm:pt-5 py-5">
                        <div className="flex flex-row items-center gap-1">
                            <GoDotFill size={12} color="#2C59DE" className="h-4 w-4 rounded-full fle max-2xl:h-3 max-2xl:w-3x" />
                            <div className="font-normal text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">
                                Front desk <span className="text-xs text-[#079455] font-medium max-xlm:text-[11px] max-2xl:text-[10px]">(52.57%)</span>
                            </div>
                        </div>
                        <div className="font-semibold text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">$1,945.63</div>
                    </div>
                    <div className="flex flex-row justify-between items-center  max-xlm:pb-6 max-xlm:pt-5 py-5">
                        <div className="flex flex-row items-center gap-1">
                            <GoDotFill size={12} color="#5279F0" className="h-4 w-4 rounded-full fle max-2xl:h-3 max-2xl:w-3x" />
                            <div className="font-normal text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">
                                Other <span className="text-xs text-[#079455] font-medium max-xlm:text-[11px] max-2xl:text-[10px]">(52.57%)</span>
                            </div>
                        </div>
                        <div className="font-semibold text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">$550.00</div>
                    </div>
                    <div className="flex flex-row justify-between items-center pt-5">
                        <div className="flex flex-row items-center gap-1">
                            <GoDotFill size={12} color="#819DF1" className="h-4 w-4 rounded-full fle max-2xl:h-3 max-2xl:w-3x" />
                            <div className="font-normal text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">
                                Loyalty points <span className="text-xs text-[#079455] font-medium max-xlm:text-[11px] max-2xl:text-[10px]">(52.57%)</span>
                            </div>
                        </div>
                        <div className="font-semibold text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">$550.00</div>
                    </div>
                </div>
                <div className="w-[50%] border border-borderSecondary rounded-lg min-h-[364px] max-h-[364px] p-4">
                    <div className="font-semibold text-xl text-mainTextColor  -tracking-[0.3] max-2xl:text-lg">Client Split </div>
                    <div className="flex flex-row justify-between items-center  max-xlm:pb-6 max-xlm:pt-5 py-5">
                        <div className="flex flex-row items-center gap-1">
                            <GoDotFill size={12} color="#0E34A5" className="h-4 w-4 rounded-full fle max-2xl:h-3 max-2xl:w-3x" />
                            <div className="font-normal text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">
                                Card on file <span className="text-xs text-[#079455] font-medium max-xlm:text-[11px] max-2xl:text-[10px]">(52.57%)</span>
                            </div>
                        </div>
                        <div className="font-semibold text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">$8,044.29</div>
                    </div>
                    <div className="flex flex-row justify-between items-center  max-xlm:pb-6 max-xlm:pt-5 py-5">
                        <div className="flex flex-row items-center gap-1">
                            <GoDotFill size={12} color="#1642C5" className="h-4 w-4 rounded-full fle max-2xl:h-3 max-2xl:w-3x" />
                            <div className="font-normal text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">
                                Cash <span className="text-xs text-[#079455] font-medium max-xlm:text-[11px] max-2xl:text-[10px]">(52.57%)</span>
                            </div>
                        </div>
                        <div className="font-semibold text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">$4,312.1</div>
                    </div>
                    <div className="flex flex-row justify-between items-center  max-xlm:pb-6 max-xlm:pt-5 py-5">
                        <div className="flex flex-row items-center gap-1">
                            <GoDotFill size={12} color="#2C59DE" className="h-4 w-4 rounded-full fle max-2xl:h-3 max-2xl:w-3x" />
                            <div className="font-normal text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">
                                Front desk <span className="text-xs text-[#079455] font-medium max-xlm:text-[11px] max-2xl:text-[10px]">(52.57%)</span>
                            </div>
                        </div>
                        <div className="font-semibold text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">$1,945.63</div>
                    </div>
                    <div className="flex flex-row justify-between items-center  max-xlm:pb-6 max-xlm:pt-5 py-5">
                        <div className="flex flex-row items-center gap-1">
                            <GoDotFill size={12} color="#5279F0" className="h-4 w-4 rounded-full fle max-2xl:h-3 max-2xl:w-3x" />
                            <div className="font-normal text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">
                                Other <span className="text-xs text-[#079455] font-medium max-xlm:text-[11px] max-2xl:text-[10px]">(52.57%)</span>
                            </div>
                        </div>
                        <div className="font-semibold text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">$550.00</div>
                    </div>
                    <div className="flex flex-row justify-between items-center pt-5">
                        <div className="flex flex-row items-center gap-1">
                            <GoDotFill size={12} color="#819DF1" className="h-4 w-4 rounded-full fle max-2xl:h-3 max-2xl:w-3x" />
                            <div className="font-normal text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">
                                Loyalty points <span className="text-xs text-[#079455] font-medium max-xlm:text-[11px] max-2xl:text-[10px]">(52.57%)</span>
                            </div>
                        </div>
                        <div className="font-semibold text-base text-secondaryTxtColor max-xlm:text-sm max-2xl:text-[12px]">$550.00</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClientSplit;
