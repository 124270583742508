export const MapStyle = [
    // General styles
    {
        elementType: 'geometry',
        stylers: [{ saturation: -100 }],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [{ saturation: -100 }],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [{ saturation: -100 }],
    },
    // Administrative styles
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ visibility: 'off' }],
    },
    // Landscape and POI styles
    {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ visibility: 'off' }],
    },
    // Road styles
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.stroke',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ saturation: -100 }],
    },
    // Transit styles
    {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'transit.line',
        elementType: 'labels.text.fill',
        stylers: [{ visibility: 'off' }],
    },
    {
        featureType: 'transit.line',
        elementType: 'labels.text.stroke',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ saturation: -100 }],
    },
    // Water styles
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{ saturation: -100 }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ visibility: 'off' }],
    },
];
