import React, { useEffect, useRef } from 'react';
import { IProps } from './RandomModal.interface';

const RandomModal = ({ children, classname, parentRef, handleClose }: IProps) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (modalRef.current && !((modalRef.current as any).contains(event.target) || (parentRef.current && (parentRef.current as any).contains(event.target)))) {
                handleClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div ref={modalRef} className={`fixed z-[101] w-full max-w-[252px] bg-white border border-btnborder rounded-lg shadow-InputAndButton ${classname}`}>
            {children}
        </div>
    );
};

export default RandomModal;
