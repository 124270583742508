import React from 'react';
import Footer from './Footer';
import Stepper from 'src/components/Stepper';
import { ICountStep, IStep } from 'src/components/Stepper/Stepper.interface';
import Header from '../NonLayout/Header';

interface IProps {
    activeStep: ICountStep;
    steps: IStep[];
    handleStep: (value: 'prev' | 'next') => () => void;
}

const Sidebar: React.FC<IProps> = ({ activeStep, steps, handleStep }: IProps) => (
    <div className="flex flex-col grow-0 shrink-0 h-full w-[420px] xl:w-[480px] bg-gray-100 ">
        <Header />
        <div className="flex-auto overflow-y-auto scrollbar-hide">
            <div className="pt-[84px] px-8 pb-8 login_steper">{steps && activeStep && <Stepper activeStep={activeStep} steps={steps} />}</div>
        </div>
        <Footer handleStep={handleStep} activeStep={activeStep} />
    </div>
);

export default Sidebar;
