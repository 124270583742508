import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import CustomButton from 'src/components/CustomButton';
import PageHeader from 'src/components/PageHeader';
import { Plus } from '@untitled-ui/icons-react/build/cjs';
import StaffTable from './StaffTable';
import StaffCommissionTable from './StaffCommissionTable';
import StaffRentTable from './StaffRentTable';

const Staff = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('overview');
    const tabs = useMemo(
        () => [
            {
                name: 'Overview',
                type: 'overview',
            },
            {
                name: 'Commision payouts',
                type: 'commision',
            },
            {
                name: 'Rent collection',
                type: 'rent',
            },
        ],
        [],
    );

    const handleTabClick = (type: string) => (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab(type);
    };

    const handleClick = () => {
        navigate(ROUTES.STAFF.CREATE);
    };

    return (
        <div className="inner-page-wrape flex flex-col">
            <div className="flex-1 items-center flex flex-col">
                <PageHeader title={t('Team Management')} subtitle={t('Manage and keep track of all your team members, commission payouts and rent collection statements.')}>
                    {/* <CustomButton primary onClick={handleClick} icon={<Plus width="16" />} className="!px-4 py-[9px] rounded-lg shadow-InputAndButton">
                        {t('Add team member')}
                    </CustomButton> */}
                </PageHeader>
                <div className="side-spaching flex flex-1 flex-col w-full">
                    <div className="fl-tab-btn-view w-full">
                        {tabs.map((tab, index) => (
                            <button key={index} type="button" className={`fl-tab-link ${activeTab === tab.type ? 'active' : ''}`} onClick={handleTabClick(tab.type)}>
                                {tab.name}
                            </button>
                        ))}
                    </div>
                    {activeTab === 'overview' && <StaffTable />}
                    {activeTab === 'commision' && <StaffCommissionTable />}
                    {activeTab === 'rent' && <StaffRentTable />}
                </div>
            </div>
        </div>
    );
};

export default Staff;
