import React from 'react';
import EmptyMsgWithBtn from 'src/components/EmptyMsgWithBtn';
import KpiCard from 'src/components/KpiCard';
import AmChart from '../AmChart';
import NetSalesChartPie from '../NetSalesChartPie';
import PaymentChartPie from '../PaymentChartPie';
import NetSalesIncome from '../Tables/NetSalesIncome';
import PayStructure from '../Tables/PayStructure';
import { checkFieldIsZero, formatKpiContentLable } from 'src/utils/global-functions';
import { IconMoney, IconNewClient, IconClientRotation, IconCalendar, IconProductivity } from 'src/theme/Images';

const Professional = ({ AnalyticsDetails, selectedStaff, timeFileterSelected }: any) => (
    <>
        {AnalyticsDetails && (
            <>
                <div className="mb-3 flex -mx-[6px]">
                    <div className="px-[6px] w-[20%]">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <KpiCard
                                headerIcon={IconMoney}
                                headerTitle="Total Sales"
                                contentLable={`$${formatKpiContentLable(AnalyticsDetails?.total?.amount)}`}
                                growth={AnalyticsDetails?.total?.amount_growth}
                                fromDate={AnalyticsDetails?.from_date}
                            />
                        </div>
                    </div>
                    <div className="px-[6px] w-[20%]">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <KpiCard
                                headerIcon={IconNewClient}
                                headerTitle="New Clients"
                                contentLable={`$${formatKpiContentLable(AnalyticsDetails?.total?.client)}`}
                                growth={AnalyticsDetails?.total?.client_growth}
                                fromDate={AnalyticsDetails?.from_date}
                            />
                        </div>
                    </div>
                    <div className="px-[6px] w-[20%]">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <KpiCard
                                headerIcon={IconClientRotation}
                                headerTitle="Client Retenetion"
                                contentLable={`${AnalyticsDetails?.total?.client_retention_rate}%`}
                                contentDescription="new"
                                growth={AnalyticsDetails?.total?.client_retention_rate_growth}
                                fromDate={AnalyticsDetails?.from_date}
                            />
                        </div>
                    </div>
                    <div className="px-[6px] w-[20%]">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <KpiCard
                                headerIcon={IconCalendar}
                                headerTitle="Av. Booking Frequency"
                                contentLable={`${AnalyticsDetails?.total?.avg_bookings_frequency}%`}
                                growth={AnalyticsDetails?.total?.avg_bookings_frequency_growth}
                                fromDate={AnalyticsDetails?.from_date}
                            />
                        </div>
                    </div>
                    <div className="px-[6px] w-[20%]">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <KpiCard
                                headerIcon={IconProductivity}
                                headerTitle="Productivity"
                                contentLable={`${AnalyticsDetails?.total?.productivity}%`}
                                growth={AnalyticsDetails?.total?.productivity_growth}
                                fromDate={AnalyticsDetails?.from_date}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex mb-3">
                    <div className="px-[6px] w-7/12">
                        <div className="bg-white rounded-3xl border border-lineColor p-4 h-full">
                            <h3 className="text-xl font-bold -tracking-[0.4px]">Sales Analytics</h3>

                            {/* {AnalyticsDetails.sales_line_graph.length > 0 ? (
                                <AmChart salesLineGraph={AnalyticsDetails.sales_line_graph} />
                            ) : (
                                <EmptyMsgWithBtn
                                    title="No Sales Data"
                                    description="Sales Analytics will be available once clients start
                 booking appointments."
                                    btnLabel="Create booking"
                                    onClick={() => {}}
                                />
                            )} */}
                        </div>
                    </div>
                    <div className="px-[6px] w-5/12">
                        <PayStructure paymentStructureInfo={AnalyticsDetails.payment_structure} selectedStaff={selectedStaff} handleViewClick={() => {}} />
                    </div>
                </div>

                <div className="flex mb-3">
                    <div className="px-[6px] w-1/2">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <h3 className="text-xl font-bold -tracking-[0.4px]">Net Sales by Service</h3>
                            {checkFieldIsZero(AnalyticsDetails.sales_by_services, 'total_booking_service_amount') ? (
                                <EmptyMsgWithBtn
                                    title="No Net Sales by Service"
                                    description="Net Sales by Service will be available once clients start booking appointments.                                    "
                                    btnLabel="Create booking"
                                    onClick={() => {}}
                                />
                            ) : (
                                <NetSalesChartPie netSalesIncome={AnalyticsDetails.sales_by_services} timeFileterSelected={timeFileterSelected} />
                            )}
                        </div>
                    </div>

                    <div className="px-[6px] w-1/2">
                        <div className="bg-white rounded-3xl border border-lineColor p-4">
                            <h3 className="text-xl font-bold -tracking-[0.4px]">Payment method split</h3>
                            {checkFieldIsZero(AnalyticsDetails.payment_method_split, 'total') ? (
                                <EmptyMsgWithBtn title="You have no sales" description="Create an appointment to track sales" btnLabel="Create booking" onClick={() => {}} />
                            ) : (
                                <PaymentChartPie paymentMethod={AnalyticsDetails.payment_method_split} timeFileterSelected={timeFileterSelected} />
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex mb-3">
                    <div className="px-[6px] w-1/2">
                        <NetSalesIncome netSalesIncomeDetails={AnalyticsDetails.gross_revenue} />
                    </div>

                    {/* <div className="px-[6px] w-1/2">
                        <NetIncome netIncomeDetails={AnalyticsDetails.gross_revenue} />
                    </div> */}
                </div>
            </>
        )}
    </>
);

export default Professional;
