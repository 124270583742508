import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'src/constants/api';
import { axiosPost } from 'src/utils/requestClient';
import { IinitialState } from './VerifyOtp.interface';
import { RootState } from 'src/redux/store';

interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

const initialState: IinitialState = {
    data: '',
    loading: false,
    isForgotPassword: false,
};

export const verifyOtp = createAsyncThunk('shop/verify_otp', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.VERIFY_OTP, payload);
        if (response.data.status === 200) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data as ErrorType);
    }
});
export const verifyPhoneEmailOtp = createAsyncThunk('shop/phone-email/verify_otp', async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosPost(API.USER.LOGIN_SHOP_PHONE_EMAIL_VERIFY_OTP, payload);
        if (response.data.status === 200) {
            return response.data;
        }
        return rejectWithValue({
            data: response,
        } as any);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }

        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const verifyOtpSlice = createSlice({
    name: 'verify_otp',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(verifyOtp.pending, (state) => {
                state.loading = true;
            })
            .addCase(verifyOtp.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
                state.isForgotPassword = true;
            })
            .addCase(verifyOtp.rejected, (state, { payload }: any) => {
                state.loading = false;
                state.data = payload;
            });
    },
});

export const forgotPasswordInfo = (state: RootState) => state.verifyOtp.isForgotPassword;

export const selectVerifyOtpLoading = (state: RootState) => state.verifyOtp.loading;
