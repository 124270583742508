import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { userMe } from 'src/redux/services/common/Common.slice';
import SignupStaff from './SignupStaff';
import SignupAdmin from './SignupAdmin';

const Signup = () => {
    const user = useAppSelector(userMe);
    const [isStaff, setIsStaff] = useState(false);

    useEffect(() => {
        let isAdmins = true;
        let isStaffs = false;
        if (user && user.roles && user.roles.length > 0) {
            isStaffs = user.roles.some((role: any) => role.name === 'staff_rent' || role.name === 'staff_commission');
            isAdmins = user.roles.some((role: any) => role.name === 'owner' || role.name === 'individual');
        }
        setIsStaff(isStaffs && !isAdmins);
    }, [user]);

    return <>{isStaff ? <SignupStaff /> : <SignupAdmin />}</>;
};

export default Signup;
