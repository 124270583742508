import React from 'react';
import { IProps } from './Stepper.interface';

const Stepper: React.FC<IProps> = ({ steps, activeStep }) => (
    <div className="relative">
        {/* <div className="absolute left-[calc(7%-0.25rem)] top-3 bottom-4 w-0.5 bg-gray-300 z-0"></div> */}
        <ol className="relative z-10">
            {steps.map(({ name, step, subTitle, image: ImageName }: any, index) => (
                <li
                    key={index}
                    className={`stepper_li ${step !== steps?.length ? 'w-full' : ''}  ${
                        activeStep > step ? 'step_completed' : activeStep === 7 ? 'last_step step_inprogress' : activeStep === step ? 'step_inprogress' : ''
                    }`}
                >
                    {activeStep === step ? (
                        <div className="flex flex-col">
                            <div className="stepper_content">
                                <span className="step__round">{ImageName && <ImageName className="text-gray-700" />}</span>
                                <div className="flex flex-col ml-4">
                                    <div className="steper_text">{name}</div>
                                    <div className="stepper_subtext">{subTitle}</div>
                                </div>
                            </div>
                        </div>
                    ) : activeStep > step ? (
                        <div className="stepper_content">
                            <span className="step__round">{ImageName && <ImageName className="text-gray-700" />}</span>
                            <div className="flex flex-col ml-4">
                                <div className="steper_text">{name}</div>
                                <div className="stepper_subtext">{subTitle}</div>
                            </div>
                        </div>
                    ) : (
                        <div className="stepper_content">
                            <span className="step__round">{ImageName && <ImageName />}</span>
                            <div className="flex flex-col ml-4">
                                <div className="steper_text">{name}</div>
                                <div className="stepper_subtext">{subTitle}</div>
                            </div>
                        </div>
                    )}
                </li>
            ))}
        </ol>
    </div>
);

export default Stepper;
