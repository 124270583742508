import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="flex py-6 px-8 justify-between bg-white">
            <span className="text-gray-500 text-xs font-normal leading-[18px] -tracking-[0.12px]">@ {new Date().getFullYear()} getflair.ca</span>
            <div className="flex gap-6 leading-4">
                <NavLink to="https://www.getflair.ca/terms-of-service" target="_blank" className="font-normal text-xs text-primary -tracking-[0.12px]">
                    {t('Terms & Conditions')}
                </NavLink>
                <NavLink to="https://www.getflair.ca/privacy-policy" target="_blank" className="font-normal text-xs text-primary -tracking-[0.12px]">
                    {t('Privacy Policy')}
                </NavLink>
            </div>
        </footer>
    );
};

export default Footer;
