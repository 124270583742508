import React from 'react';
import { ICheckbox } from './Checkbox.interface';

const Checkbox = ({ name, labelName, checked, onChange, id, value }: ICheckbox) => (
    <div>
        <input type="checkbox" className="custom-round-checkbox" id={id} name={name} value={value} onChange={onChange} checked={checked} />
        <label className="custom-round-label" htmlFor={id}>
            {labelName || ''}
        </label>
    </div>
);

export default Checkbox;
