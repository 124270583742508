import React, { FC, useEffect, useState } from 'react';
import './../../../utils/Datatable.scss';
import './../../../utils/prime-react-datatable.scss';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { capitalizeFirstLetterSpace, getShortName } from 'src/utils/global-functions';
import { useAppSelector } from 'src/redux/hooks';
import { currentShop } from 'src/redux/services/common/Common.slice';
import { axiosGet } from 'src/utils/requestClient';
import { API } from 'src/constants/api';
import { errorCode } from 'src/constants/errorCode';
import { toast } from 'react-toastify';
import Drawer from 'src/components/Drawer';
import { useTranslation } from 'react-i18next';
import { IconArrowUp, IconArrowDown } from 'src/theme/Images';

interface IProps {
    isOpen: boolean;
    handleClose: () => void;
}
const StaffPerfomanceDrawer: FC<IProps> = ({ isOpen, handleClose }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [staffPerfomance, setStaffPerfomance] = useState<any[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const shop = useAppSelector(currentShop);
    const shopId = shop.id;
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
        type: 'all',
        filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        },
    });
    const loadLazyData = async () => {
        try {
            // setLoading(true);
            const payloadData = {
                data: { ...lazyState },
                param: {
                    shop_id: shopId,
                },
            };
            const response = await axiosGet(API.STAFF.PERFORMANCE, payloadData.param, payloadData.data);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setTotalRecords(response.data.data.totalRecords);
                setStaffPerfomance(response.data.data.data);
                setLoading(false);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            loadLazyData();
        }
    }, [lazyState, isOpen]);

    const GetProfessional = (row: any) => {
        const [isImageError, setIsImageError] = useState<boolean>(false);
        return (
            <div className="flex items-center">
                <figure className="NoImgName">
                    {row.profile_image_url && !isImageError ? (
                        <img src={row.profile_image_url} alt="client" className="w-full h-full object-cover" onError={() => setIsImageError(true)} />
                    ) : (
                        getShortName(`${row.Professional}`)
                    )}
                </figure>
                <div className="">
                    <p className="text-sm font-medium mb-[2px] leading-[140%] -tracking-[0.12px]">{capitalizeFirstLetterSpace(`${row?.full_name}`)}</p>
                    <p className="text-xs font-medium leading-[150%] -tracking-[0.12px] text-secondaryTxtColor">{row.role.name}</p>
                </div>
            </div>
        );
    };

    const GetProductivity = (row: any) => (
        <div className="flex items-center">
            <p className="text-sm font-medium mb-[2px] leading-[140%] -tracking-[0.12px]">{`${Math.abs(row.productivity)}%`}</p>
            <i className="text-xs font-medium leading-[150%] -tracking-[0.12px] text-secondaryTxtColor">
                <img src={row.productivity >= 0 ? IconArrowUp : IconArrowDown} alt="" className="w-[18px]" />
            </i>
        </div>
    );
    const GetEarning = (row: any) => (
        <div className="flex items-center">
            <p className="text-sm font-medium mb-[2px] leading-[140%] -tracking-[0.12px]">${row.bookings_sum_total ?? 0}</p>
        </div>
    );
    const customPaginatorTemplate = {
        layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink',
        CurrentPageReport: ({ first, last, totalRecords: totalRecordsNum }: { first: number; last: number; totalRecords: number }) => (
            <div className="text-mainTextColor flex items-center font-[SF-Pro] text-sm mr-auto">
                <span>
                    {first} - {last} of {totalRecordsNum}
                </span>
            </div>
        ),
    };
    const onPage = (event: any) => {
        event.page = event.page + 1;
        setlazyState(event);
    };
    return (
        <Drawer
            isOpen={isOpen}
            handleClose={handleClose}
            title={'Staff Perfomance'}
            isEditable
            isEditClose
            handleClickOutside={handleClose}
            classDrawer="your-custom-styles"
            drawerHeight="your-height-value"
        >
            <div className="pt-5 w-full">
                <DataTable
                    paginatorClassName="flex relative justify-end"
                    value={staffPerfomance}
                    lazy
                    first={lazyState.first}
                    rows={lazyState.rows}
                    paginatorTemplate={customPaginatorTemplate}
                    totalRecords={totalRecords}
                    paginator={totalRecords > lazyState.rows}
                    loading={loading}
                    onPage={onPage}
                    sortOrder={lazyState.sortOrder}
                    filters={lazyState.filters}
                    dataKey="id"
                    className="font-[SF-Pro] text-sm flex-1 text-mainTextColor rounded-3xl overflow-hidden fl-data-table"
                    globalFilterFields={[
                        'Professional',
                        'Bookings',
                        'Earnings',
                        // 'Productivity',
                    ]}
                    emptyMessage="No records found."
                >
                    <Column field="Professional" header={t('Professional')} body={GetProfessional} className=" font-normal text-mainTextColor"></Column>
                    <Column field="bookings_count" header={t('Bookings')} className=" font-medium text-mainTextColor"></Column>
                    <Column field="bookings_sum_total" header={t('Earnings')} className="text-mainTextColor" body={GetEarning}></Column>
                    <Column field="Productivity" header="Productivity" body={GetProductivity} className="font-medium text-mainTextColor"></Column>
                </DataTable>
            </div>
        </Drawer>
    );
};

export default StaffPerfomanceDrawer;
